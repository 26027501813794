import React from 'react'
import { View } from 'dripsy'
import BookingRequestListSmallItem from './item'
import { BigCalendarBookingRequest } from '../bookings-big-calendar'

type Props = {
  bookingRequests: BigCalendarBookingRequest[] | null
  onPressBookingRequest?: (bookingRequest: BigCalendarBookingRequest) => void
  highlightedBookingRequestId?: string
} & React.ComponentProps<typeof View>

export default function BookingRequestListSmall(props: Props) {
  const {
    sx = {},
    bookingRequests,
    onPressBookingRequest,
    highlightedBookingRequestId,
  } = props

  return (
    <View sx={sx}>
      {bookingRequests?.map((bookingRequest, i) => (
        <BookingRequestListSmallItem
          isHighlighted={bookingRequest.id === highlightedBookingRequestId}
          key={bookingRequest.id}
          bookingRequest={bookingRequest}
          onPress={onPressBookingRequest}
          isFirst={!i}
        />
      ))}
    </View>
  )
}
