import React from 'react'
import { MotiPressable, useMotiPressables } from 'moti/interactions'
import Ionicons, { IconsBaseProps } from '@beatgig/design/components/ionicons'
import { styled, View, ActivityIndicator } from 'dripsy'
import { MotiView } from 'moti'
import { StyleSheet } from 'react-native'

const Pressable = styled(MotiPressable)(() => ({
  size: 32,
  borderRadius: 2,
  overflow: 'hidden',
  justifyContent: 'center',
  alignItems: 'center',
  mr: 3,
}))

const Bg = styled(MotiView)({
  bg: 'muted7',
})

type Props = {
  name: IconsBaseProps['name']
  onPress?: () => void
  loading?: boolean
  size?: number
}

const Background = ({ pressable }: { pressable: boolean }) => {
  const state = useMotiPressables(
    (containers) => {
      'worklet'

      const pressed =
        containers.icon?.value?.pressed || containers.panel?.value?.pressed
      const hovered =
        containers.icon?.value?.hovered || containers.panel?.value?.hovered

      if (!pressable) {
        return {
          opacity: 0,
        }
      }

      return {
        opacity: pressed ? 0.6 : hovered ? 0.2 : 0,
      }
    }
    // [pressable]
  )
  return (
    <Bg
      state={state}
      delay={0}
      transition={React.useMemo(
        () => ({
          type: 'timing',
          duration: 200,
        }),
        []
      )}
      style={StyleSheet.absoluteFill}
    />
  )
}

export function PromoStudioIconButton({
  name,
  onPress,
  loading = false,
  size = 18,
}: Props) {
  const pressable = !!onPress

  return (
    <Pressable
      sx={{ cursor: onPress ? 'pointer' : 'default' }}
      onPress={onPress}
      id="icon"
      disabled={loading}
    >
      <Background pressable={pressable} />
      <Ionicons
        name={name}
        size={size}
        color={loading ? 'transparent' : undefined}
      />
      {loading && (
        <View
          sx={{ justifyContent: 'center', alignItems: 'center' }}
          style={StyleSheet.absoluteFill}
        >
          <ActivityIndicator color="text" />
        </View>
      )}
    </Pressable>
  )
}
