import React from 'react'
import { View, H5, Text, Sx } from 'dripsy'


import Card from '@beatgig/design/components/card'
import {
  AUTO_BOOKINGS_FEATURE_ING_NOUN,
} from '@beatgig/constants/auto-bookings'
import { APP_NAME } from '@beatgig/constants'
import Spacer from '@beatgig/design/components/spacer'
// import LottieAlgorithmPeople from '../lottie-people'

type Props = {
  // recommendedBookings: DisplayRecommendedBooking[] | null
  // onPressCreate: () => void
  venueName?: string
  sx?: Sx
}

export default function RecommendedBookingsExplanationCard(props: Props) {
  const { sx, venueName } = props

  //   if (!recommendedBookings) {
  //     return null
  //   }

  //   if (recommendedBookings.length >= 2) {
  //     return null
  //   }
  const title = `How it works`
  const message = `Fill out the form below, and based on your preferences, ${APP_NAME} will recommend the best artists to book ${
    venueName ? `at ${venueName}` : ''
  } that are confirmed available and within budget.`

  // const message = `Our algorithm will recommend the best artists to book based on your date, budget, genre, location, and more.`
  const actionText = `Your ${APP_NAME} support representative will notify you when we've selected artists for your review.`

  const buttonText = `Create ${AUTO_BOOKINGS_FEATURE_ING_NOUN}`

  //   if (recommendedBookings.length === 1) {
  //     title = `Congratulations!`
  //     message = `You've created your first ${AUTO_BOOKINGS_FEATURE_ING_NOUN} request.`
  //     actionText = `We'll notify you when your recommended artists are ready for your review. When your recommendations are ready, they will show up on this screen.`
  //     buttonText = `Create Another`
  //   }

  const textAlign = 'left'

  return (
    <Card sx={sx}>
      <Card.Content sx={{ p: 3 }}>
        <View sx={{ flexDirection: [null, 'row'] }}>
          <View sx={{ flex: 1, maxWidth: 500 }}>
            <H5 sx={{ textAlign }}>{title}</H5>
            <Text
              sx={{
                color: 'mutedText',
                fontSize: 3,
                lineHeight: 4,
                textAlign,
                // mb: 3,
              }}
            >
              {message}
            </Text>
            <Spacer />
            <Text
              sx={{
                color: 'mutedText',
                fontSize: 3,
                lineHeight: 4,
                textAlign,
                // mb: 3,
              }}
            >
              {`Once you press "Create", you can track the status of your recommended booking on your calendar.`}
            </Text>
            {/* <Text
              sx={{
                color: 'mutedText',
                fontSize: 3,
                lineHeight: 4,
                textAlign,
                // mb: 3,
              }}
            >
              Bookings are not binding until you approve the artists.
            </Text> */}
          </View>
        </View>
      </Card.Content>
      <Card.Footer sx={{ justifyContent: 'space-between', bg: 'muted' }}>
        <Text sx={{ color: 'text', textAlign }}>{actionText}</Text>
      </Card.Footer>
    </Card>
  )
}
