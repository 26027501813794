import { useRouting } from 'expo-next-react-navigation'
import PublicRoutes from '../routes/public-routes'

export default function useNavigateToVenue() {
  const { navigate } = useRouting()

  return {
    navigate: ({ slug }: { slug: string }) => {
      navigate(PublicRoutes.venue({ slug }))
    },
  }
}
