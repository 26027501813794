import { BookingStatus } from '@beatgig/api-services/booking'
import useBookingStatusToColor from '@beatgig/hooks/use-booking-status-to-color'
import useEnumFilter from '@beatgig/search/filters/enum/use-enum-filter'

export default function useBookingStatusFilter() {
  const { bookingStatusToColor } = useBookingStatusToColor()

  const variants = Object.keys(BookingStatus).reduce(
    (acc, status) => ({
      ...acc,
      [status]: bookingStatusToColor(BookingStatus[status], {
        isActionRequired: false,
      }).variant,
    }),
    {}
  )

  return useEnumFilter(BookingStatus, 'status', {
    variants,
    title: 'Status',
    persistKey: 'booking-status',
  })
}
