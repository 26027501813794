import usePost, { UsePostOptions } from './use-post'
import { RecommendedBookingApi } from '@beatgig/api/recommended-booking'
import Analytics from '@beatgig/analytics/typez'

export default function useCancelRecommendedBooking(
  options?: UsePostOptions<
    typeof RecommendedBookingApi.cancelRecommendedBooking
  >
) {
  return usePost(
    (
      props: Parameters<
        typeof RecommendedBookingApi.cancelRecommendedBooking
      >[0]
    ) => {
      const { recommendedBookingId } = props
      Analytics.track('Cancel Recommended Booking', {
        recommendedBookingId,
      })
      return RecommendedBookingApi.cancelRecommendedBooking(props)
    },
    options
  )
}
