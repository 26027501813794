import HoverTooltip from '@beatgig/components/hover-tooltip'
import { DripsyTheme } from '@beatgig/theme'
import React from 'react'
import FilterPopover from '../filter-popover'
import BooleanFilter from './boolean-filter'

type Props = {
  // label: React.ReactNode | string
  tooltipProps?: Omit<
    React.ComponentProps<typeof HoverTooltip>,
    'children' | 'text'
  >
  hideCount?: boolean
  booleanProps: React.ComponentProps<typeof BooleanFilter>
  mode?: React.ComponentProps<typeof FilterPopover>['mode']
}

export default function BooleanFilterPopover({
  booleanProps,
  mode,
  ...props
}: Props) {
  const onSelected = booleanProps.booleanState.on.selected
  const offSelected = booleanProps.booleanState.off.selected

  let { title } = booleanProps

  const onOnly = onSelected && !offSelected
  const offOnly = !onSelected && offSelected

  let variant: keyof DripsyTheme['colors'] | undefined

  if (onOnly && booleanProps.booleanState.on.title) {
    const { on } = booleanProps.booleanState
    title = on.title
    if (on.variant) {
      variant = on.variant
    }
  }
  if (offOnly && booleanProps.booleanState.off.title) {
    const { off } = booleanProps.booleanState
    title = off.title
    if (off.variant) {
      variant = off.variant
    }
  }

  return (
    <FilterPopover
      {...props}
      mode={mode}
      color={variant}
      selectedCount={booleanProps.selectedCount}
      totalCount={Object.keys(booleanProps.booleanState).length}
      hideCount
      label={title}
      description={booleanProps.description}
      filter={<BooleanFilter {...booleanProps} />}
    />
  )
}
