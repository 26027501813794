import { VerticalAlign } from '@beatgig/api-services'
import Button from '@beatgig/components/button'
import { useFieldFast } from '../hooks/use-fast-field'
import { useOpenImagePickerModal } from '@beatgig/navigation/hooks/use-image-picker-modal'
import { View, Text } from 'dripsy'
import Press from '@beatgig/components/press'
import { ArtistCoverImage } from '@beatgig/ui/artist-cover-image'
import { ErrorMessage } from 'formik'
import ErrorText from './error'
import { FieldName } from './types'
import { ImageSmall, ImageCover } from '@beatgig/api-services/image'
import { ScrollToField } from './scroll-to-field'
import { WithoutTypename } from '@beatgig/gql/types/without-typename'

type Props<T extends object> = {
  name: FieldName<T>
  suggestedImages?: ImageSmall[]
  subtitle?: string
  addText?: string
}

export default function CoverImageField<T extends object>({
  name: nameProp,
  suggestedImages,
  subtitle = 'Adjust your screen width to see how your cover image will appear on different sizes.',
  addText = 'Add cover photo',
}: Props<T>) {
  const name = Array.isArray(nameProp) ? nameProp.join('.') : nameProp
  const [{ value: coverImage }, , { setValue }] = useFieldFast<
    WithoutTypename<ImageCover> | undefined
  >(name as string)
  // const coverImage = getIn(values, 'cover_image')
  const url =
    coverImage?.url ||
    'https://media.idownloadblog.com/wp-content/uploads/2020/06/macOS-Big-Sur-Vector-Wave-Wallpaper-iDownloadBlog.jpg'

  const { open } = useOpenImagePickerModal(name as string, {
    onSelect: (images) => {
      const img = images[0]
      if (img) {
        setValue({
          height: img.height,
          width: img.width,
          url: img.url,
          publicCloudinaryId: img.public_cloudinary_id,
          verticalAlign: coverImage?.verticalAlign || VerticalAlign.CENTER,
        })
      }
    },
    suggestedImages,
    title: 'Cover Photo',
  })

  const onChangeVerticalAlign = (verticalAlign: VerticalAlign) => {
    if (!coverImage) return

    setValue({ ...coverImage, verticalAlign })
  }

  const renderButtonGroup = () => {
    const shouldShowVerticalAlignEditor = !!(
      coverImage?.url &&
      coverImage.height &&
      coverImage.width
    )

    if (!shouldShowVerticalAlignEditor) return null
    return (
      <View sx={{ flexDirection: 'row' }}>
        {Object.values(VerticalAlign ?? {}).map((align, i) => {
          const isLast = i === Object.keys(VerticalAlign)?.length - 1
          const isFirst = i === 0
          const radius = 2
          const isSelected = align === coverImage?.verticalAlign
          return (
            <Button
              key={align}
              sx={{
                textTransform: 'capitalize',
                borderWidth: 1,
                fontWeight: '500',
                px: 2,
                py: 1,
                borderRadius: 0,
                borderLeftWidth: isLast ? 0 : 1,
                borderRightWidth: isFirst ? 0 : 1,
                borderTopRightRadius: isLast ? radius : 0,
                borderBottomLeftRadius: isFirst ? radius : 0,
                borderBottomRightRadius: isLast ? radius : 0,
                borderTopLeftRadius: isFirst ? radius : 0,
                fontSize: 1,
              }}
              variant={isSelected ? 'small' : 'smallOutlined'}
              onPress={() => onChangeVerticalAlign(align)}
            >
              {align}
            </Button>
          )
        })}
      </View>
    )
  }

  return (
    <View>
      <ScrollToField name={name as string} />
      <Press onPress={open}>
        <ArtistCoverImage.Container>
          <ArtistCoverImage.Image
            verticalAlign={coverImage?.verticalAlign}
            source={{ uri: url }}
          />
        </ArtistCoverImage.Container>
      </Press>
      <View
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          my: 2,
          alignItems: 'center',
        }}
      >
        {/* <FormLabel sx={{ mt: 2, color: 'text' }}>Cover Photo</FormLabel> */}
        {renderButtonGroup()}
      </View>
      <Text sx={{ color: 'mutedText', mb: 2 }}>{subtitle}</Text>
      {coverImage?.height && coverImage?.width && (
        <Text sx={{ color: 'mutedText', mt: 2 }}>
          This {`image`} is {coverImage?.width}x{coverImage?.height}.
        </Text>
      )}
      {!!coverImage?.url && (
        <Text
          sx={{ mt: 2, color: 'primary' }}
          onPress={() => {
            setValue(undefined)
          }}
        >
          Remove cover photo
        </Text>
      )}
      {!coverImage?.url && (
        <Text sx={{ mt: 2, color: 'primary' }} onPress={open}>
          {addText}
        </Text>
      )}
      <ErrorMessage name={name as string}>
        {(message) => <ErrorText>{message}</ErrorText>}
      </ErrorMessage>
    </View>
  )
}
