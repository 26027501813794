import React from 'react'
import PublicRoutes from '../routes/public-routes'
import { Link } from 'solito/link'

type Props = {
  children: React.ReactNode
  slug: string
}

export default function LinkToVenue({ slug, children }: Props) {
  const { path, as } = PublicRoutes.venue({ slug }).web
  return (
    <Link href={path} as={as}>
      {children}
    </Link>
  )
}
