import { useRoute, useNavigation } from '@react-navigation/native'
import { useCallback } from 'react'

type Callback = {
  callback: {
    routeName: string
    paramKey: string
  }
  title?: string
}

export default function useCloseModalCallback<T = any>() {
  const route = useRoute()
  const {
    callback: { routeName, paramKey },
    title,
  } = route.params as Callback
  const { navigate, goBack } = useNavigation()

  const submit = useCallback(
    (callback: T) => {
      // @ts-expect-error RN types
      navigate({
        name: routeName,
        params: { [paramKey]: callback },
        merge: true,
      })
    },
    [navigate, paramKey, routeName]
  )

  return {
    submit,
    cancel: goBack,
    title,
  }
}
