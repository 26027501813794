import type { Rename } from '@beatgig/helpers/types/rename'

export type FirstArg<T extends (t: any) => any> = Parameters<T>[0]

export type RequestBody<
  T extends ({ requestBody: any }) => any
> = FirstArg<T>['requestBody']

export type RenameRequestBody<
  F extends (t: any) => any,
  NewName extends string
> = Rename<Parameters<F>[0], 'requestBody', NewName>

type NotKey<T extends object, Path> = Path extends keyof T ? never : string

type RenameAdminOnBehalfOf<
  F extends (t: any) => any,
  Required extends boolean
> = Omit<Parameters<F>[0], 'adminOnBehalfOf'> &
  (Required extends true ? { userId: string } : { userId?: string })

export type SWRKeyProps<
  Fetcher extends (props: any) => any
> = Parameters<Fetcher>[0]

export function renameAdminOnBehalfOf<
  F extends (t: { adminOnBehalfOf?: string }) => any,
  Required extends boolean = true
>(
  func: F,
  // no-op, just there so we can declaratively type
  config?: { required: Required }
): (props: RenameAdminOnBehalfOf<F, Required>) => ReturnType<F> {
  return ({
    userId: adminOnBehalfOf,
    ...props
  }: RenameAdminOnBehalfOf<F, Required>) => {
    return func({
      ...props,
      adminOnBehalfOf,
    })
  }
}

export function renameRequestBody<
  F extends (t: { requestBody: any }) => any,
  P extends string,
  Path extends NotKey<Parameters<F>[0], P>
>(func: F, key: Path): (props: RenameRequestBody<F, Path>) => ReturnType<F> {
  return ({ [key]: requestBody, ...props }: RenameRequestBody<F, Path>) => {
    return func({
      ...props,
      requestBody,
    })
  }
}

export type ValueOf<
  T,
  Key = keyof T extends number | symbol ? never : keyof T
> = Key extends keyof T ? T[Key] : never
