// https://github.com/react-navigation/react-navigation/blob/860adbfd8b4c493dc3e28c99ad5d78699c2f8797/packages/stack/src/TransitionConfigs/CardStyleInterpolators.tsx#L271
import { Animated } from 'react-native'
const { add } = Animated

import type {
  StackCardInterpolationProps,
  StackCardInterpolatedStyle,
} from '@react-navigation/stack'
import conditional from './conditional'

export default function forScaleFromCenterWeb({
  current,
  next,
  closing,
}: StackCardInterpolationProps): StackCardInterpolatedStyle {
  const progress = add(
    current.progress.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 1],
      extrapolate: 'clamp',
    }),
    next
      ? next.progress.interpolate({
          inputRange: [0, 1],
          outputRange: [0, 1],
          extrapolate: 'clamp',
        })
      : 0
  )

  const opacity = next
    ? 1
    : progress.interpolate({
        inputRange: [0, 0.75, 0.875, 1, 1.0825, 1.2075, 2],
        outputRange: [0, 0, 1, 1, 1, 1, 0],
      })

  const scale = conditional(
    closing,
    current.progress.interpolate({
      inputRange: [0, 1],
      outputRange: [1.1, 1],
      extrapolate: 'clamp',
    }),
    // 1
    progress.interpolate({
      inputRange: [0, 1, 2],
      outputRange: [0.85, 1, 1.1].reverse(),
    })
  )

  return {
    containerStyle: {
      opacity,
      transform: [{ scale: next ? 1 : scale }],
    },
  }
}
