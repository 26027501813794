import CreateOrSelectVenueForm from '@beatgig/ui/create-or-select-venue-form'
import React from 'react'
import { useCreateBookingRequestRoute } from './use-create-booking-request-route'

import { Billing, IndoorsOrOutdoors } from '@beatgig/api-services/booking'
import { DateTime } from 'luxon'
import { CreateBookingRequestForm } from '../../create'

export function SmartCreateBookingRequestRoute() {
  const route = useCreateBookingRequestRoute()

  const {
    venues,
    onSelectVenue,
    getVenuesError,
    goBack,
    onSubmit,
    createError,
    startDate: _startDate,
    selectedVenue,
  } = route

  if (!selectedVenue) {
    return (
      <CreateOrSelectVenueForm
        venues={venues ?? null}
        onSelectVenue={onSelectVenue}
        onClose={goBack}
        venuesError={getVenuesError}
        selectVenueDescription="First, choose the venue for your artist recommendations."
      />
    )
  }

  let startDate: typeof _startDate

  if (_startDate) {
    const proposedStartDate = DateTime.fromObject(_startDate)
    if (proposedStartDate.isValid) {
      const now = DateTime.fromObject({})
      const isProposedStartDateInFuture = proposedStartDate > now
      if (isProposedStartDateInFuture) {
        startDate = _startDate
      }
    }
  }

  return (
    <CreateBookingRequestForm
      onCreate={onSubmit}
      onClose={goBack}
      venueName={selectedVenue.name}
      venueLocation={selectedVenue.location}
      networkError={createError}
      initialBookingRequest={{
        genres: [],
        billing: Billing.HEADLINER,
        indoorsOrOutdoors: IndoorsOrOutdoors.INDOORS,
        startTimes: [
          DateTime.fromObject({
            hour: 21,
            minute: 0,
            second: 0,
            ...startDate,
          })
            .setZone(selectedVenue.location.timezone, {
              keepLocalTime: true,
            })
            .toJSDate()
            .toISOString(),
        ],
        adminInstructions: '',
        artistInstructions: '',
        bandConfigurationOptions: [],
        publicEventDescription: '',
        // @ts-expect-error initialize it empty in the form, it's ok
        performanceLengthMins: undefined,
      }}
    />
  )
}
