import { VenueSelectorsBoxesFilter } from '@beatgig/search/components/venue-selectors-boxes'
import React from 'react'
import { View, Text } from 'dripsy'

type Props = React.ComponentProps<typeof VenueSelectorsBoxesFilter>

export function SmartSmallCalendarScreenFilters({
  onPressCreateVenue,
  selectedVenues,
}: Props) {
  console.log('[selected-venues]', selectedVenues)
  return (
    <View>
      <Text sx={{ pb: 3, pt: 2, textAlign: 'center', color: 'mutedText' }}>
        Select the venues to view on your calendar.
      </Text>
      <VenueSelectorsBoxesFilter
        onPressCreateVenue={onPressCreateVenue}
        selectedVenues={selectedVenues}
      />
    </View>
  )
}
