import create from 'zustand'

type By = 'artist' | 'venue'

export const useQueryAdminCalBy = create<{
  by: By
  setBy: (by: By) => void
}>()((set) => ({
  by: 'artist' as By,
  setBy: (by: By) => set({ by }),
}))
