import React, { useMemo } from 'react'
import { View, Text } from 'dripsy'
import Press from '@beatgig/components/press'
import { getDisplayStartTime } from '@beatgig/helpers/display-booking-start-time'
import numeral from 'numeral'
import Dot from '@beatgig/design/components/dot'
import Avatar from '@beatgig/design/components/avatar'
import Cloudinary from '@beatgig/helpers/cloudinary'
import AvatarGroup from '@beatgig/design/components/avatar/avatar-group'
import HoverTooltip from '@beatgig/components/hover-tooltip'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import isFuture from 'date-fns/isFuture'
import HoverPulse from '@beatgig/components/hover-pulse/index'
import { BidStatus } from '@beatgig/api-services/booking-request'
import { useBookingRequestStatusColor } from '@beatgig/hooks/use-booking-request-status-to-color'

// https://github.com/date-fns/date-fns/issues/1706#issuecomment-836601089
import locale from 'date-fns/locale/en-US'
import { BigCalendarBookingRequest } from '../bookings-big-calendar/types'
const formatDistanceLocale = {
  lessThanXSeconds: '{{count}}s',
  xSeconds: '{{count}}s',
  halfAMinute: '30s',
  lessThanXMinutes: '{{count}}m',
  xMinutes: '{{count}}m',
  aboutXHours: '{{count}}h',
  xHours: '{{count}}h',
  xDays: '{{count}}d',
  aboutXWeeks: '{{count}}w',
  xWeeks: '{{count}}w',
  aboutXMonths: '{{count}}m',
  xMonths: '{{count}}mo',
  aboutXYears: '{{count}}y',
  xYears: '{{count}}y',
  overXYears: '{{count}}y',
  almostXYears: '{{count}}y',
}

type BookingRequest = BigCalendarBookingRequest

type Props = {
  bookingRequest: BookingRequest
  onPress?: (bookingRequest: BookingRequest) => void
  isFirst: boolean
  isHighlighted?: boolean
} & React.ComponentProps<typeof View>

export default React.memo(function BookingRequestListSmallItem(props: Props) {
  const { sx = {}, bookingRequest, onPress, isFirst, isHighlighted } = props

  const onPressBookingRequest = () => {
    onPress?.(bookingRequest)
  }

  const { timeWithCustomFormat } = getDisplayStartTime({
    startTime: bookingRequest.start_time,
    timezone: bookingRequest.venue_location.timezone,
  })

  const maybeRenderArtists = useMemo(() => {
    const bids = bookingRequest.bids
    const bidCount = bids?.length

    if (!bidCount) return null

    return (
      <HoverTooltip
        text={
          !bookingRequest.booking?.id &&
          `${bidCount} recommended ${bidCount > 1 ? 'artists' : 'artist'}`
        }
        placement="bottom"
        anchor="left"
        bg="text"
        sx={{ mb: 3 }}
      >
        <AvatarGroup>
          {bids?.map((bid) => {
            const { artist } = bid
            if (bid.status !== BidStatus.PENDING) {
              return null
            }
            return (
              <Avatar
                key={artist.id}
                uri={Cloudinary(artist?.profileImage, { width: 100 })}
                size="small"
                Container={View}
              />
            )
          })}
        </AvatarGroup>
      </HoverTooltip>
    )
  }, [bookingRequest.bids, bookingRequest.booking])

  const budgetText = `${
    bookingRequest.budget?.low
      ? `${numeral(bookingRequest.budget?.low).format('$0,0')}`
      : ''
  }${bookingRequest.budget?.high && bookingRequest.budget.low ? ` - ` : ''}${
    bookingRequest.budget?.high
      ? `${numeral(bookingRequest.budget?.high).format('$0,0')}`
      : ''
  }`

  const { bids } = bookingRequest

  const acceptedArtist = bids?.find(
    (artistRec) => artistRec.status === BidStatus.ACCEPTED
  )
  let acceptedPriceText = ''

  if (acceptedArtist?.buyerPrice) {
    acceptedPriceText = numeral(acceptedArtist.buyerPrice).format('$0,0')
  }

  const { status, buyer_frontend_copy, isActionRequired } = bookingRequest
  const { color } = useBookingRequestStatusColor({
    status,
    isActionRequired,
    isPastEvent: !isFuture(new Date(bookingRequest.start_time)),
  })

  let textColor = 'text'
  let backgroundColor = 'background'
  let mutedTextColor = 'muted6'
  let borderColor = 'border'

  if (isHighlighted) {
    textColor = 'text'
    backgroundColor = 'muted'
    mutedTextColor = 'muted7'
    borderColor = 'text'
  }

  return (
    <HoverPulse scaleTo={1.02}>
      <Press
        disabled={!onPress}
        onPress={onPress && onPressBookingRequest}
        sx={{
          p: 3,
          borderRadius: 3,
          borderColor,
          borderWidth: 1,
          mt: isFirst ? 0 : 3,
          bg: backgroundColor,
          ...sx,
        }}
      >
        {maybeRenderArtists}
        {!!buyer_frontend_copy?.short_message?.body && (
          <View sx={{ zIndex: -1 }}>
            <Dot textSx={{ color: textColor }} variant={color as any}>
              {buyer_frontend_copy?.short_message?.body}
            </Dot>
            {!!bookingRequest.last_updated && (
              <Text sx={{ color: mutedTextColor }}>
                {timeWithCustomFormat('MMMM d')}
                {/*  */}
              </Text>
            )}
          </View>
        )}
        <View
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            mt: 3,
            borderTopWidth: '1',
            borderTopColor: 'border',
            pt: 3,
            alignItems: 'center',
          }}
        >
          <View
            sx={{
              flex: 1,
            }}
          >
            <Text sx={{ color: textColor }}>
              {acceptedPriceText || budgetText}
            </Text>
          </View>
          <View>
            <Text sx={{ color: mutedTextColor, textAlign: 'right' }}>
              {formatDistanceToNow(new Date(bookingRequest.last_updated), {
                addSuffix: true,
                includeSeconds: false,
                locale: {
                  ...locale,
                  formatDistance(token, count, options) {
                    options = options || {}

                    const result = formatDistanceLocale[token].replace(
                      '{{count}}',
                      count
                    )

                    if (options.addSuffix) {
                      if (options.comparison > 0) {
                        return 'in ' + result
                      } else {
                        return result + ' ago'
                      }
                    }

                    return result
                  },
                },
              }).replace('about', '')}
              {/* {timeWithCustomFormat('MMMM dd')} */}
            </Text>
          </View>
        </View>
      </Press>
    </HoverPulse>
  )
})
