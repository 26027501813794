import React from 'react'
import { BookingsSmallCalendarListItem } from './item'

// export const BookingSmallCalendarListPlaceholder = [
//   <BookingsSmallCalendarListItem loading key={'loading-0'} />,
//   <BookingsSmallCalendarListItem loading key={'loading-1'} />,
//   <BookingsSmallCalendarListItem loading key={'loading-2'} />,
// ]

export const BookingSmallCalendarListPlaceholder = () => (
  <>
    {new Array(3).fill('').map((_, i) => (
      <BookingsSmallCalendarListItem
        loading
        index={i}
        key={i + 'placeholder'}
      />
    ))}
  </>
)
