import React from 'react'
import { View, H5, Text } from 'dripsy'
import Card from '@beatgig/design/components/card'
import { APP_NAME } from '@beatgig/constants'

export function ExternalBookingBuyerExplanation() {
  const title = `How it works`
  const message = `If you've made bookings outside of ${APP_NAME}, you can import them here. 
 
Imported bookings will show up on your ${APP_NAME} calendar.`

  return (
    <Card sx={{ mb: 3 }}>
      <Card.Content>
        <View sx={{ flexDirection: [null, 'row'] }}>
          <View sx={{ flex: 1, maxWidth: 500 }}>
            <H5 sx={{ fontSize: 4 }}>{title}</H5>
            <Text
              sx={{
                color: 'mutedText',
                fontSize: 3,
                lineHeight: 4,
              }}
            >
              {message}
            </Text>
          </View>
        </View>
      </Card.Content>
    </Card>
  )
}
