import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { CalendarStackParams, CalendarStackProps } from './types'
import BookingCalendarScreen from '@beatgig/screens/booking-calendar-screen'
import EditRecommendedBookingScreen from '@beatgig/screens/edit-recommended-booking-screen'
import AddRecommendedArtistsScreen from '@beatgig/screens/add-recommended-artists-screen'
import BookingPreviewScreen from '@beatgig/screens/booking-preview-screen'
import CreateExternalBookingScreen from '@beatgig/screens/create-external-booking'
import EditExternalBookingScreen from '@beatgig/screens/edit-external-booking'
import CreateVenueScreen from '@beatgig/screens/create-venue-screen'
import WebStackContainer from '../../components/web-stack-container'
import forScaleFromCenterWeb from '../../interpolators/for-scale-from-center-web'
import VenuePromptEmbedScreen from '@beatgig/screens/venue-prompt-embed-screen'
import MediaPickerScreen from '@beatgig/screens/media-picker-screen'
import AdminCalendarScreen from '@beatgig/screens/admin-calendar-screen'
import AdminUpdateRecommendedArtistsScreen from '@beatgig/screens/update-recommended-artists-screen'
import { CalendarScreen } from '@beatgig/screens/calendar-screen'
import { SmartCreateBookingRequestRoute } from '@beatgig/app-forms/booking-requests/smart/create'
import { SmartUpdateBookingRequestRoute } from '@beatgig/app-forms/booking-requests/smart/update'
import { BookingActionsRequiredScreen } from '@beatgig/screens/booking-actions-required-screen'

const { Navigator, Screen } = createStackNavigator<CalendarStackParams>()

const CalendarStack = React.memo(function CalendarStackWrapped(
  props: CalendarStackProps
) {
  return (
    <WebStackContainer>
      <Navigator
        {...props}
        screenOptions={{
          headerShown: false,
          cardStyle: {
            flex: 1,
            backgroundColor: 'transparent',
          },
          presentation: 'modal',
          detachPreviousScreen: false,

          animationEnabled: true,
          cardStyleInterpolator: forScaleFromCenterWeb,
        }}
      >
        <Screen name="calendar" component={BookingCalendarScreen} />
        {/* <Screen
          name="CreateRecommendedBooking"
          component={CreateRecommendedBookingScreen}
        /> */}
        <Screen
          name="EditRecommendedBooking"
          component={EditRecommendedBookingScreen}
        />
        <Screen name="BookingPreview" component={BookingPreviewScreen} />
        <Screen
          name="AddArtistsToRecommendedBooking"
          component={AddRecommendedArtistsScreen}
        />
        <Screen name="ImagePicker" component={MediaPickerScreen} />
        <Screen
          name="CreateExternalBooking"
          component={CreateExternalBookingScreen}
        />
        <Screen
          name="EditExternalBooking"
          component={EditExternalBookingScreen}
        />
        <Screen
          name="VenuePromptEmbedScreen"
          component={VenuePromptEmbedScreen}
        />
        <Screen name="CreateVenue">
          {() => (
            <CreateVenueScreen
              shouldGoBackOnCreate
              shouldRedirectToVenueOnCreate={false}
            />
          )}
        </Screen>
        <Screen name="AdminAllShowsCalendar" component={AdminCalendarScreen} />
        <Screen
          name="AdminUpdateRecommendedArtists"
          component={AdminUpdateRecommendedArtistsScreen}
          options={{
            title: 'Update Artists',
          }}
        />
        <Screen
          name="UpdateBookingRequest"
          component={SmartUpdateBookingRequestRoute}
        />
        <Screen
          name="CreateBookingRequest"
          component={SmartCreateBookingRequestRoute}
        />
        <Screen name="BuyerCalendar" component={CalendarScreen} />
        <Screen
          name="BookingActionsRequired"
          component={BookingActionsRequiredScreen}
        />
      </Navigator>
    </WebStackContainer>
  )
})

export default CalendarStack
