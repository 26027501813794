import React from 'react'

import AuthenticatedPage from '@beatgig/auth-components/authenticated-page'
import { UserRole } from '@beatgig/api-services/user'
import { Platform, useWindowDimensions } from 'react-native'

import Router from 'next/router'
import globalSelectedVenueState from '@beatgig/helpers/global-selected-venue-state'
import AdminOnBehalfOfVenueContainer from '@beatgig/auth-components/admin-on-behalf-of-venue-container'
import { getBreakpoint } from '@beatgig/helpers/get-breakpoint'
import { SmallCalendarScreen } from '../small-calendar-screen'
import { CalendarScreen } from '../calendar-screen'

type Props = {
  userId: string
  userName: string
}

const setWebParams = (venueId?: string) => {
  if (Platform.OS === 'web') {
    const { query = {} } = Router
    if (venueId) {
      query.venue = venueId
    } else {
      delete query.venue
    }
    Router.push(
      {
        pathname: Router.pathname,
        query,
      },
      undefined,
      {
        shallow: true,
      }
    )
  }
}

const onSelectUser = () => {
  globalSelectedVenueState.setGlobalState('selectedVenueId', '')
  setWebParams()
}

const BookingCalendarScreen = React.memo(function BookingCalendarScreen() {
  const { width } = useWindowDimensions()
  const breakpoint = getBreakpoint(width)
  return (
    <AuthenticatedPage showsPendingBanner={false}>
      <AdminOnBehalfOfVenueContainer
        userRole={UserRole.BUYER}
        onChangeUser={onSelectUser}
        onClearUser={onSelectUser}
        unselectedUserText={`This will show you their bookings, recommended bookings, and make their venues searchable.`}
      >
        {({ userId, userName, userRole, isUserApproved }) => {
          // it breaks when we change this for admins, idk
          // this will never be the case for normal users
          if (!userId || !userRole) return null

          // if (userRole === UserRole.SELLER) {
          //   // hack work-around for deep linking on native, which uses the calendar screen as the root of the stack
          //   // however, the calendar isn't available for sellers, so we redirect to the bookings list
          //   const BookingsScreen = (require('@beatgig/screens/bookings-screen') as typeof import('@beatgig/screens/bookings-screen'))
          //     .default
          //   return (
          //     <BookingsScreen.Authenticated
          //       isUserApproved={isUserApproved}
          //       userRole={userRole}
          //       userId={userId}
          //     />
          //   )
          // }

          if (breakpoint < 2 || Platform.OS !== 'web') {
            return (
              <SmallCalendarScreen
                userRole={userRole}
                userName={userName}
                userId={userId}
              />
            )
          }

          return (
            <CalendarScreen
              userRole={userRole}
              userId={userId}
              userName={userName}
            />
          )
        }}
      </AdminOnBehalfOfVenueContainer>
    </AuthenticatedPage>
  )
})

export default function BookingCalendarScreenContainer() {
  return (
    <>
      <BookingCalendarScreen />
    </>
  )
}
