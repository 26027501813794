import {
  BookingRequestDisplay,
  BookingRequestStatus,
} from '@beatgig/api-services/booking-request'
import { useCallback } from 'react'
import { Filling } from '@beatgig/design/components/types'
import { DripsyTheme } from '@beatgig/theme'

export function useSellerBookingRequestPersonalized() {
  const getStatusDescription = useCallback(
    (props: {
      bookingRequest: Pick<
        BookingRequestDisplay,
        | 'myAcceptedBid'
        | 'didSomeoneElsesBidGetChosen'
        | 'areAllMyBidsRejected'
        | 'isActionRequired'
        | 'status'
        | 'start_time'
        | 'isPast'
      > | null
    }) => {
      let statusDescription:
        | {
            text: string
            variant: keyof DripsyTheme['colors']
            filling: Filling
            capitalize?: boolean
          }
        | undefined

      const { bookingRequest } = props

      const {
        isActionRequired = false,
        myAcceptedBid,
        areAllMyBidsRejected,
        didSomeoneElsesBidGetChosen,
        status,
        isPast = false,
      } = props.bookingRequest || {}

      const isPastEvent = isPast

      const isCancelled = status === BookingRequestStatus.CANCELLED

      if (myAcceptedBid?.artist) {
        statusDescription = {
          text: 'Bid Accepted',
          filling: 'faded',
          variant: 'success',
        }
      } else if (isActionRequired) {
        statusDescription = {
          text: 'Action Required',
          filling: 'faded',
          variant: 'error',
        }
      } else if (isCancelled) {
        statusDescription = {
          capitalize: true,
          variant: 'warning',
          filling: 'faded',
          text: `${BookingRequestStatus.CANCELLED} by Buyer`,
        }
      } else if (didSomeoneElsesBidGetChosen) {
        statusDescription = {
          capitalize: true,
          filling: 'faded',
          variant: 'warning',
          text: 'Other artist chosen',
        }
      } else if (
        isPastEvent &&
        bookingRequest?.status === BookingRequestStatus.PENDING
      ) {
        statusDescription = {
          filling: 'filled',
          variant: 'muted',
          text: 'Expired - no artist chosen',
        }
      } else if (areAllMyBidsRejected) {
        statusDescription = {
          filling: 'faded',
          variant: 'warning',
          text: 'Rejected',
        }
      } else {
        statusDescription = {
          filling: 'faded',
          variant: 'text',
          text: bookingRequest?.status.replaceAll('_', ' ') ?? '',
        }
      }

      return statusDescription
    },
    []
  )

  return {
    getStatusDescription,
  }
}
