import { SWRResponse, SWRConfiguration } from 'swr'
import { useRef, useEffect, useContext } from 'react'
import { AppState } from 'react-native'
import type NetInfo from '@react-native-community/netinfo'
import type { NetInfoState } from '@react-native-community/netinfo'
import { NavigationContext } from '@react-navigation/native'

type Props<Data, Error> = {
  /**
   * Required: pass the `revalidate` function returned to you by SWR.
   */
  revalidate: SWRResponse<Data | null, Error>['mutate']
  disableNavigationRevalidate?: boolean
  disableAppStateFocusRevalidate?: boolean
  /*
   * Default: `false`. If `true`, the `revalidate` function will be called when the app state becomes active, even when the screen you call this hook from isn't active.
   */
  revalidateOnAppStateChangeWhenNavigationIsBlurred?: boolean
  testID?: string
} & Pick<
  SWRConfiguration,
  'revalidateOnFocus' | 'revalidateOnReconnect' | 'focusThrottleInterval'
>

import { Platform } from 'react-native'

/**
 * swr-react-native
 *
 * This helps you revalidate your SWR calls, based on navigation actions in `react-navigation`.
 */
export default function useSWRReactNavigation<Data = any, Error = any>(
  props: Props<Data, Error>
) {
  const {
    revalidate,
    // copy defaults from SWR
    revalidateOnFocus = true,
    revalidateOnReconnect = true,
    focusThrottleInterval = 5000,
    disableNavigationRevalidate = false,
    disableAppStateFocusRevalidate = false,
    revalidateOnAppStateChangeWhenNavigationIsBlurred = false,
    testID,
  } = props

  const maybeNavigationContext = useContext(NavigationContext)
  const { addListener, isFocused } = maybeNavigationContext || {}

  const lastFocusedAt = useRef<number | null>(null)
  const fetchRef = useRef(revalidate)
  useEffect(() => {
    fetchRef.current = revalidate
  })
  const focusCount = useRef(
    Platform.select({
      web: 1,
      // react-navigation fire a focus event on the initial mount, but not on web
      default: 0,
    })
  )

  const previousAppState = useRef(AppState.currentState)
  const previousNetworkState = useRef<NetInfoState | null>(null)

  useEffect(() => {
    let unsubscribeReconnect: ReturnType<
      typeof NetInfo.addEventListener
    > | null = null
    if (Platform.OS !== 'web' && revalidateOnReconnect) {
      // inline require to avoid breaking SSR when window doesn't exist
      const Network: typeof NetInfo = require('@react-native-community/netinfo')
        .default
      // SWR does all of this on web.
      unsubscribeReconnect = Network.addEventListener((state) => {
        if (
          previousNetworkState.current?.isInternetReachable === false &&
          state.isConnected &&
          state.isInternetReachable
        ) {
          fetchRef.current()
        }
        previousNetworkState.current = state
      })
    }

    const onFocus = (event?: any) => {
      const isThrottled =
        focusThrottleInterval &&
        lastFocusedAt.current &&
        Date.now() - lastFocusedAt.current <= focusThrottleInterval
      if (testID) {
        console.log('[use-native-revalidate] did focus', {
          focusCount: focusCount.current,
          testID,
          isThrottled,
          lastFocusedAt: lastFocusedAt.current,
          event: event ? 'navigation focus' : 'app state change',
        })
      }

      if (focusCount.current < 1) {
        focusCount.current++
        return
      }

      if (!isThrottled) {
        lastFocusedAt.current = Date.now()
        fetchRef.current()
      }
    }

    const onAppStateChange = (nextAppState: AppState['currentState']) => {
      if (
        previousAppState.current.match(/inactive|background/) &&
        nextAppState === 'active'
      ) {
        const shouldRevalidate =
          revalidateOnAppStateChangeWhenNavigationIsBlurred || isFocused?.()
        if (shouldRevalidate) {
          onFocus()
        }
      }

      previousAppState.current = nextAppState
    }

    let unsubscribeFocus: ReturnType<
      NonNullable<typeof addListener>
    > | null = null

    let unsubscribeAppState: ReturnType<
      typeof AppState.addEventListener
    > | null = null

    if (revalidateOnFocus) {
      if (!disableNavigationRevalidate && addListener) {
        unsubscribeFocus = addListener('focus', onFocus)
      }
      if (
        !disableAppStateFocusRevalidate &&
        // swr does this on web
        Platform.OS !== 'web'
      ) {
        unsubscribeAppState = AppState.addEventListener(
          'change',
          onAppStateChange
        )
      }
    }

    return () => {
      if (revalidateOnFocus) {
        unsubscribeFocus?.()
        if (Platform.OS !== 'web') {
          // swr does this on web
          unsubscribeAppState?.remove()
          // AppState.removeEventListener('change', onAppStateChange)
        }
      }
      if (revalidateOnReconnect) {
        unsubscribeReconnect?.()
      }
    }
  }, [
    addListener,
    disableNavigationRevalidate,
    focusThrottleInterval,
    revalidateOnFocus,
    revalidateOnReconnect,
    isFocused,
    disableAppStateFocusRevalidate,
    testID,
    revalidateOnAppStateChangeWhenNavigationIsBlurred,
  ])
}
