import React, { useMemo } from 'react'
import { View, Text } from 'dripsy'
import { Skeleton } from 'moti/skeleton'
import Entity from '@beatgig/design/components/entity'
import { DripsyTheme } from '@beatgig/theme'
import EntityField from '@beatgig/design/components/entity/entity-field'
import { SmallCalendarListEvent } from './types'
import Press from '@beatgig/components/press'
import Avatar from '@beatgig/design/components/avatar'
import AvatarGroup from '@beatgig/design/components/avatar/avatar-group'

type Props = (
  | {
      loading: true
    }
  | ({ loading: false } & SmallCalendarListEvent)
) & {
  index: number
}

const SIDEBAR_SIZE = 4

export const BookingsSmallCalendarListItem = React.memo(
  function BookingsSmallCalendarListItem(props: Props) {
    let color: keyof DripsyTheme['colors'] = 'primary'
    if (!props.loading) {
      color = props.color
    }

    const { index, loading } = props

    const separator = !!index && (
      <View sx={{ mx: 3, height: 1, bg: 'border' }} />
    )

    const thumbnail = (
      <View sx={{ bg: color, width: SIDEBAR_SIZE, flex: 1, borderRadius: 3 }} />
    )
    const avatarURLs = props.loading ? undefined : props.avatarURLs

    const avatars = useMemo(() => {
      const urls = avatarURLs?.split('|new image|').filter(Boolean)
      if (urls?.length === 1) {
        return <Avatar uri={urls[0]} size="large" />
      } else if (urls?.length) {
        return (
          <AvatarGroup>
            {urls.map((url) => (
              <Avatar key={url} uri={url} size="small" />
            ))}
          </AvatarGroup>
        )
      }
      return null
    }, [avatarURLs])

    const opacity = loading ? 0 : 1
    const mt = index ? 1 : 0

    const onPress = () => {
      if (!props.loading) {
        props.onPress({ id: props.id })
      }
    }

    return (
      <Press onPress={onPress}>
        {separator}
        <View sx={{ mt, mb: 1, px: 3 }}>
          <Entity
            border={false}
            padding={1}
            thumbnailMarginRight={2}
            thumbnail={thumbnail}
            thumbnailSize={SIDEBAR_SIZE}
            actions={avatars}
          >
            <EntityField
              title={
                <View>
                  <Skeleton show={loading}>
                    <Text sx={{ fontWeight: 'bold', opacity }}>
                      {props.loading ? 'Artist name' : props.title}
                    </Text>
                  </Skeleton>
                </View>
              }
              subtitle={
                <View>
                  {!props.loading && !!props.shortMessage && (
                    <Text
                      sx={{
                        mb: 1,
                        color: props.color,
                        textTransform: 'capitalize',
                      }}
                    >
                      {props.shortMessage}
                    </Text>
                  )}
                  <Skeleton show={loading}>
                    <Text sx={{ color: 'mutedText', opacity }}>
                      {props.loading ? '9:00PM' : props.startTime}{' '}
                      {!props.loading &&
                        !!props.venueName &&
                        `at ${props.venueName}`}
                    </Text>
                  </Skeleton>
                  {/* {!props.loading && !!props.venueName && (
                    <Text sx={{ mt: 1, color: 'mutedText' }}>
                      {props.venueName}
                    </Text>
                  )} */}
                </View>
              }
            />
          </Entity>
        </View>
      </Press>
    )
  }
)
