import usePost, { UsePostOptions } from './use-post'
import { RecommendedBookingApi } from '@beatgig/api/recommended-booking'
import Analytics from '@beatgig/analytics/typez'

export default function useEditRecommendedBooking(
  options?: UsePostOptions<typeof RecommendedBookingApi.editRecommendedBooking>
) {
  return usePost(
    (
      ...props: Parameters<typeof RecommendedBookingApi.editRecommendedBooking>
    ) => {
      const [recommendedBookingId] = props
      Analytics.track('Edit Recommended Booking', {
        recommendedBookingId,
      })
      return RecommendedBookingApi.editRecommendedBooking(...props)
    },
    options
  )
}
