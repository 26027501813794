import React, { useMemo } from 'react'
import { Sx } from 'dripsy'
import AutoComplete from '@beatgig/design/components/auto-complete'
import { AllUserAccountTypes, UserRole } from '@beatgig/api-services'
import useSearchAllUsersInfinite from '@beatgig/search/hooks/use-search-all-users-infinite'
import { AutoCompleteOption } from '@beatgig/design/components/auto-complete/types'
import Avatar from '@beatgig/design/components/avatar'
import Cloudinary from '@beatgig/helpers/cloudinary'
import * as Urql from 'urql'
import {
  UserFromAdminDocument,
  UserFromAdminQuery,
  UserFromAdminQueryVariables,
} from '@beatgig/gql'

type Props = {
  onSelectUser?: (user: AllUserAccountTypes) => void
  onClearUser?: () => void
  userRole?: UserRole | UserRole[]
  /**
   * Called if we already had a user, and are now selecting a new one
   */
  onChangeUser?: () => void
  value: string
  onChangeText: (next: string) => void
  selectedUser: AllUserAccountTypes | null
  //   onSelectUser: (user: AllUserAccountTypes) => void
  sx?: Sx
}

const filterNull = <T extends unknown | null | undefined>(
  t: T
): t is NonNullable<T> => {
  return t != null
}

export default function AdminUserSearch({
  sx,
  onSelectUser,
  onClearUser,
  userRole,
  onChangeUser,
  value,
  onChangeText,
  selectedUser,
}: Props) {
  const { data, error, fetchMore, isFetchingMore, isLoadingInitialData } =
    useSearchAllUsersInfinite({
      query: value,
      userRole: userRole && (Array.isArray(userRole) ? userRole : [userRole]),
    })

  const options = useMemo<AutoCompleteOption[]>(
    () =>
      data?.filter(filterNull).map(({ document: user }) => ({
        label: user.name,
        value: user.id,
        subtitle: user.metadata?.user_type.replaceAll('_', ' '),
        prefix: (
          <Avatar
            uri={
              user.profile_image &&
              Cloudinary(user.profile_image, {
                width: 250,
              })
            }
            text={user.name}
            makeInitials
          />
        ),
      })) ?? [],
    [data]
  )

  const client = Urql.useClient()

  return (
    <AutoComplete
      sx={sx}
      align="left"
      options={options}
      defaultValue={value}
      value={value}
      onChangeText={onChangeText}
      placeholder="Search users..."
      onEndReached={fetchMore}
      keyExtractor={(item) => item.value}
      shouldSetControlledStateOnPress="label"
      searching={isLoadingInitialData || isFetchingMore}
      editable
      hasErrored={!!error}
      onClear={() => {
        onClearUser?.()
        // setAdminOverrideUser(null)
      }}
      allowsCustomValue={false}
      onSelect={async (id) => {
        const { data } = await client
          .query<UserFromAdminQuery, UserFromAdminQueryVariables>(
            UserFromAdminDocument,
            {
              id,
            }
          )
          .toPromise()

        if (data) {
          onSelectUser?.(data?.user)
          if (selectedUser && selectedUser?.id !== data.user?.id) {
            onChangeUser?.()
          }
          //   setAdminOverrideUser(user)
        }
      }}
    />
  )
}
