import React, { useMemo, useImperativeHandle, useRef, useCallback } from 'react'
import { View, TextInput } from 'dripsy'
import Ionicons from '@expo/vector-icons/build/Ionicons'
import {
  TouchableOpacity,
  TextInput as InputType,
  Platform,
} from 'react-native'
import useWhyDidYouUpdate from '@beatgig/hooks/use-why-did-you-update'
import useAnimatedHover from '@beatgig/helpers/use-animated-hover'
import useIsFocused from '@beatgig/hooks/use-is-focused'
import Loading from '@beatgig/design/components/loading'
import Press from '../press'
import useTheme from '@beatgig/theme/use-theme'

type Props = {
  showClear?: boolean
  showBack?: boolean
  onClear?: (text: '') => void
  onGoBack?: () => void
  loading?: boolean
  inputRef?: React.MutableRefObject<InputType | null>
} & React.ComponentProps<typeof TextInput>

const SearchInput = React.memo(function SearchInput(props: Props) {
  const {
    sx = {},
    value,
    onChangeText,
    showClear = true,
    onClear,
    showBack = false,
    onGoBack,
    onFocus,
    onBlur,
    autoFocus,
    loading = false,
    placeholder = 'Search artists to book...',
    inputRef,
    // ...inputProps
  } = props

  const localInputRef = useRef<InputType>(null as any)
  const { bg = 'background' } = sx as any

  useImperativeHandle(inputRef, () => localInputRef.current)

  const { isHovered, ref, resetStyle } = useAnimatedHover<typeof View>({
    webStyleKeys: ['border-color'],
    duration: 500,
  })
  const [isFocused, focusBindings] = useIsFocused()

  const { colors } = useTheme()
  const iconColor = isFocused ? colors?.background : (colors?.text as string)

  useWhyDidYouUpdate('search-input', props)

  const textColor = isFocused ? 'background' : 'text'

  const renderInput = useMemo(() => {
    return (
      <TextInput
        autoFocus={autoFocus}
        onFocus={
          onFocus
            ? (e) => {
                focusBindings.onFocus()
                onFocus?.(e)
              }
            : focusBindings.onFocus
        }
        onBlur={
          onBlur
            ? (e) => {
                onBlur?.(e)
                focusBindings.onBlur()
              }
            : focusBindings.onBlur
        }
        // ref={ref as any}
        ref={localInputRef as any}
        sx={{
          px: [1, 2],
          py: (theme) => [2, null, theme.space?.[2] * 1.5 + 0.000001],
          color: textColor,
          // bg: 'text',
          outlineWidth: Platform.select({ web: `0` }),
          textAlign: 'center',
          outlineColor: 'transparent',
          fontSize: 3,
          ...resetStyle,
        }}
        value={value}
        onChangeText={onChangeText}
        placeholder={placeholder}
        placeholderTextColor={
          isFocused ? colors?.muted6 : (colors?.muted5 as string)
        }
        // placeholderTextColor={colors?.background}
      />
    )
  }, [
    autoFocus,
    onFocus,
    focusBindings,
    onBlur,
    textColor,
    resetStyle,
    value,
    onChangeText,
    placeholder,
    isFocused,
    colors?.muted6,
    colors?.muted5,
  ])

  const focus = useCallback(() => localInputRef.current?.focus(), [])

  return (
    <View
      ref={ref as any}
      sx={{
        // bg: 'text',
        borderRadius: 'rounded',
        // borderWidth: 1,
        // borderStyle: 'solid',
        borderColor: isHovered || isFocused ? 'text' : 'border',
        ...sx,
        // bg: (theme) =>
        //   isFocused ? 'text' : isHovered ? `${theme.colors?.text}50` : bg,
        bg: isFocused ? 'text' : 'muted',
        ...resetStyle,
      }}
    >
      <View sx={{ flexDirection: 'row', alignItems: 'center' }}>
        <View sx={{ px: 2 }}>
          <TouchableOpacity
            onPress={showBack ? onGoBack : focus}
            hitSlop={{ left: 15, right: 15, bottom: 15, top: 15 }}
          >
            <Ionicons
              name={showBack ? 'chevron-back' : 'ios-search'}
              size={20}
              color={iconColor}
            />
          </TouchableOpacity>
        </View>
        <View sx={{ flex: 1 }}>
          {renderInput}
          {!!loading && (
            <View
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                justifyContent: 'center',
              }}
              pointerEvents="none"
            >
              <Loading variant={isFocused ? 'background' : 'default'} />
            </View>
          )}
        </View>
        <View
          sx={{
            px: 2,
            opacity: showClear && value ? 1 : 0,
            // position: 'absolute',
            // top: 0,
            // bottom: 0,
            // right: 0,
            // justifyContent: 'center',
          }}
        >
          <Press
            disabled={!(showClear && value)}
            onPress={
              showClear && value
                ? () => {
                    onClear?.('')
                    focus()
                  }
                : undefined
            }
            hitSlop={10}
          >
            <Ionicons name="close-outline" size={30} color={iconColor} />
          </Press>
        </View>
      </View>
    </View>
  )
})

export default SearchInput
