import {
  useExchangeAuthCodeGoogleMutation,
  useGoogleOAuthUrlQuery,
} from '@beatgig/gql'
import { useAuthRequest, makeRedirectUri, AuthError } from 'expo-auth-session'
import { maybeCompleteAuthSession } from 'expo-web-browser'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { Platform } from 'react-native'
import qs from 'qs'
import NextRouter from 'next/router'

maybeCompleteAuthSession()

export function useUserGoogleCalendarAuth() {
  const redirectUri = makeRedirectUri({
    scheme: Platform.select({
      native: process.env.EXPO_APP_SCHEME,
    }),
    path: NextRouter.router ? NextRouter.asPath.split('?')[0] : undefined,
  })
  const cachedQueryParams = useRef<{} | null>()
  const [authUrl] = useGoogleOAuthUrlQuery({
    variables: {
      redirectUri,
    },
  })

  const extraParams = useMemo(() => {
    if (!authUrl.data?.googleOauthUrl) {
      return undefined
    }
    return qs.parse(authUrl.data.googleOauthUrl.split('?')[1]) as Record<
      string,
      string
    >
  }, [authUrl.data?.googleOauthUrl])

  const [request, response, prompt] = useAuthRequest(
    {
      clientId:
        extraParams?.clientId ||
        (Platform.select({
          web: GOOGLE_CLIENT_ID_WEB,
          ios: GOOGLE_CLIENT_ID_IOS,
        }) as string),
      redirectUri,
      extraParams,
      // THIS IS IMPORTANT. ENSURES THAT THE BACKEND IS GENERATING THE TOKEN
      usePKCE: false,
    },
    useMemo(
      () =>
        authUrl.data?.googleOauthUrl
          ? { authorizationEndpoint: authUrl.data.googleOauthUrl.split('?')[0] }
          : null,
      [authUrl.data?.googleOauthUrl]
    )
  )
  const [exchangeMutation, exchangeToken] = useExchangeAuthCodeGoogleMutation()

  let code: string | null = null
  if (response?.type === 'success') {
    code = response.params.code
  }

  useEffect(
    function exchange() {
      if (code) {
        exchangeToken({ code, redirectUri })
      }
    },
    [code, exchangeToken, redirectUri]
  )

  let responseError: AuthError | undefined
  if (response?.type == 'error' && response.error) {
    responseError = response.error
  }

  return {
    request,
    response,
    prompt: useCallback(() => {
      cachedQueryParams.current = NextRouter.query
      prompt()
    }, [prompt]),
    isLoading: (!authUrl.data && authUrl.fetching) || exchangeMutation.fetching,
    error: authUrl.error || exchangeMutation.error || responseError,
    success: exchangeMutation.data?.exchangeAuthCodeGoogle === true,
  }
}

const isSandbox = process.env.NEXT_PUBLIC_BACKEND_ENV === 'sandbox'

const GOOGLE_CLIENT_ID_WEB = isSandbox
  ? '1087216282988-onf2a3gnvq94ato448noske0n6ilm48c.apps.googleusercontent.com'
  : '676970844475-09omilq1see9j4upta8jpmaoj70h2por.apps.googleusercontent.com'

const GOOGLE_CLIENT_ID_IOS = isSandbox
  ? '1087216282988-et8klp5sv053n27h961f3hsg9p8oatu0.apps.googleusercontent.com'
  : '676970844475-gjkn8f3or2tuhl97n9osdedof62eo5a2.apps.googleusercontent.com'
