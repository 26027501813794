import React from 'react'
import { View } from 'dripsy'
import { useFieldArray } from '@beatgig/forms/hooks/use-field-array'
import type { ArtistHit } from '@beatgig/search/types/hits'
import { ArtistRecommendationUpdate } from '@beatgig/api-services/recommended-booking'
import AddRecommendedArtistItem from './artist-form-item'

type ArtistSearchFieldProps<Form, Name extends string = string> = {
  name: keyof Form
}

export default function AddArtistsArray<Form>({
  name,
}: ArtistSearchFieldProps<Form>) {
  const [{ value }, , { remove }] = useFieldArray<
    ArtistHit & ArtistRecommendationUpdate
  >(name)
  return (
    <View sx={{ mt: 3 }}>
      {value?.map((artistRec, index) => {
        return (
          <AddRecommendedArtistItem
            index={index}
            artistRecommendation={artistRec}
            remove={remove}
            // TODO don't allow duplicates
            key={artistRec.artist_id}
          />
        )
      })}
    </View>
  )
}
