import React from 'react'
import { View } from 'dripsy'
import { AuthedRoutes } from '@beatgig/navigation/routes/authed-routes'
import A from '@beatgig/components/a'
import Button from '@beatgig/components/button'
import { Platform } from 'react-native'
import { useRouting } from 'expo-next-react-navigation'

type Props = {
  bookingId: string
  children?: string
} & React.ComponentProps<typeof View>

export default function OpenBookingExternallyButton(props: Props) {
  const { bookingId, sx, children = 'Open' } = props

  const { navigate } = useRouting()

  return (
    <A href={`/dashboard/bookings/${bookingId}`} target="_blank">
      <Button
        onPress={Platform.select({
          web: undefined,
          default: (e) => {
            e.preventDefault()
            navigate(AuthedRoutes.booking(bookingId))
          },
        })}
        sx={sx}
        disabled={false}
        iconLeft={{
          name: 'open-outline',
        }}
      >
        {children}
      </Button>
    </A>
  )
}
