import React, { useState, useMemo } from 'react'
import { View } from 'dripsy'
import SearchInput from '@beatgig/components/search-input'
import EnumFilterPopover from '@beatgig/search/filters/enum/enum-filter-popover'
import BooleanFilterPopover from '@beatgig/search/filters/boolean/boolean-filter-popover'
import useBooleanFilter from '../../filters/boolean/use-boolean-filter'
import useEnumFilter from '../../filters/enum/use-enum-filter'
import Spacer from '@beatgig/design/components/spacer'
import Sizer from '@beatgig/components/sizer'
import { Modal } from 'react-native'
import Link from '@beatgig/design/components/link'
import WebModal from '@beatgig/components/web-modal'

type BooleanPopoverProps = React.ComponentProps<typeof BooleanFilterPopover>
type EnumPopoverProps = React.ComponentProps<typeof EnumFilterPopover>

type BooleanFilters = ReturnType<typeof useBooleanFilter> | BooleanPopoverProps
type EnumFilters = ReturnType<typeof useEnumFilter> | EnumPopoverProps

function isBooleanPopoverProps(
  props: BooleanFilters
): props is BooleanPopoverProps {
  return !!(props as BooleanPopoverProps).booleanProps
}
function isEnumPopoverProps(props: EnumFilters): props is EnumPopoverProps {
  return !!(props as EnumPopoverProps).enumProps
}

type Props = {
  isValidating: boolean
  query: string
  onClear?: () => void
  onChangeText: (value: string) => void
  booleanFilters?: React.ReactElement | BooleanFilters[]
  enumFilters?: React.ReactElement | EnumFilters[]
  row?: boolean
  placeholder: string
  sort?: React.ReactNode
  flexDirection?: 'row' | 'column' | ('row' | 'column' | null)[]
  filters?: React.ReactNode
  search?: React.ReactNode
  mode?: 'modal' | 'popover'
}

function useModalState() {
  const [modalOpen, setModalOpen] = useState(false)

  const handlers = useMemo(
    () => ({
      closeModal: () => setModalOpen(false),
      openModal: () => setModalOpen(true),
    }),
    []
  )

  return [modalOpen, handlers] as const
}

type ReactChildArray = ReturnType<typeof React.Children.toArray>

function flattenChildren(children: React.ReactNode): ReactChildArray {
  const childrenArray = React.Children.toArray(children)
  // @ts-expect-error idk
  return childrenArray.reduce((flatChildren: ReactChildArray, child) => {
    if ((child as React.ReactElement<any>).type === React.Fragment) {
      return flatChildren.concat(
        flattenChildren((child as React.ReactElement<any>).props.children)
      )
    }
    flatChildren.push(child)
    return flatChildren
  }, [])
}

export default function AdminSearchFilters({
  query,
  onChangeText,
  isValidating,
  booleanFilters,
  enumFilters,
  row = true,
  onClear,
  placeholder,
  sort = null,
  flexDirection,
  filters: customFilters,
  search,
  ...props
}: Props) {
  const [isModalOpen, { openModal, closeModal }] = useModalState()

  let filterCount = 0
  if (!React.isValidElement(booleanFilters)) {
    filterCount += booleanFilters?.length || 0
  }
  if (!React.isValidElement(enumFilters)) {
    filterCount += enumFilters?.length || 0
  }
  const { mode = filterCount > 2 ? 'modal' : 'popover' } = props

  const renderBooleanFilters = () => {
    if (!booleanFilters) return null

    if (React.isValidElement(booleanFilters)) return booleanFilters

    return booleanFilters?.map((props, i) => {
      if (isBooleanPopoverProps(props)) {
        return (
          <View
            sx={{ ml: i && mode !== 'modal' ? 3 : 0 }}
            key={props.booleanProps.filterPath}
          >
            <BooleanFilterPopover {...props} mode={mode} />
          </View>
        )
      }
      return (
        <View sx={{ ml: i && mode !== 'modal' ? 3 : 0 }} key={props.filterPath}>
          <BooleanFilterPopover booleanProps={props} mode={mode} />
        </View>
      )
    })
  }
  const renderEnumFilters = () => {
    if (!enumFilters) return null

    if (React.isValidElement(enumFilters)) return enumFilters

    return enumFilters?.map((props, i) => {
      if (isEnumPopoverProps(props)) {
        return (
          <View
            sx={{ ml: i && mode === 'popover' ? 3 : 0 }}
            key={props.enumProps.filterPath}
          >
            <EnumFilterPopover {...props} mode={mode} />
          </View>
        )
      }
      return (
        <View
          sx={{ ml: i && mode === 'popover' ? 3 : 0 }}
          key={props.filterPath}
        >
          <EnumFilterPopover enumProps={props} mode={mode} />
        </View>
      )
    })
  }

  const renderCustomFilters = () => {
    if (!customFilters) return null

    const flatFilters = flattenChildren(customFilters)
    return React.Children.map(flatFilters, (child, i) => {
      return (
        child && (
          <View
            sx={{
              ml: i && mode !== 'modal' ? 3 : 0,
            }}
          >
            {React.cloneElement(child as any, {
              mode,
            })}
          </View>
        )
      )
    })
  }

  const filters = (
    <View sx={{ flexDirection: mode === 'popover' ? 'row' : 'column' }}>
      {renderCustomFilters()}
      {!!(booleanFilters || enumFilters) &&
        !!customFilters &&
        mode === 'popover' && <Spacer width={3} />}
      {renderEnumFilters()}
      {!!booleanFilters && !!enumFilters && mode === 'popover' && (
        <Spacer width={3} />
      )}
      {renderBooleanFilters()}
    </View>
  )

  const modal = (
    <>
      <Link
        onPress={openModal}
        iconName="filter-outline"
        icon
        block
        iconSide="left"
      >
        Filter
      </Link>
      <Modal transparent visible={isModalOpen} onRequestClose={closeModal}>
        <WebModal title="Filters" header onRequestClose={closeModal}>
          {filters}
        </WebModal>
      </Modal>
    </>
  )

  return (
    <View
      sx={{
        p: 3,
        flexDirection:
          flexDirection ?? mode === 'modal' ? 'row' : ['column', null, 'row'],
        zIndex: 1,
        bg: 'muted',
        justifyContent: 'space-between',
        // borderBottomWidth: '1',
        // borderColor: 'border',
      }}
    >
      <Sizer
        {...{
          width: '100%',
          maxWidth: 700,
          alignSelf: 'center',
          flex: 1,
        }}
      >
        {search ?? (
          <SearchInput
            loading={isValidating}
            value={query}
            onChangeText={onChangeText}
            placeholder={placeholder}
            onClear={onClear}
          />
        )}
      </Sizer>
      {/* <Spacer
        height={[mode === 'popover' ? 2 : 0, null, 0]}
        width={[mode === 'modal' ? 2 : 0, null, 2]}
      /> */}
      {(filterCount > 0 || !!customFilters) && (
        <Sizer
          mt={[mode === 'popover' ? 2 : 0, null, 0]}
          flex={[null, null, 1]}
        >
          <View
            sx={{
              flex: 1,
              alignItems: 'flex-end',
              justifyContent: 'center',
              px: [3],
              py: mode === 'popover' ? [2, null, 0] : 0,
            }}
          >
            {mode === 'popover' ? filters : modal}
          </View>
        </Sizer>
      )}
      {!!sort && (
        <View sx={{ ml: [0, null, 2], justifyContent: 'center', zIndex: -1 }}>
          {sort}
        </View>
      )}
    </View>
  )
}
