import 'setimmediate'

import LoadingScreen from '@beatgig/components/loading-screen'
import React from 'react'
import { View } from 'dripsy'
import useAuth from '@beatgig/auth-hooks/use-auth'
import useMyAccount from '@beatgig/auth-hooks/use-my-account'
import { User } from '@beatgig/api/user'
import UserProfilePendingBanner from '@beatgig/ui/user-profile-pending-banner'
import { SellersInviteOnlyScreen } from '../sellers-invite-only'
import useOpenAuthPopup from '@beatgig/auth-hooks/use-open-auth-popup'
import { Box } from '@beatgig/design/components/box'
import { Text } from '@beatgig/design/components/text'
import { APP_NAME } from '@beatgig/constants'
import Button from '@beatgig/components/button'
import Spacer from '@beatgig/design/components/spacer'
import { MotiView } from 'moti'
import { Easing } from 'react-native-reanimated'

export default function AuthenticatedPage(props: {
  children: React.ReactNode
  showsPendingBanner?: boolean
  allowsScreenWhenSellerIsPendingApproval?: boolean
  onPressOpenAuth?: () => void
}) {
  const { loading } = useAuth()

  const { data: user, error, isLoadingInitial } = useMyAccount()
  const {
    children,
    showsPendingBanner = !User.isSeller(user),
    allowsScreenWhenSellerIsPendingApproval,
  } = props

  const openAuthPopup = useOpenAuthPopup()

  const openAuth = () => {
    openAuthPopup()
    props.onPressOpenAuth?.()
  }

  if (loading || isLoadingInitial) {
    return <LoadingScreen error={error}>Signing in...</LoadingScreen>
  }
  if (!User.hasOnboarded(user)) {
    return (
      <Box flex={1} alignItems="center" justifyContent="center" px="$3">
        <Box
          // width="100%"
          // maxWidth={600}
          p={['$3', '$4']}
          width={400}
          maxWidth="100%"
          borderRadius="$4"
          // bg="muted"
        >
          <MotiView
            from={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{
              type: 'timing',
              delay: 100,
              duration: 1200,
              easing: Easing.out(Easing.quad),
            }}
          >
            <Text
              textAlign="center"
              fontWeight="500"
              fontSize={['$6', '$5', '$6']}
            >
              Welcome to {APP_NAME}
            </Text>
            <Spacer y={['$4']} />
            <Box alignItems="center">
              <MotiView
                from={{ translateY: 0 }}
                animate={{ translateY: -5 }}
                transition={{
                  loop: true,
                  type: 'timing',
                  easing: Easing.linear,
                  duration: 1200,
                }}
              >
                <Button onPress={openAuth}>Get Started</Button>
              </MotiView>
            </Box>
          </MotiView>
        </Box>
      </Box>
    )
  }

  const InviteOnly = allowsScreenWhenSellerIsPendingApproval
    ? React.Fragment
    : SellersInviteOnlyScreen

  return (
    <View sx={{ flex: 1 }}>
      <InviteOnly>
        <>
          {showsPendingBanner && <UserProfilePendingBanner />}
          <View sx={{ flex: 1 }}>{children}</View>
        </>
      </InviteOnly>
    </View>
  )
}
