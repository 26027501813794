import React from 'react'
import { BookingsSmallCalendarList } from '../bookings-small-calendar-list'
import { useBookingsActionRequiredList } from './use-bookings-action-required-list'
import { Text, H4, View } from 'dripsy'
import { useCalendarActions } from '@beatgig/screens/calendar-screen/hooks/actions'
import Card from '@beatgig/design/components/card'
import Button from '@beatgig/components/button'

type Props = {
  onGoBack: () => void
} & Parameters<typeof useCalendarActions>[0]

export function BookingsActionRequiredList({
  userRole,
  userId,
  userName,
  onGoBack,
}: Props) {
  const { bookings, bookingRequests, fetchMore, isFetchingMore, canFetchMore } =
    useBookingsActionRequiredList({
      userId,
    })

  const { onNavigateToBooking, onPressBookingRequest } = useCalendarActions({
    userId,
    userName,
    userRole,
  })

  const Empty = (
    <View sx={{ px: 3 }}>
      <Card>
        <Card.Content>
          <H4>All Set 🎸</H4>
          <Text>You are all up-to-date.</Text>
        </Card.Content>
        <Card.Footer sx={{ justifyContent: 'flex-end' }} bg="muted">
          <Button onPress={onGoBack}>Go Back</Button>
        </Card.Footer>
      </Card>
    </View>
  )

  return (
    <>
      <BookingsSmallCalendarList
        bookings={
          bookings?.map((booking) => ({
            ...booking,
            buyer: {
              metadata: booking.buyerMetadata,
            },
            artist: {
              ...booking.artist,
              profileImage: booking.artist.profileImage && {
                ...booking.artist.profileImage,
                publicCloudinaryId:
                  booking.artist.profileImage.public_cloudinary_id,
              },
            },
          })) ?? null
        }
        bookingRequests={bookingRequests ?? null}
        // recommendedBookings={recommendedBookings.data ?? null}
        externalBookings={null}
        onPressExternalBooking={console.log} // no-op
        onPressBooking={onNavigateToBooking}
        onPressBookingRequest={onPressBookingRequest}
        // onPressRecommendedBooking={wrapPressEvent(onPressRecommendedBooking)}
        userRole={userRole}
        shouldFilterBySelectedDate={false}
        Empty={Empty}
      />
      {(canFetchMore || isFetchingMore) && (
        <Button
          sx={{ alignSelf: 'center', mt: '$4' }}
          color="text"
          onPress={fetchMore}
          loading={isFetchingMore}
        >
          Load More
        </Button>
      )}
    </>
  )
}
