import { useTypesenseSearchInfinite } from './use-search-typesense-infinite'
import { useAdminTypesenseKey } from '@beatgig/api-hooks/src/use-admin-typesense-key'
import { VenueTypesenseSnake } from '@beatgig/gql/types/venue-typesense'
import { TypesenseCollectionName } from '@beatgig/gql'

export default function useSearchAllVenuesInfinite({
  query,
}: {
  query: string | null
}) {
  return useTypesenseSearchInfinite<VenueTypesenseSnake>(query, {
    index: TypesenseCollectionName.VENUES,
    apiKey: useAdminTypesenseKey().data || null,
    queryBy: ['name'],
    sortOrder: 'desc',
    sortBy: 'last_updated',
  })
}
