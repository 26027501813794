// @ts-nocheck
// deprecated, delete this
import React, { useState, useMemo } from 'react'
import { View, Text } from 'dripsy'
import { useFieldArray } from '@beatgig/forms/hooks/use-field-array'
import type { ArtistHit } from '@beatgig/search/types/hits'
import { AutoCompleteOption } from '@beatgig/design/components/auto-complete/types'
import AutoComplete from '@beatgig/design/components/auto-complete'
import Card from '@beatgig/design/components/card'
import Button from '@beatgig/components/button'
import { DisplayArtist } from '@beatgig/api-services'
import TextInput from '@beatgig/design/components/input'
import MaskedInput from '@beatgig/design/components/input/masked-input'
import Spacer from '@beatgig/design/components/spacer'
import Avatar from '@beatgig/design/components/avatar'
import Cloudinary from '@beatgig/helpers/cloudinary'
import numeral from 'numeral'
import { ArtistRecommendationUpdate } from '@beatgig/api-services/recommended-booking'

type EditProps = {
  /**
   * If you want to edit an artist recommendation, use this.
   */
  initialInputArtistRecommendation: ArtistRecommendationUpdate
  initialSelectedArtist: DisplayArtist
}

function isEditProps(props: EditProps | {}): props is EditProps {
  return (
    !!(props as EditProps).initialInputArtistRecommendation &&
    !!(props as EditProps).initialSelectedArtist
  )
}

type ArtistSearchFieldProps<Form> = {
  name: keyof Form
  //   value?: StrictField<Form, Name>
  // renderItem: (value: StrictField<Form, Name>) => React.ReactNode
} & (EditProps | {})

export default function ArtistSearchField<Form>(
  props: ArtistSearchFieldProps<Form>
) {
  const { name } = props
  const [{ value }, , { insert, replace }] = useFieldArray<
    ArtistHit & ArtistRecommendationUpdate
  >(name)

  const [queryText, setQueryText] = useState('')

  const artists = null
  const isLoadingInitialData = false

  const [artistRecommendation, setArtistRecommendation] =
    useState<ArtistRecommendationUpdate | null>(
      isEditProps(props) ? props.initialInputArtistRecommendation : null
    )

  const [selectedArtist, setSelectedArtist] = useState<
    ArtistHit | DisplayArtist | null
  >(isEditProps(props) ? props.initialSelectedArtist : null)

  const addArtist = () => {
    const isValidated = () => {
      if (
        value?.some(({ id }) => id === artistRecommendation?.artist_id) &&
        !isEditProps(props)
      ) {
        alert(`You tried to add the same artist twice.`)
        return false
      }
      if (artistRecommendation?.artist_id && artistRecommendation?.price) {
        return true
      }
      return false
    }
    if (isValidated()) {
      const isEditing = isEditProps(props)
      if (isEditing) {
        // TEST THIS!
        const index = value?.findIndex(
          ({ id }) => id === artistRecommendation?.artist_id
        )
        if (index !== -1) {
          replace(index, { ...selectedArtist, ...artistRecommendation })
        }
      } else {
        // this order is important to allow field overrides!
        insert(0, { ...selectedArtist, ...artistRecommendation })
      }
      setSelectedArtist(null)
      setArtistRecommendation(null)
    } else {
    }
  }

  const options = useMemo<AutoCompleteOption[]>(() => {
    return (
      artists
        ?.filter((artist) => artist?.objectID)
        .map((artist) => {
          return {
            value: artist?.objectID,
            label: artist?.name,
            subtitle:
              artist?.display_band_configuration &&
              `${numeral(
                artist?.display_band_configuration?.full_band?.prices?.public
                  ?.low ?? 0
              ).format('$0,[0]a')} - ${numeral(
                artist?.display_band_configuration?.full_band?.prices?.public
                  ?.high ?? 0
              ).format('$0,[0]a')}`,
            prefix: (
              <Avatar
                uri={Cloudinary(artist?.profile_image, {
                  width: 100,
                  quality: 75,
                })}
                size="small"
              />
            ),
          } as AutoCompleteOption
        }) ?? []
    )
  }, [artists])

  const renderSelectedArtist = () => {
    if (!selectedArtist) return null

    return (
      <Card sx={{ mt: 3, bg: 'background', borderColor: 'primary' }}>
        <Card.Content>
          <Text sx={{ color: 'primary', mb: 3 }}>
            This artist has not been added yet. Please enter details then click{' '}
            {`"Add"`}.
          </Text>
          <View sx={{ flexDirection: 'row', alignItems: 'center' }}>
            <Avatar
              source={{
                uri: Cloudinary(selectedArtist.profile_image, {
                  quality: 75,
                  width: 300,
                }),
              }}
              sx={{ mr: 3 }}
              size="large"
            />
            <View sx={{ flex: 1 }}>
              <Text sx={{ fontWeight: 'bold', fontSize: 3 }}>
                {selectedArtist.name}
              </Text>
              <Text sx={{ color: 'mutedText' }}>{`${numeral(
                selectedArtist?.display_band_configuration?.full_band?.prices
                  ?.public?.low ?? 0
              ).format('$0,0')} - ${numeral(
                selectedArtist?.display_band_configuration?.full_band?.prices
                  ?.public?.high ?? 0
              ).format('$0,0')}`}</Text>
              <Spacer height={2} />
              <Text>
                {selectedArtist.category_data?.category},{' '}
                {selectedArtist.category_data?.subgenres?.join(', ')}
              </Text>
            </View>
          </View>
          <Spacer />
          <MaskedInput
            type="money"
            onSubmitEditing={addArtist}
            onChangeText={(price) =>
              setArtistRecommendation(
                (recommendation) =>
                  recommendation && { ...recommendation, price: Number(price) }
              )
            }
            // number to string
            value={(artistRecommendation?.price as any) ?? undefined}
            keyboardType="number-pad"
            placeholder="Price"
            label="$"
            options={{
              precision: 0,
              separator: '.',
              delimiter: ',',
              unit: '',
              suffixUnit: '',
            }}
          />
          <Spacer />
          <TextInput
            value={artistRecommendation?.details ?? ''}
            onChangeText={(details) =>
              setArtistRecommendation(
                (recommendation) =>
                  recommendation && { ...recommendation, details }
              )
            }
            onSubmitEditing={addArtist}
            editable
            multiline
            numberOfLines={3}
            placeholder="(Optional) Add details about this artist for buyers to see."
          />
        </Card.Content>
        <Card.Footer sx={{ justifyContent: 'space-between' }}>
          <Button onPress={addArtist}>Add</Button>
          <Button
            sx={{ borderColor: 'error', color: 'error' }}
            variant="outlined"
            onPress={() => setSelectedArtist(null)}
          >
            Cancel
          </Button>
        </Card.Footer>
      </Card>
    )
  }

  return (
    <View sx={{}}>
      <AutoComplete
        shouldSetControlledStateOnPress={false}
        searching={isLoadingInitialData || isLoadingMore}
        options={options}
        keyExtractor={(item) => item.value}
        value={queryText}
        onChangeText={setQueryText}
        placeholder="Search Artists..."
        align="left"
        onSelect={(artistId) => {
          const artist = artists?.find(
            (artist) => artist && artist.id === artistId
          )

          if (artist?.id) {
            setSelectedArtist(artist)
            setArtistRecommendation({
              artist_id: artist.id,
              details: '',
              price:
                artist?.display_band_configuration?.full_band?.prices?.public
                  ?.high ?? 0,
            })
          }

          //   if (artist) {
          //     push(artist)
          //   }
        }}
      />
      {renderSelectedArtist()}
    </View>
  )
}
