// @ts-nocheck
import React, { useCallback } from 'react'
import EditRecommendedBookingForm, {
  EditProps,
} from '../edit-recommended-booking-screen/form'
import { useNavigation, useRoute } from '@react-navigation/native'
import type { EditRecommendedBookingRoute } from '@beatgig/navigation/stacks/calendar-stack/types'
import { Status } from '@beatgig/api-services'
import { Text } from 'dripsy'
import RecommendedBookingsExplanationCard from '@beatgig/ui/recommended-bookings-explanation-card'
import useEditRecommendedBooking from '@beatgig/api-hooks/src/use-edit-recommended-booking'
import useGetInputRecommendedBooking from '@beatgig/api-hooks/src/use-get-input-recommended-booking'

import WebModal from '@beatgig/components/web-modal'
import Button from '@beatgig/components/button'
import LoadingScreen from '@beatgig/components/loading-screen'
import useRecommendedBooking from '@beatgig/api-hooks/src/use-recommended-booking'
import AcceptRecommendedArtistForm from './accept-artist-form'
import Note from '@beatgig/design/components/note'
import useCancelRecommendedBooking from '@beatgig/api-hooks/src/use-cancel-recommended-booking'
import useAreYouSure from '@beatgig/hooks/use-are-you-sure'
import useToast from '@beatgig/design/hooks/use-toast'
import FormRow from '@beatgig/forms/components/row'
import useServerFormError from '@beatgig/forms/hooks/use-server-form-error'
import useNavigateToBooking from '@beatgig/navigation/hooks/use-navigate-to-booking'
import { useAdminContext } from '@beatgig/providers/Admin/context'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

export default function EditRecommendedBookingScreen() {
  const { goBack } = useNavigation()
  const {
    params: { recommendedBookingId, venueLocation, venueName },
  } = useRoute<EditRecommendedBookingRoute>()

  const { toast } = useToast()

  const { execute, error, setError } = useEditRecommendedBooking()

  const maybeAdminContext = useAdminContext()

  const { navigate: openBooking } = useNavigateToBooking({
    navigationMethod: maybeAdminContext?.isAdminApp
      ? 'react-navigation'
      : 'platform-specific',
  })

  const makeOpenBooking = (bookingId: string) => () => {
    openBooking({
      id: bookingId,
    })
  }

  const { handleServerFormError } = useServerFormError()

  const onSubmit = useCallback<EditProps['onEdit']>(
    async (props, formik, scrollableProps) => {
      try {
        const newRecommendation = await execute(recommendedBookingId, props)
        return { shouldGoBack: !!newRecommendation?.id }
      } catch (error) {
        handleServerFormError({
          error,
          ...formik,
          ...scrollableProps,
          setError,
          formName: 'Edit Recommended Booking',
        })

        return {
          shouldGoBack: false,
        }
      }
    },
    [execute, handleServerFormError, recommendedBookingId, setError]
  )

  const {
    execute: cancelRecommendedBooking,
    loading: isCanceling,
    error: cancelError,
  } = useCancelRecommendedBooking()

  const areYouSureYouWantToCancel = useAreYouSure({
    action: 'cancel this artist recommendation request',
    cancelText: 'Never mind, go back',
  })

  const { data: inputRecommendedBooking, error: inputRecommendedBookingError } =
    useGetInputRecommendedBooking({ recommendedBookingId })

  const { data: recommendedBooking, error: recommendedBookingError } =
    useRecommendedBooking(
      {
        recommendedBookingId,
      },
      {
        cachePolicy: 'network-only',
      }
    )

  const { top } = useSafeAreaInsets()

  if (!inputRecommendedBooking || !recommendedBooking) {
    return (
      <WebModal headerTopInset={top} header onRequestClose={goBack}>
        <LoadingScreen
          error={inputRecommendedBookingError || recommendedBookingError}
        >
          Loading recommended booking...
        </LoadingScreen>
      </WebModal>
    )
  }

  if (recommendedBooking.status === Status.CANCELLED) {
    return (
      <WebModal
        headerTopInset={top}
        onRequestClose={goBack}
        header
        title="Canceled"
      >
        <FormRow>
          <Text>This recommended booking request was cancelled.</Text>
        </FormRow>
      </WebModal>
    )
  }

  if (!recommendedBooking.can_i_edit_recommended_booking) {
    // this will never be the case for admins

    return (
      <AcceptRecommendedArtistForm
        onClose={goBack}
        recommendedBooking={recommendedBooking}
        animated={false}
      />
    )
  }

  const bookingId = recommendedBooking.booking_id

  return (
    <EditRecommendedBookingForm
      useMultipleDates={false}
      formHeader={
        <>
          {!!bookingId && (
            <Note
              variant="blue"
              filling="filled"
              actions={
                <Button
                  onPress={makeOpenBooking(bookingId)}
                  iconLeft={{
                    name: 'open-outline',
                  }}
                  sx={{ bg: 'text', borderColor: 'text' }}
                >
                  View Booking
                </Button>
              }
              sx={{ mb: 3 }}
            >
              Your booking is confirmed.
            </Note>
          )}
          {recommendedBooking.frontend_directions?.notes?.map((note, i) => (
            <Note sx={{ mb: 3 }} key={i} {...note} filling="filled" />
          ))}
          <RecommendedBookingsExplanationCard
            venueName={venueName}
            sx={{ mb: 4, mt: 3 }}
          />
        </>
      }
      isCancelingRecommendedBooking={isCanceling}
      onCancelRecommendedBooking={async () => {
        areYouSureYouWantToCancel(async () => {
          const didCancel = await cancelRecommendedBooking({
            recommendedBookingId,
          })
          if (didCancel) {
            goBack()
            toast('Your recommended booking was successfully canceled.')
          }
        })
      }}
      title="Edit Recommended Booking"
      animated={false}
      buttonTitle="Save"
      onEdit={onSubmit}
      cancelRecommendedBookingError={cancelError}
      initialRecommendedBooking={inputRecommendedBooking}
      onClose={goBack}
      networkError={error}
      venueLocation={venueLocation}
      venueName={venueName}
    />
  )
}
