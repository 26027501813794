// @ts-nocheck

import React, { useState } from 'react'
import { View, H4, Text } from 'dripsy'
import {
  DisplayRecommendedBooking,
  ServerError,
  Status,
} from '@beatgig/api-services'
import Entity from '@beatgig/design/components/entity'
import Avatar from '@beatgig/design/components/avatar'
import Cloudinary from '@beatgig/helpers/cloudinary'
import EntityField from '@beatgig/design/components/entity/entity-field'
import numeral from 'numeral'
import Button from '@beatgig/components/button'
import A from '@beatgig/components/a'
import { RecommendedBookingApi } from '@beatgig/api/recommended-booking'
import useDisplayBookingStartTime from '@beatgig/helpers/display-booking-start-time'
import MetaIcon from '@beatgig/design/components/meta-icon'
import Spacer from '@beatgig/design/components/spacer'
import Description from '@beatgig/design/components/description'
import Note from '@beatgig/design/components/note'
import Link from '@beatgig/design/components/link'
import Card from '@beatgig/components/card'
import locationToString from '@beatgig/helpers/location-to-string'
import Badge from '@beatgig/design/components/badge'
import { makeArtistSlug } from '@beatgig/navigation/routes/artist-routes'
import EntityFooter from '@beatgig/design/components/entity/entity-footer'
import useNavigateToBooking from '@beatgig/navigation/hooks/use-navigate-to-booking'
import { useAdminContext } from '@beatgig/providers/Admin/context'
import Input from '@beatgig/design/components/input'
import { APP_NAME } from '@beatgig/constants'
import ShowMoreText from '@beatgig/design/components/show-more-text'
import OpenBookingExternallyButton from '../booking/open-booking-externally-button'
import ErrorNote from '@beatgig/components/ErrorNote'

type Props = {
  recommendedBooking: DisplayRecommendedBooking | null
} & React.ComponentProps<typeof View> &
  (
    | (RecommendedBookingActions & {
        disableRecommendationActions: false
      })
    | {
        disableRecommendationActions: true
      }
  )

export type RecommendedBookingActions = {
  onAcceptRecommendedArtist?: (
    a: Parameters<typeof RecommendedBookingApi['acceptRecommendedBooking']>[0]
  ) => void
  isAcceptingRecommendedArtist?: boolean
  onRejectRecommendedArtists: (
    a: Parameters<typeof RecommendedBookingApi['rejectRecommendedBooking']>[0]
  ) => void
  isRejectingRecommendedArtists: boolean
  acceptRecommendedArtistError?: ServerError | null
}

/**
 * @deprecated this feature isn't used anymore
 */
export default function RecommendedBookingExpandedPreview(props: Props) {
  const { recommendedBooking, ...rest } = props

  const [pendingSelectedArtistId, setPendingSelectedArtistId] = useState('')
  const [pendingRejectRecommendation, setPendingRejectRecommendation] =
    useState(false)
  const [rejectionMessage, setRejectionMessage] = useState('')

  const maybeAdminContext = useAdminContext()

  const { navigate: openBooking } = useNavigateToBooking({
    navigationMethod: maybeAdminContext?.isAdminApp
      ? 'react-navigation'
      : 'platform-specific',
  })

  const makeOpenBooking = (bookingId: string) => () => {
    openBooking({
      id: bookingId,
    })
  }

  const { formattedStartTime, formattedStartDate } =
    useDisplayBookingStartTime(recommendedBooking)
  if (!recommendedBooking) return null

  const onPressRejectRecommendation = () => {
    if (pendingRejectRecommendation && !props.disableRecommendationActions) {
      props.onRejectRecommendedArtists({
        recommendedBookingId: recommendedBooking.id,
        rejectionMessage,
      })
    } else {
      setPendingRejectRecommendation(true)
    }
  }

  const onPressAcceptRecommendedArtist = (artistId: string) => {
    if (pendingSelectedArtistId !== artistId) {
      setPendingSelectedArtistId(artistId)
      return
    }
    if (
      pendingSelectedArtistId === artistId &&
      !props.disableRecommendationActions
    ) {
      props.onAcceptRecommendedArtist?.({
        artistId,
        recommendedBookingId: recommendedBooking.id,
      })
    }
  }

  const {
    budget,
    created_by,
    created_at,
    start_time,
    status,
    artist_recommendations,
    admin_instructions,
    artist_instructions,
    genres,
    frontend_directions,
    can_recommend_artists,
    billing,
    indoors_or_outdoors,
    venue_location,
    venue_name,
    can_accept_and_reject_artists = false,
    booking_id,
    performance_length_mins,
    rejection_message,
  } = recommendedBooking

  let budgetText = ``
  const formatRate = (rate?: number) =>
    rate ? numeral(`${rate}`).format('$0,0') : ''

  if (budget.low) {
    budgetText = formatRate(budget.low)
    if (budget.high) {
      budgetText = `${budgetText} - `
    }
  }
  if (budget.high) {
    budgetText = `${budgetText}${formatRate(budget.high)}`
  }

  console.log('[recommended-booking-preview]', { frontend_directions })

  return (
    <View {...rest}>
      {!!booking_id && (
        <Note
          variant="blue"
          filling="filled"
          actions={
            <OpenBookingExternallyButton
              sx={{ bg: 'text', borderColor: 'text' }}
              bookingId={booking_id}
            >
              View Booking
            </OpenBookingExternallyButton>
          }
          sx={{ mb: 3, justifyContent: 'center' }}
        >
          Your booking is confirmed.
        </Note>
      )}
      {!!frontend_directions?.notes?.length &&
        frontend_directions.notes.map((note, i) => (
          <Note key={i} {...note} variant={note.variant} sx={{ mb: 3 }} />
        ))}
      {!!artist_recommendations?.length && !props.disableRecommendationActions && (
        <>
          <Card>
            <H4 sx={{ mb: 2 }}>
              Recommended{' '}
              {artist_recommendations.length > 1 ? 'Artists' : 'Artist'}
            </H4>
            <Text sx={{ mb: 3, color: 'mutedText' }}>
              {status !== Status.ACCEPTED &&
                (artist_recommendations.length > 1
                  ? `Choose one of these artists to perform at ${venue_name}.`
                  : `${artist_recommendations?.[0].name} is an ideal fit to perform at ${venue_name}.`)}
            </Text>
            {artist_recommendations.map(
              ({ name, profile_image, price, artist_id, details, slug }, i) => {
                const isRecommendationPendingSelected =
                  artist_id === pendingSelectedArtistId
                return (
                  <Entity
                    key={artist_id}
                    thumbnail={<Avatar uri={Cloudinary(profile_image)} />}
                    sx={{ mt: i > 0 ? 3 : 0, bg: 'background' }}
                    footer={
                      details && (
                        <EntityFooter>
                          <EntityField title="Details" subtitle={details} />
                          {isRecommendationPendingSelected &&
                            !props.disableRecommendationActions && (
                              <ErrorNote
                                error={props.acceptRecommendedArtistError}
                                sx={{ mt: 3 }}
                              />
                            )}
                        </EntityFooter>
                      )
                    }
                    wrap
                    actions={
                      can_accept_and_reject_artists && (
                        <Button
                          onPress={() =>
                            onPressAcceptRecommendedArtist(artist_id)
                          }
                          disabled={props.isAcceptingRecommendedArtist}
                          loading={
                            props.isAcceptingRecommendedArtist &&
                            isRecommendationPendingSelected
                          }
                          sx={
                            isRecommendationPendingSelected
                              ? {
                                  bg: 'success',
                                  borderColor: 'success',
                                  color: 'background',
                                }
                              : undefined
                          }
                          variant="small"
                        >
                          {isRecommendationPendingSelected
                            ? 'Confirm Booking?'
                            : 'Book Artist'}
                        </Button>
                      )
                    }
                  >
                    <EntityField
                      title={
                        <>
                          <A
                            isText={false}
                            href={slug && `/${makeArtistSlug(slug)}`}
                            target="_blank"
                            sx={{ cursor: 'pointer' }}
                          >
                            <View
                              sx={{
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <Text sx={{ fontWeight: 'bold' }}>{name} </Text>
                              <Link
                                disabled
                                sx={{ cursor: 'pointer' }}
                                color
                                icon
                              >
                                {' '}
                              </Link>
                            </View>
                          </A>
                        </>
                      }
                      subtitle={numeral(price).format('$0,0')}
                    />
                  </Entity>
                )
              }
            )}
          </Card>
          <Spacer />
        </>
      )}
      <Card>
        <H4 sx={{ mb: 0 }}>Preferences</H4>
        {!!rejection_message && (
          <Description title="Rejection Message" sx={{ my: 3 }}>
            {rejection_message}
          </Description>
        )}
        {/* <Description></Description> */}
        <Spacer height={3} />
        <Description title="Budget">{budgetText}</Description>
        <Spacer height={4} />
        <Description title="Date">
          <View
            sx={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              mt: (t) => t.space[3] * -1,
            }}
          >
            <MetaIcon
              sx={{ mr: 3, mt: 3 }}
              icon={{ name: 'ios-calendar' }}
              label={formattedStartDate}
              color="text"
            />
            <MetaIcon
              sx={{ mr: 3, mt: 3 }}
              icon={{ name: 'ios-time' }}
              label={formattedStartTime}
              color="text"
            />
            <MetaIcon
              sx={{ mr: 3, mt: 3 }}
              icon={{ name: 'watch' }}
              label={`${performance_length_mins} minutes`}
              color="text"
            />
          </View>
        </Description>
        {!!genres?.length && (
          <Description
            sx={{ mt: 4 }}
            title={genres?.length > 1 ? 'Genres' : 'Genre'}
          >
            <View sx={{ flexDirection: 'row', flexWrap: 'wrap' }}>
              {genres.map((genre) => (
                <Badge
                  variant="callout"
                  filling="outlined"
                  key={genre}
                  sx={{ mr: 2, mb: 2 }}
                >
                  {genre}
                </Badge>
              ))}
            </View>
          </Description>
        )}
        <Spacer height={4} />
        <View sx={{ flexDirection: 'row' }}>
          <Description sx={{ flex: 1 }} title="Billing">
            {billing}
          </Description>
          <Description sx={{ flex: 1 }} title="Indoors/Outdoors">
            {indoors_or_outdoors}
          </Description>
        </View>
      </Card>
      <Spacer height={3} />
      {!!(admin_instructions || artist_instructions) && (
        <Card>
          <H4>Instructions</H4>
          {!!admin_instructions && (
            <Description title={APP_NAME + ' instructions'}>
              <ShowMoreText text={admin_instructions} />
            </Description>
          )}
          {!!artist_instructions && (
            <Description sx={{ mt: 4 }} title="Artist Instructions">
              <ShowMoreText text={artist_instructions} />
            </Description>
          )}
        </Card>
      )}
      <Spacer height={3} />
      <Card>
        <H4>Venue</H4>
        <EntityField
          title={venue_name}
          subtitle={locationToString(venue_location)}
        />
        {/* <Entity sx={{ bg: 'background' }}>
        </Entity> */}
      </Card>
      {!!recommendedBooking.can_accept_and_reject_artists &&
        !props.disableRecommendationActions && (
          <Card sx={{ mt: 3 }}>
            <Text>
              Do you want to reject these recommendations?{' '}
              {pendingRejectRecommendation &&
                'This will permanently remove this recommendation.'}
            </Text>
            {pendingRejectRecommendation && (
              <Input
                // sx={{ mt: 3 }}
                pressSx={{
                  mt: 3,
                }}
                value={rejectionMessage}
                onChangeText={setRejectionMessage}
                multiline
                numberOfLines={3}
                placeholder="Please tell us why you are rejecting. This will help improve future recommendations."
              />
            )}
            <View sx={{ mt: 3 }}>
              <Button
                disabled={props.isRejectingRecommendedArtists}
                loading={props.isRejectingRecommendedArtists}
                onPress={onPressRejectRecommendation}
                sx={{
                  alignSelf: 'flex-start',
                  color: 'error',
                  bg: 'transparent',
                  borderColor: 'error',
                }}
              >
                {pendingRejectRecommendation
                  ? 'Yes, I want to reject'
                  : 'Reject Recommendation'}
              </Button>
            </View>
          </Card>
        )}
      <Spacer />
    </View>
  )
}
