import React, { useMemo } from 'react'
import { UpdateBookingRequestForm } from '../update'
import type {
  CreateBookingRequestProps,
  UpdateOrCreateBookingRequestSharedProps,
} from '../update/types'
import { CreateBookingRequestHeader } from './explanation'

type Props = Omit<CreateBookingRequestProps, 'useMultipleDates'> &
  UpdateOrCreateBookingRequestSharedProps

export function CreateBookingRequestForm(props: Props) {
  return (
    <UpdateBookingRequestForm
      formHeader={useMemo(
        () => (
          <CreateBookingRequestHeader venueName={props.venueName} />
        ),
        [props.venueName]
      )}
      useMultipleDates
      title="New Request"
      buttonTitle="Create"
      {...props}
    />
  )
}
