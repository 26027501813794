import { useState, useMemo, useCallback, useRef, useEffect } from 'react'
import { DateTime } from 'luxon'
import { cheatFormatBookingDateForCalendar } from '@beatgig/helpers/cheat-format-booking-calendar-date'
import { UserRole } from '@beatgig/api-services'
import { useSelectedCalendarVenueSlugs } from '../use-selected-calendar-venues'
import locationToString from '@beatgig/helpers/location-to-string'
import { useBadgeCount } from '@beatgig/search/hooks/authed/use-authed-bookings-and-recs-action-required-badge'
import { useRouting } from 'expo-next-react-navigation'
import {
  useCalendarScreenQuery,
  useMyExternalCalendarEventsQuery,
} from '@beatgig/gql'
import { useLatestCallback } from '@beatgig/helpers/use-latest-callback'
import { useAdminOnBehalfOf } from '@beatgig/gql/admin-on-behalf-of'
import {
  BigCalendarBooking,
  BigCalendarBookingRequest,
  BigCalendarExternalBooking,
} from '@beatgig/ui/bookings-big-calendar/types'
import { SelectedDate } from '@beatgig/screens/small-calendar-screen/use-selected-date/types'
import useMyVenues from '@beatgig/api-hooks/src/use-my-venues'
import useMyArtists from '@beatgig/api-hooks/src/use-my-artists'
import useSWRReactNavigation from '@beatgig/api-hooks/src/use-native-revalidate'

type Props = {
  userId: string
  userRole: UserRole
  selectedDate: SelectedDate
}

export const useCalendarQuery = ({ userId, userRole, selectedDate }: Props) => {
  const [query, setQuery] = useState('')
  const { visibleVenueSlugs: unsortedVenueSlugs, setVisibleVenueSlugs } =
    useSelectedCalendarVenueSlugs()

  const myVenues = useMyVenues({ userId }).data

  const visibleVenueSlugs = useMemo(
    () =>
      myVenues
        ?.map(({ slug }) => slug)
        .filter((venue) => {
          if (!unsortedVenueSlugs?.length) {
            return true
          }

          return unsortedVenueSlugs.includes(venue)
        }),
    [myVenues, unsortedVenueSlugs]
  )

  const dateFilter = useMemo(() => {
    const { day, month, year } = selectedDate
    const date = DateTime.fromObject({
      day,
      month,
      year,
    })

    const min = date.startOf('month').minus({ day: 6 }).toLocal().toISO()
    const max = date
      .endOf('month')
      .set({ hour: 1, minute: 0, second: 0, millisecond: 0 })
      .plus({ day: 6 })
      .toLocal()
      .toISO()

    return {
      min,
      max,
    }
  }, [selectedDate])

  const [calendarQuery, refetch] = useCalendarScreenQuery(
    useAdminOnBehalfOf(userId, {
      variables: {
        venueSlugs: visibleVenueSlugs ?? undefined,
        startTimeMin: dateFilter.min,
        startTimeMax: dateFilter.max,
        limit: 1500, // FIXME gql profile image goes empty if this is too high for cade??
        offset: 0,
      },
      requestPolicy: 'cache-and-network',
    })
  )
  const [externalCalendarQuery] = useMyExternalCalendarEventsQuery({
    variables: {
      startTimeMin: dateFilter.min,
      startTimeMax: dateFilter.max,
    },
    requestPolicy: 'cache-first',
  })

  const bookingRequestId: string | undefined =
    useRouting().getParam('bookingRequestId')

  const toggleSelectedVenueVisible = useCallback(
    ({ slug }: { slug: string }) => {
      setVisibleVenueSlugs((_state) => {
        const state = _state || myVenues?.map((venue) => venue.slug) || []
        if (state?.includes(slug)) {
          return state.filter((venueSlug) => venueSlug !== slug)
        }
        if (!state) {
          return [slug]
        }
        return [...state, slug]
      })
    },
    [myVenues, setVisibleVenueSlugs]
  )

  const selectedVenues = useMemo(() => {
    return myVenues?.map(({ slug, name, location, id }) => {
      const hasOtherVenueInSameCity = myVenues?.some(
        (venue) => venue.location.city === location.city && venue.id !== id
      )
      const selected = visibleVenueSlugs?.includes(slug)
      return {
        selected,
        name,
        slug,
        toggleSelected: toggleSelectedVenueVisible,
        location: locationToString(location, {
          postalCode: false,
          stateAbbr: false,
          streetAddress: hasOtherVenueInSameCity,
        }),
      }
    })
  }, [myVenues, toggleSelectedVenueVisible, visibleVenueSlugs])

  const { revalidate: revalidateActionsRequired, badgeCount } = useBadgeCount({
    userId,
  })

  const firstActionRequiredStartTime = undefined

  const actionsRequiredDateObject = useMemo(() => {
    if (!firstActionRequiredStartTime) return null

    const { year, month, day } = DateTime.fromJSDate(
      new Date(firstActionRequiredStartTime)
    )

    return {
      year,
      month,
      day,
    }
  }, [firstActionRequiredStartTime])

  const revalidate = useLatestCallback(async () => {
    revalidateActionsRequired()
    refetch()
    return false
  })

  const isPulllingToRefreshRef = useRef(false)
  const onPullToRefresh = useLatestCallback(async () => {
    isPulllingToRefreshRef.current = true
    revalidate()
  })

  const isPullingToRefresh =
    calendarQuery.stale && isPulllingToRefreshRef.current

  useEffect(() => {
    if (!calendarQuery.stale) {
      isPulllingToRefreshRef.current = false
    }
  }, [calendarQuery.stale])
  useSWRReactNavigation({
    // FIXME this should not be needed. however, myBookingRequests isn't updating after creating one.
    // https://github.com/nandorojo/beatgig-playground/issues/459
    revalidate,
  })

  const bookingRequests = useMemo<Array<BigCalendarBookingRequest>>(
    () =>
      calendarQuery.data?.myBookingRequests.map((bookingRequest) => ({
        ...bookingRequest,
        venue: bookingRequest.venue && {
          id: bookingRequest.venue.id,
          name: bookingRequest.venue.name,
        },
      })) || [],
    [calendarQuery.data?.myBookingRequests]
  )

  type BookingRequests = Array<BigCalendarBookingRequest>

  const bookingRequestsWithFakeDates = useMemo<BookingRequests>(() => {
    return bookingRequests?.map((request) => {
      const nextRequest: BigCalendarBookingRequest =
        cheatFormatBookingDateForCalendar(request)

      return nextRequest
    })
  }, [bookingRequests])

  type Bookings = Array<BigCalendarBooking>

  const bookings = useMemo<Bookings | undefined>(
    () =>
      calendarQuery.data?.myBookings.map((booking) => ({
        ...booking,
        buyer: {
          metadata: booking.buyerMetadata,
        },
        artist: {
          ...booking.artist,
          profileImage: booking.artist.profileImage && {
            ...booking.artist.profileImage,
            publicCloudinaryId:
              booking.artist.profileImage?.public_cloudinary_id,
          },
        },
      })),
    [calendarQuery.data?.myBookings]
  )

  const bookingsWithFakeDates = useMemo(() => {
    const bookingsWithFakeDates: Bookings = []
    for (const booking of bookings || []) {
      bookingsWithFakeDates.push(
        cheatFormatBookingDateForCalendar({
          ...booking,
          venue_location: booking.location,
        })
      )
    }
    return bookingsWithFakeDates
  }, [bookings])

  type ExternalBookings = Array<BigCalendarExternalBooking>

  const externalBookings = useMemo<ExternalBookings | undefined>(
    () =>
      calendarQuery.data?.myExternalBookings.map((booking) => ({
        ...booking,
        location: booking.venue.location,
        venue_name: booking.venue.name,
      })),
    [calendarQuery.data?.myExternalBookings]
  )

  const externalBookingsWithFakeDates = useMemo(() => {
    const externalBookingsWithFakeDates: ExternalBookings = []

    for (const externalBooking of externalBookings || []) {
      externalBookingsWithFakeDates.push(
        cheatFormatBookingDateForCalendar({
          ...externalBooking,
          venue_location: externalBooking.location,
        })
      )
    }

    return externalBookingsWithFakeDates
  }, [externalBookings])

  const handlers = useMemo(() => {
    return {
      onChangeText: setQuery,
      // onChangeDateRange,
      // onMonthChange,
    }
  }, [])

  const selectedVenueCount = useMemo(
    () => selectedVenues?.filter((venue) => venue.selected)?.length ?? 0,
    [selectedVenues]
  )

  const myArtists = useMyArtists().data
  const myArtistIds = useMemo(() => {
    return myArtists?.map((artist) => artist.id) || null
  }, [myArtists])

  return [
    {
      bookings: {
        data: bookings,
        withFakeDates: bookingsWithFakeDates,
      },
      bookingRequests: {
        withFakeDates: bookingRequestsWithFakeDates,
        data: bookingRequests,
      },
      extenalBookings: {
        withFakeDates: externalBookingsWithFakeDates,
        data: externalBookings,
      },
      // externalCalendarEvents: me?.externalCalendarEvents,
      externalCalendarEvents:
        externalCalendarQuery.data?.myExternalCalendarEvents,
      // externalCalendarQuery.data?.myExternalCalendarEvents,
      actionsRequiredBadgeCount: badgeCount,
      query,
      actionsRequiredDateObject,
      selectedVenues,
      selectedVenueCount,
      revalidate,
      bookingRequestId,
      isValidating: calendarQuery.fetching,
      isPullingToRefresh,
      onPullToRefresh,
      myArtistIds,
    },
    handlers,
  ] as const
}
