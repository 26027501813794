import { Billing, IndoorsOrOutdoors } from '@beatgig/api-services/booking'
import { CreateBookingRequestProps } from '../../update/types'
import useServerFormError from '@beatgig/forms/hooks/use-server-form-error'
import useToast from '@beatgig/design/hooks/use-toast'
import { useNavigation } from '@react-navigation/native'
import { useRoute } from '@react-navigation/native'
import type { CreateBookingRequestRoute } from '@beatgig/navigation/stacks/calendar-stack/types'
import { useCallback, useState, useMemo, useRef } from 'react'
import useCreateBookingRequest from '@beatgig/api-hooks/booking-request/create'
import { DateTime } from 'luxon'
import { validateParamsDate } from '@beatgig/screens/small-calendar-screen/use-selected-date/types'
import useMyVenues from '@beatgig/api-hooks/src/use-my-venues'

export function useCreateBookingRequestRoute() {
  const {
    params: { userId, startDay, startMonth, startYear },
  } = useRoute<CreateBookingRequestRoute>()
  const { goBack } = useNavigation()

  const { data: venues, error: getVenuesError } = useMyVenues({ userId })
  const [selectedVenue, setSelectedVenue] = useState(() => {
    if (venues?.length === 1) {
      return venues?.[0] ?? null
    }
    return null
    // if (!venueId) return null
    // return venues?.find(({ id }) => id === venueId) ?? null
  })
  const now = useRef(DateTime.fromObject({}))

  const startDate = useMemo<
    | {
        year: number
        day: number
        month: number
      }
    | undefined
  >(
    () =>
      startDay && startMonth && startYear
        ? validateParamsDate(
            {
              day: startDay,
              month: startMonth,
              year: startYear,
            },
            now.current
          )
        : undefined,
    [startDay, startMonth, startYear]
  )

  const { toast } = useToast()

  const { execute, error: createError } = useCreateBookingRequest({
    onSuccess() {
      toast('Booking request created!')
    },
    userId,
  })

  const { handleServerFormError } = useServerFormError()

  const venueId = selectedVenue?.id

  const onSubmit = useCallback<CreateBookingRequestProps['onCreate']>(
    async ({ startTimes, ...input }, formik, scrollableProps) => {
      if (!venueId) {
        return {
          didWork: false,
        }
      }

      const { data, error } = await execute({
        input,
        startTimes,
        venueId,
        artistIds: [],
      })

      if (data?.createBookingRequestsBuyer && !error) {
        toast('Your booking request was sent!')
        return { didWork: true }
      }

      if (error) {
        handleServerFormError({
          error,
          ...formik,
          ...scrollableProps,
          formName: 'Create Booking Request',
        })
      }

      return {
        didWork: false,
      }
    },
    [execute, handleServerFormError, toast, venueId]
  )

  const onSelectVenue = useCallback(
    (venue: NonNullable<typeof selectedVenue>) => {
      setSelectedVenue(venue)
    },
    []
  )

  const initialBookingRequest = useMemo<
    CreateBookingRequestProps['initialBookingRequest'] | null
  >(
    () =>
      selectedVenue && {
        genres: [],
        performanceLengthMins: 0,
        billing: Billing.HEADLINER,
        indoorsOrOutdoors: IndoorsOrOutdoors.INDOORS,
        startTimes: [
          // startTime ||
          DateTime.fromObject({
            hour: 21,
            minute: 0,
            second: 0,
            ...startDate,
          })
            .setZone(selectedVenue.location.timezone, {
              keepLocalTime: true,
            })
            .toJSDate()
            .toISOString(),
        ],
        adminInstructions: '',
        artistInstructions: '',
      },
    [selectedVenue, startDate]
  )

  return {
    onSubmit,
    onSelectVenue,
    selectedVenue,
    goBack,
    getVenuesError,
    initialBookingRequest,
    createError,
    venues,
    startDate,
  }
}
