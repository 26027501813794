import { useCreateVenueMutation } from '@beatgig/gql'

export function useCreateVenue() {
  const [{ error }, execute] = useCreateVenueMutation()

  return {
    error,
    execute,
  }
}
