import { useCallback } from 'react'

import { useOpenModal } from '@beatgig/modals/create-modal-stack/use-open-modal'
import { WebCalendarModalStackParams } from '@beatgig/ui/booking-request-modal-route/types'

// export type UseCalendarActionRequiredProps = {
//   setSelectedDate: UseSelectedDate['setSelectedDate']
//   firstUpcomingDateObject: SelectedDate | null
//   calendarRef?: React.RefObject<SmallCalendar>
//   onMonthChange: ReturnType<typeof useCalendarQuery>[1]['onMonthChange']
//   selectedDate: UseSelectedDate['selectedDate']
//   anchorsRef: ReturnType<typeof useAnchors>
//   userRole: UserRole
//   userId: string
//   userName: string
// }

export function useCalendarActionRequired() {
  // {
  // calendarRef,
  // firstUpcomingDateObject,
  // setSelectedDate,
  // onMonthChange,
  // selectedDate,
  // anchorsRef,
  // userRole,
  // userId,
  // userName,
  // }: UseCalendarActionRequiredProps
  // const stableUpcomingDate = useStable(firstUpcomingDateObject)
  // const stableSelectedDate = useStable(selectedDate)

  // const { navigate } = useNavigation<CalendarScreenNavigation>()

  const { openModal } = useOpenModal<WebCalendarModalStackParams>()

  // const onPressOLD = useCallback(async () => {
  //   const firstUpcomingDateObject = stableUpcomingDate.current
  //   const selectedDate = stableSelectedDate.current

  //   if (!firstUpcomingDateObject) return

  //   // navigate('BookingActionsRequired', {
  //   //   userRole,
  //   //   userId,
  //   //   userName,
  //   // })

  //   const pressedCurrentDay = isSameDay(
  //     DateTime.fromObject(selectedDate).toJSDate(),
  //     DateTime.fromObject(firstUpcomingDateObject).toJSDate()
  //   )

  //   // const didScroll = await anchorsRef.current?.scrollTo('list')
  //   // if (!didScroll?.success) {
  //   //   Sentry.captureEvent({
  //   //     message:
  //   //       '[Small Calendar] action required scroll error. Did not scroll.',
  //   //     extra: {
  //   //       message: didScroll?.message,
  //   //     },
  //   //   })
  //   // }
  //   if (pressedCurrentDay) {
  //     return
  //   }

  //   // a hacky way to call these ourselves
  //   // first, set the selected day
  //   setSelectedDate(firstUpcomingDateObject)
  //   // next, scroll to the month of that day
  //   // however, don't fire events for that day
  //   // why? because firing events selects the first day of the month
  //   calendarRef?.current?.updateMonth?.(firstUpcomingDateObject, true)
  //   // since we disabled firing events, we instead trigger this change manually 💅
  //   onMonthChange(firstUpcomingDateObject)
  // }, [
  //   stableUpcomingDate,
  //   stableSelectedDate,
  //   setSelectedDate,
  //   calendarRef,
  //   onMonthChange,
  // ])

  const onPress = useCallback(() => {
    openModal('BookingActionsRequired')
  }, [openModal])

  return {
    onPress,
  }
}
