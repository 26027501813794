import { AllUserAccountTypes } from '@beatgig/api-services/user'
import {
  useState,
  useContext,
  createContext,
  SetStateAction,
  Dispatch,
} from 'react'
import { UserRole, UserDisplay } from '@beatgig/api-services/user'

type Context = {
  hasParentContainer: boolean
  userRoleProp?: UserRole
  adminOverrideUser: null | UserDisplay
  setAdminOverrideUser: Dispatch<SetStateAction<null | UserDisplay>>
}

// only show the user search once in a given component tree
export const OnBehalfOfUserContext = createContext<Context | undefined>(
  undefined
)

export const useOnBehalfOfContext = () => useContext(OnBehalfOfUserContext)
export const useAdminOnBehalfOfUser = ({
  userRole,
}: // independent,
{ userRole?: UserRole } = {}) => {
  const maybeContext = useOnBehalfOfContext()
  const { userRoleProp } = maybeContext || {}

  const independentState = useState<AllUserAccountTypes | null>(null)

  if (independentState[0]?.firebaseId) {
    // we use auth IDs for admin on behalf of.
    // we should really remake this monster of a complicated feature
    independentState[0].id = independentState[0].firebaseId
  }

  if (
    // independent ||
    !maybeContext ||
    (userRole && userRoleProp && userRole !== userRoleProp)
  ) {
    return independentState
  }
  return [
    maybeContext.adminOverrideUser,
    maybeContext.setAdminOverrideUser,
  ] as const
}
