import React from 'react'
import AutoCompleteField from './auto-complete-field'
import type { AutoCompleteFieldProps } from './auto-complete-field'
import { useFieldFast } from '../hooks/use-fast-field'
import { View } from 'dripsy'

export type AutoCompleteFieldRequiredProps<
  T extends object,
  S extends string
> = {
  //   name: FieldName<T> | PathsDot<T>
  /**
   * Required: a function that renders based on the currently selected value for this form path.
   *
   * This is useful if the text input might not exactly match the value that is actually selected.
   *
   * By using this value, we can have a required field, while giving feedback of what the currently selected value is.
   *
   * Typical usecase is selected one of your venues, which cannot be custom text, while letting users see they still have a value selected below.
   */
  renderValue: (value: any) => React.ReactNode
} & AutoCompleteFieldProps<T, S>

export default function AutoCompleteFieldRequired<
  T extends object,
  S extends string = string
>({ renderValue, name, ...props }: AutoCompleteFieldRequiredProps<T, S>) {
  const localName = Array.isArray(name) ? name.join('.') : name
  const [{ value }] = useFieldFast(localName as string)

  return (
    <>
      <View sx={{ zIndex: 1 }}>
        <AutoCompleteField<T>
          name={name as any}
          allowsCustomValue={false}
          shouldClearInputOnEmptyBlur
          {...props}
        />
      </View>
      <View sx={{ zIndex: -1 }}>{renderValue(value)}</View>
    </>
  )
}
