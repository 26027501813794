import { useUserFromAdminQuery } from '@beatgig/gql'

export default function useUserFromAdmin(id: string | null) {
  // const { data } = useMyAccount()
  // return useGet(
  //   () => (User.isAdmin(data) && id ? AdminApi.userSWRKey(id) : null),
  //   (uid: string) => AdminApi.getUser(uid)
  // )
  const [query] = useUserFromAdminQuery({
    variables: {
      id: id || '',
    },
    pause: !id,
  })

  return {
    data: query.data?.user,
    error: query.error,
    isValidating: query.stale,
  }
}
