import { ArtistRecommendationsService } from '@beatgig/api-services/recommended-booking'
import { renameRequestBody, RenameRequestBody } from './types'

export class RecommendedBookingAdminApi {
  static addArtistRecommendations({
    recommendedArtists,
    ...rest
  }: RenameRequestBody<
    typeof ArtistRecommendationsService.writeArtistRecommendations,
    'recommendedArtists'
  >) {
    return ArtistRecommendationsService.writeArtistRecommendations({
      requestBody: recommendedArtists,
      ...rest,
    })
  }
  static get removeArtistRecommendation() {
    return ArtistRecommendationsService.removeArtistRecommendation
  }
  static get updateArtistRecommendations() {
    return renameRequestBody(
      ArtistRecommendationsService.writeArtistRecommendations,
      'artistRecommendations'
    )
  }
}
