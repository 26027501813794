import FilterPopover from '@beatgig/search/filters/filter-popover'
import { useAccountExecVenuesFilter } from './use-account-exec-venues-filter'
import Checkbox from '@beatgig/design/components/checkbox'
import { View, Text } from 'dripsy'
import Link from '@beatgig/design/components/link'
import { useState } from 'react'
import Card from '@beatgig/design/components/card'
import LinkToVenue from '@beatgig/navigation/links/link-to-venue'

type Props = {} & Pick<React.ComponentProps<typeof FilterPopover>, 'mode'> &
  Pick<
    ReturnType<typeof useAccountExecVenuesFilter>,
    | 'accountExecOptions'
    | 'addAccountExec'
    | 'removeAccountExec'
    | 'selectedAccountExecSlugs'
    | 'onClearAllAccountExecs'
    | 'onSelectAllAccountExecs'
    | 'venuesFromAccountExecs'
  >

export function AdminFilterAccountExecVenues({
  mode,
  accountExecOptions,
  addAccountExec,
  removeAccountExec,
  selectedAccountExecSlugs,
  onClearAllAccountExecs,
  onSelectAllAccountExecs,
  venuesFromAccountExecs,
}: Props) {
  const [showVenuesForUserId, setShowVenuesForUserId] = useState<string | null>(
    null
  )
  const closeVenuesForUserId = () => setShowVenuesForUserId(null)
  const filter = (
    <>
      {accountExecOptions.data?.users?.map((accountExec) => {
        const selected = selectedAccountExecSlugs.includes(accountExec.slug)
        const onChange = () => {
          if (!selected) {
            addAccountExec({ slug: accountExec.slug })
          } else {
            removeAccountExec({ slug: accountExec.slug })
          }
        }
        const venuesForThisAccountExec = accountExec.venuesAssignedTo
        const venueCount = venuesForThisAccountExec?.length || 0

        const shouldShowVenues =
          showVenuesForUserId === accountExec.id && !!venueCount

        const onClickVenues = () => {
          setShowVenuesForUserId((id) => {
            if (id === accountExec.id) {
              return null
            }
            return accountExec.id
          })
        }

        return (
          <View key={accountExec.id}>
            <View
              sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Checkbox checked={selected} onChange={onChange} centerContent>
                {accountExec.name}
              </Checkbox>
              {!!venueCount && (
                <Link onPress={onClickVenues} textSx={{ fontSize: '1' }} color>
                  Venues ({venueCount})
                </Link>
              )}
            </View>
            {shouldShowVenues && (
              <View sx={{ ml: 4, my: 3 }}>
                <Card>
                  <Card.Content>
                    {venuesForThisAccountExec?.map((venue) => {
                      return (
                        <View key={venue.id}>
                          <LinkToVenue slug={venue.slug}>
                            <Text>{venue.name}</Text>
                          </LinkToVenue>
                        </View>
                      )
                    })}
                  </Card.Content>
                </Card>
              </View>
            )}
          </View>
        )
      })}
    </>
  )

  return (
    <FilterPopover
      label="Account Execs"
      mode={mode}
      selectedCount={selectedAccountExecSlugs.length}
      totalCount={accountExecOptions.data?.users?.length ?? 0}
      onClearAll={onClearAllAccountExecs}
      onSelectAll={onSelectAllAccountExecs}
      filter={filter}
      initialExpanded={false}
    />
  )
}
