import useBooleanFilter from '@beatgig/search/filters/boolean/use-boolean-filter'

export default function useChargedBuyerFilter() {
  return useBooleanFilter('charge.charged_buyer', {
    defaults: {
      off: true,
      on: true,
    },
    titles: {
      off: 'Not Charged',
      on: '🌯 Charged',
      default: '🌯 Charges',
    },
    labels: {
      off: 'Not Charged',
      on: '🌯 Charged',
    },
    variants: {
      off: 'accent',
      on: 'success',
    },
    persistKey: null,
  })
}
