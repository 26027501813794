import { useLatestCallback } from '@beatgig/helpers/use-latest-callback'
import { useCallback, useState } from 'react'
import type * as Urql from 'urql'

type Args<Variables> = Omit<Urql.UseQueryArgs<Variables>, 'query'>

type UseQuery<Data, Variables> = (
  args: Args<Variables>
) => Urql.UseQueryResponse<Data>

export function usePaginate<Data, Variables = any>(
  useQuery: UseQuery<Data, Variables>,
  arg: Omit<Args<Variables>, 'variables'> & {
    variables: Omit<Variables, 'offset'>
  },
  {
    getLength,
  }: {
    getLength: (data: Data) => number
  }
): Urql.UseQueryResponse<Data>[0] & {
  revalidate: () => void
  canFetchMore: boolean
  isFetchingMore: boolean
  fetchMore: () => void
  isValidating: boolean
  pullToRefresh: () => void
  setFirstPage: () => void
} {
  const limit = (arg.variables as any as { limit: number })?.limit
  if (limit == null) {
    const err = '[usePaginate]: limit is not defined for ' + useQuery.name
    if (__DEV__) {
      throw new Error(err)
    } else {
      console.error(err)
    }
  }
  const [page, setPage] = useState(1)
  const [query, refresh] = useQuery({
    ...arg,
    variables: Object.assign({}, arg.variables as Variables, {
      offset: (page - 1) * limit,
    }),
  })

  const isFetchingMore = Boolean(
    query.stale &&
      query.data &&
      page > 1 &&
      getLength(query.data) === (page - 1) * limit
  )

  const canFetchMore = Boolean(
    !query.fetching && query.data && getLength(query.data) >= page * limit
  )

  const fetchMore = useCallback(
    () => canFetchMore && setPage((page) => page + 1),
    [canFetchMore]
  )

  const revalidate = useCallback(
    () => refresh({ requestPolicy: 'network-only' }),
    [refresh]
  )

  const pullToRefresh = useLatestCallback(() => {
    revalidate()
    setPage(1)
  })

  return {
    ...query,
    revalidate,
    canFetchMore,
    isFetchingMore,
    fetchMore,
    isValidating: query.fetching || query.stale,
    pullToRefresh,
    setFirstPage: useLatestCallback(() => setPage(1)),
  }
}
