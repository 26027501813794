import { useAsyncCallback, UseAsyncCallbackOptions } from 'react-async-hook'
import { Sentry } from '@beatgig/helpers/sentry'
import { Unpromisify } from '@beatgig/helpers/types'
import { useCallback } from 'react'
import { ServerError } from '@beatgig/api-services'
import { AlgoliaRevalidator } from '@beatgig/search/swr-revalidate'
import { AlgoliaPendingLoader } from '@beatgig/search/components/pending-algolia-tasks/algolia'

export type UsePostOptions<
  Returns extends (...args: any) => any
> = UseAsyncCallbackOptions<Unpromisify<ReturnType<Returns>>> & {
  /*
   * Example: `Uploading booking request`, `Sending bid`, etc
   */
  taskName?: string
}

type Fetcher<R = unknown, Args extends any[] = any[]> = (
  ...args: Args
) => Promise<R> | R

export default function usePost<R = unknown, Args extends any[] = any[]>(
  asyncFunction: Fetcher<R, Args>,
  options?: UseAsyncCallbackOptions<R> &
    (
      | {
          /*
           * Example: `Uploading booking request`, `Sending bid`, etc
           */
          taskName?: string
        }
      | null
      | undefined
    )
) {
  const maybeAlgoliaLoaderContext = AlgoliaPendingLoader.useUpdatePendingLoader()
  const { set, result, loading, status, ...callback } = useAsyncCallback<
    R,
    Args
  >(
    async (...args) => {
      let value: R
      try {
        value = await asyncFunction(...args)
      } catch (e) {
        Sentry.captureEvent({
          message: `POST error.`,
          extra: {
            error: e,
          },
        })
        throw e
      }

      if (value && typeof value === 'object' && '__headers' in value) {
        const algoliaIndicesToRevalidate: string | undefined = ((value as any)
          .__headers as object)?.['x-algolia-index-names']
        const algoliaTaskIds: string | undefined = ((value as any)
          .__headers as object)?.['x-algolia-task-ids']

        console.log('[use-post]', {
          algoliaTaskIds,
          algoliaIndicesToRevalidate,
        })

        if (algoliaTaskIds && typeof algoliaTaskIds === 'string') {
          const algoliaTaskIdsArray = algoliaTaskIds.split(',')
          const tasks: Parameters<
            typeof maybeAlgoliaLoaderContext.addTasks
          >[0] = {}

          for (const taskId of algoliaTaskIdsArray) {
            tasks[taskId] = {
              name: options?.taskName || 'Uploading',
            }
          }

          maybeAlgoliaLoaderContext?.addTasks(tasks)
        } else if (
          algoliaIndicesToRevalidate &&
          typeof algoliaIndicesToRevalidate === 'string'
        ) {
          const algoliaIndicesToRevalidateArray = algoliaIndicesToRevalidate.split(
            ','
          )

          AlgoliaRevalidator.revalidate(...algoliaIndicesToRevalidateArray)
        }

        // @ts-expect-error not existing
        delete value.__headers
      }
      return value
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    {
      ...options,
    }
  )

  const setError = useCallback(
    (error: ServerError) => {
      set({
        error,
        result,
        loading,
        status,
      })
    },
    [loading, result, set, status]
  )

  return { ...callback, set, result, loading, setError }
}
