import { RecommendedBookingApi } from '@beatgig/api/recommended-booking'
import { RecommendedBookingAdminApi } from '@beatgig/api/recommended-booking-admin'
import usePost, { UsePostOptions } from '../src/use-post'

const poster = RecommendedBookingAdminApi.updateArtistRecommendations

type Poster = typeof poster

export default function useAdminUpdateArtistRecommendations(
  options?: UsePostOptions<Poster>
) {
  return usePost(
    async (...[{ artistRecommendations, ...rest }]: Parameters<Poster>) => {
      const recommendedBooking = await poster({
        artistRecommendations,
        ...rest,
      })

      if (recommendedBooking?.id) {
        await RecommendedBookingApi.refetchRecommendedBookingSWR({
          recommendedBookingId: recommendedBooking.id,
        })
      }

      return recommendedBooking
    },
    options
  )
}
