import React from 'react'
import { View, H2 } from 'dripsy'
import Ionicons from '@beatgig/design/components/ionicons'
import { MotiView } from 'moti'
import { BookingRequestBuyerExplanation } from '@beatgig/ui/booking-request-buyer-explanation'

export const CreateBookingRequestHeader = React.memo(
  function CreateBookingRequestHeader(
    props: React.ComponentProps<typeof BookingRequestBuyerExplanation>
  ) {
    return (
      <View sx={{ mt: 3, mb: 4 }}>
        <View sx={{ mb: 4 }}>
          <MotiView
            from={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ type: 'timing' }}
            delay={300}
          >
            <Ionicons
              name="ios-flash"
              color={'primary'}
              size={40}
              sx={{
                alignSelf: 'center',
              }}
            />
          </MotiView>
          <H2 sx={{ textAlign: 'center', my: 3 }}>
            Get Artist Recommendations
          </H2>
        </View>
        <BookingRequestBuyerExplanation {...props} />
      </View>
    )
  }
)
