import { useCallback } from 'react'
import { BookingStatus } from '@beatgig/api-services/booking'
import useTheme from '@beatgig/theme/use-theme'

export default function useBookingStatusToColor() {
  const { colors } = useTheme()

  return {
    bookingStatusToColor: useCallback(
      (
        status: BookingStatus,
        { isActionRequired = false }: { isActionRequired: boolean }
      ) => {
        let key: keyof typeof colors = 'text'
        switch (status) {
          // case BookingStatus.ADVANCING:
          //   key = 'success'
          //   break
          case BookingStatus.CONTRACTING:
            key = 'text'
            break
          case BookingStatus.NEGOTIATING:
            key = 'text'
            break
          case BookingStatus.CONFIRMED:
            key = 'success'
            break
          case BookingStatus.CANCELED:
            key = 'muted4'
            break
          case BookingStatus.DECLINED:
            key = 'muted4'
            break
          case BookingStatus.RESCHEDULING:
            key = 'accent'
            break
          case BookingStatus.CREATED:
            key = 'text'
            break
          default:
            key = 'primary'
            break
        }

        if (isActionRequired) {
          key = 'error'
        }

        return { color: colors[key], variant: key }
      },
      [colors]
    ),
  }
}
