import React, { useMemo, useState } from 'react'
import useAdminCalendarFilters from './use-admin-calendar-filters'
import AdminSearchFilters from '@beatgig/search/components/admin-filters'
import useEnumFilter from '@beatgig/search/filters/enum/use-enum-filter'
import useBooleanFilter from '@beatgig/search/filters/boolean/use-boolean-filter'
import { AdminFilterAccountExecVenues } from './filter-account-execs'
import { DropdownMenu } from '@beatgig/design/components/dropdown-menu/dropdown-menu'
import { useQueryAdminCalBy } from './use-query-admin-calendar-by'
import Link from '@beatgig/design/components/link'
import { TypesenseCollectionName } from '@beatgig/gql'
import { useAdminTypesenseKey } from '@beatgig/api-hooks/src/use-admin-typesense-key'
import { BookingTypesenseSnake } from '@beatgig/gql/types/booking-typesense'
import { useTypesenseSearchInfinite } from '@beatgig/search/hooks/use-search-typesense-infinite'
import AutoComplete from '@beatgig/design/components/auto-complete'
import Router from 'next/router'
import Avatar from '@beatgig/design/components/avatar'
import Cloudinary from '@beatgig/helpers/cloudinary'
import { Text } from '@beatgig/design/components/text'
import { getDisplayStartTime } from '@beatgig/helpers/display-booking-start-time'
import useFormatAlgoliaFilters from '@beatgig/search/hooks/use-format-algolia-filters'
import { DateTime } from 'luxon'
import { dateTimeToAlgolia } from '@beatgig/search/filters/date-range/date-time-algolia'
import { useBookingSlugsFilter } from './use-slug-filter'
import {
  AdminBookingArtistSlugsFilter,
  AdminBookingVenueSlugsFilter,
} from './use-slug-filter-view'

type Props = ReturnType<typeof useAdminCalendarFilters> & {
  isValidating: boolean
} & {
  bookingStatusFilters: ReturnType<typeof useEnumFilter>
  bookingFlowFilter?: ReturnType<typeof useEnumFilter>
  chargedBuyerFilter: ReturnType<typeof useBooleanFilter>
  paidSellerFilter: ReturnType<typeof useBooleanFilter>

  // venueBookingFilter: ReturnType<typeof useBooleanFilter>
  // transferIdFilter: ReturnType<typeof useBooleanFilter>
  // invoicedItemIdFilter: ReturnType<typeof useBooleanFilter>
  // refundedInCreditsFilter: ReturnType<typeof useBooleanFilter>
  // hasExtraChargeFilter: ReturnType<typeof useBooleanFilter>
  // hasExtraPayoutFilter: ReturnType<typeof useBooleanFilter>

  artistFilter: ReturnType<typeof useBookingSlugsFilter>
  venueFilter: ReturnType<typeof useBookingSlugsFilter>
} & React.ComponentProps<typeof AdminFilterAccountExecVenues>

export default function BookingSearchFilters({
  query,
  handlers,
  isValidating,
  bookingStatusFilters,
  chargedBuyerFilter,
  paidSellerFilter,
  // transferIdFilter,
  // invoicedItemIdFilter,
  // hasExtraChargeFilter,
  // hasExtraPayoutFilter,
  // refundedInCreditsFilter,
  accountExecOptions,
  addAccountExec,
  removeAccountExec,
  selectedAccountExecSlugs,
  onSelectAllAccountExecs,
  onClearAllAccountExecs,
  venuesFromAccountExecs,
  bookingFlowFilter,
  artistFilter,
  venueFilter,
}: Props) {
  const queryAdminCal = useQueryAdminCalBy()
  const [artistQuery, setArtistQuery] = useState('')
  const [venueQuery, setVenueQuery] = useState('')

  const [searchQuery, setQuery] = useState('')

  const isUpcoming = searchQuery.toLowerCase().startsWith('upcoming:')

  const isYesterday = searchQuery.toLowerCase().startsWith('yesterday:')

  const isToday = searchQuery.toLowerCase().startsWith('today:')

  const isTomorrow = searchQuery.toLowerCase().startsWith('tomorrow:')

  const isThisWeek = searchQuery.toLowerCase().startsWith('this week:')

  const isNextWeek = searchQuery.toLowerCase().startsWith('next week:')

  const isLastWeek = searchQuery.toLowerCase().startsWith('last week:')

  const isThisMonth = searchQuery.toLowerCase().startsWith('this month:')

  const startTimeKey: keyof BookingTypesenseSnake = 'start_time'

  const { end, start } = useMemo(() => {
    let start: undefined | DateTime
    let end: undefined | DateTime

    const now = DateTime.fromObject({})

    if (isUpcoming) {
      start = now.minus({ hours: 4 })
    } else if (isToday) {
      start = now.startOf('day')
      end = now.endOf('day')
    } else if (isYesterday) {
      start = now.minus({ days: 1 }).startOf('day')
      end = now.minus({ days: 1 }).endOf('day')
    } else if (isTomorrow) {
      start = now.plus({ days: 1 }).startOf('day')
      end = now.plus({ days: 1 }).endOf('day')
    } else if (isThisWeek) {
      start = now.startOf('week')
      end = now.endOf('week')
    } else if (isLastWeek) {
      start = now.minus({ weeks: 1 }).startOf('week')
      end = now.minus({ weeks: 1 }).endOf('week')
    } else if (isNextWeek) {
      start = now.plus({ weeks: 1 }).startOf('week')
      end = now.plus({ weeks: 1 }).endOf('week')
    } else if (isThisMonth) {
      start = now.startOf('month')
      end = now.endOf('month')
    }

    return {
      start: start && Math.round(dateTimeToAlgolia(start)),
      end: end && Math.round(dateTimeToAlgolia(end)),
    }
  }, [
    isLastWeek,
    isNextWeek,
    isThisMonth,
    isThisWeek,
    isToday,
    isTomorrow,
    isUpcoming,
    isYesterday,
  ])

  const startTimeFilter = useFormatAlgoliaFilters({
    numericFilters:
      start && !end
        ? {
            [startTimeKey]: {
              filters: [['>=', start]],
            },
          }
        : start && end
        ? {
            [startTimeKey]: {
              filters: [
                ['>=', start],
                ['<=', end],
              ],
              condition: 'AND',
            },
          }
        : undefined,
  })

  //
  const bookings = useTypesenseSearchInfinite<BookingTypesenseSnake>(
    searchQuery.includes(':') ? searchQuery.split(':')[1] : searchQuery,
    {
      index: TypesenseCollectionName.BOOKINGS,
      apiKey: useAdminTypesenseKey().data || null,
      queryBy: [
        'artist_name',
        'venue_name',
        'buyer_metadata_location',
        'buyer_metadata_organization',
        'buyer_name',
        'start_time_queryable_str',
      ],
      sortBy: 'last_updated',
      sortOrder: 'desc',
      numericFilters: startTimeFilter.numericFilters,
    },
    {
      limit: 40,
    }
  )

  return (
    <AdminSearchFilters
      query={query}
      onChangeText={handlers.onChangeText}
      isValidating={isValidating}
      placeholder=""
      search={
        <>
          <AutoComplete
            value={searchQuery}
            onChangeText={setQuery}
            shouldSetControlledStateOnPress="label"
            searching={isValidating}
            options={bookings.data?.map((booking) => ({
              label: booking?.document.artist_name as string,
              value: booking?.document.firebase_id as string,
              subtitle: (booking?.document.venue_name ||
                `${
                  booking?.document.buyer_metadata_organization
                    ? `${booking?.document.buyer_metadata_organization}, `
                    : ''
                }${booking?.document.buyer_name}`) as string,
              prefix: (
                <Avatar
                  uri={
                    booking?.document.artist_profile_image &&
                    Cloudinary(booking?.document.artist_profile_image, {
                      width: 300,
                    })
                  }
                />
              ),
              suffix: booking?.document && (
                <Text color="mutedText">
                  {getDisplayStartTime({
                    startTime: new Date(booking?.document.start_time * 1000),
                    timezone: booking?.document.location.timezone,
                  })
                    ?.timeWithCustomFormat('M/d/yyyy @ h:mm aa')
                    ?.replace(`/${new Date().getFullYear()}`, '')}
                </Text>
              ),
            }))}
            align="left"
            allowsCustomValue={false}
            placeholder={`Try "upcoming:", "today:" or "this week:"`}
            keyExtractor={(option) => option.value}
            onSelect={(bookingFirebaseId) => {
              if (Router.asPath.startsWith('/dashboard/bookings')) {
                Router.push(
                  '/dashboard/bookings?id=' + bookingFirebaseId,
                  `/dashboard/bookings/${bookingFirebaseId}`,
                  {
                    shallow: true,
                  }
                )
              } else {
                Router.push(`/dashboard/bookings/${bookingFirebaseId}`)
              }
            }}
          />
          {/* {queryAdminCal.by === 'artist' && (
            <AdminArtistSearch
              onSelectArtist={(artist) => handlers.onChangeText(artist.name)}
              value={artistQuery}
              onChangeText={setArtistQuery}
              onClearArtist={() => {
                setArtistQuery('')
                handlers.onChangeText('')
              }}
            />
          )}
          {queryAdminCal.by === 'venue' && (
            <AdminVenueSearch
              onSelectVenue={(venue) => handlers.onChangeText(venue.name)}
              value={venueQuery}
              onChangeText={setVenueQuery}
              onClearVenue={() => {
                setVenueQuery('')
                handlers.onChangeText('')
              }}
            />
          )} */}
        </>
      }
      sort={
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <Link iconName="search" block iconSide="left">
              By {queryAdminCal.by}
            </Link>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            <DropdownMenu.CheckboxItem
              value={queryAdminCal.by == 'artist' ? 'on' : 'off'}
              onValueChange={(value) => {
                value === 'on' && queryAdminCal.setBy('artist')

                handlers.onChangeText('')
              }}
              key="artist"
            >
              <DropdownMenu.ItemTitle>Search by artist</DropdownMenu.ItemTitle>
            </DropdownMenu.CheckboxItem>
            <DropdownMenu.CheckboxItem
              value={queryAdminCal.by == 'venue' ? 'on' : 'off'}
              key="venue"
              onValueChange={(value) => {
                value === 'on' && queryAdminCal.setBy('venue')

                handlers.onChangeText('')
              }}
            >
              <DropdownMenu.ItemTitle>Search by venue</DropdownMenu.ItemTitle>
            </DropdownMenu.CheckboxItem>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      }
      booleanFilters={[
        // transferIdFilter,
        // venueBookingFilter,
        chargedBuyerFilter,
        paidSellerFilter,
        // invoicedItemIdFilter,
        // hasExtraChargeFilter,
        // hasExtraPayoutFilter,
        // refundedInCreditsFilter,
      ]}
      enumFilters={(() => {
        const filters = [bookingStatusFilters]

        if (bookingFlowFilter) {
          filters.push(bookingFlowFilter)
        }

        return filters
      })()}
      filters={
        <>
          <AdminFilterAccountExecVenues
            accountExecOptions={accountExecOptions}
            addAccountExec={addAccountExec}
            removeAccountExec={removeAccountExec}
            selectedAccountExecSlugs={selectedAccountExecSlugs}
            onSelectAllAccountExecs={onSelectAllAccountExecs}
            onClearAllAccountExecs={onClearAllAccountExecs}
            venuesFromAccountExecs={venuesFromAccountExecs}
          />
          <AdminBookingVenueSlugsFilter {...venueFilter} />
          <AdminBookingArtistSlugsFilter {...artistFilter} />
        </>
      }
    />
  )
}
