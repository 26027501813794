import React from 'react'
import { Variants } from '../types'
import { useDripsyTheme, View, remToPixels, Text } from 'dripsy'
import type { Sx as SxStyleProp } from 'dripsy'

interface Props {
  variant?: Variants
  sx?: SxStyleProp
  textSx?: SxStyleProp
  /**
   * If `true`, the color will match the dot color, (as long as there is enough contrast?)
   */
  color?: boolean
}

type NativeAttrs = Omit<React.ComponentType<typeof View>, keyof Props>

export type DotProps = Props & NativeAttrs

const Dot: React.FC<React.PropsWithChildren<DotProps>> = ({
  variant = 'default',
  children,
  sx = {},
  textSx = {},
  color,
  ...props
}) => {
  const { theme } = useDripsyTheme()
  const backgroundColor = variant
    ? theme.colors?.[variant] ?? variant
    : theme.colors?.mutedText
  return (
    <View sx={{ alignItems: 'center', flexDirection: 'row', ...sx }} {...props}>
      <View
        sx={{
          height: remToPixels('0.625rem'),
          width: remToPixels('0.625rem'),
          borderRadius: 'rounded',
          backgroundColor,
          userSelect: 'none',
        }}
      />
      {!!children && (
        <Text
          sx={{
            ml: 2,
            textTransform: 'capitalize',
            color: color ? backgroundColor : `text`,
            ...textSx,
          }}
        >
          {children}
        </Text>
      )}
    </View>
  )
}

export default Dot
