import { useCallback, useState } from 'react'

export function useBookingSlugsFilter() {
  const [slugs, setSlugs] = useState<Array<string>>([])

  return {
    slugs,
    addSlug: useCallback((slug: string) => {
      setSlugs((slugs) => {
        return Array.from(new Set([...slugs, slug]))
      })
    }, []),
    toggleSlug: useCallback((slug: string) => {
      setSlugs((slugs) => {
        return new Set(slugs).has(slug)
          ? slugs.filter((s) => s !== slug)
          : [...slugs, slug]
      })
    }, []),
    removeSlug: useCallback((slug: string) => {
      setSlugs((slugs) => slugs.filter((s) => s !== slug))
    }, []),
  }
}
