import { useRef } from 'react'
import EditExternalBookingForm from './form'
import { useRoute, useNavigation } from '@react-navigation/native'
import useEditExternalBooking from './use-edit-external-booking'
import useInputExternalBooking from './use-input-external-booking'
import type { EditExternalBookingRoute } from '@beatgig/navigation/stacks/calendar-stack/types'
import WebModal from '@beatgig/components/web-modal'
import LoadingScreen from '@beatgig/components/loading-screen'
import useServerFormError from '@beatgig/forms/hooks/use-server-form-error'
import useDeleteExternalBooking from './use-delete-external-booking'
import useAreYouSure from '@beatgig/hooks/use-are-you-sure'
import useToast from '@beatgig/design/hooks/use-toast'
import { withoutTypename } from '@beatgig/gql/types/without-typename'

export default function EditExternalBookingScreen() {
  const { goBack } = useNavigation()
  const { externalBookingId } = useRoute<EditExternalBookingRoute>().params
  const { execute, error } = useEditExternalBooking()

  const {
    execute: deleteExternalBooking,
    loading: isDeleting,
    error: deleteError,
  } = useDeleteExternalBooking()

  const { toast } = useToast()

  const { handleServerFormError } = useServerFormError()

  const { data: inputExternalBooking, error: inputError } =
    useInputExternalBooking({
      id: externalBookingId,
    })

  const shouldAnimate = useRef(true)

  const areYouSureYouWantToDelete = useAreYouSure({
    action: 'delete this external booking',
  })

  if (!inputExternalBooking) {
    shouldAnimate.current = false
    return (
      <WebModal header onRequestClose={goBack}>
        <LoadingScreen error={inputError}>
          Loading external booking...
        </LoadingScreen>
      </WebModal>
    )
  }

  return (
    <EditExternalBookingForm
      initialExternalBooking={{
        coverImage: withoutTypename(inputExternalBooking.coverImage),
        performanceLengthMins: inputExternalBooking.performanceLengthMins,
        startTime: inputExternalBooking.startTime,
        artistName: inputExternalBooking.artistName,
        indoorsOrOutdoors: inputExternalBooking.indoorsOrOutdoors,
        publicEventDescription: inputExternalBooking.publicEventDescription,
      }}
      animated={shouldAnimate.current}
      onClose={goBack}
      deleteError={deleteError}
      onDelete={() => {
        areYouSureYouWantToDelete(async () => {
          const result = await deleteExternalBooking({
            id: externalBookingId,
          })
          if (!result.error) {
            toast(
              'Successfully deleted external booking. This may take a moment to show on your dashboard.'
            )
            goBack()
          }
        })
      }}
      isDeleting={isDeleting}
      error={error}
      onSubmit={async (externalBooking, formik, scrollableProps) => {
        const result = await execute({
          id: externalBookingId,
          patch: {
            ...externalBooking,
            coverImage: externalBooking.coverImage && {
              height: externalBooking.coverImage.height,
              width: externalBooking.coverImage.width,
              url: externalBooking.coverImage.url,
              publicCloudinaryId:
                externalBooking.coverImage.publicCloudinaryId ||
                // FIXME gql this shouldn't use this field anymore
                (externalBooking.coverImage as any).public_cloudinary_id,
            },
          },
        })
        if (result) {
          const { data, error } = result
          if (error) {
            handleServerFormError({
              error,
              formName: 'Edit External Booking',
              ...formik,
              ...scrollableProps,
            })
          } else if (data?.updateExternalBooking) {
            goBack()
          }
        }
      }}
      timezone={inputExternalBooking.venue.location.timezone}
    />
  )
}
