import React from 'react'
import { View } from 'dripsy'
import Checkbox from '@beatgig/design/components/checkbox'
import useBooleanFilter from './use-boolean-filter'

type Props = ReturnType<typeof useBooleanFilter> & {
  sx?: React.ComponentProps<typeof View>['sx']
  itemSx?: React.ComponentProps<typeof View>['sx']
}

export default function BooleanFilter({
  handlers,
  sx,
  booleanState,
  itemSx,
}: Props) {
  return (
    <View sx={sx}>
      {Object.keys(booleanState).map((value: 'on' | 'off') => {
        const { selected, variant, label } = booleanState[value]
        return (
          <View sx={itemSx} key={value}>
            <Checkbox
              checked={selected}
              onChange={handlers.makeToggleItem(value)}
              variant={variant}
              centerContent
            >
              {label}
            </Checkbox>
          </View>
        )
      })}
    </View>
  )
}
