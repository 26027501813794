import usePost, { UsePostOptions } from './use-post'
import { RecommendedBookingApi } from '@beatgig/api/recommended-booking'
import Analytics from '@beatgig/analytics/typez'

export default function useAcceptRecommendedBooking(
  options?: UsePostOptions<
    typeof RecommendedBookingApi.acceptRecommendedBooking
  >
) {
  return usePost(
    (
      params: Parameters<
        typeof RecommendedBookingApi.acceptRecommendedBooking
      >[0]
    ) => {
      const { recommendedBookingId, artistId } = params
      Analytics.track('Accept Recommended Booking', {
        recommendedBookingId,
        artistId,
      })
      return RecommendedBookingApi.acceptRecommendedBooking(params)
    },
    options
  )
}
