import React from 'react'
import { BookingsSmallCalendar } from '@beatgig/ui/bookings-small-calendar'
import { BookingsSmallCalendarList } from '@beatgig/ui/bookings-small-calendar-list'
import { SmallCalendarLayout } from '@beatgig/ui/small-calendar-layout'
import { useCalendarActions } from '../calendar-screen/hooks/actions'
import { useSelectedDate } from './use-selected-date'
import { CreateRecommendationButton } from './create-recommendation-button'
import { CalendarActionsRequired } from '@beatgig/ui/bookings-small-calendar-list/action-required'
import { useSmallCalendar } from '@beatgig/calendars/components/calendar/use-small-calendar'
import { useCalendarActionRequired } from '@beatgig/ui/bookings-small-calendar-list/action-required/use-action-required'
import { useAnchors } from '@nandorojo/anchor'
import { UserRole } from '@beatgig/api-services'
import { WebCalendarModalStack } from '@beatgig/ui/booking-request-modal-route'
import { useCalendarQuery } from '../calendar-screen/hooks/calendar-queries'

type Props = {
  userId: string
  userName: string
  userRole: UserRole
}

export const SmallCalendarScreen = function SmallCalendarScreen({
  userId,
  userName,
  userRole,
}: Props) {
  const { selectedDate, setSelectedDate } = useSelectedDate()
  const [
    {
      bookings,
      extenalBookings,
      actionsRequiredBadgeCount,
      actionsRequiredDateObject,
      selectedVenues,
      selectedVenueCount,
      revalidate,
      bookingRequests,
      isPullingToRefresh,
      onPullToRefresh,
    },
  ] = useCalendarQuery({ userId, userRole, selectedDate })
  const {
    onPressExternalBooking,
    onNavigateToBooking,
    onPressCreateBookingRequest,
    onPressCreateVenue,
    onPressCreateExternalBooking,
    onPressBookingRequest,
    onMonthChange,
  } = useCalendarActions({ userId, userName, userRole, setSelectedDate })

  const calendarRef = useSmallCalendar()
  const anchorsRef = useAnchors()
  const actionRequired = useCalendarActionRequired()

  const screen = (
    <SmallCalendarLayout
      anchorsRef={anchorsRef}
      onPullToRefresh={onPullToRefresh}
      isPullingToRefresh={isPullingToRefresh}
      footer={
        userRole === UserRole.BUYER && (
          <SmallCalendarLayout.Footer>
            <CreateRecommendationButton
              selectedDate={selectedDate}
              onPress={onPressCreateBookingRequest}
            />
          </SmallCalendarLayout.Footer>
        )
      }
      header={
        <SmallCalendarLayout.Header
          loading={isPullingToRefresh}
          onRefresh={onPullToRefresh}
          userRole={userRole}
          selectedVenueCount={selectedVenueCount}
          onPressCreateExternalBooking={onPressCreateExternalBooking}
          onPressCreateVenue={onPressCreateVenue}
          selectedVenues={selectedVenues}
        />
      }
    >
      <SmallCalendarLayout.Calendar>
        {React.useMemo(
          () => (
            <BookingsSmallCalendar
              userRole={userRole}
              bookings={bookings.withFakeDates}
              externalBookings={extenalBookings.withFakeDates}
              onMonthChange={onMonthChange}
              bookingRequests={bookingRequests.withFakeDates}
              // recommendedBookings={recommendedBookings.withFakeDates}
              selectedDate={selectedDate}
              calendarRef={calendarRef}
              onPressDate={setSelectedDate}
            />
          ),
          [
            bookingRequests.withFakeDates,
            bookings.withFakeDates,
            calendarRef,
            extenalBookings.withFakeDates,
            onMonthChange,
            selectedDate,
            setSelectedDate,
            userRole,
          ]
        )}
      </SmallCalendarLayout.Calendar>

      <SmallCalendarLayout.List>
        <SmallCalendarLayout.ActionsRequired>
          <CalendarActionsRequired
            onPress={actionRequired.onPress}
            badgeCount={actionsRequiredBadgeCount}
          />
        </SmallCalendarLayout.ActionsRequired>
        <BookingsSmallCalendarList
          bookingRequests={bookingRequests.data || null}
          onPressBookingRequest={onPressBookingRequest}
          userRole={userRole}
          onPressCreateBookingRequest={onPressCreateBookingRequest}
          onPressExternalBooking={onPressExternalBooking}
          selectedDate={selectedDate}
          externalBookings={extenalBookings.data || null}
          bookings={bookings.data || null}
          shouldFilterBySelectedDate
          onPressBooking={onNavigateToBooking}
        />
      </SmallCalendarLayout.List>
    </SmallCalendarLayout>
  )

  return (
    <WebCalendarModalStack
      userRole={userRole}
      onDidClose={revalidate}
      webHeaderSafeArea
      screen={screen}
    />
  )
}

// export function TestSmallCalendarScreen() {
//   return (
//     <View sx={{ flex: 1 }}>
//       <WebHeaderSpacer />
//       <AdminOnBehalfOfVenueContainer unselectedUserText="View a buyer's calendar.">
//         {({ userId, userName }) => {
//           if (!userId) return null

//           return (
//             <SmallCalendarScreenAuthenticated
//               userName={userName}
//               userId={userId}
//             />
//           )
//         }}
//       </AdminOnBehalfOfVenueContainer>
//     </View>
//   )
// }
