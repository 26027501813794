import useAuth from '@beatgig/auth-hooks/use-auth'
import { useBadgeCountQuery } from '@beatgig/gql'
import { useAdminOnBehalfOf } from '@beatgig/gql/admin-on-behalf-of'
import { useLatestCallback } from '@beatgig/helpers/use-latest-callback'
import { useNotificationBadges } from '@beatgig/hooks/use-notification-badges'

export function useBadgeCount({ userId }: { userId: string }) {
  const auth = useAuth()
  const [{ data }, revalidate] = useBadgeCountQuery(
    useAdminOnBehalfOf(userId, {
      pause: !userId || !auth.user,
      variables: undefined,
    })
  )

  const badgeCount = data?.myBadges.total || 0

  useNotificationBadges({ badgeCount })

  return {
    badgeCount,
    bookingRequests: data?.myBadges.bookingRequests || 0,
    bookings: data?.myBadges.bookings || 0,
    revalidate: useLatestCallback(() =>
      revalidate({
        requestPolicy: 'network-only',
      })
    ),
  }
}
