import { RecommendedBookingApi } from '@beatgig/api/recommended-booking'
import useGet from './use-get'
import { UseGetConfig } from './use-get.types'

type KeyMaker = typeof RecommendedBookingApi['getInputRecommendedBookingSWRKey']

export default function useGetInputRecommendedBooking(
  { recommendedBookingId }: Parameters<KeyMaker>[0],
  options: UseGetConfig<
    typeof RecommendedBookingApi['getInputRecommendedBooking']
  > = {}
) {
  return useGet(
    () => {
      if (!recommendedBookingId) return null
      return RecommendedBookingApi.getInputRecommendedBookingSWRKey({
        recommendedBookingId,
      })
    },
    (...[recommendedBookingId]: ReturnType<KeyMaker>) => {
      return RecommendedBookingApi.getInputRecommendedBooking({
        recommendedBookingId,
      })
    },
    { cachePolicy: 'network-only', ...options }
  )
}
