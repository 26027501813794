import React, { useMemo } from 'react'
import type { Sx } from 'dripsy'
import AutoComplete from '@beatgig/design/components/auto-complete'
import { AutoCompleteOption } from '@beatgig/design/components/auto-complete/types'
import type { ArtistDisplay } from '@beatgig/api-services/artist'
import Avatar from '@beatgig/design/components/avatar'
import Cloudinary from '@beatgig/helpers/cloudinary'
import useStable from '@beatgig/design/hooks/use-stable'
import { useAdminTypesenseKey } from '@beatgig/api-hooks/src/use-admin-typesense-key'
import { ArtistTypesenseSnake } from '@beatgig/gql/types/artist-typesense'
import { useTypesenseSearchInfinite } from '@beatgig/search/hooks/use-search-typesense-infinite'
import { TypesenseCollectionName } from '@beatgig/gql'

type Props = {
  onSelectArtist?: (artist: Pick<ArtistDisplay, 'slug' | 'name'>) => void
  onClearArtist?: () => void
  sx?: Sx
  value: string
  onChangeText: (next: string) => void
  searching?: boolean
}

export default function AdminArtistSearch({
  sx,
  onSelectArtist,
  onClearArtist,
  value,
  onChangeText,
  searching,
}: Props) {
  const key = useAdminTypesenseKey()
  const {
    data,
    isLoadingInitialData,
    isLoadingMore,
    fetchMore,
    error,
    isValidating,
  } = useTypesenseSearchInfinite<ArtistTypesenseSnake>(value, {
    index: TypesenseCollectionName.ARTISTS,
    sortBy: 'last_updated',
    sortOrder: 'desc',
    apiKey: key.data || null,
    queryBy: 'name',
  })

  const stableData = useStable(data)

  const options = useMemo(() => {
    const options: AutoCompleteOption[] = []

    if (data) {
      for (const item of data || []) {
        const artist = item?.document
        if (artist?.name && artist.slug) {
          options.push({
            label: artist.name,
            value: artist.slug,
            prefix: (
              <Avatar uri={Cloudinary(artist.profile_image, { width: 250 })} />
            ),
          })
        }
      }
    }

    return options
  }, [data])

  return (
    <AutoComplete
      sx={sx}
      align="left"
      options={options}
      defaultValue={value}
      value={value}
      onChangeText={onChangeText}
      placeholder="Search artists..."
      onEndReached={fetchMore}
      keyExtractor={(item) => item.value}
      shouldSetControlledStateOnPress="label"
      searching={
        isLoadingInitialData ||
        isLoadingMore ||
        !key.data ||
        isValidating ||
        searching
      }
      editable
      hasErrored={!!error}
      onClear={onClearArtist}
      allowsCustomValue={false}
      onSelect={useMemo(
        () => (artistSlug) => {
          const artist = stableData.current?.find(
            (artist) => artist?.document.slug === artistSlug
          )

          if (artist?.document) {
            onSelectArtist?.(artist.document)
          }
        },
        [onSelectArtist, stableData]
      )}
    />
  )
}
