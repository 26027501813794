import { useRef, useEffect, useCallback } from 'react'
import type FullCalendar from '@fullcalendar/react'
import type { SelectedDate } from '@beatgig/screens/small-calendar-screen/use-selected-date/types'
import isSameMonth from 'date-fns/isSameMonth'
import { DateTime } from 'luxon'
import type BookingsBigCalendar from '@beatgig/ui/bookings-big-calendar'

type Props = {
  selectedDate: SelectedDate
  setSelectedDate: (s: SelectedDate) => void
}

export function useBigCalendarActions({
  selectedDate,
  setSelectedDate,
}: // onChangeDateRange,
Props) {
  const calendarRef = useRef<FullCalendar>(null)

  // this feels like a complete hack. I don't like it.
  const isUpdatingImperatively = useRef(false)

  const hasMounted = useRef(false)

  const datesSet = useCallback<
    Required<React.ComponentProps<typeof BookingsBigCalendar>>['datesSet']
  >(
    (props) => {
      const { view } = props
      const { year, month, day } = DateTime.fromJSDate(
        view.currentStart
      ).toLocal()

      if (
        selectedDate.month !== month &&
        !isUpdatingImperatively.current &&
        hasMounted.current
      ) {
        setSelectedDate({ year, month, day })
      }
      isUpdatingImperatively.current = false
    },
    [selectedDate.month, setSelectedDate]
  )

  useEffect(
    function updateCalendarMonthOnStateChange() {
      const ref = calendarRef.current?.getApi()
      const calendarDate = ref?.getDate()
      if (selectedDate && calendarDate) {
        const selectedJSDate = DateTime.fromObject(selectedDate).toJSDate()
        if (!isSameMonth(selectedJSDate, calendarDate)) {
          console.log('[should update calendar date]', {
            selectedDate,
            calendarDate,
          })
          isUpdatingImperatively.current = true
          ref?.gotoDate(selectedJSDate)
        }
      }
    },
    [selectedDate]
  )
  useEffect(function onMount() {
    hasMounted.current = true

    return () => {
      hasMounted.current = false
    }
  }, [])

  return {
    calendarRef,
    datesSet,
  }
}
