import React from 'react'

import { View, StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    overflow: 'hidden',
  },
})

type Props = {
  children: React.ReactNode
}

export default function WebStackContainer({ children }: Props) {
  return <View style={styles.container}>{children}</View>
}
