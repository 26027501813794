import { useActionRequiredListQuery } from '@beatgig/gql'
import { useAdminOnBehalfOf } from '@beatgig/gql/admin-on-behalf-of'
import { usePaginate } from '@beatgig/gql/use-paginate'

type Props = {
  userId: string
}

export const useBookingsActionRequiredList = ({ userId }: Props) => {
  const {
    data,
    error,
    stale,
    fetching,
    revalidate,
    canFetchMore,
    fetchMore,
    isFetchingMore,
  } = usePaginate(
    useActionRequiredListQuery,
    useAdminOnBehalfOf(userId, {
      variables: {
        limit: 10,
      },
      requestPolicy: 'cache-and-network',
    }),
    {
      getLength(data) {
        return Math.max(
          data.myBookingRequestsWhereActionIsRequired.length,
          data.myBookingsWhereActionIsRequired.length
        )
      },
    }
  )

  return {
    bookings: data?.myBookingsWhereActionIsRequired,
    bookingRequests: data?.myBookingRequestsWhereActionIsRequired,
    error,
    revalidate,
    get isValidating() {
      return stale || fetching
    },
    canFetchMore,
    fetchMore,
    isFetchingMore,
  }
}
