import useSWRReactNavigation from '@beatgig/api-hooks/src/use-native-revalidate'
import BookingsBigCalendar, {
  BigCalendarBooking,
} from '@beatgig/ui/bookings-big-calendar'
import React from 'react'
import { View, ScrollView } from 'dripsy'
import BookingSearchFilters from './filters'
import { UserRole } from '@beatgig/api-services'
import { CalendarScreenNavigation } from '@beatgig/navigation/stacks/calendar-stack/types'
import { useNavigation } from '@react-navigation/native'
import Spacer from '@beatgig/design/components/spacer'
import { AdminCalendarQuery } from '@beatgig/gql'
import { useFilteredBookingsFromAdmin } from './use-filtered-query'
import { cheatFormatBookingDateForCalendar } from '@beatgig/helpers/cheat-format-booking-calendar-date'

const formatBigCalendarBookingFromGraphql = (
  booking: AdminCalendarQuery['bookings'][number],
  searchBy: 'artist' | 'venue'
): BigCalendarBooking => {
  let title = ''
  if (booking.charge?.chargedBuyer) {
    title += '🌯'
  }
  if (booking.payout?.paidSeller) {
    if (title) title += ' '
    title += '💸'
  }
  if (booking.charge?.invoiceId) {
    if (title) title += ' '
    title += '💌'
  }
  if (booking.payout?.transferId) {
    if (title) title += ' '
    title += '🏧'
  }
  title += ` ${
    searchBy == 'artist'
      ? booking.artistName
      : booking.venueName || booking.buyerMetadata.organization || 'No Venue'
  }`
  title = title.trim()
  return {
    artist: {
      name: title,
    },
    buyer: {
      metadata: booking.buyerMetadata,
    },
    status: booking.status,
    id: booking.id,
    start_time: cheatFormatBookingDateForCalendar({
      start_time: booking.startTime,
      venue_location: booking.location,
    }).start_time,
    venue_name: booking.venueName,
    performance_length_mins: booking.performanceLengthMins,
    location: booking.location,
  }
}

export default function AdminCalendarScreen() {
  const [height, setHeight] = React.useState(0)
  const { navigate } = useNavigation<CalendarScreenNavigation>()
  const onPressBooking = (booking: BigCalendarBooking) => {
    navigate('BookingPreview', {
      bookingId: booking.id,
    })
  }
  const {
    handlers,
    accountExecVenuesFilter,
    bookingFlowFilter,
    bookingStatusFilters,
    calendarQuery,
    chargedBuyerFilter,
    paidSellerFilter,
    searchQueryBy,
    artistFilter,
    venueFilter,
    ...filters
  } = useFilteredBookingsFromAdmin()

  const isValidating = calendarQuery.fetching || calendarQuery.stale

  const bookings = React.useMemo(
    () =>
      calendarQuery.data?.bookings.map((booking) =>
        formatBigCalendarBookingFromGraphql(booking, searchQueryBy)
      ),
    [calendarQuery.data?.bookings, searchQueryBy]
  )
  useSWRReactNavigation({
    // revalidate: revalidateBookings,
    revalidate: async () => {
      calendarQuery.refetch()

      return true
    },
  })

  return (
    <View sx={{ flex: 1, bg: 'background' }}>
      <BookingSearchFilters
        bookingStatusFilters={bookingStatusFilters}
        isValidating={isValidating}
        chargedBuyerFilter={chargedBuyerFilter}
        paidSellerFilter={paidSellerFilter}
        bookingFlowFilter={bookingFlowFilter}
        accountExecOptions={accountExecVenuesFilter.accountExecOptions}
        addAccountExec={accountExecVenuesFilter.addAccountExec}
        removeAccountExec={accountExecVenuesFilter.removeAccountExec}
        onClearAllAccountExecs={accountExecVenuesFilter.onClearAllAccountExecs}
        venuesFromAccountExecs={accountExecVenuesFilter.venuesFromAccountExecs}
        onSelectAllAccountExecs={
          accountExecVenuesFilter.onSelectAllAccountExecs
        }
        selectedAccountExecSlugs={
          accountExecVenuesFilter.selectedAccountExecSlugs
        }
        artistFilter={artistFilter}
        venueFilter={venueFilter}
        handlers={handlers}
        {...filters}
      />
      <ScrollView>
        <View
          onLayout={({ nativeEvent }) => setHeight(nativeEvent.layout.height)}
          sx={{ px: 3, zIndex: -1, height: '200vh' }}
        >
          <Spacer height={2} />
          <BookingsBigCalendar
            userRole={UserRole.ADMIN}
            bookings={bookings || null}
            externalBookings={null}
            // recommendedBookings={null}
            externalCalendarEvents={null}
            bookingRequests={null}
            onPressBooking={onPressBooking}
            datesSet={handlers.onChangeDateRange}
            // aspectRatio={2.2}
            height={height}
            moreLinkClick="popover"
          />
        </View>
      </ScrollView>
    </View>
  )
}
