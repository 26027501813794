import {
  AdminCalendarQueryVariables,
  useAdminCalendarQuery,
} from '@beatgig/gql'
import useDebounce from '@beatgig/hooks/use-debouce'
import { BooleanGraphqlFilter } from '@beatgig/search/filters/boolean/use-boolean-filter'
import { DateRangeGraphqlFilter } from '@beatgig/search/filters/date-range/types'

type Props = {
  dateRange: DateRangeGraphqlFilter
  limit?: number
  offset?: number
} & Pick<
  AdminCalendarQueryVariables,
  | 'accountExecSlugs'
  | 'artistQueryName'
  | 'statuses'
  | 'bookingFlows'
  | 'venueQueryName'
  | 'artistSlugs'
  | 'venueSlugs'
> & {
    [key in keyof Pick<
      AdminCalendarQueryVariables,
      'hasCharge' | 'hasPayout'
    >]: BooleanGraphqlFilter
  }

export function useAdminCalendar(props: Props) {
  const {
    dateRange,
    artistQueryName,
    statuses,
    accountExecSlugs,
    hasCharge,
    hasPayout,
    bookingFlows,
    venueQueryName,
    limit = 1500,
    offset = 0,
    artistSlugs,
    venueSlugs,
  } = props

  const [query, refetch] = useAdminCalendarQuery({
    variables: {
      startTimeMin: dateRange.min,
      startTimeMax: dateRange.max,
      artistQueryName: useDebounce(artistQueryName, 250),
      statuses,
      accountExecSlugs,
      hasCharge,
      hasPayout,
      bookingFlows,
      venueQueryName: useDebounce(venueQueryName, 250),
      limit,
      offset,
      artistSlugs,
      venueSlugs,
    },
    requestPolicy: 'cache-and-network',
  })

  return {
    ...query,
    refetch,
  }
}
