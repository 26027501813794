import { useRoute, useNavigation } from '@react-navigation/native'
import { BookingPreviewRoute } from '@beatgig/navigation/stacks/calendar-stack/types'
import BookingPreviewModal from '../public-booking-preview-screen'
import { Platform } from 'react-native'
/**
 * Screen container that wraps the `BookingPreviewModal` for a private booking and passes it props.
 */
export default function BookingPreviewScreen() {
  const {
    params: { bookingId },
  } = useRoute<BookingPreviewRoute>()
  const { goBack } = useNavigation()

  return (
    <BookingPreviewModal
      headerTopInset={Platform.select({ ios: 0 })}
      bookingId={bookingId}
      onClose={goBack}
    />
  )
}
