import { useState, useMemo } from 'react'
import { DateTime } from 'luxon'
import type { FormattedAlgoliaFacetOrNumericFilters } from '@beatgig/search/hooks/use-format-algolia-filters'
import { DateRangeGraphqlFilter } from '@beatgig/search/filters/date-range/types'
import isSameDay from 'date-fns/isSameDay'

const dateRangeAlgolia = (start: Date) => {
  return {
    start:
      DateTime.fromJSDate(start).startOf('month').plus({ day: -1 }).toMillis() /
      1000,
    end:
      DateTime.fromJSDate(start).endOf('month').plus({ day: 1 }).toMillis() /
      1000,
  }
}

type Props = {
  initialDateRange?: () => { start: number; end: number }
}

export default function useAlgoliaBookingFilters({
  initialDateRange = () => dateRangeAlgolia(new Date()),
}: Props = {}) {
  const [query, setQuery] = useState('')
  const [bookingTypes, setBookingTypes] = useState({
    external: false,
    real: true,
    recommended: true,
  })
  const [dateRange, setDateRange] = useState(initialDateRange)

  const dateRangeFilters: FormattedAlgoliaFacetOrNumericFilters = [
    `start_time:${dateRange.start} TO ${dateRange.end}`,
  ]

  const graphqlDateRangeFilter: DateRangeGraphqlFilter = useMemo(
    () => ({
      // it's important to memoize because of a race condition
      // where computing date range from seconds causes this to get the wrong date
      min: DateTime.fromMillis(dateRange.start * 1000).toISO(),
      max: DateTime.fromMillis(dateRange.end * 1000).toISO(),
    }),
    [dateRange]
  )

  const handlers = useMemo(() => {
    const toggleBookingType = (bookingType: keyof typeof bookingTypes) => {
      setBookingTypes((state) => ({
        ...state,
        [bookingType]: !state[bookingType],
      }))
    }
    return {
      toggleBookingType,
      makeToggleBookingType: (bookingType: keyof typeof bookingTypes) => {
        return () => toggleBookingType(bookingType)
      },
      /**
       * Pass as `datesSet` prop to `<BigCalendar />
       */
      onChangeDateRange: ({ start, end }: { start: Date; end: Date }) => {
        setDateRange((currentRange) => {
          const nextStartDate = DateTime.fromJSDate(start)
            .plus({ day: -1 })
            .toJSDate()

          // we already added day: -1 to the currentRange
          const currentStartDate = DateTime.fromMillis(
            currentRange.start * 1000
          ).toJSDate()

          const sameDay = isSameDay(nextStartDate, currentStartDate)

          if (sameDay) {
            return currentRange
          }

          return {
            start: DateTime.fromJSDate(nextStartDate).toMillis() / 1000,
            end: DateTime.fromJSDate(end).plus({ day: 1 }).toMillis() / 1000,
          }
        })
      },
      onChangeText: setQuery,
    }
  }, [])

  return {
    bookingTypes,
    dateRange,
    handlers,
    dateRangeFilters,
    query,
    graphqlDateRangeFilter,
  }
}
