import useStable from '@beatgig/design/hooks/use-stable'
import {
  useAccountExecsQuery,
  useVenuesFromAccountExecsQuery,
} from '@beatgig/gql'
import { usePersistState } from '@beatgig/hooks/use-persist-state'
import { useState, useMemo } from 'react'

export function useAccountExecVenuesFilter() {
  const [state, setState] = useState<string[]>([])

  const [selectedAccountExecSlugs, setAccountExecSlugs] = usePersistState(
    'account-exec-venue-slugs',
    {
      setState,
      state,
    }
  )
  const [accountExecOptions] = useAccountExecsQuery({
    variables: {
      limit: 1000,
      offset: 0,
    },
  })

  const stableIds = useStable(selectedAccountExecSlugs)

  const stableOptions = useStable(accountExecOptions.data)
  const handlers = useMemo(() => {
    return {
      addAccountExec({ slug }: { slug: string }) {
        const nextState = () => {
          if (!stableIds.current.includes(slug)) {
            return [...stableIds.current, slug]
          }
          return stableIds.current
        }

        setAccountExecSlugs(nextState())
      },
      removeAccountExec({ slug }: { slug: string }) {
        const nextState = () => {
          if (stableIds.current.includes(slug)) {
            return stableIds.current.filter((id) => id !== slug)
          }
          return stableIds.current
        }
        setAccountExecSlugs(nextState())
      },
      onSelectAllAccountExecs() {
        if (stableOptions.current?.users.length) {
          setAccountExecSlugs(
            stableOptions.current.users.map((option) => option.slug)
          )
        }
      },
      onClearAllAccountExecs() {
        setAccountExecSlugs([])
      },
    }
  }, [setAccountExecSlugs, stableIds, stableOptions])

  const [venuesFromAccountExecs] = useVenuesFromAccountExecsQuery({
    variables: { execSlugs: selectedAccountExecSlugs, offset: 0, limit: 1000 },
  })

  return {
    venuesFromAccountExecs,
    accountExecOptions,
    selectedAccountExecSlugs,
    ...handlers,
  }
}
