import usePost, { UsePostOptions } from './use-post'
import { RecommendedBookingAdminApi } from '@beatgig/api/recommended-booking-admin'
import { RecommendedBookingApi } from '@beatgig/api/recommended-booking'

const poster = RecommendedBookingAdminApi.removeArtistRecommendation

export default function useRemoveArtistRecommendation(
  options?: UsePostOptions<
    typeof RecommendedBookingAdminApi['removeArtistRecommendation']
  >
) {
  return usePost(async (...params: Parameters<typeof poster>) => {
    const displayRecommendedBooking = await poster(...params)

    if (displayRecommendedBooking) {
      await RecommendedBookingApi.refetchRecommendedBookingSWR({
        recommendedBookingId: displayRecommendedBooking.id,
      })
    }

    return displayRecommendedBooking
  }, options)
}
