import { useInputExternalBookingQuery } from '@beatgig/gql'

export default function useInputExternalBooking({ id }: { id: string | null }) {
  const [{ data, error }] = useInputExternalBookingQuery({
    variables: {
      id: id || '',
    },
    pause: !id,
  })

  return {
    data: data?.externalBooking,
    error,
  }
}
