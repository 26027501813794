import React from 'react'
import { RecommendedBookingAdminApi } from '@beatgig/api/recommended-booking-admin'
import { ScrollableFormikOnSubmitProp } from '@beatgig/forms/context/scrollables/scrollables-context'
import { Formik } from 'formik'
import ScrollablesProvider from '@beatgig/forms/context/scrollables/scrollables-provider'
import FormRow from '@beatgig/forms/components/row'
import CheckboxField from '@beatgig/forms/fields/checkbox-field'
import SubmitButton from '@beatgig/forms/fields/submit'
import Spacer from '@beatgig/design/components/spacer'
import Label from '@beatgig/forms/fields/field-label'
import TextField from '@beatgig/forms/fields/text-field'
import { useFieldArray } from '@beatgig/forms/hooks/use-field-array'
import { Text, H2 } from 'dripsy'
import RecommendedBookingExpandedPreview from '@beatgig/ui/recommended-booking-expanded-preview'

import WebModal from '@beatgig/components/web-modal'
import Button from '@beatgig/components/button'
import useRequestCloseForm from '@beatgig/forms/hooks/use-request-close-form'
import Entity from '@beatgig/design/components/entity'
import Avatar from '@beatgig/design/components/avatar'
import Cloudinary from '@beatgig/helpers/cloudinary'
import EntityField from '@beatgig/design/components/entity/entity-field'
import EntityFooter from '@beatgig/design/components/entity/entity-footer'
import * as yup from 'yup'
import { Unpromisify } from '@beatgig/helpers/types'
import { RecommendedBookingApi } from '@beatgig/api/recommended-booking'
import useAreYouSure from '@beatgig/hooks/use-are-you-sure'
import useRemoveArtistRecommendation from '@beatgig/api-hooks/src/use-remove-recommended-artist'
import { View } from 'dripsy'
import { StyleSheet } from 'react-native'
import Loading from '@beatgig/design/components/loading'

type Params = Parameters<
  typeof RecommendedBookingAdminApi['updateArtistRecommendations']
>[0]

type FormState = Omit<Params, 'recommendedBookingId'>

type Props = {
  onSubmit: ScrollableFormikOnSubmitProp<FormState, void>
  onClose: () => void
  revalidate: () => void
} & {
  recommendedBooking: Unpromisify<
    ReturnType<typeof RecommendedBookingApi['getRecommendedBooking']>
  >
}

const validationSchema = yup.object().shape<Partial<FormState>>({
  notifyBuyer: yup.boolean(),
  artistRecommendations: yup.array(
    yup
      .object({
        artist_id: yup.string().required('Missing artist ID.'),
        details: yup.string().optional(),
        price: yup.number().required('Set a price.'),
      })
      .required()
  ),
})

export default function AdminUpdateRecommendedArtistsForm(props: Props) {
  const { onSubmit, recommendedBooking, onClose, revalidate } = props

  const { requestClose } = useRequestCloseForm()

  const {
    execute: removeArtistRecommendation,
    loading: removing,
  } = useRemoveArtistRecommendation()

  const areYouSureYouWantToRemove = useAreYouSure({
    action: 'remove this artist',
  })

  return (
    <ScrollablesProvider>
      {(scrollableProps) => (
        <Formik<FormState>
          onSubmit={async (...formik) => {
            return onSubmit(...formik, scrollableProps)
          }}
          initialValues={{
            notifyBuyer: true,
            artistRecommendations:
              recommendedBooking.artist_recommendations ?? [],
          }}
          validationSchema={validationSchema}
        >
          {({ dirty, values }) => (
            <WebModal
              animated={false}
              header
              title="Update Recommendations"
              onRequestClose={requestClose(dirty, onClose)}
              button={
                !!values.artistRecommendations.length && (
                  <SubmitButton formName="Update Recommendations">
                    Update
                  </SubmitButton>
                )
              }
            >
              {!!values.artistRecommendations.length && (
                <FormRow>
                  <Label>Want to notify users about these changes?</Label>
                  <CheckboxField
                    centerContent
                    description="Notify users"
                    schema={(null as any) as FormState}
                    name="notifyBuyer"
                  />
                </FormRow>
              )}
              <FormRow>
                <Recommendations
                  isRemoving={removing}
                  onRemove={({ artistName, artist_id }) => {
                    return new Promise((resolve) => {
                      areYouSureYouWantToRemove(
                        async () => {
                          const next = await removeArtistRecommendation({
                            recommendedBookingId: recommendedBooking.id,
                            artistId: artist_id,
                          })
                          resolve({
                            didRemove: !next.artist_recommendations?.some(
                              (recommendation) =>
                                recommendation.artist_id === artist_id
                            ),
                          })
                        },
                        {
                          action: `remove ${artistName}`,
                        }
                      )
                    })
                  }}
                  revalidate={revalidate}
                  recommendedBookingId={recommendedBooking.id}
                  recommendations={
                    recommendedBooking.artist_recommendations ?? []
                  }
                />
              </FormRow>
              <H2>Details</H2>
              <RecommendedBookingExpandedPreview
                recommendedBooking={recommendedBooking}
                disableRecommendationActions
              />
            </WebModal>
          )}
        </Formik>
      )}
    </ScrollablesProvider>
  )
}

function Recommendations({
  recommendations,
  recommendedBookingId,
  revalidate,
  onRemove,
  isRemoving,
}: {
  recommendations: NonNullable<
    Props['recommendedBooking']['artist_recommendations']
  >
  recommendedBookingId: Props['recommendedBooking']['id']
  revalidate: () => void
  onRemove: ({
    artist_id,
    artistName: string,
  }: {
    artist_id: string
    artistName: string
  }) => Promise<{ didRemove: boolean }>
  isRemoving: boolean
}) {
  const [{ value }, , { remove }] = useFieldArray<
    FormState['artistRecommendations'][number]
  >('artistRecommendations')

  const areYouSureYouWantToRemove = useAreYouSure({
    action: 'remove this artist',
  })

  return (
    <>
      {value?.length === 0 && (
        <Text>
          You removed all recommendations. To add more, go to the add screen.
        </Text>
      )}
      {value?.map(({ artist_id, price }, index) => {
        const artist = recommendations.find((recommendation) => {
          return recommendation.artist_id === artist_id
        })
        if (!artist) {
          return (
            <React.Fragment key={artist_id}>
              <Text>
                Trying to edit artist with ID {artist_id}, price: {price}, but
                it looks like this is not a current recommendation. Try
                refreshing this window and see what happens.
              </Text>
              <Text sx={{ mt: 3 }}>
                It is possible this happened because you removed this artist. If
                this is the case no worries.
              </Text>
            </React.Fragment>
          )
        }

        const onPressRemove = async () => {
          if (artist.name) {
            const { didRemove } = await onRemove({
              artistName: artist.name,
              artist_id,
            })
            if (didRemove) {
              remove(index)
            }
          }
          // areYouSureYouWantToRemove(
          //   async () => {
          //     const next = await removeFromNetwork({
          //       recommendedBookingId,
          //       artistId: artist_id,
          //     })
          //     if (
          //       !next.artist_recommendations?.some(
          //         (recommendation) => recommendation.artist_id === artist_id
          //       )
          //     ) {
          //       remove(index)
          //       revalidate()
          //     }
          //   },
          //   {
          //     action: `remove ${artist.name}`,
          //   }
          // )
        }

        return (
          <React.Fragment key={artist_id}>
            <Entity
              actions={
                <Button
                  onPress={onPressRemove}
                  iconLeft={{ name: 'trash-bin-outline', color: 'error' }}
                  variant="smallOutlined"
                  sx={{ borderColor: 'error' }}
                />
              }
              thumbnail={
                <Avatar
                  uri={Cloudinary(artist.profile_image, {
                    width: 250,
                  })}
                />
              }
              sx={{ mb: 3, bg: 'background' }}
              footer={
                <EntityFooter>
                  <TextField<FormState>
                    name={`artistRecommendations.${index}.price`}
                    mask="money"
                    label="Price"
                    required
                  />
                  <Spacer height={3} />
                  <TextField<FormState>
                    name={`artistRecommendations.${index}.details`}
                    label="Details"
                    multiline
                    numberOfLines={4}
                    placeholder="Enter details about this artist for buyers."
                  />
                  {/* <ErrorMessage name={`artistRecommendations.${index}.details`}>
                    {(error) =>
                      !!error && (
                        <>
                          <Spacer height={3} />
                          <ErrorText>{error}</ErrorText>
                        </>
                      )
                    }
                  </ErrorMessage> */}
                </EntityFooter>
              }
            >
              <EntityField title={artist.name} />
            </Entity>
          </React.Fragment>
        )
      })}
      {isRemoving && (
        <View
          style={StyleSheet.absoluteFillObject}
          sx={{
            bg: (theme) => theme.colors.background + '50',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loading variant="primary">Removing...</Loading>
        </View>
      )}
    </>
  )
}
