import type { useCalendarQuery } from '@beatgig/screens/calendar-screen/hooks/calendar-queries'
import React from 'react'
import Card from '@beatgig/design/components/card'
import { View, Text } from 'dripsy'
import Checkbox from '@beatgig/design/components/checkbox'
import { Skeleton } from 'moti/skeleton'
import Description from '@beatgig/design/components/description'
import Spacer from '@beatgig/design/components/spacer'
import Button from '@beatgig/components/button'

type Props = {
  selectedVenues: ReturnType<typeof useCalendarQuery>[0]['selectedVenues']
  onPressCreateVenue: () => void
}

type Placeholder<
  Props,
  Loading extends boolean,
  Extra = {}
> = Loading extends true
  ? { loading: true } & Partial<Record<keyof Props, never>> & Extra
  : { loading: false } & Props & Extra

type ItemProps = NonNullable<Props['selectedVenues']>[number]

const Venue = React.memo(function Venue<Loading extends boolean>(
  props: Placeholder<ItemProps, Loading, { index: number }>
) {
  return (
    <View
      sx={{
        py: 3,
        borderTopWidth: props.index ? '1' : '0',
        borderColor: 'border',
      }}
    >
      <Checkbox
        checked={props.selected}
        onChange={() =>
          props.slug && props.toggleSelected?.({ slug: props.slug })
        }
        centerContent
      >
        <View sx={{ flexGrow: 1 }}>
          <Skeleton show={props.loading}>
            <Text numberOfLines={1} ellipsizeMode="tail">
              {props.name || 'Loading venue...'}
            </Text>
          </Skeleton>
          {(props.loading || !!props.location) && (
            <Skeleton show={props.loading}>
              <Text sx={{ color: 'mutedText' }}>
                {props.location || 'New York'}
              </Text>
            </Skeleton>
          )}
        </View>
      </Checkbox>
    </View>
  )
})

const Placeholder = new Array(2)
  .fill('')
  .map((_, i) => <Venue key={i} loading index={i} />)

function Empty({ onPressCreateVenue }: Pick<Props, 'onPressCreateVenue'>) {
  return (
    <View sx={{ py: 2 }}>
      <Text>{`You don't have any venues yet. Time to create one!`}</Text>
      <Spacer height={3} />
      <Button onPress={onPressCreateVenue}>Create Venue</Button>
    </View>
  )
}

export function VenueSelectorsBoxesFilter({
  selectedVenues,
  onPressCreateVenue,
}: Props) {
  const totalVenueCount = selectedVenues?.length ?? 0
  const selectedVenuesCount =
    selectedVenues?.filter(({ selected }) => selected).length ?? 0

  return (
    <Card>
      <Card.Content sx={{ py: 2 }}>
        <Description
          title={`Venues${
            totalVenueCount > 1
              ? ` (${
                  selectedVenuesCount != totalVenueCount
                    ? `${selectedVenuesCount}/`
                    : ''
                }${totalVenueCount})`
              : ''
          }`}
        />
        {!selectedVenues && Placeholder}
        {selectedVenues &&
          selectedVenues?.length > 0 &&
          selectedVenues.map((venue, i) => (
            <Venue index={i} key={venue.slug} {...venue} loading={false} />
          ))}
        {selectedVenues?.length === 0 && (
          <Empty onPressCreateVenue={onPressCreateVenue} />
        )}
      </Card.Content>
    </Card>
  )
}
