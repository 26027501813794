import React, { ComponentProps, useMemo } from 'react'
import { View, ScrollView, Text, ActivityIndicator } from 'dripsy'
import Card from '@beatgig/design/components/card'
import SidebarBookingsList from './bookings-list'
import Spacer from '@beatgig/design/components/spacer'
import { useCalendarActions } from '@beatgig/screens/calendar-screen/hooks/actions'
import { APP_NAME } from '@beatgig/constants'
import Button from '@beatgig/components/button'
import type { useCalendarQuery } from '@beatgig/screens/calendar-screen/hooks/gql/use-calendar-query-gql'
import { CalendarActionsRequired } from '../bookings-small-calendar-list/action-required'
import { SelectedDate } from '@beatgig/screens/small-calendar-screen/use-selected-date/types'
import Gradient from '@beatgig/design/components/gradient'
import { VenueSelectorsBoxesFilter } from '@beatgig/search/components/venue-selectors-boxes'
import BookingRequestListSmall from '../booking-request-list-small'
import isFuture from 'date-fns/isFuture'
import { StyleSheet } from 'react-native'
import Press from '@beatgig/components/press'
import Ionicons from '@beatgig/design/components/ionicons'
import { BigCalendarBookingRequest } from '../bookings-big-calendar'
import { SellerSyncCalendar } from '@beatgig/screens/calendar-screen/seller-sidebar/sync-calendar'

type Props = {
  userId: string
  bookingRequests: null | BigCalendarBookingRequest[]
  selectedDate: SelectedDate
  setSelectedDate: (date: SelectedDate) => void
  highlightedBookingRequestId?: string
  onRefresh: () => void
  isLoading: boolean
} & Pick<ComponentProps<typeof SidebarBookingsList>, 'bookings'> &
  ReturnType<typeof useCalendarActions> &
  Pick<
    ReturnType<typeof useCalendarQuery>[0],
    'actionsRequiredBadgeCount' | 'selectedVenues'
  >

export default function BuyerCalendarSidebar({
  onPressCreateBookingRequest,
  bookingRequests,
  selectedDate,
  selectedVenues,
  actionsRequiredBadgeCount,
  onPressCreateVenue,
  onPressBookingRequest,
  highlightedBookingRequestId,
  onPressOpenActionRequired,
  onRefresh,
  isLoading,
}: Props) {
  const futureBookingRequests = useMemo(() => {
    if (!bookingRequests) return null

    return bookingRequests?.filter((request) => {
      return isFuture(new Date(request.start_time))
    })
  }, [bookingRequests])

  const onPressActionRequired = onPressOpenActionRequired

  const Loader = null && (
    // FIXME the loader does nothing on Web?
    <View sx={{ mx: 3, mb: 3, alignItems: 'flex-end' }}>
      <View>
        <Press
          sx={{ opacity: isLoading ? 0 : 1 }}
          disabled={isLoading}
          onPress={onRefresh}
        >
          <Ionicons name="refresh-outline" sx={{ mx: 2 }} />
        </Press>
        <View
          style={StyleSheet.absoluteFillObject}
          sx={{
            opacity: isLoading ? 1 : 0,
          }}
          pointerEvents="none"
        >
          <ActivityIndicator color="text" />
        </View>
      </View>
    </View>
  )

  return (
    <View sx={{ height: '100%', bg: 'muted' }}>
      <SellerSyncCalendar />
      <ScrollView showsVerticalScrollIndicator={false} sx={{ pt: 3, pb: 5 }}>
        {Loader}
        <View sx={{ px: 3 }}>
          <VenueSelectorsBoxesFilter
            onPressCreateVenue={onPressCreateVenue}
            selectedVenues={selectedVenues}
          />
        </View>
        <CalendarActionsRequired
          onPress={onPressActionRequired}
          badgeCount={actionsRequiredBadgeCount}
        />
        <Spacer height={3} />

        <View sx={{ px: 3 }}>
          <Card>
            <Card.Content>
              <Text>
                Want help choosing artists to book? Let {APP_NAME} find them for
                you.
              </Text>
              <Spacer height={3} />
              <Button
                onPress={() => {
                  onPressCreateBookingRequest({ startDay: selectedDate })
                }}
              >
                Get Artist Recommendations
              </Button>
            </Card.Content>
          </Card>
        </View>
        <Spacer height={3} />
        <View sx={{ px: 3 }}>
          <BookingRequestListSmall
            bookingRequests={futureBookingRequests}
            onPressBookingRequest={onPressBookingRequest}
            highlightedBookingRequestId={highlightedBookingRequestId}
          />
        </View>
      </ScrollView>
      <Gradient
        colors={['transparent', 'muted']}
        stretch
        sx={{ top: 'auto', height: 100 }}
        pointerEvents="none"
      />
    </View>
  )
}
