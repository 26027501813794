import React, { useState } from 'react'
import { View } from 'dripsy'
import useEnumFilter from './use-enum-filter'
import Checkbox from '@beatgig/design/components/checkbox'
import { Box } from '@beatgig/design/components/box'
import { Text } from '@beatgig/design/components/text'
import Link from '@beatgig/design/components/link'

type Props = ReturnType<typeof useEnumFilter> & {
  sx?: React.ComponentProps<typeof View>['sx']
  itemSx?: React.ComponentProps<typeof View>['sx']
}

export default function EnumFilter({
  values,
  handlers,
  state,
  sx,
  itemSx,
}: Props) {
  const [hoveredValue, setHoveredValue] = useState<null | string>(null)
  return (
    <View
      sx={sx}
      // @ts-ignore
      onMouseLeave={() => setHoveredValue(null)}
    >
      {values.map((value) => {
        const { selected = false, variant } = state[value] || {}
        return (
          <View
            // @ts-ignore
            onMouseEnter={() => setHoveredValue(value)}
            // @ts-ignore
            onMouseLeave={() => setHoveredValue(null)}
            sx={{ flexDirection: 'row', alignItems: 'center', ...itemSx }}
            key={value}
          >
            <Checkbox
              checked={selected}
              onChange={handlers.makeToggleItem(value)}
              variant={variant}
              centerContent
            />
            <Box flex={1} ml="$3">
              <Text>{value}</Text>
            </Box>
            {hoveredValue === value && (
              <Link onPress={() => handlers.setSingleItemOnly(value)} color>
                Only
              </Link>
            )}
          </View>
        )
      })}
    </View>
  )
}
