import React from 'react'
import { View, Text } from 'dripsy'
import AnimateHeight from '@beatgig/design/components/animate-height'
import Entity from '@beatgig/design/components/entity'
import EntityField from '@beatgig/design/components/entity/entity-field'
import Press from '@beatgig/components/press'
import Ionicons from '@beatgig/design/components/ionicons'
import { DripsyTheme } from '@beatgig/theme'
import { CalendarActionRequiredProps } from './types'
import useTheme from '@beatgig/theme/use-theme'
import HoverPulse from '@beatgig/components/hover-pulse'

export const CalendarActionsRequired = React.memo(
  function SmallCalendarActionsRequired(props: CalendarActionRequiredProps) {
    const { onPress, badgeCount } = props
    const { space } = useTheme()

    const color: keyof DripsyTheme['colors'] = 'error'

    const note = (
      <HoverPulse scaleTo={0.96}>
        <Press onPress={onPress}>
          <View sx={{ height: space[3] }} />
          <Entity
            thumbnail={<Ionicons name="alert-circle-outline" color={color} />}
            sx={{ mx: 3, borderColor: color }}
            alignFirstRow="center"
          >
            <EntityField
              title={
                <Text
                  selectable={false}
                  sx={{ fontWeight: 'bold' }}
                >{`Action Required (${badgeCount})`}</Text>
              }
            />
          </Entity>
        </Press>
      </HoverPulse>
    )

    return <AnimateHeight hide={!badgeCount}>{note}</AnimateHeight>
  }
)
