import { UserRole } from '@beatgig/api-services'
import { AlgoliaFacets } from '../types/facets'
import useFormatAlgoliaFilters from '../hooks/use-format-algolia-filters'
import { UserSearchAllUsersInfiniteProps } from '../types/search-all-users-types'
import { TypesenseCollectionName } from '@beatgig/gql'
import { useTypesenseSearchInfinite } from './use-search-typesense-infinite'
import { UserTypesenseSnake } from '@beatgig/gql/types/user-typesense'
import { useAdminTypesenseKey } from '@beatgig/api-hooks/src/use-admin-typesense-key'

export default function useSearchAllUsersInfinite(
  props: UserSearchAllUsersInfiniteProps
) {
  const {
    query,
    userRole = [],
    userType = [],
    approvalFilter = [],
    publicOrPrivateFilter = [],
    userTypeFilter = [],
  } = props

  const { facetFilters } = useFormatAlgoliaFilters({
    facetFilters: {
      [AlgoliaFacets.user_role]: {
        filters: userRole,
        condition: 'OR',
      },
      [AlgoliaFacets.user_type]: {
        filters: userType,
        condition: 'OR',
      },
    },
  })
  const filters = [...facetFilters, ...approvalFilter, ...userTypeFilter]
  if (userRole.includes(UserRole.BUYER)) {
    filters.push(...publicOrPrivateFilter)
  }

  const apiKey = useAdminTypesenseKey()

  const response = useTypesenseSearchInfinite<UserTypesenseSnake>(query, {
    index: TypesenseCollectionName.USERS,
    facetFilters: filters,
    queryBy: [
      'name',
      'user_type',
      'phone_number',
      'metadata_location',
      'metadata_organization',
    ],
    sortBy: 'last_updated',
    sortOrder: 'desc',
    apiKey: apiKey.data || null,
  })

  return response
}
