import React from 'react'
import { IndoorsOrOutdoors, ServerError } from '@beatgig/api-services'
import { Formik } from 'formik'
import useRequestCloseForm from '@beatgig/forms/hooks/use-request-close-form'

import WebModal from '@beatgig/components/web-modal'
import Button from '@beatgig/components/button'
import FormRow from '@beatgig/forms/components/row'
import Timezone from '@beatgig/forms/components/timezone'
import FormSet from '@beatgig/forms/components/set'
import PickerField from '@beatgig/forms/fields/picker-field'
import CoverImageField from '@beatgig/forms/fields/cover-image-field'
import TextField from '@beatgig/forms/fields/text-field'
import SubmitButton from '@beatgig/forms/fields/submit'
import * as yup from 'yup'
import DateTimePickerContainer from '@beatgig/forms/fields/date-time-container/provider'
import Grid from '@beatgig/design/components/grid'
import DateTimeContextPickerField from '@beatgig/forms/fields/date-time-container/picker'
import ScrollablesProvider from '@beatgig/forms/context/scrollables/scrollables-provider'
import { ScrollableFormikOnSubmitProp } from '@beatgig/forms/context/scrollables/scrollables-context'
import { ExternalBookingUpdate } from '@beatgig/api-services/external-booking'
import ErrorNote from '@beatgig/components/ErrorNote'
import Subtitle from '@beatgig/forms/components/subtitle'

type FormState = ExternalBookingUpdate

type Props = {
  initialExternalBooking: ExternalBookingUpdate
  animated?: boolean
  // onSubmit: (form: FormState) => Promise<void>
  onSubmit: ScrollableFormikOnSubmitProp<FormState, void>
  onClose: () => void
  timezone: string
  title?: string
  buttonText?: string
  onDelete: (() => void) | null
  isDeleting: boolean | null
  deleteError?: ServerError | null
  error?: ServerError | null
}

export default function EditExternalBookingForm(props: Props) {
  const {
    initialExternalBooking,
    animated,
    onSubmit,
    onClose,
    timezone,
    title = 'External Booking',
    buttonText,
    onDelete,
    isDeleting,
    deleteError,
    error,
  } = props

  const { requestClose } = useRequestCloseForm()

  return (
    <ScrollablesProvider>
      {(scrollableProps) => (
        <Formik<FormState>
          onSubmit={async (data, formik) =>
            onSubmit(data, formik, scrollableProps)
          }
          initialValues={initialExternalBooking}
          validationSchema={yup.object().shape<FormState>({
            artistName: yup.string().nullable().required(' '),
            performanceLengthMins: yup
              .number()
              .typeError('Please enter a number.')
              .nullable()
              .required(' ')
              .min(1, ' '),
            startTime: yup.date().nullable().required(' ') as any,
          })}
        >
          {({ dirty }) => (
            <WebModal
              button={
                <SubmitButton formName={title}>{buttonText}</SubmitButton>
              }
              header
              title={title}
              animated={animated}
              onRequestClose={requestClose(dirty, onClose)}
            >
              <ErrorNote error={error} sx={{ mb: '$3' }} />
              <FormRow>
                <CoverImageField<FormState>
                  addText="Upload image"
                  subtitle="Add an image to appear on your external calendar for this event."
                  name="coverImage"
                />
              </FormRow>

              <FormRow>
                <TextField<FormState>
                  name="artistName"
                  required
                  label="Artist Name"
                  placeholder="Drake"
                  tooltip="Who is performing?"
                />
                <Subtitle sx={{ mt: '$3' }}>
                  Enter the name of the artist, or the name of the event.
                </Subtitle>
              </FormRow>

              <FormRow>
                <DateTimePickerContainer<FormState>
                  name="startTime"
                  timezone={timezone}
                >
                  <Grid columns={[1, 2]}>
                    <Grid.Item>
                      <DateTimeContextPickerField<FormState>
                        name="startTime"
                        mode="date"
                        label="Event Date"
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <DateTimeContextPickerField<FormState>
                        name="startTime"
                        mode="time"
                        label="Start Time"
                      />
                    </Grid.Item>
                  </Grid>
                  <Timezone sx={{ mt: 3 }} timezoneName={timezone} />
                </DateTimePickerContainer>
              </FormRow>

              <FormRow>
                <FormSet>
                  <TextField<FormState>
                    name="performanceLengthMins"
                    label="Performance length (mins)"
                    placeholder="Enter minutes"
                    keyboardType="number-pad"
                    required
                    mask="number"
                    tooltip="How long is this performance?"
                  />

                  <PickerField<FormState>
                    items={Object.values(IndoorsOrOutdoors)}
                    name="indoorsOrOutdoors"
                    label="Indoors or Outdoors"
                    placeholder={{}}
                  />
                </FormSet>
              </FormRow>

              <FormRow>
                <TextField<FormState>
                  name="publicEventDescription"
                  numberOfLines={4}
                  multiline
                  label="Public Event Description"
                  placeholder="What should customers know? Any drink specials? Prices or discounts?"
                />
              </FormRow>
              {!!onDelete && (
                <FormRow sx={{ alignItems: 'center' }}>
                  <Button
                    onPress={onDelete}
                    loading={!!isDeleting}
                    sx={{ bg: 'error', borderColor: 'error', color: 'text' }}
                  >
                    Delete
                  </Button>
                  <ErrorNote sx={{ mt: 3 }} error={deleteError} />
                </FormRow>
              )}
            </WebModal>
          )}
        </Formik>
      )}
    </ScrollablesProvider>
  )
}
