import React, { useMemo } from 'react'
import type { Sx } from 'dripsy'
import AutoComplete from '@beatgig/design/components/auto-complete'
import useSearchAllVenuesInfinite from '@beatgig/search/hooks/use-search-all-venues-infinite'
import locationToString from '@beatgig/helpers/location-to-string'
import { VenueQuery } from '@beatgig/gql'

type DisplayVenue = VenueQuery['venue']

type Props = {
  onSelectVenue?: (venue: Pick<DisplayVenue, 'slug' | 'name'>) => void
  onClearVenue?: () => void
  /**
   * Called if we already had a venue, and are now selecting a new one
   */
  // onChangeVenue?: (nextVenue: Pick<DisplayVenue>, oldVenue: Pick<DisplayVenue>) => void
  value: string
  onChangeText: (next: string) => void
  sx?: Sx
}

const filterNull = <T extends unknown | null | undefined>(
  t: T
): t is NonNullable<T> => {
  return t != null
}

export default function AdminVenueSearch({
  sx,
  onSelectVenue,
  onClearVenue,
  value,
  onChangeText,
}: Props) {
  const { data, error, fetchMore, isFetchingMore, isLoadingInitialData } =
    useSearchAllVenuesInfinite({
      query: value,
    })

  const options = useMemo(
    () =>
      data?.filter(filterNull).map(({ document: venue }) => ({
        label: venue.name,
        value: venue.slug,
        subtitle: locationToString(venue.location, {
          city: true,
          postalCode: false,
          stateAbbr: false,
          streetAddress: true,
        }),
      })) ?? [],
    [data]
  )

  return (
    <AutoComplete
      sx={sx}
      align="left"
      options={options}
      defaultValue={value}
      value={value}
      onChangeText={onChangeText}
      placeholder="Search venues..."
      onEndReached={fetchMore}
      keyExtractor={(item) => item.value}
      shouldSetControlledStateOnPress="label"
      searching={isLoadingInitialData || isFetchingMore}
      editable
      hasErrored={!!error}
      onClear={onClearVenue}
      allowsCustomValue={false}
      onSelect={(venueSlug) => {
        // FIXME gql this should all use gql types...
        const venue = data?.find(
          (venue) => venue?.document.slug === venueSlug
        )?.document

        if (venue) {
          onSelectVenue?.({
            slug: venue.slug,
            name: venue.name,
          })
          // if (selectedVenue && selectedVenue?.id !== venue?.id) {
          //   onChangeVenue?.(venue, selectedVenue)
          // }
        }
      }}
    />
  )
}
