import { useOpenModal } from '@beatgig/modals/create-modal-stack/use-open-modal'
import { BookingRequestStackParams } from '../stacks/booking-requests-stack/types'
import { useCallback } from 'react'

export function useOpenBookingRequestSeller() {
  const { openModal } = useOpenModal<BookingRequestStackParams>()

  return {
    openBookingRequest: useCallback(
      ({
        bookingRequestId,
      }: BookingRequestStackParams['BookingRequestDetailSeller']) => {
        openModal('BookingRequestDetailSeller', {
          params: { bookingRequestId },
          webOverrides: {
            replace: (when) => !!when.query.bookingRequestId,
          },
        })
      },
      [openModal]
    ),
  }
}
