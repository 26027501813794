import React, { ComponentProps } from 'react'
import WebModal from '@beatgig/components/web-modal'
import LoadingScreen from '@beatgig/components/loading-screen'
import VenueSelectorForm from '@beatgig/screens/create-booking-screen/venue-selector-form'
import { ServerError } from '@beatgig/api-services'
import { MyVenuesQuery } from '@beatgig/gql'

type Props = {
  venues: MyVenuesQuery['myVenues'] | null
  venuesError?: ServerError
  onClose: () => void
} & Pick<
  ComponentProps<typeof VenueSelectorForm>,
  'onSelectVenue' | 'selectVenueDescription' | 'title' | 'formHeader'
>

export default function CreateOrSelectVenueForm({
  venues,
  venuesError,
  onClose,
  onSelectVenue,
  selectVenueDescription,
  title,
  formHeader,
}: Props) {
  if (!venues) {
    return (
      <WebModal title={title} header onRequestClose={onClose}>
        <LoadingScreen
          containerSx={{ minHeight: 400 }}
          error={venuesError}
          childrenUnderLoader={!!venuesError}
          errorMessageFallback={
            venuesError
              ? `We're having trouble retrieving your venues.`
              : undefined
          }
        >
          Loading your venues...
        </LoadingScreen>
      </WebModal>
    )
  }
  return (
    <VenueSelectorForm
      title={title}
      venues={venues}
      onRequestClose={onClose}
      selectVenueDescription={selectVenueDescription}
      onSelectVenue={onSelectVenue}
      formHeader={formHeader}
    />
  )
}
