import React from 'react'
import { createModalStack } from '@beatgig/modals/create-modal-stack'
import type { BookingRequestDetailRoute as RouteComponentType } from '@beatgig/screens/booking-request-detail-screen'
import { useSx } from 'dripsy'
import { WebCalendarModalStackParams } from './types'
import WebHeaderSpacer from '@beatgig/components/web-header/spacer'
import dynamic from 'next/dynamic'
import { BookingActionsRequiredScreen } from '@beatgig/screens/booking-actions-required-screen'
import type { BookingRequestDetailSellerScreen as SellerScreen } from '@beatgig/screens/booking-request-detail-seller-screen'
import type { BigCalendarDayOverlaySheet as BigCalendarDayOverlaySheetType } from '@beatgig/ui/big-calendar-day-overlay-sheet'
import { UserRole } from '@beatgig/backend-types'

const BookingRequestDetailSellerScreen = dynamic<
  React.ComponentProps<typeof SellerScreen>
>(() =>
  import('@beatgig/screens/booking-request-detail-seller-screen').then(
    (m) => m.BookingRequestDetailSellerScreen
  )
)

const BookingRequestDetailRoute = dynamic<
  React.ComponentProps<typeof RouteComponentType>
>(() =>
  import('@beatgig/screens/booking-request-detail-screen').then(
    (m) => m.BookingRequestDetailRoute
  )
)

const BookingRequestUpdateArtistsScreen = dynamic<
  React.ComponentProps<
    typeof import('@beatgig/screens/booking-request-update-artists-screen')['BookingRequestUpdateArtistsScreen']
  >
>(() =>
  import('@beatgig/screens/booking-request-update-artists-screen').then(
    (m) => m.BookingRequestUpdateArtistsScreen
  )
)

const BigCalendarDayOverlaySheet = dynamic<
  React.ComponentProps<typeof BigCalendarDayOverlaySheetType>
>(() =>
  import('@beatgig/ui/big-calendar-day-overlay-sheet').then(
    (m) => m.BigCalendarDayOverlaySheet
  )
)

type Props = {
  screen: React.ReactElement
  onDidClose: () => void
  webHeaderSafeArea?: boolean
  userRole: UserRole
}

const {
  ModalStack,
  RouteModal,
} = createModalStack<WebCalendarModalStackParams>()

export function WebCalendarModalStack({
  screen,
  onDidClose,
  webHeaderSafeArea,
  userRole,
}: Props) {
  const sx = useSx()

  return (
    <ModalStack screen={screen}>
      <RouteModal
        name="BookingRequestDetail"
        shouldRender={({ state }) => !!state.query.bookingRequestId}
        // component={}
        style={sx({ backgroundColor: 'background' })}
      >
        {({ active }) =>
          active && (
            <>
              {webHeaderSafeArea && <WebHeaderSpacer />}
              <BookingRequestDetailRoute onDidClose={onDidClose} />
            </>
          )
        }
      </RouteModal>
      <RouteModal
        name="BookingActionsRequired"
        component={BookingActionsRequiredScreen}
      />
      <RouteModal
        name="BookingRequestDetailSeller"
        // component={BookingRequestDetailSellerScreen}
        shouldRender={({ state, matches }) =>
          // if we don't use matches too, then this seller screen will render for buyers when it shouldn't
          // but this is here for admins only...
          // god, what a fucking mess navigation is.
          // in retrospect, I wish this screen was just for all users. ☹️
          matches && !!state.query.bookingRequestId
        }
        component={BookingRequestDetailSellerScreen}
      />
      <RouteModal
        name="UpdateBookingRequestArtists"
        component={BookingRequestUpdateArtistsScreen}
        shouldRender={({ state, matches }) =>
          matches && !!state.query.bookingRequestId
        }
      />
      <RouteModal
        name="EventsForDay"
        shouldRender={(when) =>
          when.matches &&
          !!(
            when.state.query.day &&
            when.state.query.month &&
            when.state.query.year
          )
        }
      >
        {({ active }) =>
          active && <BigCalendarDayOverlaySheet userRole={userRole} />
        }
      </RouteModal>
    </ModalStack>
  )
}
