import React, { useState } from 'react'
import EditExternalBookingForm from '../edit-external-booking/form'
import { useRoute, useNavigation } from '@react-navigation/native'
import type { CreateExternalBookingRoute } from '@beatgig/navigation/stacks/calendar-stack/types'
import WebModal from '@beatgig/components/web-modal'
import LoadingScreen from '@beatgig/components/loading-screen'
import useCreateExternalBooking from './use-create-external-booking'
import useVenue from '@beatgig/api-hooks/src/use-venue'
import { DateTime } from 'luxon'
import useServerFormError from '@beatgig/forms/hooks/use-server-form-error'
import CreateOrSelectVenueForm from '@beatgig/ui/create-or-select-venue-form'
import { ExternalBookingBuyerExplanation } from '@beatgig/ui/external-booking-buyer-explanation'
import useMyVenues from '@beatgig/api-hooks/src/use-my-venues'
import { IndoorsOrOutdoors } from '@beatgig/gql'

export default function CreateExternalBookingScreen() {
  const { goBack } = useNavigation()
  const {
    // venueSlug,
    adminOnBehalfOf,
  } = useRoute<CreateExternalBookingRoute>().params
  const { execute, error } = useCreateExternalBooking()

  const [selectedVenueSlug, setSelectedVenueSlug] = useState<
    string | undefined
  >()

  const { handleServerFormError } = useServerFormError()

  const { data: venues, error: venuesError } = useMyVenues({
    userId: adminOnBehalfOf,
  })
  const { data: venue, error: venueError } = useVenue({
    slug: selectedVenueSlug,
  })

  if (!selectedVenueSlug) {
    return (
      <CreateOrSelectVenueForm
        venues={venues || null}
        onSelectVenue={({ slug }) => setSelectedVenueSlug(slug)}
        onClose={goBack}
        title="Import Booking"
        formHeader={<ExternalBookingBuyerExplanation />}
        venuesError={venuesError}
        selectVenueDescription="First, choose a venue for your imported booking."
      />
    )
  }

  if (!venue) {
    return (
      <WebModal animated={false} header onRequestClose={goBack}>
        <LoadingScreen error={venueError}>Loading venue...</LoadingScreen>
      </WebModal>
    )
  }

  return (
    <EditExternalBookingForm
      buttonText="Import"
      animated={false}
      onClose={goBack}
      onDelete={null}
      isDeleting={null}
      title="Import External Booking"
      onSubmit={async (externalBooking, formik, scrollableProps) => {
        try {
          const newExternalBooking = await execute({
            venueId: venue.id,
            input: externalBooking,
          })
          if (newExternalBooking.data?.createExternalBooking.id) {
            goBack()
          }
        } catch (error) {
          handleServerFormError({
            error,
            // setError,
            ...formik,
            ...scrollableProps,
            formName: 'Create External Booking',
          })
        }
      }}
      initialExternalBooking={{
        artistName: '',
        startTime: DateTime.fromObject({
          hour: 21,
          minute: 0,
          second: 0,
        })
          .setZone(venue.location.timezone as string, {
            keepLocalTime: true,
          })
          .toJSDate()
          .toISOString(),
        // required in types, but field should be empty to start
        performanceLengthMins: undefined as any,
        indoorsOrOutdoors: IndoorsOrOutdoors.INDOORS,
      }}
      timezone={venue.location.timezone as string}
    />
  )
}
