import React, { useCallback, useMemo } from 'react'
import { View, Text } from 'dripsy'
import { SelectedDate } from './use-selected-date/types'
import Press from '@beatgig/components/press/index'
import type { useCalendarActions } from '../calendar-screen/hooks/actions'
import Gradient from '@beatgig/design/components/gradient'
import { StyleSheet } from 'react-native'
import HoverPulse from '@beatgig/components/hover-pulse'
import useTheme from '@beatgig/theme/use-theme'
import { Platform } from 'react-native'

type Props = {
  // onPress: ReturnType<
  //   typeof useCalendarActions
  // >['onPressCreateRecommendedBooking']
  onPress: ReturnType<typeof useCalendarActions>['onPressCreateBookingRequest']
  selectedDate: SelectedDate | null
  children?: string
  fontSize?: number
  align?: 'left' | 'center'
} & React.ComponentProps<typeof View>

export function CreateRecommendationButton(props: Props) {
  const {
    onPress,
    selectedDate,
    children = `Recommendations`,
    fontSize,
    align,
  } = props

  const { colors } = useTheme()

  const create = useCallback(() => {
    onPress({
      startDay: selectedDate || undefined,
    })
  }, [onPress, selectedDate])

  const child = useMemo(() => {
    return (
      <HoverPulse
        scaleTo={Platform.select({
          web: 1.04,
          default: 0.96,
        })}
      >
        <View
          sx={{ borderRadius: 'rounded', overflow: 'hidden', py: 2, px: 3 }}
          style={{
            shadowColor: colors.text,
            shadowOpacity: 0.5,
            shadowRadius: 20,
            shadowOffset: {
              height: 0,
              width: 0,
            },
          }}
        >
          <View sx={{ ...StyleSheet.absoluteFillObject, bg: 'blue' }}>
            <Gradient
              start={[-1, 0]}
              end={[1, 0]}
              stretch
              colors={['accent', 'blue']}
              // colors={['primary', 'primary']}
            />
          </View>
          <View
            sx={{
              zIndex: 1,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: align === 'center' ? 'center' : 'space-between',
            }}
          >
            <Text
              selectable={false}
              sx={{ color: 'text', fontWeight: 'bold', fontSize }}
            >
              {children}
            </Text>
            <Text
              selectable={false}
              sx={{ color: 'text', fontWeight: 'bold', fontSize }}
            >
              {'⚡️'}
            </Text>
          </View>
        </View>
      </HoverPulse>
    )
  }, [children, colors.text, fontSize, align])

  return <Press onPress={create}>{child}</Press>
}
