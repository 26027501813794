import React from 'react'
import { View } from 'dripsy'
import { ArtistHit } from '@beatgig/search/types/hits'
import Avatar from '@beatgig/design/components/avatar'
import Cloudinary from '@beatgig/helpers/cloudinary'
import numeral from 'numeral'
import Description from '@beatgig/design/components/description'
import Entity from '@beatgig/design/components/entity'
import EntityField from '@beatgig/design/components/entity/entity-field'
import Button from '@beatgig/components/button'
import EntityFooter from '@beatgig/design/components/entity/entity-footer'
import { ArtistRecommendationUpdate } from '@beatgig/api-services/recommended-booking'

type Props = {
  index: number
  remove: (index: number, id: string) => void
  artistRecommendation: (ArtistRecommendationUpdate & ArtistHit) | null
} & React.ComponentProps<typeof View>

export default function AddRecommendedArtistItem(props: Props) {
  const { sx = {}, artistRecommendation, remove, index } = props

  const onRemove = () => remove(index, artistRecommendation?.id as string)

  return (
    <Entity
      thumbnail={
        <Avatar
          source={{
            uri: Cloudinary(artistRecommendation?.profile_image, {
              quality: 75,
              width: 300,
            }),
          }}
          size="large"
        />
      }
      sx={{ mb: 3, bg: 'muted' }}
      actions={
        <Button
          iconLeft={{ name: 'ios-trash', color: 'error' }}
          sx={{ color: 'error', borderColor: 'error' }}
          variant="smallOutlined"
          onPress={onRemove}
        >
          {/* Remove */}
        </Button>
      }
      footer={
        !!artistRecommendation?.details && (
          <EntityFooter>
            <Description title="Details">
              {artistRecommendation?.details}
            </Description>
          </EntityFooter>
        )
      }
    >
      <EntityField
        title={artistRecommendation?.name}
        subtitle={`${numeral(artistRecommendation?.price).format('$0,0')}`}
      />
    </Entity>
  )
}
