import { ArtistDisplay } from '@beatgig/api-services/artist'
import { useState, useMemo } from 'react'
import Router from 'next/router'
import { Platform } from 'react-native'
import { VenueQuery } from '@beatgig/gql'
import useOwnersOfVenue from '@beatgig/api-hooks/venue-access/use-owners-of-venue'
import useSellersOfArtist from '@beatgig/api-hooks/artist-linking-access/use-sellers-of-artist'
import { useRouting } from 'expo-next-react-navigation'

export default function useAdminOnBehalfOfVenueContainer({
  lookUpBy,
}: {
  lookUpBy: 'artist' | 'venue'
}) {
  const { getParam } = useRouting()
  const adminOnBehalfOfArtistSlug: string | undefined = getParam(
    'adminOnBehalfOfArtistSlug'
  )
  const adminOnBehalfOfVenueSlug: string | undefined = getParam(
    'adminOnBehalfOfVenueSlug'
  )
  const [venue, setVenue] = useState<Pick<VenueQuery['venue'], 'slug'> | null>(
    adminOnBehalfOfVenueSlug
      ? {
          slug: adminOnBehalfOfVenueSlug,
        }
      : null
  )
  const [searchBy, setSearchBy] = useState<'venue' | 'user' | 'artist'>(
    lookUpBy
  )
  const [artist, setArtist] = useState<Pick<ArtistDisplay, 'slug'> | null>(
    adminOnBehalfOfArtistSlug
      ? {
          slug: adminOnBehalfOfArtistSlug,
        }
      : null
  )

  const [venueSearchQuery, setVenueSearchQuery] = useState('')
  const [artistSearchQuery, setArtistSearchQuery] = useState('')

  const handlers = useMemo(
    () => ({
      setVenue,
      setArtist,
      setSearchQuery(text: string) {
        if (searchBy === 'venue') {
          setVenueSearchQuery(text)
        } else {
          setArtistSearchQuery(text)
        }
      },
      // setArtistSearchQuery,
      clearArtist: () => setArtist(null),
      clearVenue: () => {
        if (Platform.OS === 'web') {
          const { query = {} } = Router

          delete query.venue
          delete query.adminOnBehalfOfVenueId
          delete query.adminOnBehalfOfVenueSlug

          Router.push(
            {
              pathname: Router.pathname,
              query: {
                ...query,
              },
            },
            undefined,
            {
              shallow: true,
            }
          )
        }
        setVenue(null)
      },
      setSearchByUser: () => setSearchBy('user'),
      setSearchByVenue: () => setSearchBy('venue'),
      setSearchByArtist: () => setSearchBy('artist'),
      // setVenueSearchQuery,
      clearVenueSearchQuery: () => setVenueSearchQuery(''),
    }),
    [searchBy]
  )

  const searchQuery =
    searchBy === 'venue' ? venueSearchQuery : artistSearchQuery

  const { data: ownersOfVenue } = useOwnersOfVenue({
    slug: lookUpBy === 'venue' && venue ? venue.slug : null,
  })
  const { data: sellersOfArtist } = useSellersOfArtist(
    lookUpBy === 'artist' && artist ? { artistSlug: artist.slug } : null
  )

  const entityName =
    searchBy === 'venue'
      ? ownersOfVenue?.name || 'this venue'
      : sellersOfArtist?.name || 'this artist'

  return [
    { venue, searchQuery, artist, entityName, ownersOfVenue, sellersOfArtist },
    handlers,
    {
      isSearchingByVenue: searchBy === 'venue',
      isSearchingByUser: searchBy === 'user',
      isSearchingByArtist: searchBy === 'artist',
    },
  ] as const
}
