import { DripsyTheme } from '@beatgig/theme'
import { BookingRequestStatus } from '@beatgig/api-services/booking-request'
import useTheme from '@beatgig/theme/use-theme'

type ValueOf<T> = T[keyof T]

type Color = ValueOf<DripsyTheme['colors']>

// TODO add isPastEvent to the type
export function getBookingRequestStatusColor(
  colors: DripsyTheme['colors'],
  {
    status,
    isActionRequired,
    isPastEvent,
  }: {
    status: BookingRequestStatus
    isActionRequired: boolean
    isPastEvent: boolean
  }
) {
  let variant: keyof DripsyTheme['colors'] = `muted6`
  let borderColor: Color = colors?.muted5

  switch (status) {
    case BookingRequestStatus.ACCEPTED:
      variant = 'success'
      borderColor = colors?.success
      break
    case BookingRequestStatus.PENDING:
      variant = 'warning'
      break
    case BookingRequestStatus.CANCELLED:
      variant = 'muted4'
      borderColor = colors?.border
      break
    default:
      break
  }
  if (isActionRequired) {
    borderColor = colors.error
    variant = 'error'
  }
  if (isPastEvent && status !== BookingRequestStatus.ACCEPTED) {
    variant = 'muted5'
    borderColor = colors.muted5
  }

  return {
    color: colors[variant],
    borderColor,
    variant,
  }
}

export function useBookingRequestStatusColor(
  props: Parameters<typeof getBookingRequestStatusColor>[1]
) {
  const { colors } = useTheme()

  /**
   * Abstracted for use outside of hooks.
   */
  return getBookingRequestStatusColor(colors, props)
}
