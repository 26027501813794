import React, { useEffect, useMemo } from 'react'
import { View } from 'dripsy'
import { UserRole, WhatShouldIDoAlert } from '@beatgig/api-services'
import BigCalendar from '@beatgig/components/big-calendar'
import isAfter from 'date-fns/isAfter'
import Color from 'color'
import type { EventInput } from '@fullcalendar/core'
import numeral from 'numeral'
import { Platform } from 'react-native'
import useBookingStatusToColor from '@beatgig/hooks/use-booking-status-to-color'
import useTheme from '../../theme/use-theme'
import isFuture from 'date-fns/isFuture'
import isSameDay from 'date-fns/isSameDay'
import { getBookingRequestStatusColor } from '@beatgig/hooks/use-booking-request-status-to-color'
import { DateTime } from 'luxon'

import type {
  BigCalendarBooking,
  BigCalendarExternalBooking,
  BigCalendarBookingRequest,
  BigCalendarExternalCalendarEvent,
} from './types'

export type {
  BigCalendarBooking,
  BigCalendarExternalBooking,
  BigCalendarBookingRequest,
}

type Props = {
  bookings: BigCalendarBooking[] | null
  externalCalendarEvents: BigCalendarExternalCalendarEvent[] | null
  onPressBooking?: (booking: BigCalendarBooking) => void
  /**
   * For admin, use `buyer_id`.
   * For buyer, use `venue_id`.
   * For seller, user `artist_id`.
   */
  // coordindateColorBy?: 'venue_id' | 'buyer_id' | 'artist_id' | 'status'
  /**
   * @default `true`
   */
  shouldFadeOldEvents?: boolean
  bookingRequests: BigCalendarBookingRequest[] | null
  onPressBookingRequest?: (bookingRequest: BigCalendarBookingRequest) => void
  // recommendedBookings: DisplayRecommendedBooking[] | null
  // onPressRecommendedBooking?: (booking: DisplayRecommendedBooking) => void
  onPressDate?: (info: { date: Date; hasNoEventsOnThisDate?: boolean }) => void
  externalBookings: BigCalendarExternalBooking[] | null
  onPressExternalBooking?: (externalBooking: BigCalendarExternalBooking) => void
  // bookingTooltipMessage?: (booking: DisplayBooking) => string
  containerSx?: React.ComponentProps<typeof View>['sx']
} & React.ComponentProps<typeof BigCalendar> &
  (
    | {
        userRole: UserRole.SELLER
        artistIds: string[] | null
      }
    | {
        userRole: UserRole.BUYER | UserRole.ADMIN
        artistIds?: never
      }
  )

export default function BookingsBigCalendar(props: Props) {
  const {
    bookings,
    onPressBooking,
    shouldFadeOldEvents = true,
    // recommendedBookings,
    // onPressRecommendedBooking,
    onPressDate,
    onPressBookingRequest,
    bookingRequests,
    externalBookings,
    onPressExternalBooking,
    // bookingTooltipMessage,
    containerSx,
    dateClick,
    externalCalendarEvents,
    artistIds: _omit,
    ...otherProps
  } = props

  useEffect(function setCalendarEventHeightHack() {
    const calendarElement = document.getElementsByClassName(
      'fc-scrollgrid-sync-table'
    )[0]

    if (calendarElement.tagName == 'TABLE') {
      const trElements = calendarElement.getElementsByTagName('tr')

      for (let i = 0; i < trElements.length; i++) {
        const tr = trElements[i]

        const height = `${100 / trElements.length}%`

        if (tr.style.height !== height) {
          tr.style.height = height
        }
      }
    }
  })

  const { bookingStatusToColor } = useBookingStatusToColor()

  const { colors } = useTheme()

  const bookingEvents = useMemo(
    () =>
      bookings
        ?.filter(({ start_time }) => start_time)
        ?.map((booking) => {
          const {
            artist,
            start_time,
            id,
            status,
            what_should_i_do_alerts,
            venue_name,
            buyer,
            performance_length_mins,
          } = booking
          // if (!event_date || !start_time) return null as any

          const now = new Date()
          const date = new Date(start_time as string)
          const isOldEvent = isAfter(now, date) && !isSameDay(now, date)

          // const step =
          //   display_status_information.all_steps[
          //     display_status_information.current_step_index
          //   ]

          let color = bookingStatusToColor(status, {
            isActionRequired:
              !isOldEvent &&
              what_should_i_do_alerts?.[props.userRole] ===
                WhatShouldIDoAlert.ACTION_REQUIRED,
          }).color
          let textColor: string = colors.background
          // let color = getColors(booking)
          if (isOldEvent && shouldFadeOldEvents) {
            color = Color(color).alpha(0.3).rgb().string()
            textColor = 'white'
          } else if (Color(color).isDark()) {
            textColor = 'white'
          }
          if (!isOldEvent) {
            if (
              props.userRole === UserRole.SELLER &&
              what_should_i_do_alerts?.seller ===
                WhatShouldIDoAlert.ACTION_REQUIRED
            ) {
              color = colors.error
            } else if (
              props.userRole === UserRole.BUYER &&
              what_should_i_do_alerts?.buyer ===
                WhatShouldIDoAlert.ACTION_REQUIRED
            ) {
              color = colors.error
            }
          }

          // const start = new Date(event_date as string)
          // start.setTime(new Date(start_time as string).getTime())
          return {
            title:
              props.userRole !== UserRole.SELLER
                ? artist?.name
                : venue_name ||
                  buyer.metadata.organization ||
                  buyer.metadata.location ||
                  artist?.name,
            // start,
            start: start_time,
            color,
            textColor,
            id,

            // this results in stuff looking weird across days
            end: DateTime.fromJSDate(new Date(start_time))
              .plus({
                minutes: performance_length_mins,
              })
              .toJSDate(),
          } as EventInput
        }) ?? [],
    [
      bookings,
      bookingStatusToColor,
      shouldFadeOldEvents,
      props.userRole,
      colors.background,
      colors.error,
    ]
  )

  const externalBookingEvents = useMemo(
    () =>
      externalBookings
        // remove items that have a booking ID, since these will show up in bookings
        ?.filter(({ start_time, id }) => start_time && id)
        ?.map(({ start_time, id, artist_name, performance_length_mins }, i) => {
          return {
            title: artist_name + ' (Import)',
            date: start_time,
            color: 'transparent',
            textColor: colors?.text,
            id,
            end: DateTime.fromJSDate(new Date(start_time))
              .plus({
                minutes: performance_length_mins,
              })
              .toJSDate(),
            // display: 'list-item',
          } as EventInput
        }) ?? [],
    [colors, externalBookings]
  )

  const bookingRequestEvents = useMemo(
    () =>
      bookingRequests
        // remove items that have a booking ID, since these will show up in bookings
        ?.filter(({ start_time, booking }) => start_time && !booking?.id)
        ?.map((booking) => {
          const {
            start_time,
            id,
            status,
            budget,
            isActionRequired,
            performance_length_mins,
          } = booking
          // if (!event_date || !start_time) return null as any

          const now = new Date()
          const date = new Date(start_time as string)
          const isOldEvent = isAfter(now, date)

          const isPastEvent = !isFuture(date)

          let { color } = getBookingRequestStatusColor(colors, {
            status,
            isActionRequired,
            isPastEvent,
          })
          if (isOldEvent && shouldFadeOldEvents) {
            color = Color(color).alpha(0.3).rgb().string()
          }

          let textColor = 'black'
          if (Color(color).isDark() || isPastEvent) {
            textColor = 'white'
          }

          return {
            title:
              (budget?.high
                ? `${numeral(budget.high).format('$0.[0]a')} `
                : '') + 'Request',
            date: start_time,
            color,
            textColor,
            id,
            end: DateTime.fromJSDate(new Date(start_time))
              .plus({
                minutes: performance_length_mins,
              })
              .toJSDate(),
          } as EventInput
        }) ?? [],
    [bookingRequests, colors, shouldFadeOldEvents]
  )

  const events = useMemo<Array<EventInput>>(
    () => [
      ...bookingRequestEvents,
      ...bookingEvents,
      ...externalBookingEvents,
      ...(externalCalendarEvents?.map(({ when, title }): EventInput => {
        let start: EventInput['start']
        let end: EventInput['end']
        switch (when.__typename) {
          case 'WhenDate':
            start = when.date
            break
          case 'WhenDatespan':
            start = when.startDate
            end = when.endDate
            break
          case 'WhenTime':
            start = when.time
            break
          case 'WhenTimespan':
            start = when.startTime
            end = when.endTime
            break
        }

        const allDay = when.__typename === 'WhenDate'

        return {
          title: title,
          start,
          end,
          allDay,
          display: 'list-item',
          color: colors.text,
          textColor: colors.text,
        }
      }) || []),
    ],
    [
      bookingRequestEvents,
      bookingEvents,
      externalBookingEvents,
      externalCalendarEvents,
      // colors.muted,
      colors.text,
    ]
  )

  return (
    <View sx={containerSx}>
      <BigCalendar
        {...otherProps}
        // important to prevent bars from overflowing into the next one when they shouldn't
        // https://fullcalendar.io/docs/nextDayThreshold
        nextDayThreshold={'09:00:00'}
        dateClick={
          (dateClick || onPressDate) &&
          ((clicked) => {
            dateClick?.(clicked)
            if (onPressDate) {
              const hasNoEventsOnThisDate = !events.some(
                (event) =>
                  event.date &&
                  isSameDay(new Date(event.date.toString()), clicked.date)
              )
              onPressDate({ date: clicked.date, hasNoEventsOnThisDate })
            }
          })
        }
        eventDidMount={async ({ el, event: { id } }) => {
          const booking = bookings?.find((booking) => booking.id === id)
          if (booking && Platform.OS === 'web') {
            const tippy = (await import('tippy.js')).default
            let content: string | undefined

            if (!content) {
              const now = new Date()
              const date = new Date(booking.start_time as string)
              const isOldEvent = isAfter(now, date)
              content = isOldEvent ? 'past event' : booking.status
            }
            tippy(el, {
              content,
              theme: 'gradient',
              interactive: true,
            })
          }
        }}
        displayEventEnd
        events={events}
        eventClick={({ event }) => {
          const booking = bookings?.find(({ id }) => event.id === id)

          if (booking) {
            onPressBooking?.(booking)
            return
          }
          // const recommendedBooking = recommendedBookings?.find(
          //   ({ id }) => event.id === id
          // )
          const bookingRequest = bookingRequests?.find(
            ({ id }) => event.id === id
          )
          if (bookingRequest && onPressBookingRequest) {
            onPressBookingRequest?.(bookingRequest)
            return
          }

          // if (recommendedBooking) {
          //   onPressRecommendedBooking?.(recommendedBooking)
          //   return
          // }
          const externalBooking = externalBookings?.find(
            ({ id }) => id === event.id
          )
          if (externalBooking) {
            onPressExternalBooking?.(externalBooking)
            return
          }
        }}
        initialDate={bookings?.[0]?.start_time}
      />
    </View>
  )
}
