import React, { useState, useRef, useEffect, ReactNode } from 'react'
import { Text, View } from 'dripsy'
import { AllUserAccountTypes, UserRole } from '@beatgig/api-services'
import useMyAccount from '@beatgig/auth-hooks/use-my-account'
import { User } from '@beatgig/api/user'
import { useRouting } from 'expo-next-react-navigation'
import useUserFromAdmin from '@beatgig/api-hooks/src/use-user-from-admin'
import {
  useAdminOnBehalfOfUser,
  OnBehalfOfUserContext,
  useOnBehalfOfContext,
} from '@beatgig/auth-hooks/use-admin-on-behalf-of-user'
import AdminUserSearch from '../admin-user-search'
import { Platform } from 'react-native'
import { UserDisplay } from '@beatgig/api-services/user'
// import AnimateHeight from '@beatgig/design/components/animate-height'
import Press from '@beatgig/components/press'
import Ionicons from '@beatgig/design/components/ionicons'
import useResponsiveValue from '@beatgig/hooks/use-responsive-value'
import Spacer from '@beatgig/design/components/spacer'
import useAuth from '@beatgig/auth-hooks/use-auth'
import { UrqlProvider } from '@beatgig/providers/urql'

type Props = {
  // independent?: boolean
  children: (props: {
    userId: string
    userName: string
    userRole: UserRole | undefined
    isUserApproved: boolean
    userType: UserDisplay['metadata']['user_type'] | undefined
  }) => ReactNode | null
  /**
   * Describe to admins what happens after searching for a user.
   *
   * Example: `This will show you their bookings, recommended bookings, and make their venues searchable.`
   */
  unselectedUserText: string
  onSelectUser?: (user: AllUserAccountTypes) => void
  onClearUser?: () => void
  userRole: UserRole
  /**
   * Called if we already had a user, and are now selecting a new one
   */
  onChangeUser?: () => void
  onPressAlternateSearchMethod?: () => void
  searchByAlternateMethodText?: string
  // forceShow?: boolean
}

export default function AdminOnBehalfOfUserContainer({
  children,
  unselectedUserText,
  onSelectUser,
  onClearUser,
  userRole: userRoleFilter,
  onChangeUser,
  searchByAlternateMethodText,
  onPressAlternateSearchMethod,
}: // independent = false,
// forceShow = false,
Props) {
  const { data: me } = useMyAccount()

  const maybeBehalfOfContext = useOnBehalfOfContext()

  const [adminOverrideUser, setAdminOverrideUser] = useAdminOnBehalfOfUser({
    // independent,
    userRole: userRoleFilter,
  })
  // = useState<AllUserAccountTypes | null>(null)
  const [adminOverrideSearchQuery, setAdminOverrideSearchQuery] = useState(
    adminOverrideUser?.name ?? ''
  )

  const { getParam } = useRouting()

  const userIdFromParams: string | undefined = getParam('uid')

  const { data: adminUserFromParams } = useUserFromAdmin(
    userIdFromParams ?? null
  )

  const hasSetUserFromParams = useRef(false)

  useEffect(
    function setInitialUserFromQueryParams() {
      if (
        adminUserFromParams &&
        User.isBuyer(adminUserFromParams) &&
        User.isAdmin(me) &&
        !hasSetUserFromParams.current
      ) {
        hasSetUserFromParams.current = true
        console.log('[booking-calendar-screen] will set admin override')
        setAdminOverrideUser((state) => {
          // only do this if we haven't already selected a user
          if (!state) {
            setAdminOverrideSearchQuery(adminUserFromParams.name)
            return adminUserFromParams
          }
          return state
        })
      }
    },
    [adminUserFromParams, me, setAdminOverrideUser]
  )

  const myAuth = useAuth()

  let userId = ''
  let userName = ''
  let userRole: UserRole | undefined
  let userType: UserDisplay['metadata']['user_type'] | undefined
  let isUserApproved = false
  if (User.isAdmin(me)) {
    userId = adminOverrideUser?.id ?? ''
    userName = adminOverrideUser?.name ?? ''
    userRole = adminOverrideUser?.user_role
    isUserApproved = !!adminOverrideUser?.approved
    userType = adminOverrideUser?.metadata.user_type
  } else if (User.hasOnboarded(me) && myAuth.user) {
    userId = myAuth.user.uid
    userName = me.name
    userRole = me.user_role
    isUserApproved = !!me.approved
    userType = me.metadata.user_type
  }

  const clearUser = () => {
    onClearUser?.()
    setAdminOverrideUser(null)
  }

  const [adminBarHidden, setAdminBarHidden] = useState(!!adminOverrideUser)

  const shouldShowSelectorBar =
    !maybeBehalfOfContext?.hasParentContainer ||
    !!(
      maybeBehalfOfContext.userRoleProp &&
      userRole &&
      maybeBehalfOfContext.userRoleProp !== userRole
    )

  const toggleAdminBarHidden = () => setAdminBarHidden((state) => !state)

  const responsiveLastName = useResponsiveValue(['none', 'none', undefined])

  const renderAdminSelectUserBar = () => {
    if (!User.isAdmin(me) || (Platform.OS !== 'web' && adminOverrideUser)) {
      return null
    }

    const bg = userRoleFilter === UserRole.SELLER ? 'accent' : 'blue'

    const firstName = adminOverrideUser?.name?.split(' ')[0] ?? ''
    const lastName = adminOverrideUser?.name?.split(' ')[1]

    const toggle = !!adminOverrideUser && (
      <Press
        sx={{
          alignItems: 'flex-end',
        }}
        onPress={toggleAdminBarHidden}
      >
        <View
          sx={{
            bg,
            px: 3,

            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Text
            selectable={false}
            sx={{ mr: 2 }}
            numberOfLines={1}
            lineBreakMode="tail"
          >
            {firstName || 'Select User'}
            {!!lastName && (
              <Text sx={{ display: responsiveLastName }}>{` ${lastName}`}</Text>
            )}
          </Text>
          <Ionicons
            color="text"
            name={adminBarHidden ? 'chevron-down' : 'chevron-up'}
          />
        </View>
      </Press>
    )

    const AnimateHeight = require('@beatgig/design/components/animate-height')
      .default as typeof import('@beatgig/design/components/animate-height').default

    return (
      <View sx={{ zIndex: adminBarHidden ? -1 : 2 }}>
        {toggle}
        <AnimateHeight
          style={{ overflow: 'visible' }}
          initialHeight={101}
          hide={adminBarHidden}
          delay={0}
        >
          <View
            sx={{
              bg,
              zIndex: 2,
            }}
          >
            <View
              sx={{
                p: 3,
                flexDirection: [null, null, 'row'],
                alignItems: [null, null, 'center'],
              }}
            >
              <View sx={{ flex: 1, mr: [0, null, 2], mb: [2, null, 0] }}>
                <Text sx={{ fontWeight: 'bold' }}>
                  Admin-only {userRoleFilter || 'user'} search
                </Text>
                <Spacer height={2} />
                {!adminOverrideUser && (
                  <Text>Search for a user. {unselectedUserText}</Text>
                )}
                {!!adminOverrideUser && (
                  <>
                    <Text>
                      {`You're viewing the screen below as`}{' '}
                      {adminOverrideUser.name},{' '}
                      {adminOverrideUser.metadata.user_type.replaceAll(
                        '_',
                        ' '
                      )}
                      .{' '}
                      <Text onPress={clearUser} sx={{ fontWeight: 'bold' }}>
                        (Clear)
                      </Text>
                    </Text>
                  </>
                )}
              </View>
              <View
                sx={{
                  flex: 1,
                  ml: [null, null, 2],
                  mt: [2, null, 0],
                  zIndex: 1_000,
                }}
              >
                <AdminUserSearch
                  value={adminOverrideSearchQuery}
                  onChangeText={setAdminOverrideSearchQuery}
                  onChangeUser={onChangeUser}
                  onSelectUser={(user) => {
                    onSelectUser?.(user)
                    setAdminOverrideUser(user)
                  }}
                  selectedUser={adminOverrideUser}
                  onClearUser={clearUser}
                  userRole={userRoleFilter}
                />
                {!adminOverrideUser &&
                  !!onPressAlternateSearchMethod &&
                  !!searchByAlternateMethodText && (
                    <Text
                      sx={{ mt: 2, textAlign: 'right' }}
                      onPress={onPressAlternateSearchMethod}
                    >
                      {searchByAlternateMethodText}
                    </Text>
                  )}
              </View>
            </View>
          </View>
        </AnimateHeight>
      </View>
    )
  }

  const withUrql = (node: React.ReactNode) => {
    if (User.isAdmin(me)) {
      return <UrqlProvider key={userId}>{node}</UrqlProvider>
    }

    return <>{node}</>
  }

  return (
    <OnBehalfOfUserContext.Provider
      value={{
        hasParentContainer: true,
        userRoleProp: userRoleFilter,
        adminOverrideUser,
        setAdminOverrideUser,
      }}
    >
      {withUrql(
        <>
          {shouldShowSelectorBar && renderAdminSelectUserBar()}
          {children({ userId, userName, userRole, isUserApproved, userType })}
        </>
      )}
    </OnBehalfOfUserContext.Provider>
  )
}
