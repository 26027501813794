import React from 'react'
import { useRoute, useNavigation } from '@react-navigation/native'
import {
  AddArtistsToRecommendedBookingRoute,
  AddArtistsToRecommendedBookingNavigation,
} from '@beatgig/navigation/stacks/calendar-stack/types'
import AddRecommendedArtistsForm from './form'
import WebModal from '@beatgig/components/web-modal'
import LoadingScreen from '@beatgig/components/loading-screen'
import useAdminAddArtistsToRecommendedBooking from '@beatgig/api-hooks/src/use-admin-add-artists-to-recommended-booking'
import useRecommendedBooking from '@beatgig/api-hooks/src/use-recommended-booking'
import useAcceptRecommendedBooking from '@beatgig/api-hooks/src/use-accept-recommended-booking'
import useNavigateToBooking from '@beatgig/navigation/hooks/use-navigate-to-booking'
import useToast from '@beatgig/design/hooks/use-toast'
import useAreYouSure from '@beatgig/hooks/use-are-you-sure'
import { APP_NAME } from '@beatgig/constants'
import useRejectRecommendedBooking from '@beatgig/api-hooks/src/use-reject-recommended-booking'
import useRemoveArtistRecommendation from '@beatgig/api-hooks/src/use-remove-recommended-artist'
import useSWRReactNavigation from '@beatgig/api-hooks/src/use-native-revalidate'

export default function AddRecommendedArtistsScreen() {
  const {
    params: {
      recommendedBookingId,
      onBehalfOfBuyerUsername,
      onBehalfOfBuyerUserId,
    },
  } = useRoute<AddArtistsToRecommendedBookingRoute>()
  const {
    goBack,
    navigate,
  } = useNavigation<AddArtistsToRecommendedBookingNavigation>()
  // const {
  //   data: initialArtists,
  //   error: initialArtistsError,
  // } = useArtistsFromRecommendedBooking({
  //   recommendedBookingId,
  // })
  const {
    execute,
    loading,
    error: addRecommendedArtistsError,
  } = useAdminAddArtistsToRecommendedBooking()
  const {
    data: recommendedBooking,
    error: recommendedBookingError,
    isValidating,
    mutate,
  } = useRecommendedBooking({
    recommendedBookingId,
  })

  useSWRReactNavigation({
    revalidate: async () => !!mutate(),
  })

  const {
    execute: rejectRecommendedArtist,
    loading: isRejectingRecommendedArtist,
    error: rejectRecommendedArtistError,
  } = useRejectRecommendedBooking()

  const {
    execute: removeArtistRecommendation,
    loading: isRemovingArtistRecommendation,
    error: artistRecommendationError,
  } = useRemoveArtistRecommendation()

  const { toast } = useToast()

  const { navigate: navigateToBooking } = useNavigateToBooking()

  const areYouSureYouWantToAccept = useAreYouSure({
    action: `accept this booking on behalf of ${onBehalfOfBuyerUsername}`,
    dangerousText: "Yes, I'm sure.",
  })
  const areYouSureYouWantToReject = useAreYouSure({
    action: `reject this booking on behalf of ${onBehalfOfBuyerUsername}`,
    dangerousText: "Yes, I'm sure.",
  })

  const onPressUpdateRecommendations = () => {
    navigate('AdminUpdateRecommendedArtists', {
      recommendedBookingId,
    })
  }

  const {
    execute: acceptRecommendedArtist,
    loading: isAcceptingRecommendedArtist,
    error: acceptRecommendedArtistError,
  } = useAcceptRecommendedBooking()

  if (!recommendedBooking) {
    return (
      <WebModal header onRequestClose={goBack}>
        <LoadingScreen
          childrenUnderLoader={!!recommendedBookingError}
          error={recommendedBookingError}
        >
          Loading recommendation...
        </LoadingScreen>
      </WebModal>
    )
  }

  const { artist_recommendations } = recommendedBooking

  return (
    <AddRecommendedArtistsForm
      isValidating={isValidating}
      onPressUpdateRecommendations={onPressUpdateRecommendations}
      onRemoveArtistRecommendation={removeArtistRecommendation}
      isRemovingRecommendation={isRemovingArtistRecommendation}
      onSubmit={async ({ recommendedArtists, notifyBuyer }) => {
        const result = await execute({
          recommendedArtists,
          recommendedBookingId,
          notifyBuyer,
        })
        if (result) {
          goBack()
          toast(
            'Successfully added recommendations. It might take a sec for these changes to reflect in the admin dashboard.'
          )
        }
        return
      }}
      onRejectRecommendedArtists={(rejection) => {
        /**
         * This means we're on the admin app, and don't know whom we're acting on behalf of
         */
        if (!onBehalfOfBuyerUserId) {
          return toast(
            'Admins cannot reject recommended bookings from this screen. If you want to do this, open the calendar screen, enter the user name you want to reject on behalf of, click this event, and cancel.',
            {
              variant: 'error',
              duration: 12000,
            }
          )
        }
        areYouSureYouWantToReject(async () => {
          await rejectRecommendedArtist({
            ...rejection,
            adminOnBehalfOf: onBehalfOfBuyerUserId,
          })
          goBack()
          toast('You rejected this recommended booking.')
        })
      }}
      isRejectingRecommendedArtists={isRejectingRecommendedArtist}
      onAcceptRecommendedArtist={(recommendation) => {
        /**
         * This means we're on the admin app, and don't know whom we're acting on behalf of
         */
        if (!onBehalfOfBuyerUserId) {
          return toast(
            'Admins cannot accept recommended bookings from this screen. If you want to do this, ' +
              APP_NAME +
              ' go to the calendar screen, enter this venue, and accept the booking from there.',
            {
              variant: 'error',
              duration: 12000,
            }
          )
        }
        areYouSureYouWantToAccept(async () => {
          const createdBooking = await acceptRecommendedArtist({
            ...recommendation,
            adminOnBehalfOf: onBehalfOfBuyerUserId,
          })
          if (createdBooking.id && createdBooking.artist.name) {
            goBack()
            toast(
              'Successfully created booking for ' +
                createdBooking.artist.name +
                '.',
              {
                actions: [
                  {
                    label: 'View Booking',
                    onPress: () => navigateToBooking({ id: createdBooking.id }),
                  },
                ],
                duration: 6000,
              }
            )
          }
          // if (createdBooking.id) {
          //   navigateToBooking({ id: createdBooking.id })
          // }
        })
      }}
      networkError={
        acceptRecommendedArtistError ||
        rejectRecommendedArtistError ||
        addRecommendedArtistsError ||
        artistRecommendationError
      }
      animated={false}
      isAcceptingRecommendedArtist={isAcceptingRecommendedArtist}
      recommendedBooking={recommendedBooking}
      onClose={goBack}
    />
  )
}
