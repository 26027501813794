import WebModal from '@beatgig/components/web-modal'
import React from 'react'
import { BookingsActionRequiredList } from '@beatgig/ui/bookings-actions-required-list'
import { Platform } from 'react-native'
import Spacer from '@beatgig/design/components/spacer'
import AdminOnBehalfOfVenueContainer from '@beatgig/auth-components/admin-on-behalf-of-venue-container'
import { UserRole } from '@beatgig/api-services'
import { useOpenModal } from '@beatgig/modals/create-modal-stack/use-open-modal'
import type { WebCalendarModalStackParams } from '@beatgig/ui/booking-request-modal-route/types'
import { useResponsiveValue } from 'dripsy'

export function BookingActionsRequiredScreen() {
  // const { goBack } = useRouting()
  const { closeModal } = useOpenModal<WebCalendarModalStackParams>()

  const goBack = React.useCallback(() => closeModal('BookingActionsRequired'), [
    closeModal,
  ])

  const isFullScreen = [true, false]
  const safeHeaderTopMargin = useResponsiveValue(isFullScreen)

  return (
    <WebModal
      padded={false}
      header={Platform.OS === 'web'}
      onRequestClose={goBack}
      isFullMobileScreen={[true, false]}
      // safeHeaderTopMargin={safeHeaderTopMargin}
      title="Action Required"
    >
      <Spacer height={3} />
      <AdminOnBehalfOfVenueContainer
        userRole={UserRole.BUYER}
        unselectedUserText="Select a user to view their action required list."
      >
        {(user) => {
          if (!user.userId || !user.userRole || !user.userName) {
            return null
          }
          return (
            <BookingsActionRequiredList
              userId={user.userId}
              userName={user.userName}
              userRole={user.userRole}
              onGoBack={goBack}
            />
          )
        }}
      </AdminOnBehalfOfVenueContainer>
    </WebModal>
  )
}
