import React, { useMemo } from 'react'
import { BookingsSmallCalendarListProps } from './types'
import { useBookingsSmallCalendarList } from './use-bookings-small-calendar-list'
import { BookingSmallCalendarListPlaceholder } from './placeholder'
import { BookingsSmallCalendarListItem } from './item'
import { AnimatePresence, MotiView } from 'moti'
import { EmptyBookingsSmallCalendarList } from './empty'

const Placeholder = <BookingSmallCalendarListPlaceholder />

type MotiProps = React.ComponentProps<typeof MotiView>

const listContainerProps: MotiProps = {
  from: { opacity: 0, translateY: 5 },
  animate: { opacity: 1, translateY: 0 },
  exit: { opacity: 0, translateY: -5 },
  transition: { type: 'timing' },
}

const opacityProps: MotiProps = {
  from: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
}

export function BookingsSmallCalendarList(
  props: BookingsSmallCalendarListProps
) {
  const { events, loading, empty } = useBookingsSmallCalendarList(props)

  const List = useMemo(
    () =>
      events?.map((event, i) => (
        <BookingsSmallCalendarListItem
          index={i}
          key={event.id}
          loading={false}
          {...event}
        />
      )),
    [events]
  )

  return (
    <AnimatePresence exitBeforeEnter>
      {loading ? (
        <MotiView {...opacityProps} key="skeleton">
          {Placeholder}
        </MotiView>
      ) : empty ? (
        <MotiView key="empty" {...opacityProps}>
          {props.onPressCreateBookingRequest && props.selectedDate ? (
            <EmptyBookingsSmallCalendarList
              userRole={props.userRole}
              onPressCreateBookingRequest={props.onPressCreateBookingRequest}
              selectedDate={props.selectedDate}
            />
          ) : (
            props.Empty
          )}
        </MotiView>
      ) : (
        <MotiView
          key={'list' + JSON.stringify(props.selectedDate)}
          {...listContainerProps}
        >
          {List}
        </MotiView>
      )}
    </AnimatePresence>
  )
}
