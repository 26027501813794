import React, { useState, useMemo } from 'react'
import { View, Text, H3 as H2, H4 } from 'dripsy'
import WebModal from '@beatgig/components/web-modal'
import { Formik } from 'formik'
import CreateVenueScreen from '../create-venue-screen'
import { AutoCompleteOption } from '@beatgig/design/components/auto-complete/types'
import locationToString from '@beatgig/helpers/location-to-string'
import * as yup from 'yup'
import Spacer from '@beatgig/design/components/spacer'
import SubmitButton from '../../forms/fields/submit'
import AutoCompleteFieldRequired from '../../forms/fields/auto-complete-field-required'
import FormRow from '../../forms/components/row'
import { APP_NAME } from '@beatgig/constants'
import { MotiView } from 'moti'
import { Platform } from 'react-native'
import { MyVenuesQuery } from '@beatgig/gql'

type Submission = {
  venue_id: string
}

type Props = {
  onRequestClose: () => void
  title?: string
  onSelectVenue: (venue: MyVenuesQuery['myVenues'][number]) => void
  // initialSubmission: Submission
  /**
   * Venues **must** be specified. Loading states should be handled outside of this component. If they are null, we will assume that, with certainty, we have 0 venues.
   */
  venues: NonNullable<MyVenuesQuery['myVenues']>
  /**
   * Default: `First, select the venue for your booking.`
   */
  selectVenueDescription?: string
  shouldRedirectToVenueOnCreate?: boolean
  formHeader?: React.ReactNode
}

export default function VenueSelectorForm(props: Props) {
  const {
    onRequestClose,
    onSelectVenue,
    venues,
    selectVenueDescription = `First, select the venue for your booking.`,
    shouldRedirectToVenueOnCreate = false,
    title = 'Select Venue',
    formHeader = null,
  } = props

  // if you already have a venue, but you want to add a new one
  const [isCreatingAdditionalVenue, setIsCreatingAdditionalVenue] =
    useState(false)

  const options = useMemo<AutoCompleteOption[]>(() => {
    return venues
      .map(({ id, name, location }) => {
        const option: AutoCompleteOption = {
          label: name,
          value: id as string,
          subtitle: locationToString(location, {
            postalCode: false,
            stateAbbr: false,
          }),
        }
        return option
      })
      .filter(({ label, value }) => label && value)
  }, [venues])

  if (!venues.length || isCreatingAdditionalVenue) {
    return (
      <CreateVenueScreen
        shouldRedirectToVenueOnCreate={shouldRedirectToVenueOnCreate}
        onVenueDidCreate={(venue) => {
          if (venue) {
            // FIXME gql test this!
            onSelectVenue(venue)
          }
        }}
        animated={false}
        formHeader={
          isCreatingAdditionalVenue ? null : (
            <View sx={{ my: 3 }}>
              <H2 sx={{ mb: 2 }}>Add your venue</H2>
              <Text>
                Before submitting a booking, you need to add your venue to{' '}
                {APP_NAME}. Search for yours on Google Maps below, then press{' '}
                {`"Create"`}.
              </Text>
            </View>
          )
        }
      />
    )
  }

  const renderCurrentlySelectedVenue = ({ id }: { id: string }) => {
    const venue = venues?.find((venue) => venue.id === id)

    if (!venue) {
      return (
        <View>
          <Text>{selectVenueDescription}</Text>
        </View>
      )
    }

    return (
      <View>
        <Text sx={{ fontWeight: 'bold' }}>{venue.name}</Text>
        <Text sx={{ color: 'mutedText' }}>
          {locationToString(venue.location)}
        </Text>
      </View>
    )
  }

  console.log('[venue-selector-form]', { options })

  const onSubmit = ({ venue_id }: { venue_id: string }) => {
    if (venue_id) {
      const venue = venues.find(({ id }) => id === venue_id)
      if (venue) {
        onSelectVenue(venue)
      }
    }
  }

  return (
    <Formik<Submission>
      validationSchema={yup.object().shape({
        venue_id: yup.string().required(' '),
      })}
      onSubmit={async ({ venue_id }) => {
        onSubmit({ venue_id })
      }}
      initialValues={{
        venue_id: '',
      }}
    >
      {({ values, submitForm }) => {
        return (
          <WebModal
            header
            animated={false}
            title={title}
            onRequestClose={onRequestClose}
            button={
              <MotiView
                animate={{
                  opacity: values?.venue_id ? 1 : 0,
                  scale: values?.venue_id
                    ? [{ value: 1.1 }, { value: 1 }]
                    : 0.8,
                }}
                transition={Platform.select({
                  web: { type: 'timing', duration: 200 },
                  default: { scale: { type: 'spring' } },
                })}
              >
                <SubmitButton
                  pointerEvents={values?.venue_id ? 'auto' : 'none'}
                  formName="Venue Selector"
                >
                  Next
                </SubmitButton>
              </MotiView>
            }
          >
            {!!formHeader && formHeader}
            <FormRow>
              <H4 sx={{ mb: 2 }}>Venue</H4>
              <AutoCompleteFieldRequired<Submission>
                name="venue_id"
                // options={venues.map(({ name }) => name)}
                options={options}
                tooltip="Which venue are you booking for?"
                shouldSetControlledStateOnPress={'label'}
                placeholder="Search venue..."
                shouldClearInputOnEmptyBlur={false}
                onPressContinue={() => {
                  onSubmit({ venue_id: values.venue_id })
                }}
                onSelect={(venue_id) => onSubmit({ venue_id })}
                renderValue={(id: string) => {
                  return (
                    <>
                      <Spacer height={2} />
                      {renderCurrentlySelectedVenue({ id })}
                    </>
                  )
                }}
              />
              {/* <TextField<Submission> name="venue_id" /> */}
            </FormRow>
            {!values.venue_id && (
              <Text
                // sx={{ mt: 2 }}
                sx={{ zIndex: -1 }}
                onPress={() => setIsCreatingAdditionalVenue(true)}
              >
                Or, <Text sx={{ color: 'primary' }}>create a new venue.</Text>
              </Text>
            )}
          </WebModal>
        )
      }}
    </Formik>
  )
}
