import React from 'react'
import { View, Text } from 'dripsy'
import { Tab } from './types'
import { TouchableOpacity } from 'react-native'
import useAnimatedHover from '@beatgig/helpers/use-animated-hover'

type Props = {
  tab: Tab
  onPress: () => void
  isSelected: boolean
} & React.ComponentProps<typeof View>

export default function TabItem(props: Props) {
  const { sx = {}, tab, onPress, isSelected } = props

  const { badgeCount } = tab

  const { isHovered, resetStyle, ref } = useAnimatedHover({
    webStyleKeys: ['color'],
  })

  const color = isSelected || isHovered ? 'text' : 'muted5'

  return (
    <TouchableOpacity ref={ref} onPress={onPress}>
      <View sx={{ flexDirection: 'row', alignItems: 'center' }}>
        <Text
          sx={(theme) => ({
            px: 2,
            py: 3,
            cursor: 'pointer',
            textTransform: 'capitalize',
            color,
            fontSize: 3,
            ...resetStyle,
          })}
        >
          {tab.title}
        </Text>
        {isSelected && (
          <View
            sx={{
              height: 2.01,
              left: 0,
              right: 0,
              bottom: 0,
              bg: 'text',
              position: 'absolute',
              animationKeyframes: {
                from: {
                  opacity: 0,
                },
                to: {
                  opacity: 1,
                },
              },
            }}
          />
        )}
        {!!badgeCount && (
          <View
            sx={{
              height: 22,
              width: `${badgeCount}`.length * 6 + 16,
              borderRadius: 'rounded',
              bg: 'error',
              // alignItems: 'center',
              justifyContent: 'center',
              mr: 1,
            }}
          >
            <Text sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 1 }}>
              {`${badgeCount}`.trim()}
            </Text>
          </View>
        )}
      </View>
    </TouchableOpacity>
  )
}
