import Analytics from '@beatgig/analytics/typez'
import { useUpdateExternalBookingMutation } from '@beatgig/gql'
import { useCallback } from 'react'

export default function useEditExternalBooking() {
  const [{ error }, execute] = useUpdateExternalBookingMutation()

  return {
    execute: useCallback<typeof execute>(
      (param, ...rest) => {
        Analytics.track('Edit External Booking', {
          artistName: param?.patch.artistName || 'no artist name',
          externalBookingId: param?.id || 'missing ID',
        })

        return execute(param, ...rest)
      },
      [execute]
    ),
    error,
  }
  // return usePost((params: Parameters<Poster>[0]) => {
  //   return poster(params)
  // }, options)
}
