import React from 'react'
import { DisplayRecommendedBooking } from '@beatgig/api-services'
import WebModal from '@beatgig/components/web-modal'
import RecommendedBookingExpandedPreview from '@beatgig/ui/recommended-booking-expanded-preview'
import useAcceptRecommendedBooking from '@beatgig/api-hooks/src/use-accept-recommended-booking'
import useToast from '@beatgig/design/hooks/use-toast'
import ErrorNote from '@beatgig/components/ErrorNote'
import useRejectRecommendedBooking from '@beatgig/api-hooks/src/use-reject-recommended-booking'
import OpenBookingExternallyButton from '@beatgig/ui/booking/open-booking-externally-button'

type Props = {
  recommendedBooking: DisplayRecommendedBooking
  onClose: () => void
  animated?: boolean
}

/**
 * Buyer-only component. Admins will never see this.
 */
export default function AcceptRecommendedArtistForm(props: Props) {
  const { recommendedBooking, onClose, animated } = props

  const { toast } = useToast()
  const {
    execute: acceptRecommendedArtist,
    loading: isAcceptingRecommendedArtist,
    error: acceptRecommendedArtistError,
  } = useAcceptRecommendedBooking({
    onSuccess: (newBooking) => {
      if (newBooking.id) {
        onClose()
        toast('Successfully created booking.')
      }
    },
  })

  const {
    execute: rejectRecommendedArtist,
    loading: isRejectingRecommendedArtist,
    error: rejectRecommendedArtistError,
  } = useRejectRecommendedBooking({
    onSuccess: () => {
      onClose()
      toast('You rejected this recommended booking.')
    },
  })

  return (
    <WebModal
      animated={animated}
      onRequestClose={onClose}
      title={`Review ${
        (recommendedBooking.artist_recommendations?.length ?? 0) > 1
          ? 'Artists'
          : 'Artist'
      }`}
      header
      button={
        !!recommendedBooking.booking_id && (
          <OpenBookingExternallyButton
            bookingId={recommendedBooking.booking_id}
          />
        )
      }
    >
      <ErrorNote sx={{ mb: 3 }} error={rejectRecommendedArtistError} />
      <RecommendedBookingExpandedPreview
        recommendedBooking={recommendedBooking}
        onAcceptRecommendedArtist={acceptRecommendedArtist}
        isAcceptingRecommendedArtist={isAcceptingRecommendedArtist}
        onRejectRecommendedArtists={rejectRecommendedArtist}
        isRejectingRecommendedArtists={isRejectingRecommendedArtist}
        disableRecommendationActions={false}
        acceptRecommendedArtistError={acceptRecommendedArtistError}
      />
    </WebModal>
  )
}
