import HoverTooltip from '@beatgig/components/hover-tooltip'
import React from 'react'
import FilterPopover from '../filter-popover'
import EnumFilter from './enum-filter'

type Props = {
  // label: React.ReactNode | string
  tooltipProps?: Omit<
    React.ComponentProps<typeof HoverTooltip>,
    'children' | 'text'
  >
  hideCount?: boolean
  enumProps: React.ComponentProps<typeof EnumFilter>
  mode?: React.ComponentProps<typeof FilterPopover>['mode']
}

export default function EnumFilterPopover({ enumProps, ...props }: Props) {
  const firstSelectedItem = enumProps.selected[0]
  const firstSelectedVariant =
    enumProps.selectedCount === 1 && enumProps.state[firstSelectedItem].variant

  return (
    <FilterPopover
      {...props}
      onSelectAll={enumProps.handlers.selectAll}
      onClearAll={enumProps.handlers.clearAll}
      selectedCount={enumProps.selectedCount}
      totalCount={enumProps.totalCount}
      hideCount={enumProps.selectedCount === 1 || enumProps.totalCount < 3}
      color={firstSelectedVariant || undefined}
      label={
        enumProps.selectedCount === 1 ? firstSelectedItem : enumProps.title
      }
      filter={<EnumFilter {...enumProps} />}
    />
  )
}

// import HoverTooltip from '@beatgig/components/hover-tooltip'
// import React from 'react'
// import EnumFilter from './enum-filter'
// import { Text, View } from 'dripsy'
// import Ionicons from '@beatgig/design/components/ionicons'

// type Props = React.ComponentProps<typeof EnumFilter> & {
//   label: React.ReactNode | string
//   tooltipProps?: Omit<
//     React.ComponentProps<typeof HoverTooltip>,
//     'children' | 'text'
//   >
//   hideCount?: boolean
// }

// export default function EnumFilterPopover({
//   label,
//   tooltipProps,
//   hideCount,
//   selectedCount,
//   values,
//   ...props
// }: Props) {
//   return (
//     <HoverTooltip
//       {...tooltipProps}
//       text={
//         <EnumFilter values={values} selectedCount={selectedCount} {...props} />
//       }
//     >
//       <View
//         sx={{
//           flexDirection: 'row',
//           alignItems: 'center',
//         }}
//       >
//         <Text sx={{ mr: 2, borderBottomWidth: 1, borderColor: 'text' }}>
//           {label}{' '}
//           {!hideCount &&
//             `(${
//               selectedCount === values.length ? 'Any' : selectedCount || 'Any'
//             })`}
//         </Text>
//         <Ionicons name="chevron-down" />
//       </View>
//     </HoverTooltip>
//   )
// }
