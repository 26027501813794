import { Variants } from '@beatgig/design/components/types'
import useStable from '@beatgig/design/hooks/use-stable'
import { usePersistState } from '@beatgig/hooks/use-persist-state'
import { DripsyTheme } from '@beatgig/theme'
import { useState, useMemo } from 'react'
import useFormatAlgoliaFilters from '../../hooks/use-format-algolia-filters'

type Props<Key extends string, Value extends string> = {
  defaults?: { [key in Value]?: boolean } & {
    fallback?: boolean
  }
  variants?: { [key in Key]?: keyof DripsyTheme['colors'] }
  title: string
  persistKey: string | null
}

type State<Value extends string> = {
  [value in Value]: {
    selected: boolean
    variant?: Variants
  }
}

export default function useEnumFilter<
  Key extends string,
  Value extends string,
  Enum extends { [key in Key]: Value }
>(
  enumerable: Enum,
  filterPath: string,
  { defaults, variants, title, persistKey }: Props<Key, Value>
) {
  const values = Object.values(enumerable) as Value[]

  const [state, setState] = useState(() =>
    Object.entries(enumerable).reduce(
      (acc, [key, value]: [Key, Value]) => ({
        ...acc,
        [value]: {
          selected: defaults?.[value] ?? defaults?.fallback ?? true,
          variant: variants?.[key],
        },
      }),
      {} as State<Value>
    )
  )

  const [enumState, setEnumState] = usePersistState<State<Value>>(persistKey, {
    state,
    setState,
  })

  const filters: Array<string> = []

  Object.keys(enumState).forEach((value: Value) => {
    const { selected } = enumState[value]
    if (selected) {
      filters.push(value)
    }
  })

  const graphqlFilter: Array<string> = filters

  const { facetFilters } = useFormatAlgoliaFilters({
    facetFilters: {
      [filterPath]: {
        condition: 'OR',
        filters,
      },
    },
  })

  const stateRef = useStable(state)

  const handlers = useMemo(() => {
    const toggleItem = (value: Value) => {
      setEnumState({
        ...stateRef.current,
        [value]: {
          ...stateRef.current[value],
          selected: !stateRef.current[value].selected,
        },
      })
    }

    const setSingleItemOnly = (value: Value) => {
      const next = {
        ...stateRef.current,
        [value]: {
          ...stateRef.current[value],
          selected: true,
        },
      }

      for (const key in next) {
        if (key !== value) {
          next[key].selected = false
        }
      }

      setEnumState(next)
    }

    const selectAll = () => {
      const next = { ...stateRef.current }
      for (const key in next) {
        next[key].selected = true
      }

      setEnumState(next)
    }

    const clearAll = () => {
      const next = { ...stateRef.current }
      for (const key in next) {
        next[key].selected = false
      }
    }

    return {
      toggleItem,
      makeToggleItem: (value: Value) => () => toggleItem(value),
      setSingleItemOnly,
      selectAll,
      clearAll,
    }
  }, [setEnumState, stateRef])

  const selected = values.filter((value) => enumState[value]?.selected)

  return {
    values,
    state: enumState,
    handlers,
    facetFilters,
    selectedCount: selected.length,
    selected,
    filterPath,
    title,
    totalCount: values.length,
    graphqlFilter,
  }
}
