import React from 'react'
import { View, ScrollView } from 'dripsy'
import useLayout from '@beatgig/hooks/use-layout'
import useResponsiveValue from '@beatgig/hooks/use-responsive-value'
import useResponsive from '@beatgig/hooks/use-responsive'
import Sizer from '@beatgig/components/sizer'
import Spacer from '@beatgig/design/components/spacer'
import { Platform } from 'react-native'
import useTheme from '@beatgig/theme/use-theme'

type Props = {
  children: React.ReactNode
}

type Context = Pick<ReturnType<typeof useLayout>, 'onLayout'> & {
  calendarHeight: number
}

const Context = React.createContext<Context>({
  onLayout: () => {
    // no-op
  },
  calendarHeight: 0,
})

export default function BookingsCalendarLayout({ children }: Props) {
  const { vw } = useResponsive()
  const { height, onLayout } = useLayout()

  const horizontal = useResponsiveValue([false, false, true])

  return (
    <Context.Provider
      value={React.useMemo(
        () => ({ calendarHeight: height, onLayout }),
        [height, onLayout]
      )}
    >
      <View sx={{ flex: 1, width: vw(100), maxWidth: '100%' }}>
        <ScrollView
          // crucial for the stretching on web.
          contentContainerStyle={{
            flex: 1,
            // flexDirection: horizontal ? 'row' : 'column-reverse',
          }}
          horizontal={horizontal}
        >
          {children}
        </ScrollView>
      </View>
    </Context.Provider>
  )
}

type CalendarProps = {
  children: (p: {
    responsiveCalendarHeight: number | string
  }) => React.ReactNode
  // overlay?: React.ReactNode
  hoverStyle?: React.ReactNode
}

// const defaultHoverStyle = (colors: ReturnType<typeof useTheme>['colors']) =>
//   Platform.OS === 'web' && (
//     <style jsx>{`
//       .fc-day-future,
//       .fc-day-today {
//         cursor: pointer;
//         transition: background-color 0.25s ease;
//         will-change: background-color;
//       }
//       .fc-day-today:hover,
//       .fc-day-future:hover {
//         background-color: ${colors.muted3}!important;
//       }
//     `}</style>
//   )

function HoverStyle() {
  const { colors } = useTheme()
  if (Platform.OS !== 'web') {
    return <></>
  }

  return (
    <style jsx>{`
      .fc-day-future,
      .fc-day-today {
        cursor: pointer;
        transition: background-color 0.25s ease;
        will-change: background-color;
      }
      .fc-day-today:hover,
      .fc-day-future:hover {
        background-color: ${colors.muted3}!important;
      }
    `}</style>
  )
}

BookingsCalendarLayout.Calendar = function BookingsCalendarLayoutCalendar(
  props: CalendarProps
) {
  const { children } = props
  const { vh } = useResponsive()
  const { onLayout, calendarHeight } = React.useContext(Context)
  const responsiveCalendarHeight = useResponsiveValue([
    vh(50),
    vh(50),
    calendarHeight,
  ])
  return (
    <>
      <View
        onLayout={onLayout}
        sx={{ flex: [null, null, 1], bg: 'background' }}
      >
        <Sizer minHeight={[vh(50), null, 'auto']} flex={1}>
          <View sx={{ px: [2, 3, 4], flex: 1 }}>
            <Spacer height={3} />
            {children({ responsiveCalendarHeight })}
            <HoverStyle />
          </View>
          {/* {overlay} */}
        </Sizer>
      </View>
    </>
  )
}

type SidebarProps = {
  children: React.ReactNode
}

BookingsCalendarLayout.Sidebar = function Sidebar({ children }: SidebarProps) {
  const collapsedWidth = 100
  const expandedWidth = 350

  return (
    <Sizer
      // flexGrow={[1, 1, 0]}
      borderLeftWidth={[0, 0, 1]}
      borderColor="border"
      width={[null, 250, 300, 350]}
    >
      {children}
    </Sizer>
  )
}
