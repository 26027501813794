import React from 'react'
import { View, Text } from 'dripsy'
import type { Sx as SxStyleProp } from 'dripsy'
import { AvatarProps } from './avatar'

interface Props {
  count?: number
  sx?: SxStyleProp
  limit?: number
  text?: string
}

const defaultProps = {
  sx: {},
}

type NativeAttrs = Omit<React.ComponentProps<typeof View>, keyof Props>
export type AvatarGroupProps = Props & NativeAttrs

const AvatarGroup: React.FC<React.PropsWithChildren<AvatarGroupProps>> = ({
  count,
  sx,
  children,
  limit,
  text,
}) => {
  let countText = count
  if (!countText && limit) {
    if (React.Children.count(children) > limit) {
      countText = limit - React.Children.count(children)
    }
  }
  return (
    <View
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        height: 'auto',
        alignSelf: 'flex-start',
        ...sx,
      }}
    >
      {/* {children} */}
      {React.Children.toArray(children).map(
        (child: React.ReactElement<AvatarProps>, index) =>
          React.cloneElement(child, {
            stacked: index > 0,
            borderWidth: true,
          })
      )}
      {!!countText && (
        <Text
          sx={{
            fontSize: 1,
            paddingLeft: 2,
            color: 'mutedText',
            alignItems: 'center',
            // textAlign: 'center'
          }}
        >
          +{countText}
        </Text>
      )}
      {!!text && (
        <Text
          sx={{
            fontSize: 1,
            paddingLeft: 2,
            color: 'mutedText',
            alignItems: 'center',
            // textAlign: 'center'
          }}
        >
          {text}
        </Text>
      )}
    </View>
  )
}

AvatarGroup.defaultProps = defaultProps

export default AvatarGroup
