import { RecommendedBookingApi } from '@beatgig/api/recommended-booking'
import useGet from './use-get'
import { UseGetConfig } from './use-get.types'

type Getter = typeof RecommendedBookingApi['getRecommendedBooking']
type KeyProps = typeof RecommendedBookingApi['getRecommendedBookingSWRKey']

export default function useRecommendedBooking(
  keyProps: Parameters<KeyProps>[0],
  config?: UseGetConfig<Getter>
) {
  return useGet(
    () => {
      if (!keyProps.recommendedBookingId) return null

      const key = RecommendedBookingApi.getRecommendedBookingSWRKey(keyProps)
      // get over TS readonly error
      return [...key]
    },
    (
      ...[recommendedBookingId]: ReturnType<
        typeof RecommendedBookingApi['getRecommendedBookingSWRKey']
      >
    ) => {
      return RecommendedBookingApi.getRecommendedBooking({
        recommendedBookingId,
      })
    },
    config
  )
}
