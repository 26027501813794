import { useRouting } from 'expo-next-react-navigation'
import { useRef } from 'react'
import { DateTime } from 'luxon'
import { SelectedDate, UseSelectedDate } from './types'
import { RouteProp } from '@react-navigation/native'
import { useMemo } from 'react'
import Router from 'next/router'
import { validateParamsDate } from './types'

type Route = RouteProp<
  {
    calendar: SelectedDate
  },
  'calendar'
>

const setSelectedDate = (date: SelectedDate) => {
  const { query = {} } = Router

  Router.push(
    {
      pathname: Router.pathname,
      query: {
        ...query,
        ...date,
      },
    },
    undefined,
    {
      shallow: true,
    }
  )
}

export function useSelectedDate(): UseSelectedDate {
  const now = useRef<SelectedDate>()
  const getNow = () => {
    if (!now.current) {
      const { year, month, day } = DateTime.fromObject({
        hour: 1,
      })
      now.current = {
        year,
        month,
        day,
      }
    }
    return now.current
  }
  const { getParam } = useRouting<Route>()

  const monthParam: string = getParam('month', getNow().month)
  const yearParam: string = getParam('year', getNow().year)
  const dayParam: string = getParam('day', getNow().day)

  const { year, day, month } = validateParamsDate(
    { year: yearParam, month: monthParam, day: dayParam },
    getNow()
  )

  const selectedDate = useMemo(
    () => ({
      year,
      month,
      day,
    }),
    [day, month, year]
  )

  return {
    selectedDate,
    setSelectedDate,
  }
}
