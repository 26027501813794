import { RecommendedBookingAdminApi } from '@beatgig/api/recommended-booking-admin'
import usePost, { UsePostOptions } from './use-post'

export default function useAdminAddArtistsToRecommendedBooking(
  options?: UsePostOptions<
    typeof RecommendedBookingAdminApi.addArtistRecommendations
  >
) {
  return usePost(RecommendedBookingAdminApi.addArtistRecommendations, options)
}
