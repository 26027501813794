import React, {
  useMemo,
  useState,
  useCallback,
  useRef,
  useImperativeHandle,
} from 'react'
import {
  Calendar,
  CalendarProps,
  CalendarList,
  MultiDotMarking,
} from 'react-native-calendars'
import { View, Text } from 'dripsy'
import useTheme from '@beatgig/theme/use-theme'
import { useFont } from '@beatgig/theme/use-font'
import Ionicons from '@beatgig/design/components/ionicons'
import { StyleSheet, Platform } from 'react-native'
import { SmallCalendarProps } from './types'
import format from 'date-fns/format'
import isSameMonth from 'date-fns/isSameMonth'
import Press from '@beatgig/components/press'
import XDate from 'xdate'
import isPast from 'date-fns/isPast'
import { DateTime } from 'luxon'

export type SmallCalendarMarkings = Record<string, MultiDotMarking>

const isCalendarList = Platform.select({
  web: false,
  default: true,
})

const ifCalendarList = <T, G>({
  list,
  notList,
}: {
  list: T
  notList: G
}): T | G => (isCalendarList ? list : notList)

// const StyledCalendar = styled(
//   (ifCalendarList({
//     list: CalendarList,
//     notList: Calendar,
//   }) as unknown) as React.FunctionComponent<SmallCalendarProps>
// )({})
const StyledCalendar = ifCalendarList({
  list: CalendarList as typeof Calendar,
  notList: Calendar,
})

const renderArrow: CalendarProps['renderArrow'] = (side) => {
  if (isCalendarList) return null
  const style = [StyleSheet.absoluteFillObject, { bottom: 'auto', top: -1 }]
  return (
    <View style={style}>
      <Ionicons name={side === 'left' ? 'chevron-back' : 'chevron-forward'} />
    </View>
  )
}

type CalendarRef = {
  scrollToDay?: (date: Date, offset?: number, animated?: true) => void
  updateMonth?: (date: XDate, ignoreTriggers?: boolean) => void
}

// type CalendarProps = ComponentProps<typeof Calendar>

export const SmallCalendar = React.memo(function SmallCalendar(
  props: SmallCalendarProps
) {
  const [date, setDate] = useState(() => new Date())
  const { colors, fontSizes, borderWidths, space } = useTheme()
  const { padded = true } = props
  const { body: font } = useFont()
  const [width, setWidth] = useState(0)

  const localRef = useRef<CalendarRef>(null)

  useImperativeHandle(props.calendarRef, () => ({
    updateMonth: ({ year, month, day }, ignore) => {
      if (localRef.current?.updateMonth) {
        localRef.current.updateMonth(new XDate(year, month - 1, day), ignore)
      }
    },
  }))

  const theme = useMemo<CalendarProps['theme']>(
    () => ({
      monthTextColor: colors.text,
      textDayFontFamily: font.default,
      textMonthFontFamily: font.bold,
      textDayHeaderFontFamily: font.bold,
      arrowColor: colors.background,
      selectedDayBackgroundColor: colors.primary,
      selectedDayTextColor: colors.background,
      calendarBackground: colors.background,
      dayTextColor: colors.text,
      todayTextColor: colors.primary,
      textDisabledColor: colors.mutedText,
      selectedDotColor: colors.background,
      textDayFontSize: fontSizes[3],
      indicatorColor: colors.primary,

      'stylesheet.calendar-list.main': {
        calendar: {
          paddingRight: 0,
          paddingLeft: 0,
        },
        staticHeader: {
          // copied from the src code, removed padding
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          backgroundColor: colors.muted,
        },
      },
      'stylesheet.calendar.main': {
        week: {
          // marginBottom: space[2],
          flexDirection: 'row',
          justifyContent: 'space-around',
          paddingTop: space[2],
          paddingBottom: space[2],
          ...ifCalendarList({
            list: {},
            notList: {
              borderTopWidth: borderWidths[1],
              borderColor: colors.border,
            },
          }),
        },
      },
    }),
    [colors, font, fontSizes, borderWidths, space]
  )

  const hasWidth = !!width

  const show = ifCalendarList({
    notList: true,
    list: hasWidth,
  })
  const sx = useMemo(
    () =>
      // TODO is this causing a flicker? probably. use opacity: 0 instead?
      ({
        // bg: 'muted',
        borderWidth: padded ? 1 : 0,
        borderColor: 'muted3',
        borderRadius: padded ? 3 : 0,
        overflow: 'hidden',
        p: 0,
        px: 0,
        opacity: show ? 1 : 0,
      }),
    [padded, show]
  )

  const headerStyle = useMemo(
    () => ({
      backgroundColor: colors.muted,
    }),
    [colors]
  )

  const renderHeader = useCallback<NonNullable<CalendarProps['renderHeader']>>(
    (xdate) => {
      // if we don't get the date this way, the header fucks up
      const month = xdate.getMonth() + 1
      const year = xdate.getFullYear()
      const day = xdate.getDate()
      const date = DateTime.fromObject({
        month,
        year,
        day,
      }).toJSDate()
      const sameMonth = isSameMonth(date, new Date())
      const onPress = () => {
        if (!sameMonth) {
          if (localRef.current?.scrollToDay) {
            // TODO this doesn't work for far scrolled events on native
            localRef.current?.scrollToDay(new Date(), undefined, true)
          } else if (localRef.current?.updateMonth) {
            localRef.current.updateMonth(new XDate())
          }
        }
      }
      const previousMonth = !sameMonth && isPast(date)
      const futureMonth = !sameMonth && !previousMonth

      let subtitle = 'This Month'
      if (previousMonth) {
        subtitle = 'Jump to Today'
      } else if (futureMonth) {
        subtitle = 'Back to Today'
      }

      return (
        <View sx={{ my: 1 }}>
          <Press onPress={onPress}>
            <Text sx={{ fontWeight: 'bold', textAlign: 'center' }}>
              {format(date, 'LLLL u')}
            </Text>
            <Text
              sx={{
                textAlign: 'center',
                fontSize: 1,
                mt: 1,
                color: !sameMonth ? 'primary' : 'mutedText',
              }}
            >
              {subtitle}
            </Text>
          </Press>
        </View>
      )
    },
    []
  )

  return (
    <View sx={sx}>
      <View
        sx={{ mb: ifCalendarList({ notList: undefined, list: 3 }) }}
        onLayout={({ nativeEvent }) => {
          setWidth(nativeEvent.layout.width)
        }}
      >
        {show && (
          <StyledCalendar
            // @ts-expect-error ref not typed
            ref={localRef}
            renderHeader={renderHeader}
            current={date}
            headerStyle={headerStyle}
            style={styles.calendar}
            hideExtraDays
            pagingEnabled
            enableSwipeMonths={ifCalendarList({
              notList: true,
              list: undefined,
            })}
            renderArrow={renderArrow}
            theme={theme}
            calendarWidth={ifCalendarList({
              notList: undefined,
              list: width,
            })}
            key={ifCalendarList({
              list: width,
              notList: undefined,
            })}
            horizontal
            hideArrows={ifCalendarList({
              list: true,
              notList: false,
            })}
            // staticHeader={ifCalendarList({
            //   notList: undefined,
            //   list: true,
            // })}
            {...props}
            displayLoadingIndicator={false}
          />
        )}
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  calendar: {
    paddingRight: 0,
    paddingLeft: 0,
  },
  header: {},
  loader: {},
})
