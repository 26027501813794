import React, { useMemo, useRef, useState, useCallback } from 'react'
import { UserRole } from '@beatgig/api-services/user'
import { BookingRequestDisplay } from '@beatgig/api-services/booking-request'
import { View, styled } from 'dripsy'
import { Platform, RefreshControl } from 'react-native'
import { ContainerQueryProvider } from './container-query'
import { SellerBookingRequestsListItem } from './item'
import { getDisplayBookingStartTime } from '@beatgig/helpers/display-booking-start-time'
import Gradient from '@beatgig/design/components/gradient'
import Button from '@beatgig/components/button'

import { DateTime } from 'luxon'
import { ScrollView as AnchorScrollView, useAnchors } from '@nandorojo/anchor'
import { EmptyBookingRequestListSeller } from './empty'
import Tabs from '@beatgig/design/components/tabs'
import TabItem from '@beatgig/design/components/tabs/item'
import useTheme from '@beatgig/theme/use-theme'
import useScrollToTop from '@beatgig/hooks/use-scroll-to-top'
import {
  BookingRequestOrderBy,
  Dir,
  useSellerBookingRequestsListQuery,
} from '@beatgig/gql'
import { useAdminOnBehalfOf } from '@beatgig/gql/admin-on-behalf-of'
import { Box } from '@beatgig/design/components/box'
import Loading from '@beatgig/design/components/loading'

const ScrollView = styled(AnchorScrollView)()

function BookingRequestsSellerList({
  bookingRequests,
  highlightedBookingRequestId,
  onFetchMore,
  isFetchingMore,
  canFetchMore,
  isPullingToRefresh,
  onPullToRefresh,
}: {
  bookingRequests: BookingRequestDisplay[] | null
  highlightedBookingRequestId: string | null
  onFetchMore: () => void
  isFetchingMore: boolean
  canFetchMore: boolean
  isPullingToRefresh: boolean
  onPullToRefresh: () => void
}) {
  const Loading =
    !bookingRequests &&
    new Array(3)
      .fill('')
      .map((_, i) => (
        <SellerBookingRequestsListItem key={`loading-${i}`} loading />
      ))

  const List =
    !!bookingRequests &&
    bookingRequests?.map((bookingRequest) => {
      const { timeWithCustomFormat } =
        getDisplayBookingStartTime(bookingRequest)
      const startTime = timeWithCustomFormat('MMM d @ hh:mm aa')
      if (!startTime) {
        return null
      }
      const { isActionRequired } = bookingRequest
      return (
        <SellerBookingRequestsListItem
          bookingRequest={bookingRequest}
          isHighlighted={bookingRequest.id === highlightedBookingRequestId}
          id={bookingRequest.id}
          key={bookingRequest.id}
          loading={false}
          isActionRequired={isActionRequired}
          venueName={bookingRequest.venue.name}
          startTime={startTime}
        />
      )
    })

  const empty = bookingRequests?.length === 0 && (
    <EmptyBookingRequestListSeller />
  )

  const anchors = useAnchors()

  const { colors } = useTheme()

  const scrollToTopRef = useScrollToTop()

  return (
    <ContainerQueryProvider>
      <View sx={{ flex: 1 }}>
        <ScrollView
          ref={scrollToTopRef}
          anchors={anchors}
          // sx={{ pt: 3 }}
          showsVerticalScrollIndicator={false}
          refreshControl={Platform.select({
            native: (
              <RefreshControl
                onRefresh={onPullToRefresh}
                refreshing={isPullingToRefresh}
                colors={[colors.background]}
                tintColor={colors.text}
                title="Refresh Bid Requests"
                titleColor={colors.text}
              />
            ),
          })}
        >
          {empty}

          {/* <ContainerQueryWhenWidth> */}

          <View sx={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            {Loading}
            {List}
          </View>
          {!!(canFetchMore || isFetchingMore) && (
            <Button
              touchableProps={{ sx: { my: 4 } }}
              sx={{ alignSelf: 'center' }}
              onPress={onFetchMore}
              loading={isFetchingMore}
              color="text"
            >
              Load More
            </Button>
          )}
          {/* </ContainerQueryWhenWidth> */}
          <View sx={{ height: [75, 150] }} />
        </ScrollView>
        <Gradient
          colors={['transparent', 'muted']}
          pointerEvents="none"
          stretch
          sx={{ top: 'auto', height: [75, 150] }}
        />
      </View>
    </ContainerQueryProvider>
  )
}

const TimeFilters = {
  upcoming: 'Upcoming',
  past: 'Past',
} as const

function useListFilter() {
  const earlierToday = useRef(
    DateTime.fromObject({}).plus({ hours: -1 }).toISO()
  ).current
  const laterToday = useRef(DateTime.fromObject({}).toISO()).current

  const [{ page, upcomingOrPast }, setFilter] = useState<{
    upcomingOrPast: keyof typeof TimeFilters
    page: number
  }>({
    page: 1,
    upcomingOrPast: 'upcoming',
  })

  const isUpcoming = upcomingOrPast === 'upcoming'

  const startTimeMax = isUpcoming ? undefined : earlierToday

  const startTimeMin = isUpcoming ? laterToday : undefined

  let direction: Dir

  if (isUpcoming) {
    direction = Dir.ASC
  } else {
    direction = Dir.DESC
  }

  return {
    upcomingOrPast,
    setUpcomingOrPast: useCallback((next: typeof upcomingOrPast) => {
      setFilter({
        page: 1,
        upcomingOrPast: next,
      })
    }, []),
    startTimeMax,
    startTimeMin,
    page,
    incrementPage: useCallback(() => {
      setFilter((state) => ({ ...state, page: state.page + 1 }))
    }, []),
    direction,
  }
}

function useListSmart({ userId }: { userRole: UserRole; userId: string }) {
  const {
    startTimeMax,
    startTimeMin,
    direction,
    page,
    incrementPage,
    ...filters
  } = useListFilter()

  const limit = 10

  const [{ data, stale, fetching, error }, revalidate] =
    useSellerBookingRequestsListQuery(
      useAdminOnBehalfOf(userId, {
        variables: {
          limit,
          offset: limit * (page - 1),
          orderBy: BookingRequestOrderBy.START_TIME,
          startTimeMax,
          startTimeMin,
          direction,
        },
      })
    )

  const canFetchMore = data?.myBookingRequests.length === limit * page
  const isFetchingMore =
    Boolean(data) &&
    page > 1 &&
    data?.myBookingRequests.length === (page - 1) * limit &&
    stale

  const fetchMore = useCallback(
    () => canFetchMore && incrementPage(),
    [canFetchMore, incrementPage]
  )

  const onPullToRefresh = revalidate
  const isPullingToRefresh = stale // FIXME gql test this! does this work on native?

  return {
    bookingRequests: data?.myBookingRequests,
    fetchMore,
    isFetchingMore,
    canFetchMore,
    isPullingToRefresh,
    onPullToRefresh,
    stale,
    fetching,
    ...filters,
  }
}

export function BookingRequestsSellerListSmart({
  highlightedBookingRequestId,
  userId,
  userRole,
}: {
  highlightedBookingRequestId: string | null
  userId: string
  userRole: UserRole
}) {
  const {
    bookingRequests,
    stale,
    fetching,
    upcomingOrPast,
    setUpcomingOrPast,
    fetchMore,
    isFetchingMore,
    canFetchMore,
    isPullingToRefresh,
    onPullToRefresh,
  } = useListSmart({
    userId,
    userRole,
  })

  // FIXME gql move this to graphql instead of algolia!
  const badgeCount = 0
  // const { badgeCount } = useBookingsRequestsActionsRequired(``, {
  //   userId,
  //   userRole,
  // })

  return (
    <>
      <Box>
        <Tabs>
          {useMemo(
            () =>
              Object.keys(TimeFilters).map(
                (value: keyof typeof TimeFilters) => {
                  const title = TimeFilters[value]

                  return (
                    <TabItem
                      key={value}
                      isSelected={value === upcomingOrPast}
                      onPress={() => setUpcomingOrPast(value)}
                      tab={{
                        value,
                        title,
                        badgeCount: value === 'upcoming' ? badgeCount || 0 : 0,
                      }}
                    />
                  )
                }
              ),
            [badgeCount, setUpcomingOrPast, upcomingOrPast]
          )}
        </Tabs>
        {(stale || fetching) && (
          <Box
            position="absolute"
            top={0}
            right={0}
            bottom={0}
            pointerEvents="none"
            p="$2"
            justifyContent="center"
          >
            <Loading />
          </Box>
        )}
      </Box>
      <BookingRequestsSellerList
        onFetchMore={fetchMore}
        canFetchMore={canFetchMore}
        isFetchingMore={isFetchingMore}
        highlightedBookingRequestId={highlightedBookingRequestId}
        bookingRequests={bookingRequests || null}
        isPullingToRefresh={isPullingToRefresh}
        onPullToRefresh={onPullToRefresh}
      />
    </>
  )
}
