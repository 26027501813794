// @ts-nocheck
// deprecated
import React from 'react'
import { Formik } from 'formik'
import { DisplayRecommendedBooking, ServerError } from '@beatgig/api-services'
import { View, H4, Text } from 'dripsy'
import useRequestCloseForm from '@beatgig/forms/hooks/use-request-close-form'

import WebModal from '@beatgig/components/web-modal'
import Button from '@beatgig/components/button'

import ErrorText from '@beatgig/forms/fields/error'
import type { ArtistHit } from '@beatgig/search/types/hits'
import ArtistSearchField from './artist-search-field'
import AddArtistsArray from './add-artists-array'
import RecommendedBookingExpandedPreview, {
  RecommendedBookingActions,
} from '@beatgig/ui/recommended-booking-expanded-preview'
import FormRow from '@beatgig/forms/components/row'
import Spacer from '@beatgig/design/components/spacer'
import * as yup from 'yup'
import Grid from '@beatgig/design/components/grid'
import type { RecommendedBookingAdminApi } from '@beatgig/api/recommended-booking-admin'
import Card from '@beatgig/design/components/card'
import Loading from '@beatgig/design/components/loading'
import SubmitButton from '@beatgig/forms/fields/submit'
import Label from '@beatgig/forms/fields/field-label'
import Subtitle from '@beatgig/forms/components/subtitle'
import { ArtistRecommendationUpdate } from '@beatgig/api-services/recommended-booking'

type FormState = {
  artistRecommendations: (Omit<ArtistHit, 'price'> &
    ArtistRecommendationUpdate)[]
  notifyBuyer: boolean
}

type Props = {
  onClose: () => void
  onSubmit: (
    form: Omit<
      Parameters<
        typeof RecommendedBookingAdminApi['addArtistRecommendations']
      >[0],
      'recommendedBookingId'
    >
  ) => Promise<void>
  recommendedBooking: DisplayRecommendedBooking
  animated?: boolean
  networkError?: ServerError | null
  onRemoveArtistRecommendation?: typeof RecommendedBookingAdminApi['removeArtistRecommendation']
  isRemovingRecommendation: boolean
  onPressUpdateRecommendations: () => void
  isValidating: boolean
} & RecommendedBookingActions

/**
 * This is an admin screen.
 */
export default function AddRecommendedArtistsForm({
  onSubmit,
  onClose,
  recommendedBooking,
  animated,
  networkError,
  onAcceptRecommendedArtist,
  isAcceptingRecommendedArtist,
  onRejectRecommendedArtists,
  isRejectingRecommendedArtists,
  onPressUpdateRecommendations,
  isValidating,
}: Props) {
  const { requestClose } = useRequestCloseForm()
  // const [newArtistRecommendations, setNewArtistRecommendations] = useState([])

  // const dirty = !!newArtistRecommendations.length

  // const handleSubmit = () => {
  //   onSubmit()
  // }

  // return (
  //   <WebModal
  //     onRequestClose={requestClose(dirty, onClose)}
  //     header
  //     button={() => <Button>Save</Button>}
  //   >

  //   </WebModal>
  // )

  return (
    <Formik<FormState>
      initialValues={{
        artistRecommendations: [],
        notifyBuyer: undefined as any,
      }}
      validationSchema={yup.object().shape({
        notifyBuyer: yup.boolean().required('Do you want to notify users?'),
      })}
      onSubmit={async ({ artistRecommendations, notifyBuyer }) => {
        const recommendedArtists = artistRecommendations
          .filter(({ id }) => id)
          .map(({ id, price, details }) => {
            return {
              artist_id: id,
              price,
              details,
            }
          })

        return onSubmit({
          recommendedArtists,
          notifyBuyer,
        })
      }}
    >
      {({ dirty, values, setFieldValue, submitCount, errors }) => {
        return (
          <WebModal
            animated={animated}
            onRequestClose={requestClose(dirty, onClose)}
            header
            button={() =>
              (!!values.artistRecommendations.length && (
                <SubmitButton formName="Add Recommended Artists">
                  Save
                </SubmitButton>
              )) ||
              (isValidating && <Loading />)
            }
            title={recommendedBooking.venue_name}
          >
            {!!recommendedBooking.artist_recommendations?.length && (
              <Card sx={{ mb: 3 }}>
                <Card.Content>
                  <Text>Want to update these recommendations?</Text>
                </Card.Content>
                <Card.Footer>
                  <Button onPress={onPressUpdateRecommendations}>
                    Update Recommendations
                  </Button>
                </Card.Footer>
              </Card>
            )}
            {/* <ErrorNote error={networkError} sx={{ mb: 3 }} /> */}
            <RecommendedBookingExpandedPreview
              recommendedBooking={recommendedBooking}
              disableRecommendationActions={false}
              onAcceptRecommendedArtist={onAcceptRecommendedArtist}
              isAcceptingRecommendedArtist={isAcceptingRecommendedArtist}
              onRejectRecommendedArtists={onRejectRecommendedArtists}
              isRejectingRecommendedArtists={isRejectingRecommendedArtists}
              acceptRecommendedArtistError={networkError}
            />
            <Spacer height={4} />
            <H4>Admin Section</H4>
            <FormRow sx={{ zIndex: 2, mb: 0 }}>
              <View>
                <Label sx={{ color: 'text' }}>Add Artists</Label>
                <ArtistSearchField<FormState> name="artistRecommendations" />
              </View>
            </FormRow>

            <AddArtistsArray<FormState> name="artistRecommendations" />
            <FormRow sx={{ zIndex: -1 }}>
              <Label sx={{ color: 'text' }}>Notify buyers?</Label>
              <Subtitle>
                Do you want to notify buyers that you just added these artists?
              </Subtitle>
              <Spacer height={2} />
              <Grid>
                {[true, false].map((bool) => {
                  const isSelected = bool === values.notifyBuyer
                  const onPress = () => {
                    setFieldValue('notifyBuyer', bool, true)
                  }
                  return (
                    <Grid.Item key={`${bool}`}>
                      <Button
                        onPress={onPress}
                        variant={isSelected ? 'primary' : 'secondary'}
                      >
                        {bool ? 'Yes' : 'No'}
                      </Button>
                    </Grid.Item>
                  )
                })}
              </Grid>
              {submitCount > 0 && errors.notifyBuyer && (
                <ErrorText>{errors.notifyBuyer}</ErrorText>
              )}
            </FormRow>
            <Spacer height={7} />
          </WebModal>
        )
      }}
    </Formik>
  )
}
