import React, { ComponentProps } from 'react'
import { useNavigation } from '@react-navigation/native'
import useNavigateToVenue from '@beatgig/navigation/hooks/use-navigate-to-venue'
import CreateVenueForm from './form'
import useToast from '@beatgig/design/hooks/use-toast'
import { useCreateVenue } from '@beatgig/features/venue/new/use-create'
import { CreateVenueMutation } from '@beatgig/gql'

type Props = {
  /**
   * @default `true`
   */
  shouldRedirectToVenueOnCreate?: boolean
  onVenueDidCreate?: (
    venue: CreateVenueMutation['createVenueFromPlaceId']
  ) => void
  animated?: boolean
  /**
   * @default `false`
   */
  shouldGoBackOnCreate?: boolean
} & Pick<ComponentProps<typeof CreateVenueForm>, 'formHeader'>

const CreateVenueScreen = React.memo(function CreateVenueScreen({
  shouldRedirectToVenueOnCreate = true,
  onVenueDidCreate,
  formHeader,
  animated,
  shouldGoBackOnCreate,
}: Props) {
  const { execute, error } = useCreateVenue()
  const { goBack } = useNavigation()
  const { navigate } = useNavigateToVenue()
  const { toast } = useToast()

  return (
    <CreateVenueForm
      animated={animated}
      goBack={goBack}
      onSubmit={async (venue) => {
        const { placeId, venueType, accountExecId } = venue
        const newVenue = await execute({
          placeId,
          venueType,
          accountExecId,
        }).then((data) => data.data?.createVenueFromPlaceId)

        if (newVenue?.slug) {
          const { slug } = newVenue

          onVenueDidCreate?.(newVenue)

          if (shouldRedirectToVenueOnCreate) {
            // FIXME move to solito?
            navigate({ slug })
            // openEditVenue({ slug })
            toast(`Redirecting you to ${newVenue.name}'s new profile...`)
          } else if (shouldGoBackOnCreate) {
            goBack()
          }
        }

        return !!newVenue
      }}
      formHeader={formHeader}
      error={error}
    />
  )
})

export default CreateVenueScreen
