// @ts-nocheck DEPRECATED
import React from 'react'
import { Formik } from 'formik'
import WebModal from '@beatgig/components/web-modal'
import useRequestCloseForm from '@beatgig/forms/hooks/use-request-close-form'
import { IndoorsOrOutdoors, ServerError, Location } from '@beatgig/api-services'
import {
  RecommendedBookingUpdate as InputRecommendedBooking,
  RecommendedBookingCreate as ServerCreateType,
} from '@beatgig/api-services/recommended-booking'
import ErrorNote from '@beatgig/components/ErrorNote'
import { H3, Text, View } from 'dripsy'
import Spacer from '@beatgig/design/components/spacer'
import { APP_NAME } from '@beatgig/constants'
import * as yup from 'yup'
import Timezone from '@beatgig/forms/components/timezone'
import Subtitle from '@beatgig/forms/components/subtitle'
import locationToString from '@beatgig/helpers/location-to-string'
import HoverTooltip from '@beatgig/components/hover-tooltip'
import Button from '@beatgig/components/button'
import DateTimePickerContainer from '@beatgig/forms/fields/date-time-container/provider'
import DateTimeContextPickerField from '@beatgig/forms/fields/date-time-container/picker'
import Grid from '@beatgig/design/components/grid'
import ScrollablesProvider from '@beatgig/forms/context/scrollables/scrollables-provider'
import { ScrollableFormikOnSubmitProp } from '@beatgig/forms/context/scrollables/scrollables-context'
import MultiDateTimeContainer from '@beatgig/forms/fields/multi-date-time-container/provider'
import MultiDateTimePicker from '@beatgig/forms/fields/multi-date-time-container/multi-date-picker'
import SubmitButton from '@beatgig/forms/fields/submit'
import FormRow from '@beatgig/forms/components/row'
import FormSet from '@beatgig/forms/components/set'
import TextField from '@beatgig/forms/fields/text-field'
import AutoCompleteMultiSelectFieldArray from '@beatgig/forms/fields/auto-complete-multi-select-field-array'
import PickerField from '@beatgig/forms/fields/picker-field'
import { Billing } from '@beatgig/api-services/booking'
import { RecommendedBookingApi } from '@beatgig/api/recommended-booking'

type RecommendedBookingCreate = ServerCreateType &
  Pick<
    Parameters<
      typeof RecommendedBookingApi['createRecommendedBooking']
    >[0]['recommendedBooking'],
    'start_times'
  >

type FormState = InputRecommendedBooking | RecommendedBookingCreate

function isCreateForm(form: FormState): form is RecommendedBookingCreate {
  return (
    !!(form as RecommendedBookingCreate)?.start_times &&
    !(form as InputRecommendedBooking)?.start_time
  )
}

export type CreateProps = {
  onCreate: ScrollableFormikOnSubmitProp<
    RecommendedBookingCreate,
    { shouldGoBack: boolean }
  >
  initialRecommendedBooking: RecommendedBookingCreate
  useMultipleDates: true
}

export type EditProps = {
  onEdit: ScrollableFormikOnSubmitProp<
    InputRecommendedBooking,
    { shouldGoBack: boolean }
  >
  initialRecommendedBooking: FormState
  useMultipleDates: false
}

type Props = {
  onClose: () => void
  formHeader?: React.ReactNode | null
  title?: string
  networkError?: ServerError | null
  venueLocation: Location
  venueName: string
  buttonTitle?: string
  animated?: boolean
  onCancelRecommendedBooking?: () => Promise<void>
  isCancelingRecommendedBooking?: boolean
  cancelRecommendedBookingError?: ServerError
} & (EditProps | CreateProps)

export default function EditRecommendedBookingForm(props: Props) {
  const {
    initialRecommendedBooking,
    onClose,
    formHeader = null,
    title = 'Booking Request',
    networkError,
    buttonTitle = 'Save',
    venueLocation,
    venueName,
    animated,
    onCancelRecommendedBooking,
    isCancelingRecommendedBooking,
    cancelRecommendedBookingError,
    useMultipleDates,
  } = props
  const { requestClose } = useRequestCloseForm()

  const validRange = React.useMemo(
    () => ({
      startDate: new Date(),
    }),
    []
  )

  const loading = false
  const genresAndSubgenresStrings: Array<string> = []

  return (
    <ScrollablesProvider>
      {(scrollableProps) => (
        <Formik<FormState>
          onSubmit={async (recommendedBooking, formik) => {
            if (!props.useMultipleDates && !isCreateForm(recommendedBooking)) {
              const result = await props.onEdit(
                recommendedBooking,
                formik,
                scrollableProps
              )
              if (result.shouldGoBack) {
                onClose()
              }
            } else if (
              isCreateForm(recommendedBooking) &&
              props.useMultipleDates
            ) {
              const result = await props.onCreate(
                recommendedBooking,
                formik,
                scrollableProps
              )
              if (result.shouldGoBack) {
                onClose()
              }
            }
            return false
          }}
          initialValues={initialRecommendedBooking}
          validationSchema={yup.object<Partial<FormState>>({
            budget: yup.object({
              high: yup
                .number()
                .min(1, ' ')
                .typeError(' ')
                .nullable()
                .required(' '),
            }),
            genres: yup.array().of(yup.string()),
            venue_id: yup.string().required(' '),
            start_time: !useMultipleDates
              ? (yup
                  .date()
                  .nullable()
                  .min(new Date(), ' ')
                  .required(' ') as any)
              : undefined,
            start_times: useMultipleDates
              ? (yup
                  .array(
                    yup
                      .date()
                      .nullable()
                      .min(new Date(), 'Please pick a future date.')
                      .required('Date required.')
                  )
                  .required('Dates required.')
                  .min(1, 'Please enter at least one date.') as any)
              : undefined,
            // @ts-expect-error nullable, also this is deprecated
            billing: yup.string().nullable().required(' '),
            // @ts-ignore
            artist_instructions: yup.string().nullable(),
            // @ts-ignore
            admin_instructions: yup.string().nullable(),
            indoors_or_outdoors: yup
              .string()
              .oneOf(Object.values(IndoorsOrOutdoors))
              .nullable()
              .required(' '),
            performance_length_mins: yup
              .number()
              .typeError('Please enter a number.')
              .typeError(' ')
              .min(1, ' ')
              .nullable()
              .required(' '),
          })}
        >
          {({ dirty, values, setSubmitting }) => {
            return (
              <WebModal
                animated={animated}
                onRequestClose={requestClose(dirty, onClose)}
                header
                title={title}
                button={() => (
                  <SubmitButton formName={title}>{buttonTitle}</SubmitButton>
                )}
              >
                {formHeader}
                <ErrorNote error={networkError} sx={{ my: 3 }} />
                <FormRow>
                  <H3>Time</H3>
                  {props.useMultipleDates && (
                    <MultiDateTimeContainer<RecommendedBookingCreate>
                      timezone={venueLocation.timezone as string}
                      name="start_times"
                    >
                      <MultiDateTimePicker
                        timeLabel="Start Time"
                        datesLabel="Event Dates"
                        validRange={validRange}
                        timezone={venueLocation.timezone as string}
                      />
                    </MultiDateTimeContainer>
                  )}
                  {!props.useMultipleDates && (
                    <DateTimePickerContainer<InputRecommendedBooking>
                      name="start_time"
                      timezone={venueLocation.timezone as string}
                    >
                      <Grid columns={[1, 2]}>
                        <Grid.Item>
                          <DateTimeContextPickerField<InputRecommendedBooking>
                            name="start_time"
                            label="Event Date"
                            mode="date"
                          />
                        </Grid.Item>
                        <Grid.Item>
                          <DateTimeContextPickerField<InputRecommendedBooking>
                            name="start_time"
                            label="Start Time"
                            mode="time"
                          />
                        </Grid.Item>
                      </Grid>
                      <Timezone
                        sx={{ mt: 3 }}
                        timezoneName={venueLocation.timezone as string}
                      />
                    </DateTimePickerContainer>
                  )}
                </FormRow>

                <FormRow>
                  <HoverTooltip
                    text={`We'll recommend artists to perform at ${venueName}.`}
                    placement="top"
                    anchor="right"
                    bg="text"
                  >
                    <Text>
                      <Text sx={{ fontWeight: 'bold' }}>{venueName} </Text>
                      <Text sx={{ color: 'mutedText' }}>
                        {locationToString(venueLocation)}
                      </Text>
                    </Text>
                  </HoverTooltip>
                </FormRow>

                <FormRow>
                  <H3>Performance</H3>
                  <FormSet>
                    <TextField<FormState>
                      name="performance_length_mins"
                      label="Performance length (mins)"
                      mask="number"
                      required
                      placeholder="Enter minutes"
                      tooltip="How long will this performance be?"
                    />
                    <PickerField<FormState>
                      name="billing"
                      label="Billing"
                      items={Object.values(Billing)}
                      placeholder={{}}
                    />
                  </FormSet>
                  <Spacer height={3} />
                  <PickerField<FormState>
                    name="indoors_or_outdoors"
                    label="Performance location"
                    items={Object.values(IndoorsOrOutdoors)}
                    placeholder={{}}
                    required
                  />
                </FormRow>

                <FormRow sx={{ zIndex: 2 }}>
                  <H3>Budget</H3>
                  <FormSet>
                    <TextField<FormState>
                      name="budget.low"
                      mask="money"
                      label="Low"
                      tooltip="What's your minimum budget?"
                      // required
                    />
                    <TextField<FormState>
                      name="budget.high"
                      mask="money"
                      label="High"
                      tooltip="What's your max spend for this event?"
                      required
                    />
                  </FormSet>
                  <Spacer height={2} />
                  <Subtitle>
                    Tell us your approximate budget for this event. {`We'll`}{' '}
                    use it to tailor your recommended artists.
                  </Subtitle>
                </FormRow>

                <FormRow sx={{ zIndex: 2 }}>
                  <H3>About</H3>
                  <AutoCompleteMultiSelectFieldArray<FormState>
                    name="genres"
                    label="Genres"
                    options={genresAndSubgenresStrings}
                    searching={loading}
                    placeholder={`Search genres...`}
                    emptyOptionText="Custom genre"
                    tooltip="Which genres should we recommend?"
                    required
                  />
                  <Spacer height={2} />
                  <Subtitle sx={{ zIndex: -1 }}>
                    Enter genres you would like to consider for this booking.
                    This helps us optimize the artists we recommend.
                  </Subtitle>
                </FormRow>

                <FormRow>
                  <H3>{APP_NAME} Instructions</H3>
                  <TextField<FormState>
                    name="admin_instructions"
                    tooltip="Help us perfect recommendations."
                    // label="High"
                    required
                    multiline
                    numberOfLines={3}
                    placeholder="Anything else we should know about your event? Are you looking for anything in particular?"
                  />
                  <Subtitle sx={{ pb: 0, pt: 3 }}>
                    This text will only be seen by your {APP_NAME} support
                    representative. It will not be visible to artists.
                  </Subtitle>
                </FormRow>
                <FormRow>
                  <H3>Artist Instructions</H3>
                  <TextField<FormState>
                    name="artist_instructions"
                    tooltip="Information for the artist."
                    required
                    multiline
                    numberOfLines={3}
                    placeholder="Anything the artist should know about this performance?"
                  />
                  <Subtitle sx={{ pb: 0, pt: 3 }}>
                    This text is visible to the artist you select. The artist
                    will only see it once the booking is confirmed.
                  </Subtitle>
                </FormRow>
                {!!onCancelRecommendedBooking && (
                  <>
                    <Spacer height={4} />
                    <ErrorNote
                      label="Cancelation error"
                      error={cancelRecommendedBookingError}
                      sx={{ mb: 3 }}
                    />
                    <View sx={{ alignItems: 'center' }}>
                      <Button
                        sx={{
                          bg: 'error',
                          borderColor: 'error',
                          color: 'text',
                        }}
                        onPress={async () => {
                          try {
                            setSubmitting(true)
                            await onCancelRecommendedBooking()
                            setSubmitting(false)
                          } catch (e) {
                            setSubmitting(false)
                          }
                        }}
                        loading={isCancelingRecommendedBooking}
                      >
                        Cancel
                      </Button>
                    </View>
                  </>
                )}
              </WebModal>
            )
          }}
        </Formik>
      )}
    </ScrollablesProvider>
  )
}
