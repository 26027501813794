import { useCreateBookingRequestsBuyerMutation } from '@beatgig/gql'
import { adminOnBehalfOfContext } from '@beatgig/gql/admin-on-behalf-of'
import { useLatestCallback } from '@beatgig/helpers/use-latest-callback'
import { useCallback } from 'react'

export default function useCreateBookingRequest({
  userId,
  onSuccess,
}: {
  userId: string
  onSuccess: () => void
}) {
  const [{ data, error, fetching }, execute] =
    useCreateBookingRequestsBuyerMutation()

  const success = useLatestCallback(onSuccess)

  return {
    result: data?.createBookingRequestsBuyer,
    error,
    loading: fetching,
    execute: useCallback<typeof execute>(
      (props) =>
        execute(props, adminOnBehalfOfContext(userId)).then((r) => {
          if (r.data && !r.error) {
            success()
          }
          return r
        }),
      [execute, success, userId]
    ),
  }
}
