import { MotiPressable } from 'moti/interactions'
import { View, Text, styled } from 'dripsy'
import React from 'react'

const Pressable = styled(MotiPressable)()

type Props = {
  children?: React.ReactNode
  title: string
  action?: React.ReactNode
  onPress?: () => void
}

const Height = styled(View)({
  height: 32,
  justifyContent: 'center',
})

export function PromoStudioPanel({ title, children, action, onPress }: Props) {
  return (
    <View
      sx={{
        py: 2,
        borderBottomWidth: 1,
        borderColor: 'border',
      }}
    >
      <Pressable
        onPress={onPress}
        id="panel"
        sx={{
          pl: 3,
          py: 2,
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'row',
          cursor: onPress ? 'pointer' : 'default',
        }}
      >
        <Height>
          <Text selectable={false} sx={{ fontWeight: '600' }}>
            {title}
          </Text>
        </Height>
        {!!action && action}
      </Pressable>
      <View>{children}</View>
    </View>
  )
}

function PromoStudioItem({ children }: { children: React.ReactNode }) {
  return (
    <View sx={{ pl: 3, py: 2 }}>
      <Height>{children}</Height>
    </View>
  )
}

PromoStudioPanel.Item = PromoStudioItem
