import { DateTime } from 'luxon'

export type SelectedDate = {
  year: number
  month: number
  day: number
}

export type UseSelectedDate = {
  selectedDate: SelectedDate
  setSelectedDate: (date: SelectedDate) => void
}

const secureNumberFromParams = (
  str: string | undefined | number,
  fallback: number
) => {
  if (!isNaN(Number(str))) {
    return Number(str)
  }
  return fallback
}

export function validateParamsDate(
  params: {
    day?: string | number
    month?: string | number
    year?: string | number
  },
  now: { day: number; month: number; year: number }
) {
  const year: number = Math.max(
    2020, // when we launched 🕺
    Math.min(secureNumberFromParams(params.year, now.year), now.year + 100)
  )
  const month: number = Math.max(
    Math.min(
      secureNumberFromParams(params.month, now.month),
      // there are only 12 months per year...
      12
    ),
    1
  )
  const { daysInMonth } = DateTime.fromObject({ year, month })
  const day: number = Math.max(
    // at least the first day of the month
    // at most, the max number of days in this month
    Math.min(secureNumberFromParams(params.day, now.day), daysInMonth),
    1
  )

  return {
    year,
    month,
    day,
  }
}
