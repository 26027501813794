import React, { useEffect } from 'react'
import { View, Container, Text } from 'dripsy'
import { Tab } from './types'
import { Skeleton } from 'moti/skeleton'
import { MotiPressable, useMotiPressable } from 'moti/interactions'
import { LayoutView } from '../layout-view'
import { LayoutGroup } from '../layout-view/group'
import { DripsyTheme } from '@beatgig/theme'
import { ScrollView, useAnchors, Target } from '@nandorojo/anchor'

type Props<Value extends string = string> = {
  hideDivider?: boolean
  loading?: boolean
  bg?: keyof DripsyTheme['colors']
} & (
  | {
      tabs: Tab<string, Value>[]
      children?: never
      /**
       * Which `value` is selected.
       */
      selected: Value | null
      setSelected: (tab: Tab<string, Value>) => void
    }
  | {
      tabs?: never
      children: React.ReactNode
      selected?: never
      setSelected?: never
    }
)

function Tabs<Value extends string = string>(props: Props<Value>) {
  const {
    tabs = null,
    hideDivider = false,
    loading = false,
    children = null,
    bg = 'background',
    selected,
  } = props

  console.log('[tabs] render')

  const anchors = useAnchors()

  useEffect(
    function scrollToSelected() {
      if (selected && !loading) {
        anchors.current?.scrollTo(selected)
      }
    },
    [anchors, selected, loading]
  )

  return (
    <LayoutGroup>
      <View
        sx={{
          borderBottomWidth: hideDivider ? 0 : 1,
          borderBottomColor: hideDivider ? undefined : 'border',
          bg,
        }}
      >
        <Container
        // sx={{ flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }}
        >
          <ScrollView
            anchors={anchors}
            horizontal
            showsHorizontalScrollIndicator={false}
          >
            {loading &&
              !tabs?.length &&
              new Array(5).fill('').map((_, i) => {
                return (
                  <TabItem
                    isSelected={i === 0}
                    setSelected={() => {
                      // no-op
                    }}
                    title="Loading..."
                    loading
                    value={`${i}`}
                    key={i}
                  />
                )
              })}
            {props.tabs?.map((tab) => {
              return (
                <TabItem
                  key={tab.value}
                  {...tab}
                  isSelected={props.selected === tab?.value}
                  setSelected={props.setSelected}
                />
              )
            })}
            {children}
          </ScrollView>
        </Container>
      </View>
    </LayoutGroup>
  )
}

function Indicator({ isSelected }) {
  const state = useMotiPressable(({ hovered, pressed }) => {
    'worklet'

    return {
      shadowOpacity: hovered || pressed ? 1 : 0,
    }
  })
  return (
    <>
      {isSelected && (
        <LayoutView
          layoutId="tab-item-selected"
          sx={{
            height: 2.01,
            left: 0,
            right: 0,
            bg: isSelected ? 'text' : 'transparent',
            position: 'absolute',
            bottom: 0,
            shadowRadius: 10,
            shadowColor: 'white',
            shadowOffset: {
              width: 0,
              height: 0,
            },
          }}
          state={state}
        />
      )}
    </>
  )
}

const TabItem = function TabItem({
  isSelected,
  setSelected,
  badgeCount,
  loading,
  ...tab
}: Tab & {
  isSelected: boolean
  setSelected: NonNullable<Props['setSelected']>
  loading?: boolean
}) {
  const color = isSelected ? 'text' : 'muted5'

  return (
    <MotiPressable
      animate={({ pressed, hovered }) => {
        'worklet'
        return {
          opacity: pressed || hovered ? 0.7 : 1,
        }
      }}
      transition={{
        opacity: {
          type: 'timing',
          duration: 120,
        },
      }}
      onPress={() => setSelected(tab)}
    >
      <Target name={tab.value} />
      <View sx={{ flexDirection: 'row', alignItems: 'center', pb: 2.01 }}>
        <View
          sx={{
            px: 2,
            py: 3,
          }}
        >
          <Skeleton show={loading}>
            <Text
              sx={{
                cursor: 'pointer',
                textTransform: 'capitalize',
                color,
                fontSize: 3,
              }}
            >
              {tab.title}
            </Text>
          </Skeleton>
        </View>
        {!!badgeCount && (
          <View
            sx={{
              height: 22,
              width: `${badgeCount}`.length * 6 + 16,
              borderRadius: 'rounded',
              bg: 'error',
              // alignItems: 'center',
              justifyContent: 'center',
              mr: 1,
            }}
          >
            <Text sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 1 }}>
              {`${badgeCount}`.trim()}
            </Text>
          </View>
        )}
      </View>
      <Indicator isSelected={isSelected} />
    </MotiPressable>
  )
}

Tabs.Item = TabItem

export default React.memo(Tabs)
