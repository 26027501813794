import useServerLayoutEffect from '@beatgig/api-hooks/src/use-fixed-layout-effect'
import { useLatestCallback } from '@beatgig/helpers/use-latest-callback'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useCallback, useRef } from 'react'
import pak from '../../package.json'

const { version } = pak

const prefix = `__persist`

export function usePersistState<T>(
  _key: string | null,
  {
    state,
    setState,
  }: { state: T; setState: React.Dispatch<React.SetStateAction<T>> }
): [state: T, setter: (state: T) => void, hydrated: boolean] {
  const hydrated = useRef(false)

  const key = _key && `${prefix}-${version}-${_key}`

  const setter = useLatestCallback(setState)
  const initialState = useRef(state)
  const prevKey = useRef(key)

  useServerLayoutEffect(() => {
    const didKeyChange = prevKey.current !== key
    if (didKeyChange) {
      initialState.current = state
      hydrated.current = false
    }
    prevKey.current = key
  }, [key, state])

  useServerLayoutEffect(
    function getFromState() {
      const run = async () => {
        if (!hydrated.current) {
          try {
            let value = initialState.current
            if (key) {
              const storageValue = await AsyncStorage.getItem(key)
              if (storageValue) {
                console.log('[use-persist-storage]', {
                  key,
                  storageValue,
                })
                hydrated.current = true
                value = JSON.parse(storageValue)
              }
            }
            setter(value)
          } catch (e) {
            console.error('Failed to read from async storage', e)
          }
        }
      }
      run()
    },
    [key, setter]
  )

  const set = useCallback<typeof setState>(
    async (state) => {
      setter(state)
      if (key) {
        try {
          AsyncStorage.setItem(key, JSON.stringify(state))
        } catch (e) {
          console.error('Failed to write to async storage', e)
        }
      }
    },
    [key, setter]
  )

  return [state, set, hydrated.current]
}
