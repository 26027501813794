import { useMemo, useCallback } from 'react'
import useStable from '@beatgig/design/hooks/use-stable'
import { BookingsSmallCalendarListProps, SmallCalendarListEvent } from './types'
import { getDisplayStartTime } from '@beatgig/helpers/display-booking-start-time'
import numeral from 'numeral'
import Cloudinary from '@beatgig/helpers/cloudinary'
import useBookingStatusToColor from '@beatgig/hooks/use-booking-status-to-color'
import useTheme from '@beatgig/theme/use-theme'
import { WhatShouldIDoAlert } from '@beatgig/api-services'
import { cheatFormatBookingDateForCalendar } from '@beatgig/helpers/cheat-format-booking-calendar-date'
import sameDay from 'date-fns/isSameDay'
import { DateTime } from 'luxon'
import { getBookingRequestStatusColor } from '@beatgig/hooks/use-booking-request-status-to-color'
import locationToString from '@beatgig/helpers/location-to-string'
import { UserRole } from '@beatgig/api-services'
import isFuture from 'date-fns/isFuture'
import { BidStatus } from '@beatgig/api-services/booking-request'
import { useSellerBookingRequestPersonalized } from '@beatgig/hooks/use-seller-booking-request'
import useMyArtists from '@beatgig/api-hooks/src/use-my-artists'

/**
 * FIXME gql this is broken! ah
 */
const isSameDay = (
  booking: Parameters<typeof cheatFormatBookingDateForCalendar>[0],
  selectedDate: BookingsSmallCalendarListProps['selectedDate']
) => {
  const { start_time } = cheatFormatBookingDateForCalendar(booking)
  if (start_time && selectedDate) {
    const result = sameDay(
      new Date(start_time),
      DateTime.fromObject(selectedDate).toJSDate()
    )

    return result
  }

  return false
}

const getStartTime = (
  booking: Parameters<typeof getDisplayStartTime>[0],
  { showDate = false }: { showDate: boolean }
) => {
  const timeFormat = `hh:mm aa`
  const isNextYear =
    new Date(booking.startTime).getFullYear() > new Date().getFullYear()
  const format = showDate
    ? `MMM do${isNextYear ? ', yyyy' : ''} @ ${timeFormat}`
    : timeFormat
  return `${getDisplayStartTime(booking).timeWithCustomFormat(format) || 'TBD'}`
}

export function useBookingsSmallCalendarList({
  bookingRequests,
  onPressBookingRequest,
  externalBookings,
  bookings,
  onPressBooking,
  selectedDate,
  onPressExternalBooking,
  userRole,
  shouldFilterBySelectedDate,
}: BookingsSmallCalendarListProps) {
  const { bookingStatusToColor } = useBookingStatusToColor()

  const stableBookings = useStable(bookings || [])
  const stableBookingRequests = useStable(bookingRequests || [])
  const stableExternalBookings = useStable(externalBookings || [])
  const stablePressBooking = useStable(onPressBooking)
  const stablePressExternalBooking = useStable(onPressExternalBooking)
  const stablePressBookingRequest = useStable(onPressBookingRequest)

  const onPressItem = useCallback(
    ({ id }: { id: string }) => {
      const booking = stableBookings.current.find(
        (booking) => booking.id === id
      )
      if (booking) {
        return stablePressBooking.current(booking)
      }

      const bookingRequest = stableBookingRequests.current.find(
        (bookingRequest) => bookingRequest.id === id
      )
      if (bookingRequest) {
        return stablePressBookingRequest.current(bookingRequest)
      }

      const externalBooking = stableExternalBookings.current.find(
        (externalBooking) => externalBooking.id === id
      )
      if (externalBooking) {
        return stablePressExternalBooking.current(externalBooking)
      }
    },
    [
      stableBookings,
      stablePressBooking,
      stableExternalBookings,
      stablePressExternalBooking,
      stableBookingRequests,
      stablePressBookingRequest,
    ]
  )

  const { colors } = useTheme()

  const { getStatusDescription } = useSellerBookingRequestPersonalized()

  const myArtists = useMyArtists()?.data

  const events = useMemo<SmallCalendarListEvent[]>(() => {
    let events: SmallCalendarListEvent[] = []
    bookingRequests?.forEach((bookingRequest) => {
      const priceText = `${
        bookingRequest.budget?.low
          ? `${numeral(bookingRequest.budget?.low).format('$0,[0]a')}`
          : ''
      }${
        bookingRequest.budget?.high && bookingRequest.budget.low ? ` - ` : ''
      }${
        bookingRequest.budget?.high
          ? `${numeral(bookingRequest.budget?.high).format('$0,[0]a')}`
          : ''
      }`
      const startTime = getStartTime(
        {
          startTime: bookingRequest.start_time,
          timezone: bookingRequest.venue_location.timezone,
        },
        {
          showDate: !shouldFilterBySelectedDate,
        }
      )
      const shortMessage =
        userRole == UserRole.BUYER
          ? // FIXME gql these objects aren't the same...
            bookingRequest.buyer_frontend_copy?.short_message?.body
          : userRole == UserRole.SELLER
          ? getStatusDescription({
              bookingRequest,
            })?.text ?? ''
          : ''

      const urls: string[] = []
      for (const bid of bookingRequest.bids) {
        const { artist, status } = bid
        if (
          status === BidStatus.REJECTED ||
          status === BidStatus.WITHDRAWN ||
          status === BidStatus.ARTIST_UNAVAILABLE
        ) {
          continue
        }
        const profileImage =
          artist.profileImage && Cloudinary(artist.profileImage, { width: 150 })
        if (profileImage) {
          urls.push(profileImage)
        }
      }
      const avatarURLs = urls.join('|new image|')

      const { isActionRequired } = bookingRequest

      const color =
        userRole == UserRole.BUYER
          ? getBookingRequestStatusColor(colors, {
              status: bookingRequest.status,
              isActionRequired,
              isPastEvent: bookingRequest.isPast,
            }).variant
          : getStatusDescription({
              bookingRequest,
            }).variant

      if (
        !shouldFilterBySelectedDate ||
        isSameDay(bookingRequest, selectedDate)
      ) {
        events = events || []
        events.push({
          title: `${priceText ? priceText + ' ' : ''}Request`,
          shortMessage,
          startTime,
          id: bookingRequest.id,
          onPress: onPressItem,
          color,
          avatarURLs,
          venueName: bookingRequest.venue?.name,
          venueLocation: locationToString(bookingRequest.venue_location, {
            stateAbbr: false,
            streetAddress: false,
            city: true,
            postalCode: false,
          }),
        })
      }
    })
    bookings?.forEach((booking) => {
      const startTime = getStartTime(
        {
          startTime: booking.start_time,
          timezone: booking.location.timezone,
        },
        {
          showDate: !shouldFilterBySelectedDate,
        }
      )
      const avatarURLs = Cloudinary(booking.artist?.profileImage, {
        width: 250,
      })

      /**
       * TODO
       */
      const title = booking.artist.name
      const isActionRequired =
        booking.what_should_i_do_alerts?.[userRole] ===
          WhatShouldIDoAlert.ACTION_REQUIRED &&
        isFuture(new Date(booking.start_time))

      let shortMessage: string = booking.status
      let color = bookingStatusToColor(booking.status, {
        isActionRequired,
      }).variant

      if (isActionRequired) {
        color = 'error'
        shortMessage = WhatShouldIDoAlert.ACTION_REQUIRED
      }

      if (
        !shouldFilterBySelectedDate ||
        isSameDay(
          {
            start_time: booking.start_time,
            venue_location: booking.location,
          },
          selectedDate
        )
      ) {
        events = events || []
        events.push({
          title,
          startTime,
          onPress: onPressItem,
          color,
          id: booking.id,
          avatarURLs,
          shortMessage,
          venueName: booking.venue_name,
        })
      }
    })
    externalBookings?.forEach((externalBooking) => {
      if (
        !shouldFilterBySelectedDate ||
        isSameDay(
          {
            start_time: externalBooking.start_time,
            venue_location: externalBooking.location,
          },
          selectedDate
        )
      ) {
        events = events || []
        events.push({
          title: externalBooking.artist_name,
          startTime: getStartTime(
            {
              startTime: externalBooking.start_time,
              timezone: externalBooking.location.timezone,
            },
            {
              showDate: !shouldFilterBySelectedDate,
            }
          ),
          id: externalBooking.id,
          onPress: onPressItem,
          color: 'text',
          avatarURLs: Cloudinary(externalBooking.cover_image),
          shortMessage: 'Imported',
          venueName: externalBooking.venue_name,
        })
      }
    })
    return events
  }, [
    bookingRequests,
    bookings,
    externalBookings,
    shouldFilterBySelectedDate,
    userRole,
    getStatusDescription,
    myArtists,
    colors,
    selectedDate,
    onPressItem,
    bookingStatusToColor,
  ])

  const loading = !bookingRequests || !bookings

  return {
    events,
    loading,
    empty: !loading && events.length === 0,
  }
}
