import ErrorNote from '@beatgig/components/ErrorNote'
import TextFieldForm from '@beatgig/forms/components/text-field-form'
import { useUpdateBookingPublicEventDescriptionMutation } from '@beatgig/gql'

type Props = {
  bookingId: string
  publicEventDescription: string
}

export default function EditBookingPublicDescription({
  bookingId,
  publicEventDescription,
}: Props) {
  const [{ data, error }, execute] =
    useUpdateBookingPublicEventDescriptionMutation()

  return (
    <>
      <TextFieldForm<Pick<Props, 'publicEventDescription'>, boolean>
        name="publicEventDescription"
        placeholder="Public event description."
        onSubmit={async ({ publicEventDescription }) => {
          const booking = await execute({ bookingId, publicEventDescription })
          const shouldReset = !!booking.data?.updateBookings.find(
            (booking) => booking.id == bookingId
          )?.publicEventDescription

          return {
            shouldReset,
          }
        }}
        canIEdit
        result={!!data}
        initialValues={{
          publicEventDescription,
        }}
      />
      <ErrorNote sx={{ mt: 3 }} error={error} />
    </>
  )
}
