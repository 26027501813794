import Analytics from '@beatgig/analytics/typez'
import { useCreateExternalBookingMutation } from '@beatgig/gql'
import { useCallback } from 'react'

export default function useCreateExternalBooking() {
  const [{ error }, execute] = useCreateExternalBookingMutation()

  return {
    error,
    execute: useCallback<typeof execute>(
      (...props) => {
        Analytics.track('Create External Booking', {
          artistName: props[0]?.input.artistName || 'No Artist Name',
        })
        return execute(...props)
      },
      [execute]
    ),
  }
}
