import React from 'react'
import { Text, View } from 'dripsy'
import { SelectedDate } from '@beatgig/screens/small-calendar-screen/use-selected-date/types'
import { DateTime } from 'luxon'
import Spacer from '@beatgig/design/components/spacer'
import Card from '@beatgig/design/components/card'
import { useCalendarActions } from '@beatgig/screens/calendar-screen/hooks/actions'
import { APP_NAME } from '@beatgig/constants'
import Button from '@beatgig/components/button'
import isFuture from 'date-fns/isFuture'
import isToday from 'date-fns/isToday'
import isThisYear from 'date-fns/isThisYear'
import { UserRole } from '@beatgig/backend-types'

export const EmptyBookingsSmallCalendarList = React.memo(
  function EmptyBookingsSmallCalendarList({
    selectedDate,
    onPressCreateBookingRequest,
    userRole,
  }: {
    selectedDate: SelectedDate
    onPressCreateBookingRequest: ReturnType<
      typeof useCalendarActions
    >['onPressCreateBookingRequest']
    userRole: UserRole
    // onPressCreateRecommendedBooking: ReturnType<
    //   typeof useCalendarActions
    // >['onPressCreateRecommendedBooking']
  }) {
    const dateTime = DateTime.fromObject(selectedDate)
    const isInFuture = isFuture(dateTime.toJSDate())
    const isHappeningToday = isToday(dateTime.toJSDate())
    const isHappeningThisYear = isThisYear(dateTime.toJSDate())

    const date = dateTime.toFormat('MMMM d, yyyy')
    const isActionableDate = isInFuture || isHappeningToday
    let dateText = ''
    if (isInFuture) {
      const dateFormat = isHappeningThisYear ? 'MMMM d' : 'MMMM d, yyyy'
      dateText = ` on ${dateTime.toFormat(dateFormat)}`
    }
    if (isHappeningToday) {
      dateText = ` later today`
    }
    const onCreate = () => {
      // onPressCreateRecommendedBooking({
      //   startDay: selectedDate,
      // })
      onPressCreateBookingRequest({ startDay: selectedDate })
    }

    return (
      <View
        sx={{ pb: 4, px: 3, alignSelf: 'center', width: '100%', maxWidth: 500 }}
      >
        <Card sx={{ bg: 'muted' }}>
          {isActionableDate ? (
            <Card.Content>
              {userRole === UserRole.BUYER && (
                <>
                  <Text sx={{ textAlign: 'center' }}>
                    Want help choosing artists to book{dateText}? Let {APP_NAME}{' '}
                    find them for you.
                  </Text>
                  <Spacer height={3} />
                  <Button onPress={onCreate}>Get Artist Recommendations</Button>
                </>
              )}
              {userRole === UserRole.SELLER && (
                <>
                  <Text sx={{ textAlign: 'center' }}>
                    No events on this day.
                  </Text>
                </>
              )}
            </Card.Content>
          ) : (
            <Card.Content>
              <Text
                sx={{ color: 'mutedText', textAlign: 'center', fontSize: 4 }}
              >
                No events on {date}. This date is in the past.
              </Text>
            </Card.Content>
          )}
        </Card>
      </View>
    )
  }
)
