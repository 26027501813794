import React, { useCallback } from 'react'
import { View, Text } from 'dripsy'
import Sizer from '@beatgig/components/sizer'
import useAdminOnBehalfOfVenueContainer from './use-admin-on-behalf-of-venue'
import AdminOnBehalfOfUserContainer from '../admin-on-behalf-of-user-container'
import AdminVenueSearch from '../admin-venue-search'
import AdminArtistSearch from '../admin-artist-search'
import useMyAccount from '@beatgig/auth-hooks/use-my-account'
import { User } from '@beatgig/api/user'
import {
  useAdminOnBehalfOfUser,
  OnBehalfOfUserContext,
  useOnBehalfOfContext,
} from '@beatgig/auth-hooks/use-admin-on-behalf-of-user'

import Press from '@beatgig/components/press'
import Badge from '@beatgig/design/components/badge'
import Spacer from '@beatgig/design/components/spacer'
import { View as MotiView } from 'moti'
import { UserDisplay, UserRole } from '@beatgig/api-services/user'
import useConsoleLog from '@beatgig/hooks/use-log'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import useServerLayoutEffect from '@beatgig/api-hooks/src/use-fixed-layout-effect'

type Props = {} & React.ComponentProps<typeof AdminOnBehalfOfUserContainer> & {
    lookUpBy?: 'venue' | 'artist'
  } & Pick<React.ComponentProps<typeof AdminArtistSearch>, 'onSelectArtist'> &
  Pick<React.ComponentProps<typeof AdminVenueSearch>, 'onSelectVenue'>

function AdminOnBehalfOfVenue(props: Props) {
  const {
    // onChangeVenue,
    unselectedUserText,
    onSelectUser,
    onClearUser,
    onSelectArtist: onSelectArtistProp,
    onSelectVenue: onSelectVenueProp,
    ...userContainerProps
  } = props

  const insets = useSafeAreaInsets()

  const {
    userRole,
    lookUpBy = userRole === UserRole.SELLER ? 'artist' : 'venue',
  } = props

  const { data: me } = useMyAccount()

  const [adminOnBehalfOf, setOnBehalfOfUser] = useAdminOnBehalfOfUser({
    // independent,
    userRole,
  })

  const [
    { searchQuery, venue, artist, entityName, ownersOfVenue, sellersOfArtist },
    {
      setSearchQuery,
      setSearchByUser,
      setSearchByArtist,
      setVenue,
      setSearchByVenue,
      clearVenue,
      clearArtist,
      setArtist,
    },
    { isSearchingByUser },
  ] = useAdminOnBehalfOfVenueContainer({ lookUpBy })

  const onSelectArtist: NonNullable<typeof onSelectArtistProp> = useCallback(
    (artist) => {
      onSelectArtistProp?.(artist)
      setArtist(artist)
    },
    [setArtist, onSelectArtistProp]
  )

  let autoSetUser: UserDisplay | null = null

  if (User.isAdmin(me) && !adminOnBehalfOf) {
    if (lookUpBy === 'venue' && venue?.slug === ownersOfVenue?.slug) {
      if (ownersOfVenue && ownersOfVenue?.owners?.length === 1) {
        autoSetUser = ownersOfVenue.owners[0]
      }
    } else if (
      lookUpBy === 'artist' &&
      artist?.slug === sellersOfArtist?.slug
    ) {
      if (sellersOfArtist?.sellers?.length === 1) {
        autoSetUser = sellersOfArtist.sellers[0]
      }
    }
  }

  useServerLayoutEffect(() => {
    if (autoSetUser) {
      setOnBehalfOfUser(autoSetUser)
    }
    // only change if the ID changes. We don't want to change from equality updates
    // this code is really stupid anyway.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSetUser?.id, setOnBehalfOfUser])

  useConsoleLog('[admin-behalf-venue-artist-container]', { adminOnBehalfOf })

  // if the user is chosen
  // or you're searching by user
  // or if you aren't an admin, (SUUUPER IMPORTANT)
  // forward directly to the user container
  if (adminOnBehalfOf || isSearchingByUser || !User.isAdmin(me)) {
    return (
      <AdminOnBehalfOfUserContainer
        unselectedUserText={unselectedUserText}
        onSelectUser={(user) => {
          onSelectUser?.(user)
          if (lookUpBy === 'artist') {
            clearArtist()
          } else {
            clearVenue()
          }
        }}
        onClearUser={() => {
          clearArtist()
          clearVenue()
          onClearUser?.()
        }}
        onPressAlternateSearchMethod={
          lookUpBy === 'artist' ? setSearchByArtist : setSearchByVenue
        }
        searchByAlternateMethodText={`Search by ${lookUpBy} instead`}
        {...userContainerProps}
      />
    )
  }

  const potentialUsers =
    lookUpBy === 'venue' ? ownersOfVenue?.owners : sellersOfArtist?.sellers

  return (
    <>
      <View sx={{ bg: 'secondary', p: 3, zIndex: 2 }}>
        <Sizer
          flexDirection={['column', null, 'row']}
          justifyContent={[null, null, 'space-between']}
          alignItems={[null, null, 'center']}
          mt={insets.top}
        >
          <Sizer
            mr={2}
            flex={[null, null, 1]}
            justifyContent={[null, null, 'center']}
          >
            {!!potentialUsers ? (
              <View>
                <Text sx={{ fontWeight: 'bold' }}>
                  Select a user from {entityName} to act on behalf of:
                </Text>
                <View
                  sx={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                  }}
                >
                  {potentialUsers?.length === 0 && (
                    <Text sx={{ mt: 2 }}>
                      Hmm, {entityName}{' '}
                      {`doesn't have any ${
                        userRole || 'user'
                      }s associated with them. Maybe you need to link this ${
                        userRole || 'user'
                      } to their ${lookUpBy} profile?`}
                    </Text>
                  )}
                  {potentialUsers?.map((user) => {
                    return (
                      <UserItem
                        setOnBehalfOfUser={setOnBehalfOfUser}
                        key={user.id}
                        user={user}
                      />
                    )
                  })}
                </View>
              </View>
            ) : (
              <>
                <Text sx={{ fontWeight: 'bold' }}>
                  Admin-only {lookUpBy} search
                </Text>
                <Spacer height={2} />
                <Text>
                  Search for a {lookUpBy}, then select a user.{' '}
                  {unselectedUserText}
                </Text>
              </>
            )}
          </Sizer>
          <Sizer ml={[null, null, 2]} mt={[2, null, 0]} flex={[null, null, 1]}>
            {lookUpBy === 'venue' && (
              <AdminVenueSearch
                value={searchQuery}
                onChangeText={setSearchQuery}
                onSelectVenue={({ slug, name }) => {
                  onSelectVenueProp?.({ slug, name })
                  setVenue({ slug })
                }}
                onClearVenue={clearVenue}
              />
            )}
            {lookUpBy === 'artist' && (
              <AdminArtistSearch
                value={searchQuery}
                onChangeText={setSearchQuery}
                onClearArtist={clearArtist}
                onSelectArtist={onSelectArtist}
              />
            )}
            <Spacer height={2} />
            <Text sx={{ textAlign: 'right' }} onPress={setSearchByUser}>
              Search by user instead
            </Text>
          </Sizer>
        </Sizer>
      </View>
      {props.children({
        userId: '',
        userName: '',
        isUserApproved: false,
        userType: undefined,
        userRole: undefined,
      })}
    </>
  )
}

function UserItem({
  user,
  setOnBehalfOfUser,
}: {
  user: UserDisplay
  setOnBehalfOfUser: (user: UserDisplay) => void
}) {
  const { name } = user

  return (
    <Press onPress={() => setOnBehalfOfUser(user)} sx={{ mr: 2, mt: 3 }}>
      {({ hovered, focused }) => (
        <MotiView
          transition={{ type: 'timing', duration: 150 }}
          animate={{
            scale: hovered || focused ? 1.05 : 1,
          }}
        >
          <Badge filling="filled" variant="default">
            {name}
          </Badge>
        </MotiView>
      )}
    </Press>
  )
}

export default function AdminOnBehalfOfVenueOrArtistContainer(
  props: Omit<Props, 'userRole'> & { userRole?: UserRole }
) {
  const maybeContext = useOnBehalfOfContext()

  const { userRole = UserRole.BUYER } = props

  const [adminOverrideUser, setAdminOverrideUser] = useAdminOnBehalfOfUser({
    userRole,
  })

  // return <AdminOnBehalfOfVenue {...props} />

  return (
    <OnBehalfOfUserContext.Provider
      value={React.useMemo(
        () => ({
          setAdminOverrideUser,
          hasParentContainer:
            !!maybeContext?.hasParentContainer &&
            maybeContext.userRoleProp === userRole,
          adminOverrideUser,
          userRoleProp: userRole,
        }),
        [
          adminOverrideUser,
          maybeContext?.hasParentContainer,
          maybeContext?.userRoleProp,
          userRole,
          setAdminOverrideUser,
        ]
      )}
    >
      <AdminOnBehalfOfVenue {...props} userRole={userRole} />
    </OnBehalfOfUserContext.Provider>
  )
}
