import AdminArtistSearch from '@beatgig/auth-components/admin-artist-search'
import AdminVenueSearch from '@beatgig/auth-components/admin-venue-search'
import Press from '@beatgig/components/press'
import Badge from '@beatgig/design/components/badge'
import { Box } from '@beatgig/design/components/box'
import Spacer from '@beatgig/design/components/spacer'
import FilterPopover from '@beatgig/search/filters/filter-popover'
import { Fragment, useState } from 'react'
import { useBookingSlugsFilter } from './use-slug-filter'

function Filter({
  children,
  slugs,
  removeSlug,
  filterBy,
}: {
  children: React.ReactNode
  filterBy: 'Artists' | 'Venues'
} & ReturnType<typeof useBookingSlugsFilter>) {
  return (
    <FilterPopover
      totalCount={Infinity}
      label={filterBy}
      mode="modal"
      selectedCount={slugs.length}
      filter={
        <Box minHeight={200}>
          {children}
          <Spacer y="$3" />

          <Box flexDirection="row" zIndex={-1}>
            {slugs.map((slug) => {
              return (
                <Fragment key={slug}>
                  <Press onPress={() => removeSlug(slug)}>
                    <Box mr="$2" mb="$2">
                      <Badge
                        variant="text"
                        iconLeft={{
                          name: 'ios-close',
                        }}
                      >
                        {slug}
                      </Badge>
                    </Box>
                  </Press>
                </Fragment>
              )
            })}
          </Box>
        </Box>
      }
    />
  )
}

export function AdminBookingArtistSlugsFilter(
  props: ReturnType<typeof useBookingSlugsFilter>
) {
  const [value, onChangeText] = useState('')
  return (
    <Filter {...props} filterBy="Artists">
      <AdminArtistSearch
        onSelectArtist={({ slug }) => props.addSlug(slug)}
        onChangeText={onChangeText}
        value={value}
      />
    </Filter>
  )
}

export function AdminBookingVenueSlugsFilter(
  props: ReturnType<typeof useBookingSlugsFilter>
) {
  const [value, onChangeText] = useState('')
  return (
    <Filter {...props} filterBy="Venues">
      <AdminVenueSearch
        onSelectVenue={({ slug }) => props.addSlug(slug)}
        onChangeText={onChangeText}
        value={value}
      />
    </Filter>
  )
}
