import React, { useCallback, useMemo } from 'react'
import { View, Text, useBreakpoints } from 'dripsy'
import Card from '@beatgig/design/components/card'
import { Skeleton } from 'moti/skeleton'
import {
  BookingRequestDisplay,
  BookingRequestStatus,
} from '@beatgig/api-services/booking-request'
import Cloudinary from '@beatgig/helpers/cloudinary'
import Avatar from '@beatgig/design/components/avatar/avatar'
import numeral from 'numeral'
import HoverPulse from '@beatgig/components/hover-pulse'
import { useOpenBookingRequestSeller } from '@beatgig/navigation/hooks/use-open-booking-request-seller'
import Press from '@beatgig/components/press'
import type { DripsyTheme } from '@beatgig/theme'
import Badge from '@beatgig/design/components/badge'
import Ionicons, { IconProps } from '@beatgig/design/components/ionicons'
import { BidStatus } from '@beatgig/api-services/booking-request'
import { useSellerBookingRequestPersonalized } from '@beatgig/hooks/use-seller-booking-request'
import { useContainerWidth } from './container-query'
import { Target } from '@nandorojo/anchor'
import Spacer from '@beatgig/design/components/spacer'

type Props =
  | {
      loading: false
      venueName: string
      startTime: string
      isActionRequired: boolean
      isHighlighted: boolean
      id: string
      bookingRequest: BookingRequestDisplay
    }
  | {
      loading: true
      venueName?: never
      startTime?: never
      isHighlighted?: never
      isActionRequired?: never
      id?: never
      bookingRequest?: never
    }

type MyArtistProps = {
  recommendedPrice?: string
  name: string
  imageUrl?: string
  id: string
  submittedBidPrice?: string
  isBidRejected: boolean
  isActionRequired: boolean
  subtitle: string
  icon: IconProps['icon'] | null
  color: keyof DripsyTheme['colors']
}

type HighlightedColors = Record<
  'bg' | 'text' | 'mutedText' | 'highlight' | 'border',
  keyof DripsyTheme['colors']
>

function useItem({ id, isHighlighted, bookingRequest }: Props) {
  const { getStatusDescription } = useSellerBookingRequestPersonalized()

  const didSomeonElsesBidGetChosen = bookingRequest?.didSomeoneElsesBidGetChosen
  const isPastEvent = bookingRequest?.isPast
  const colors = useMemo(() => {
    const colors: HighlightedColors = {
      bg: 'background',
      text: 'text',
      mutedText: 'mutedText',
      highlight: 'primary',
      border: 'border',
    }

    if (isHighlighted) {
      colors.border = 'text'
      // colors.bg = 'muted7'
      // colors.text = 'background'
      // colors.mutedText = 'muted'
      // colors.highlight = 'blue'
    }
    return colors
  }, [isHighlighted])
  const { openBookingRequest } = useOpenBookingRequestSeller()

  const onPressBookingRequest = useCallback(() => {
    if (id) {
      openBookingRequest({ bookingRequestId: id })
    }
  }, [id, openBookingRequest])

  const myArtists: MyArtistProps[] = []
  for (const artist of bookingRequest?.artists || []) {
    if (!bookingRequest) {
      continue
    }
    const bid = bookingRequest.bids.find((bid) => bid.artist.id == artist.id)
    const isBidRejected = bid?.status === BidStatus.REJECTED
    const submittedBidPrice = bid?.sellerPrice

    const subtitle = ''
    const color: MyArtistProps['color'] = 'text'
    const icon: MyArtistProps['icon'] = null

    type Ui = Pick<MyArtistProps, 'icon' | 'color' | 'subtitle'>

    let ui: Ui = {
      subtitle,
      color,
      icon,
    }

    const setUi = (next: typeof ui) => {
      ui = next
    }

    const { isActionRequired = false } = bookingRequest || {}

    const formatPrice = (price?: number) => numeral(price).format('$0,0')
    if (bid) {
      setUi({
        subtitle: `${formatPrice(bid.sellerPrice)} bid ${bid.status}`,
        color: isBidRejected ? 'warning' : 'mutedText',
        icon: null,
      })
    } else {
      setUi({
        subtitle: `Missing bid`,
        color: isPastEvent ? 'mutedText' : 'error',
        icon: null,
      })
    }

    const hasArtistDeclined = bookingRequest?.declines?.some(
      (decline) => decline.artist.id == artist.id
    )

    if (bid?.status === BidStatus.ACCEPTED) {
      setUi({
        subtitle: `${formatPrice(bid.sellerPrice)} bid accepted!`,
        color: 'success',
        icon: null,
      })
    } else if (
      bid?.status === BidStatus.PENDING &&
      bookingRequest?.status === BookingRequestStatus.PENDING
    ) {
      setUi({
        subtitle: `${formatPrice(bid.sellerPrice)} bid sent${
          !isPastEvent ? ', awaiting response' : ''
        }`,
        color: 'mutedText',
        icon: null,
      })
    }
    if (hasArtistDeclined) {
      setUi({
        subtitle: `Artist declined to bid.`,
        color: 'mutedText',
        icon: null,
      })
    }

    myArtists.push({
      name: artist.name,
      imageUrl: Cloudinary(artist.profileImage, { width: 250 }),
      id: artist.id,
      isBidRejected,
      submittedBidPrice: submittedBidPrice
        ? numeral(submittedBidPrice).format('$0,0')
        : undefined,
      isActionRequired,
      ...ui,
    })
  }

  const statusDescription = getStatusDescription({
    bookingRequest: bookingRequest || null,
  })

  return {
    myArtists,
    onPressBookingRequest,
    colors,
    myArtistsWhoMaybeCouldBid: bookingRequest?.artists,
    myArtistsWhoCanBid: bookingRequest?.myArtistsThatCanBid,
    maybeMyAcceptedBid: bookingRequest?.myAcceptedBid,
    didSomeonElsesBidGetChosen,
    isCancelled: bookingRequest?.status === BookingRequestStatus.CANCELLED,

    isPastEvent,
    statusDescription,
  }
}

type OrNever<T> = Partial<Record<keyof T, never>>

type LoadingProps<T> =
  | ({ loading: true } & OrNever<T>)
  | ({ loading: false } & T)

const MyArtist = React.memo(function MyArtist({
  name,
  imageUrl,
  recommendedPrice,
  loading,
  colors,
  isBidRejected,
  submittedBidPrice,
  index,
  subtitle,
  color,
  icon = null,
}: LoadingProps<MyArtistProps & { colors: HighlightedColors }> & {
  index: number
}) {
  let suffix: React.ReactNode
  if (isBidRejected) {
    suffix = (
      <Text sx={{ color: colors?.mutedText }}>
        {submittedBidPrice} Bid rejected
      </Text>
    )
  } else if (submittedBidPrice) {
    suffix = (
      <Text sx={{ color: colors?.mutedText }}>
        <Ionicons size={12} name="send" /> {submittedBidPrice} bid sent.
        Awaiting response.
      </Text>
    )
  } else if (recommendedPrice) {
    // suffix = (
    //   <Text sx={{ color: colors?.mutedText }}>
    //     Recommended Bid:{' '}
    //     <Text sx={{ color: colors?.highlight }}>{recommendedPrice}</Text>
    //   </Text>
    // )
  }
  return (
    <View
      sx={{
        // mt: index ? 2 : 0,
        // py: 1,
        pt: 3,
        mt: index ? 3 : 0,
        borderTopWidth: '1',
        borderColor: 'border',
      }}
    >
      <View
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          px: 3,
        }}
      >
        <Skeleton show={loading} radius={'round'}>
          <Avatar size="small" uri={imageUrl} />
        </Skeleton>
        <View sx={{ flex: 1, ml: 3 }}>
          <Skeleton show={loading}>
            <Text
              sx={{ color: colors?.text }}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {loading ? 'Loading...' : name}
            </Text>
          </Skeleton>
          {!!subtitle && <Text sx={{ color }}>{subtitle}</Text>}
          {/* {suffix && <View sx={{ mt: 1 }}>{suffix}</View>} */}
        </View>
      </View>
    </View>
  )
})

export const SellerBookingRequestsListItem = React.memo(
  function SellerBookingRequestsListItem(props: Props) {
    const { venueName, loading, startTime, id } = props

    const {
      myArtists,
      onPressBookingRequest,
      colors,
      isPastEvent,
      statusDescription,
    } = useItem(props)

    const containerWidth = useContainerWidth()

    const breakpoints = useBreakpoints()

    let width = '100%'
    if (containerWidth >= breakpoints[1]) {
      width = '50%'
    }
    if (containerWidth >= breakpoints[2]) {
      width = '33.33%'
    }
    if (containerWidth >= breakpoints[3]) {
      // width = '25%'
    }

    let statusBadge: React.ReactNode = null

    if (statusDescription?.text) {
      const { variant, text, filling, capitalize } = statusDescription
      statusBadge = (
        <Badge
          variant={variant}
          filling={filling}
          textSx={
            capitalize
              ? {
                  textTransform: 'capitalize',
                }
              : undefined
          }
        >
          {text}
        </Badge>
      )
    }

    const pastEventBadge = isPastEvent && (
      <Badge sx={{ ml: 2, mt: 3 }} variant="muted3" filling="filled">
        Past Event
      </Badge>
    )

    return (
      <Press
        sx={{ display: containerWidth ? 'flex' : 'none', width }}
        onPress={onPressBookingRequest}
      >
        {!!id && <Target name={`booking-request-${id}`} />}
        <View sx={{ p: 3, pb: 0 }}>
          <HoverPulse scaleTo={0.96}>
            <Card sx={{ bg: colors.bg, borderColor: colors.border }}>
              <Card.Content>
                <View sx={{ flexDirection: 'row', alignItems: 'center' }}>
                  <View sx={{ flexGrow: 1 }}>
                    <Skeleton show={!venueName}>
                      <Text sx={{ fontWeight: 'bold', color: colors.text }}>
                        {venueName || 'Loading...'}
                      </Text>
                    </Skeleton>
                    <Text sx={{ color: colors.mutedText }}>{startTime}</Text>
                  </View>
                </View>
                <View sx={{ flexDirection: 'row' }}>
                  {!!statusBadge && <View sx={{ mt: 3 }}>{statusBadge}</View>}
                  {pastEventBadge}
                </View>
              </Card.Content>
              <>
                <Spacer height={4} />
                <View>
                  {!loading &&
                    myArtists.map((artist, index) => (
                      <MyArtist
                        colors={colors}
                        index={index}
                        loading={false}
                        {...artist}
                        key={artist.id}
                      />
                    ))}
                  {loading && <MyArtist index={0} loading />}
                </View>
                <Spacer height={3} />
              </>
            </Card>
          </HoverPulse>
        </View>
      </Press>
    )
  }
)
