import BookingsBigCalendar from '@beatgig/ui/bookings-big-calendar'
import BookingsCalendarLayout from '@beatgig/ui/bookings-calendar-layout'
import React, { useCallback } from 'react'
import { useCalendarQuery } from './hooks/calendar-queries'
import { View } from 'dripsy'
import BuyerCalendarSidebar from '@beatgig/ui/buyer-calendar-sidebar'
import { useCalendarActions } from './hooks/actions'
import { DateTime } from 'luxon'
import { useBigCalendarActions } from './hooks/big-calendar-actions'
import Button from '@beatgig/components/button'
import { UserRole } from '@beatgig/api-services'
import { WebCalendarModalStack } from '@beatgig/ui/booking-request-modal-route'
import { SellerCalendarSidebar } from './seller-sidebar'
import { useSelectedDate } from '../small-calendar-screen/use-selected-date'

type Props = {
  userId: string
  userName: string
  userRole: UserRole
}

export function CalendarScreen({ userId, userName, userRole }: Props) {
  const { selectedDate, setSelectedDate } = useSelectedDate()
  const [
    {
      bookings,
      bookingRequests,
      extenalBookings,
      actionsRequiredBadgeCount,
      selectedVenues,
      bookingRequestId,
      revalidate,
      // isValidating,
      isPullingToRefresh,
      onPullToRefresh,
      myArtistIds,
      externalCalendarEvents,
    },
  ] = useCalendarQuery({ userId, userRole, selectedDate })

  const actions = useCalendarActions({
    userId,
    userName,
    userRole,
    onRevalidate: revalidate,
    setSelectedDate,
  })

  const { calendarRef, datesSet } = useBigCalendarActions({
    selectedDate,
    setSelectedDate,
  })

  const calendarChild = useCallback(
    ({
      responsiveCalendarHeight,
    }: {
      responsiveCalendarHeight: number | string
    }) => (
      <WebCalendarModalStack
        userRole={userRole}
        onDidClose={revalidate}
        screen={
          <>
            <BookingsBigCalendar
              containerSx={{ mx: 3, mt: 3, flex: 1 }}
              calendarRef={calendarRef}
              height={responsiveCalendarHeight}
              bookings={bookings.withFakeDates || null}
              externalBookings={extenalBookings.withFakeDates || null}
              onPressBooking={actions.onPressBooking}
              dateClick={actions.onPressBigCalendarDate}
              onPressExternalBooking={actions.onPressExternalBooking}
              bookingRequests={bookingRequests.withFakeDates || null}
              onPressBookingRequest={actions.onPressBookingRequest}
              externalCalendarEvents={externalCalendarEvents || null}
              datesSet={datesSet}
              showNonCurrentDates={true}
              {...(userRole == UserRole.SELLER
                ? {
                    userRole,
                    artistIds: myArtistIds,
                  }
                : {
                    userRole,
                  })}
              initialDate={DateTime.fromObject(selectedDate).toISO()}
            />
            {userRole === UserRole.BUYER && (
              <View
                sx={{ position: 'absolute', bottom: 0, right: 0, m: 3 }}
                pointerEvents="box-none"
              >
                <Button
                  onPress={actions.onPressCreateExternalBooking}
                  iconLeft={{ color: 'text', name: 'ios-add' }}
                  sx={{ mb: 4 }}
                  variant="secondary"
                ></Button>
              </View>
            )}
          </>
        }
      />
    ),
    [
      selectedDate,
      datesSet,
      calendarRef,
      bookings.withFakeDates,
      extenalBookings.withFakeDates,
      actions.onPressBooking,
      actions.onPressExternalBooking,
      actions.onPressCreateExternalBooking,
      actions.onPressBookingRequest,
      bookingRequests.withFakeDates,
      revalidate,
      actions.onPressBigCalendarDate,
      userRole,
      myArtistIds,
      externalCalendarEvents,
      // recommendedBookings.withFakeDates,
      // actions.onPressRecommendedBooking,
    ]
  )

  return (
    <BookingsCalendarLayout>
      <BookingsCalendarLayout.Calendar>
        {calendarChild}
      </BookingsCalendarLayout.Calendar>
      <BookingsCalendarLayout.Sidebar>
        {userRole === UserRole.BUYER && (
          <BuyerCalendarSidebar
            selectedVenues={selectedVenues}
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
            highlightedBookingRequestId={bookingRequestId}
            onRefresh={onPullToRefresh}
            isLoading={isPullingToRefresh}
            userId={userId}
            {...actions}
            actionsRequiredBadgeCount={actionsRequiredBadgeCount}
            bookings={bookings.data || null}
            bookingRequests={bookingRequests.data || null}
            // recommendedBookings={recommendedBookings.data || null}
          />
        )}
        {userRole === UserRole.SELLER && (
          <SellerCalendarSidebar
            userId={userId}
            userRole={userRole}
            bookingRequestId={bookingRequestId}
          />
        )}
      </BookingsCalendarLayout.Sidebar>
    </BookingsCalendarLayout>
  )
}
