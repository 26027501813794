import { BookingRequestAlgolia } from '@beatgig/api-services/booking-request'
import { useCallback } from 'react'
import { useNavigation } from '@react-navigation/native'
import { CalendarScreenNavigation } from '@beatgig/navigation/stacks/calendar-stack/types'
import {
  DisplayBooking,
  DisplayRecommendedBooking,
  ExternalBookingOut,
} from '@beatgig/api-services'
import useActionSheet from '@beatgig/hooks/use-action-sheet'
import { DateTime } from 'luxon'
import isToday from 'date-fns/isToday'
import isPast from 'date-fns/isPast'
import useMyAccount from '@beatgig/auth-hooks/use-my-account'
import { User } from '@beatgig/api/user'
import useNavigateToBooking from '@beatgig/navigation/hooks/use-navigate-to-booking'
import { useOpenModal } from '@beatgig/modals/create-modal-stack/use-open-modal'
import { WebCalendarModalStackParams } from '@beatgig/ui/booking-request-modal-route/types'
import { UserRole } from '@beatgig/api-services'
import { useSelectedDate } from '@beatgig/screens/small-calendar-screen/use-selected-date'

type Props = {
  userId: string
  userName: string
  userRole: UserRole
  onRevalidate?: () => void
  setSelectedDate?: ReturnType<typeof useSelectedDate>['setSelectedDate']
}

export function useCalendarActions({
  userId,
  userName,
  userRole,
  onRevalidate,
  setSelectedDate,
}: Props) {
  const { navigate } = useNavigation<CalendarScreenNavigation>()
  const { openModal } = useOpenModal<WebCalendarModalStackParams>()

  const { showActionSheetWithOptions } = useActionSheet()

  const { navigate: navigateToBooking } = useNavigateToBooking()

  const { data: me } = useMyAccount()
  const isAdmin = User.isAdmin(me)

  const onPressOpenActionRequired = useCallback(() => {
    openModal('BookingActionsRequired')
    onRevalidate?.()
  }, [openModal, onRevalidate])

  const onPressCreateExternalBooking = useCallback(() => {
    navigate('CreateExternalBooking', {
      adminOnBehalfOf: userId,
    })
  }, [navigate, userId])

  const onPressExternalBooking = useCallback(
    ({ id }: Pick<ExternalBookingOut, 'id'>) => {
      navigate('EditExternalBooking', {
        externalBookingId: id,
      })
    },
    [navigate]
  )

  const onPressCreateVenue = useCallback(() => {
    navigate('CreateVenue')
  }, [navigate])

  const onPressCreateBookingRequest = useCallback(
    ({
      startDay,
    }: { startDay?: { day: number; month: number; year: number } } = {}) => {
      navigate('CreateBookingRequest', {
        startDay: startDay?.day,
        startYear: startDay?.year,
        startMonth: startDay?.month,
        userId,
      })
    },
    [navigate, userId]
  )

  // TODO add logic that shows this day's events if they exist
  const onPressCalendarDate = useCallback(
    ({ date }: { date: Date }) => {
      if (isPast(new Date(date)) && !isToday(new Date(date))) {
        return
      }

      const { year, month, day } = DateTime.fromJSDate(date)

      setSelectedDate?.({ year, month, day })

      onPressCreateBookingRequest({
        startDay: {
          day,

          month,
          year,
        },
      })
    },
    [onPressCreateBookingRequest, setSelectedDate]
  )

  const onPressBigCalendarDate = useCallback(
    ({
      date,
      hasNoEventsOnThisDate,
    }: {
      date: Date
      hasNoEventsOnThisDate?: boolean
    }) => {
      // if (isPast(new Date(date)) && !isToday(new Date(date))) {
      //   // TODO allow for past dates
      //   return
      // }

      const { year, month, day } = DateTime.fromJSDate(date)
      // setSelectedDate({ year, month, day })

      if (hasNoEventsOnThisDate) {
        return onPressCalendarDate({ date })
      }

      openModal('EventsForDay', {
        params: {
          month: `${month}`,
          year: `${year}`,
          day: `${day}`,
        },
      })
    },
    [openModal, onPressCalendarDate]
  )

  const onPressBookingRequest = useCallback(
    ({ id }: Pick<BookingRequestAlgolia, 'id'>) => {
      const screen: Parameters<typeof openModal>[0] =
        userRole === UserRole.SELLER
          ? 'BookingRequestDetailSeller'
          : 'BookingRequestDetail'

      openModal(screen, {
        params: {
          bookingRequestId: id,
        },
        webOverrides: {
          // replace the current route if there is already a booking request in sight
          replace: (props) => !!props.query.bookingRequestId,
        },
      })
    },
    [openModal, userRole]
  )

  const onPressRecommendedBooking = useCallback(
    (bookingRecommendation: DisplayRecommendedBooking) => {
      if (!bookingRecommendation?.venue_location?.timezone) return

      const { venue_location, venue_name, id, can_recommend_artists } =
        bookingRecommendation
      const editRecommendedBooking = () =>
        navigate('EditRecommendedBooking', {
          venueLocation: venue_location,
          venueName: venue_name,
          recommendedBookingId: id,
        })
      if (can_recommend_artists && isAdmin) {
        const options = [
            'Add/update artist recommendations',
            'Edit recommended booking',
            'Go Back',
          ],
          cancelButtonIndex = 2
        showActionSheetWithOptions(
          {
            // title:
            //   'Do you want to edit this recommended booking, or set artist recommendations?',
            options,
            cancelButtonIndex,
            destructiveButtonIndex: cancelButtonIndex,
          },
          (index) => {
            if (index === 0) {
              navigate('AddArtistsToRecommendedBooking', {
                recommendedBookingId: id,
                onBehalfOfBuyerUserId: userId,
                onBehalfOfBuyerUsername: userName,
              })
            } else if (index === 1) {
              editRecommendedBooking()
            }
          }
        )
      } else {
        editRecommendedBooking()
      }
    },
    [isAdmin, navigate, showActionSheetWithOptions, userId, userName]
  )
  const onPressBooking = useCallback(
    (booking: Pick<DisplayBooking, 'id'>) => {
      navigate('BookingPreview', {
        bookingId: booking.id,
      })
    },
    [navigate]
  )

  const onNavigateToBooking = useCallback(
    (booking: Pick<DisplayBooking, 'id'>) => {
      navigateToBooking({
        id: booking.id,
      })
    },
    [navigateToBooking]
  )

  const onMonthChange = useCallback(
    ({
      day,
      month,
      year,
    }: Parameters<NonNullable<typeof setSelectedDate>>[0]) => {
      setSelectedDate?.({
        day,
        month,
        year,
      })
    },
    [setSelectedDate]
  )

  return {
    onPressCreateExternalBooking,
    onPressExternalBooking,
    onPressCreateVenue,
    // onPressCreateRecommendedBooking,
    onPressRecommendedBooking,
    onPressBooking,
    onPressCalendarDate,
    onNavigateToBooking,
    onPressCreateBookingRequest,
    onPressBookingRequest,
    onPressOpenActionRequired,
    onPressBigCalendarDate,
    onMonthChange,
  }
}
