import { AdminAlgoliaIndex, AlgoliaIndex } from '@beatgig/api-services/algolia'

type Callback = () => void

const makeId = (): string => {
  // https://stackoverflow.com/posts/59528404/revisions
  const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  let autoId = ''

  for (let i = 0; i < 20; i++) {
    autoId += CHARS.charAt(Math.floor(Math.random() * CHARS.length))
  }
  return autoId
}

type Index = AlgoliaIndex | AdminAlgoliaIndex | (string & {})

export class AlgoliaRevalidator {
  private static listeners: Partial<
    {
      [index in Index]-?: { [id: string]: Callback }
    }
  > = {}
  static addListener(index: Index, callback: Callback) {
    const id = makeId()
    AlgoliaRevalidator.listeners[index] = {
      ...AlgoliaRevalidator.listeners[index],
      [id]: callback,
    }

    const unsubscribe = () => {
      AlgoliaRevalidator.listeners[index] = {
        ...AlgoliaRevalidator.listeners[index],
      }
      if (AlgoliaRevalidator.listeners[index]?.[id]) {
        delete AlgoliaRevalidator.listeners[index]?.[id]
      }
    }

    return unsubscribe
  }
  static async revalidate(...indices: Index[]) {
    indices.forEach((index: Index) => {
      if (index) {
        const listeners = AlgoliaRevalidator.listeners[index] || {}
        Object.keys(listeners).forEach((id: string) => {
          const listener = listeners[id]
          listener?.()
        })
      }
    })
  }
}
