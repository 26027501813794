import HoverTooltip from '@beatgig/components/hover-tooltip'
import React, { useState, useMemo } from 'react'
import { Text, View, Pressable } from 'dripsy'
import Ionicons from '@beatgig/design/components/ionicons'
import useTheme from '@beatgig/theme/use-theme'
import { DripsyTheme } from '@beatgig/theme'
import Entity from '@beatgig/design/components/entity'
import EntityField from '@beatgig/design/components/entity/entity-field'
import EntityFooter from '@beatgig/design/components/entity/entity-footer'
import { MotiView } from 'moti'
import Link from '@beatgig/design/components/link'

type Props = {
  label: React.ReactNode | string
  tooltipProps?: Omit<
    React.ComponentProps<typeof HoverTooltip>,
    'children' | 'text'
  >
  hideCount?: boolean
  filter: React.ReactNode
  selectedCount: number
  totalCount: number
  color?: (string & {}) | keyof DripsyTheme['colors']
  description?: string
  mode?: 'popover' | 'modal'
  onClearAll?: () => void
  onSelectAll?: () => void
  initialExpanded?: boolean
}

function useDropdownState(initial = true) {
  const [isExpanded, setIsExpanded] = useState(initial)

  const handlers = useMemo(
    () => ({
      expand: () => setIsExpanded(false),
      collapse: () => setIsExpanded(true),
      toggle: () => setIsExpanded((expanded) => !expanded),
    }),
    []
  )

  return [isExpanded, handlers] as const
}

export default function FilterPopover({
  label,
  tooltipProps = {},
  hideCount,
  selectedCount,
  filter,
  totalCount,
  color: _color,
  description,
  mode = 'modal',
  onSelectAll,
  onClearAll,
  initialExpanded,
}: Props) {
  const { colors } = useTheme()

  const [isExpanded, { toggle }] = useDropdownState(initialExpanded)

  let color = colors.text
  if (_color) {
    color = colors[_color] ?? _color
  }

  const title = (
    <>
      {label}
      {!hideCount &&
        ` (${selectedCount === totalCount ? 'Any' : selectedCount || 'Any'})`}
    </>
  )

  if (mode === 'modal') {
    return (
      <Entity
        footer={
          isExpanded && (
            <>
              <EntityFooter>{filter}</EntityFooter>
              {!!(onClearAll || onSelectAll) && (
                <EntityFooter>
                  <View sx={{ flexDirection: 'row' }}>
                    {!!onSelectAll && (
                      <Link sx={{ mr: 3 }} color block onPress={onSelectAll}>
                        Select All
                      </Link>
                    )}
                    {!!onClearAll && (
                      <Link color block onPress={onClearAll}>
                        Clear All
                      </Link>
                    )}
                  </View>
                </EntityFooter>
              )}
            </>
          )
        }
        alignFirstRow="center"
        actions={
          <MotiView
            animate={{
              rotateX: isExpanded ? '180deg' : '0deg',
            }}
          >
            <Pressable onPress={toggle} hitSlop={20}>
              <Ionicons name="chevron-down" color={color} />
            </Pressable>
          </MotiView>
        }
        sx={{ mb: 3, bg: 'muted' }}
      >
        <EntityField title={title} />
      </Entity>
    )
  }

  return (
    <HoverTooltip
      placement="bottom"
      anchor="right"
      borderColor="text"
      {...tooltipProps}
      width={220}
      text={
        <View sx={{ zIndex: 2 }}>
          {!!description && (
            <Text sx={{ color: 'mutedText', mb: 3, px: 1 }}>{description}</Text>
          )}
          {filter}
        </View>
      }
    >
      <View
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          zIndex: -1,
        }}
      >
        <Text
          sx={{
            mr: 2,
            borderBottomWidth: 1,
            borderColor: color,
            textTransform: 'capitalize',
            color,
          }}
        >
          {title}
        </Text>
        <Ionicons name="chevron-down" color={color} />
      </View>
    </HoverTooltip>
  )
}
