import React from 'react'
import type { BigCalendarProps } from './types'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { Platform } from 'react-native'

import interactionPlugin from '@fullcalendar/interaction'
import luxonPlugin from '@fullcalendar/luxon'
import useTheme from '@beatgig/theme/use-theme'
import useGetColors from '@beatgig/design/hooks/use-variant-colors'

import '@fullcalendar/common/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'
import { useFont } from '@beatgig/theme/use-font'

const plugins = [luxonPlugin, dayGridPlugin, interactionPlugin]

const eventDidMount = async ({ el, event: { title } }) => {
  if (Platform.OS === 'web') {
    const tippy = (await import('tippy.js')).default
    tippy(el, {
      content: title,
      theme: 'gradient',
      interactive: true,
    })
  }
}

const defaultTimedEventDuration = {
  milliseconds: 1,
  days: 0,
  months: 0,
  years: 0,
}

export default function BigCalendar({
  calendarRef,
  // @ts-expect-error avoid spreads
  userRole,
  ...props
}: BigCalendarProps) {
  const { colors, space, borderWidths, radii, fontSizes } = useTheme()

  const font = useFont()

  const { color, bg, borderColor } = useGetColors({
    filling: 'filled',
    variant: 'primary',
  })

  return (
    <>
      <FullCalendar
        ref={calendarRef}
        defaultTimedEventDuration={defaultTimedEventDuration}
        eventDidMount={eventDidMount}
        // dayMaxEvents={2}
        eventColor={bg}
        eventTextColor={color}
        eventBorderColor={borderColor}
        eventDisplay="block"
        initialView="dayGridMonth"
        showNonCurrentDates={true}
        fixedWeekCount={false}
        headerToolbar={{
          right: 'today prev,next',
        }}
        {...props}
        plugins={plugins}
      />
      <style jsx global>{`
        .fc-daygrid-day-number,
        .fc-col-header-cell-cushion,
        .fc-toolbar-title {
          color: ${colors?.text};
        }
        // maybe fix events not being squares? no idea...
        .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
          // min-height: 7rem;
        }
        .fc .fc-daygrid-day-bottom {
          font-size: 1rem;
          color: ${colors.text};
        }
        .fc-event-time {
          // fix event time getting covered
          overflow: visible !important;
          margin-right: 1px !important;
        }
        // custom, makes the dot events have legible text
        .fc-daygrid-dot-event {
          color: ${colors?.text};
        }
        :root {
          --fc-small-font-size: ${fontSizes?.[2] - 1}px;
          --fc-page-bg-color: transparent;
          --fc-neutral-bg-color: transparent;
          --fc-border-color: ${colors?.border};

          --fc-button-text-color: ${colors?.text};
          --fc-button-bg-color: ${colors.muted};
          --fc-button-border-color: transparent;
          --fc-button-hover-bg-color: ${colors?.muted2};
          --fc-button-hover-border-color: ${colors?.muted3};
          --fc-button-active-bg-color: ${colors?.muted2};
          --fc-button-active-border-color: ${colors?.muted4};

          --fc-event-bg-color: #3788d8;
          --fc-event-border-color: #3788d8;
          --fc-event-text-color: ${colors.text};
          --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);

          --fc-event-resizer-thickness: ${space?.[2]}px;
          --fc-event-resizer-dot-total-width: ${space?.[2]}px;
          --fc-event-resizer-dot-border-width: 1px;

          --fc-non-business-color: ${colors?.muted4};
          --fc-bg-event-color: ${colors?.callout};
          --fc-bg-event-opacity: 0.3;
          --fc-highlight-color: ${colors?.accent};
          --fc-today-bg-color: ${colors?.muted2};
          --fc-now-indicator-color: red;
        }

        .fc .fc-event-time {
          font-family: ${font.body[400]};
          font-weight: initial;
          font-size: ${fontSizes[1]}px;
        }

        .fc .fc-event-title {
          font-family: ${font.body[500]};
        }

        .fc .fc-scrollgrid-section > td {
          border-top-right-radius: ${radii?.[3]}px;
        }
        .fc-theme-standard .fc-scrollgrid {
          border-radius: ${radii?.[3]}px;
        }
      `}</style>
    </>
  )
}
