import React, { useState } from 'react'
import { View, Text, H3, Row } from 'dripsy'
import WebModal from '@beatgig/components/web-modal'
import { ArtistCoverImage } from '@beatgig/ui/artist-cover-image'
import Cloudinary from '@beatgig/helpers/cloudinary'
import Spacer from '@beatgig/design/components/spacer'
import Card from '@beatgig/components/card'
import A from '@beatgig/components/a'
import { isToday, isTomorrow, isThisWeek, isAfter } from 'date-fns'
import Badge from '@beatgig/design/components/badge'
import ShowMoreText from '@beatgig/design/components/show-more-text'
import LoadingScreen from '@beatgig/components/loading-screen'
import locationToString from '@beatgig/helpers/location-to-string'
import { getDisplayStartTime } from '@beatgig/helpers/display-booking-start-time'
import { Platform } from 'react-native'
import Link from '@beatgig/design/components/link'
import { APP_URL } from '@beatgig/constants'
import { makeArtistSlug } from '@beatgig/navigation/routes/artist-routes'
import { useRouting } from 'expo-next-react-navigation'
import OpenBookingExternallyButton from '@beatgig/ui/booking/open-booking-externally-button'
import EditBookingPublicDescription from './edit-booking-public-description'
import HyperLink from '../../components/hyperlink'
import useTheme from '@beatgig/theme/use-theme'
import Color from 'color'
import { useBookingPreviewQuery } from '@beatgig/gql'
import { DateTime } from 'luxon'
import isSameDay from 'date-fns/isSameDay'

type Props = {
  onClose: () => void
  /**
   * Set to `false` when embedding on a website, where there is no header.
   *
   * Default: `true`
   */
  safeHeaderTopMargin?: boolean
  bookingId: string
} & Partial<React.ComponentProps<typeof WebModal>>

export default function BookingPreviewModal(props: Props) {
  const { pathname } = useRouting()
  const {
    onClose,
    safeHeaderTopMargin = !pathname.startsWith('/dashboard'),

    bookingId,
    ...webModalProps
  } = props

  const canIEditBooking = true

  const [{ data, error }] = useBookingPreviewQuery({
    variables: {
      id: bookingId,
    },
  })

  const booking = data?.booking

  const [isEditingBooking, setIsEditingBooking] = useState(false)

  const theme = useTheme()

  if (!booking) {
    return (
      <WebModal {...webModalProps} header onRequestClose={onClose}>
        <LoadingScreen error={error}>Loading booking...</LoadingScreen>
      </WebModal>
    )
  }

  const startDate = getDisplayStartTime({
    startTime: booking.startTime,
    timezone: booking.location.timezone,
  })
  const endDate = getDisplayStartTime({
    startTime: DateTime.fromJSDate(new Date(booking.startTime))
      .plus({
        minutes: booking.performanceLengthMins,
      })
      .toISO(),
    timezone: booking.location.timezone,
  })

  const endsTheNextDay = !isSameDay(
    endDate.displayDateTime,
    startDate.displayDateTime
  )

  const formattedStartDate = startDate.timeWithCustomFormat('MMM do, yyy')
  // const date = new Date(booking.event_date ?? booking.start_time)
  const time = new Date(booking?.startTime as string)
  const date = time

  let badge = ''

  if (isToday(date)) {
    badge = 'Today'
  } else if (isTomorrow(date)) {
    badge = 'Tomorrow'
  } else if (isThisWeek(date) && isAfter(date, new Date())) {
    // this week, and it hasn't happened yet
    badge = 'This week'
  } else if (isAfter(new Date(), date)) {
    // already happened
  }

  const onPressEditDescription = () => {
    setIsEditingBooking((state) => !state)
  }

  const bio = booking.artist.bio
  const artistName = booking.artist.name
  const coverImage = booking.artist.coverImage
  const artistSlug = booking.artist.slug
  const subgenres = booking.artist.subgenres

  const getAccentTextColor = (): keyof typeof theme.colors => {
    if (
      Color(theme.colors.background).contrast(Color(theme.colors.primary)) >= 4
    ) {
      return 'primary'
    }
    return 'text'
  }

  const accentTextColor = getAccentTextColor()

  return (
    <WebModal
      {...webModalProps}
      padded={false}
      onRequestClose={onClose}
      header
      safeHeaderTopMargin={safeHeaderTopMargin}
      title={artistName}
      button={<OpenBookingExternallyButton bookingId={booking.id} />}
    >
      {!!coverImage && (
        <View sx={{ height: 250, overflow: 'hidden' }}>
          <ArtistCoverImage.Image
            source={{
              uri: Cloudinary({
                public_cloudinary_id: coverImage.public_cloudinary_id,
              }),
            }}
            verticalAlign={coverImage.verticalAlign}
          />
          <ArtistCoverImage.Gradient />
        </View>
      )}
      <View sx={{ p: 3 }}>
        <H3 sx={{ mb: 2 }}>{artistName}</H3>
        <Row sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Text>
            <Text sx={{ fontWeight: 'bold', color: accentTextColor }}>
              {formattedStartDate}
            </Text>{' '}
            <Text>
              {startDate.timeWithCustomFormat('h:mm aaa')} -{' '}
              {endDate.timeWithCustomFormat('h:mm aaa')}
              {endsTheNextDay && ` (next day)`}
            </Text>
          </Text>
          {!!badge && <Badge filling="outlined">{badge}!</Badge>}
        </Row>
        <Spacer height={3} />
        <Text>
          {!!booking.venueName && (
            <Text sx={{ fontWeight: 'bold' }}>{booking.venueName} </Text>
          )}
          <Text sx={{ color: 'mutedText' }}>
            {locationToString(booking.location)}
          </Text>
        </Text>
        {!pathname.startsWith('/venues') && Platform.OS === 'web' && (
          <View sx={{ mt: 3 }}>
            {!!booking.venueSlug && (
              <a
                href={`https://${APP_URL}/venues/${booking.venueSlug}`}
                style={{
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
                target="_blank"
                rel="noreferrer"
              >
                <Link
                  textSx={{ fontSize: 2 }}
                  disabled={false}
                  icon="hover"
                  color={accentTextColor}
                >
                  Venue Profile
                </Link>
              </a>
            )}
          </View>
        )}
        {!!booking?.publicEventDescription && (
          <View
            sx={{
              mb: 3,
              mt: 3,
              pt: 3,
              borderTopWidth: '1',
              borderColor: 'border',
            }}
          >
            <Row sx={{ alignItems: 'center', mb: 2 }}>
              <View sx={{ flex: 1 }}>
                <Text sx={{ fontSize: 6, fontWeight: '500' }}>
                  Event Details
                </Text>
              </View>
              {canIEditBooking && (
                <Link
                  onPress={onPressEditDescription}
                  block
                  color={accentTextColor}
                >
                  {isEditingBooking ? 'Done' : 'Edit'}
                </Link>
              )}
            </Row>
            {!isEditingBooking && (
              <Card>
                {!!booking.publicEventDescription && (
                  <HyperLink text={booking.publicEventDescription} />
                )}
                {!booking.publicEventDescription && canIEditBooking && (
                  <Text>
                    There is currently no description for this event.
                    {` (This message is not publicly visible.)`}
                  </Text>
                )}
              </Card>
            )}
            <View sx={{ display: isEditingBooking ? 'flex' : 'none' }}>
              <EditBookingPublicDescription
                bookingId={booking.id}
                publicEventDescription={booking.publicEventDescription ?? ''}
              />
            </View>
          </View>
        )}
        {!!artistSlug && !!(bio || subgenres) && (
          <View
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: 2,
              mt: 2,
              pt: 3,
              borderTopWidth: '1',
              borderColor: 'border',
            }}
          >
            <Text sx={{ fontSize: 6, fontWeight: '500' }}>Artist Bio</Text>

            <View>
              <A
                href={`https://${APP_URL}/${makeArtistSlug(artistSlug)}`}
                style={
                  {
                    // textDecoration: 'none',
                    // cursor: 'pointer',
                  }
                }
                ref={null}
                target="_blank"
                // rel="noreferrer"
              >
                <Link disabled={false} icon color={accentTextColor} block>
                  Full Artist Profile
                </Link>
              </A>
            </View>
          </View>
        )}
        {!!(bio || subgenres) && (
          <Card>
            {!!bio && <ShowMoreText text={bio} />}
            {!!subgenres?.length && (
              <Row sx={{ mt: 3, flexWrap: 'wrap' }}>
                {subgenres?.map((subgenre) => (
                  <Badge
                    variant="default"
                    type="faded"
                    key={subgenre}
                    sx={{ mr: 1, mb: 2 }}
                  >
                    {subgenre}
                  </Badge>
                ))}
              </Row>
            )}
          </Card>
        )}
        <Spacer height={3} />
      </View>
    </WebModal>
  )
}
