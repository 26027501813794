import WebModal from '@beatgig/components/web-modal'
import React from 'react'
import { useNavigation, useRoute } from '@react-navigation/native'
import AdminUpdateRecommendedArtistsForm from './form'
import useAdminUpdateArtistRecommendations from '@beatgig/api-hooks/recommended-booking/use-admin-update-artist-recommendations'
import useServerFormError from '@beatgig/forms/hooks/use-server-form-error'
import { AdminUpdateRecommendedArtistsRoute } from '@beatgig/navigation/stacks/calendar-stack/types'
import useRecommendedBooking from '@beatgig/api-hooks/src/use-recommended-booking'
import LoadingScreen from '@beatgig/components/loading-screen'

export default function AdminUpdateRecommendedArtistsScreen() {
  const { goBack } = useNavigation()
  const {
    params: { recommendedBookingId },
  } = useRoute<AdminUpdateRecommendedArtistsRoute>()

  const { data: recommendedBooking, error, mutate } = useRecommendedBooking(
    {
      recommendedBookingId,
    },
    {
      cachePolicy: 'network-only',
    }
  )

  const { execute, setError } = useAdminUpdateArtistRecommendations()
  const { handleServerFormError } = useServerFormError()

  if (!recommendedBooking) {
    return (
      <WebModal onRequestClose={goBack} header>
        <LoadingScreen error={error}>Loading recommendations...</LoadingScreen>
      </WebModal>
    )
  }

  return (
    <AdminUpdateRecommendedArtistsForm
      revalidate={mutate}
      recommendedBooking={recommendedBooking}
      onClose={goBack}
      onSubmit={async (data, formik, scroll) => {
        try {
          const recommendedBooking = await execute({
            recommendedBookingId,
            ...data,
          })
          if (recommendedBooking.id) {
            goBack()
          }
        } catch (error) {
          handleServerFormError({
            formName: 'Update Recommended Artists',
            error,
            setError,
            ...formik,
            ...scroll,
          })
        }
        return
      }}
    />
  )
}
