import useBooleanFilter from '@beatgig/search/filters/boolean/use-boolean-filter'

export default function usePaidSellerFilter() {
  return useBooleanFilter('payout.paid_seller', {
    defaults: {
      off: true,
      on: true,
    },
    titles: {
      off: 'Not Paid Out',
      on: '💸 Paid Out',
      default: '💸 Payouts',
    },
    labels: {
      off: 'Not Paid Out',
      on: '💸 Paid Out',
    },
    variants: {
      off: 'accent',
      on: 'success',
    },
    persistKey: null,
  })
}
