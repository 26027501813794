import Disabled from './assets/btn_google_signin_dark_disabled_web@2x.png'
import Normal from './assets/btn_google_signin_dark_normal_web@2x.png'
import Pressed from './assets/btn_google_signin_dark_pressed_web@2x.png'
import { Image } from 'react-native'
import Press from '@beatgig/components/press'
import { StyleSheet } from 'react-native'

const style = StyleSheet.create({
  image: {
    height: 40,
  },
})

export function SignInWithGoogleButton({ onPress, disabled }) {
  return (
    <Press onPress={onPress} disabled={disabled}>
      {({ pressed, hovered }) => (
        <Image
          source={disabled ? Disabled : pressed ? Pressed : (Normal as any)}
          style={style.image}
          resizeMode="contain"
        />
      )}
    </Press>
  )
}
