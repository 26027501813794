import { useSellersOfArtistQuery } from '@beatgig/gql'
import { useCallback } from 'react'

export default function useSellersOfArtist(
  keyProps: { artistSlug: string } | null
) {
  const [{ data, fetching, error }, revalidate] = useSellersOfArtistQuery({
    variables: {
      slug: keyProps?.artistSlug || '',
    },
    pause: !keyProps?.artistSlug,
  })

  return {
    data: data?.artist,
    error,
    loading: fetching,
    revalidate: useCallback(
      () => revalidate({ requestPolicy: 'network-only' }),
      [revalidate]
    ),
  }
}
