import Analytics from '@beatgig/analytics/typez'
import { useDeleteExternalBookingMutation } from '@beatgig/gql'
import { useCallback } from 'react'

export default function useDeleteExternalBooking() {
  const [{ fetching, error }, execute] = useDeleteExternalBookingMutation()

  return {
    execute: useCallback<typeof execute>(
      (arg) => {
        Analytics.track('Delete External Booking', {
          externalBookingId: arg?.id || 'Missing ID',
        })
        return execute(arg)
      },
      [execute]
    ),
    loading: fetching,
    error,
  }
}
