import { User } from '@beatgig/api/user'
import useMyAccount from '@beatgig/auth-hooks/use-my-account'
import Card from '@beatgig/components/card'
import LoadingScreen from '@beatgig/components/loading-screen'
import { APP_NAME } from '@beatgig/constants'
import { Box } from '@beatgig/design/components/box'
import Spacer from '@beatgig/design/components/spacer'
import { Text } from '@beatgig/design/components/text'
import { Text as DripsyText } from 'dripsy'
import React from 'react'
import { Platform } from 'react-native'
import PublicRoutes from '@beatgig/navigation/routes/public-routes'
import Ionicons from '@beatgig/design/components/ionicons'
import { sellerInviteOnlyText } from './text'
import { TextLink } from 'solito/link'
import { pagesPath } from '@beatgig/features/routing/pathpida/$path'
import { ScrollView } from 'dripsy'
import { RefreshControl } from 'react-native'
import useTheme from '@beatgig/theme/use-theme'
import WebHeaderSpacer from '@beatgig/components/web-header/spacer'
import A from '@beatgig/components/a'

const isAppInviteOnlyForSellers = true

type Props = {
  children: React.ReactElement
  renderUserPendingApproval?: React.ReactElement
}

export function SellersInviteOnlyScreen({
  children,
  renderUserPendingApproval,
}: Props) {
  const user = useMyAccount()
  const { colors } = useTheme()
  if (!isAppInviteOnlyForSellers) {
    return children
  }

  if (!user.data) {
    return <LoadingScreen error={user.error}>Loading user...</LoadingScreen>
  }

  const isUnapprovedSeller =
    User.isSeller(user.data) && User.isPendingApproval(user.data)

  if (isUnapprovedSeller) {
    const render = renderUserPendingApproval || (
      <Box flex={1}>
        <WebHeaderSpacer />
        <ScrollView
          refreshControl={
            <RefreshControl
              refreshing={user.isPullingToRefresh}
              onRefresh={user.pullToRefresh}
              colors={[colors.background]}
              tintColor={colors.text}
              title="Refresh Account"
              titleColor={colors.mutedText}
            />
          }
        >
          <Box
            justifyContent={['flex-start', 'center']}
            alignItems="center"
            p={['$3', '$4']}
          >
            <Card
              sx={{
                width: '100%',
                maxWidth: 500,
                p: ['$3', '$4'],
                borderRadius: 16.01,
              }}
            >
              <Text fontWeight="bold" fontSize="5">
                {`${APP_NAME} is invite-only.`}
              </Text>
              <Spacer y="$3" />
              <Text>{sellerInviteOnlyText}</Text>
              {Platform.OS == 'web' && (
                <>
                  <Spacer y="$3" />
                  <A
                    href={PublicRoutes.marketingPage('artists').web.path}
                    target="_blank"
                    isText={false}
                  >
                    <DripsyText sx={{ color: 'primary' }}>
                      Learn More{' '}
                      <Ionicons name="open-outline" color="primary" size={18} />
                    </DripsyText>
                  </A>
                </>
              )}
            </Card>

            <Spacer y="$3" />

            <Card
              sx={{
                width: '100%',
                maxWidth: 500,
                p: ['$3', '$4'],
                borderRadius: 16.01,
                bg: 'muted',
              }}
            >
              <Text fontWeight="bold" fontSize="$4">
                {`How to increase chances of approval`}
              </Text>
              <Spacer y="$3" />

              <Text>
                To speed up your {`account's review, please`}
                <TextLink href={pagesPath.dashboard.artists.new.$url()}>
                  <Text color="primary">{` build an artist profile `}</Text>
                </TextLink>
                with every field completed.
              </Text>
            </Card>
          </Box>
        </ScrollView>
      </Box>
    )
    return render
  } else {
    return children
  }
}
