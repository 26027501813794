import { useOwnersOfVenueQuery } from '@beatgig/gql'

export default function useOwnersOfVenue({ slug }: { slug: string | null }) {
  const [{ data }] = useOwnersOfVenueQuery({
    variables: {
      slug: slug || '',
    },
    pause: !slug,
  })

  return {
    data: data?.venue,
  }
}
