import type { BookingRequestAlgolia } from '@beatgig/api-services/booking-request'
import {
  DisplayBooking,
  // DisplayRecommendedBooking,
  ExternalBookingOut,
} from '@beatgig/api-services'
import { DateTime } from 'luxon'
import { VenueBookingPublicAlgolia } from '@beatgig/backend-types'

type BaseBooking =
  | DisplayBooking
  | BookingRequestAlgolia
  // | DisplayRecommendedBooking
  | ExternalBookingOut
  | VenueBookingPublicAlgolia

type StartTimeKey = keyof Pick<BaseBooking, 'start_time'>

/**
 * Make a date displayable on a calendar as if you're in the booking's timezone.
 */
export const cheatFormatBookingDateForCalendar = <
  Booking extends {
    venue_location: Pick<BaseBooking['venue_location'], 'timezone'>
  } & Record<StartTimeKey, string | Date>
>(
  booking: Booking
): Booking => {
  const { timezone } = booking.venue_location

  let startTimeReal: Date | undefined
  if (typeof booking.start_time === 'string') {
    startTimeReal = new Date(booking.start_time)
  } else if (typeof booking.start_time === 'number') {
    startTimeReal = new Date(booking.start_time * 1000)
  } else if ((booking.start_time as any) instanceof Date) {
    startTimeReal = booking.start_time
  }
  let start_time = startTimeReal ? DateTime.fromJSDate(startTimeReal) : null
  if (timezone && start_time?.isValid) {
    start_time = start_time
      .setZone(timezone)
      // this is cheating; we're manipulating the real date into a potentially fake one
      // why this extra step?
      // because this calendar has multiple timezones
      // and we want it to display as if it were the time of the place of the event
      // so, in order to cheat, we change the time to ours...
      // ...keeping local time (i.e. time of the event in its location) true
      // ex: if event is 4pm in california, and I'm looking at the calendar from NYC, we change it to 4pm in NYC
      // this way, the calendar shows the time of the event at its destination
      // and bypasses the fact that we can't use the calendar's timeZone prop w/ multiple timezones :/
      .setZone(DateTime.local().zoneName, {
        keepLocalTime: true,
      })
  }
  return {
    ...booking,
    start_time: start_time?.toISO(),
  }
}
