import React, { ReactNode, ComponentProps, useState } from 'react'
import { View, Text, useSx, ActivityIndicator } from 'dripsy'
import { ScrollView, Target } from '@nandorojo/anchor'
import { StyleSheet, Platform, Modal, RefreshControl } from 'react-native'
import Sizer from '@beatgig/components/sizer'
import useResponsive from '@beatgig/hooks/use-responsive'
import Spacer from '@beatgig/design/components/spacer'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import Ionicons from '@beatgig/design/components/ionicons'
import Press from '@beatgig/components/press'
import WebModal from '@beatgig/components/web-modal'
import { MotiView } from 'moti'
import useTheme from '@beatgig/theme/use-theme'
import { SmartSmallCalendarScreenFilters } from '@beatgig/screens/small-calendar-screen/smart-filters'
import { UserRole } from '@beatgig/backend-types'
import useScrollToTop from '@beatgig/hooks/use-scroll-to-top'

type Props = {
  children: ReactNode
  footer: ReactNode
  header: ReactNode
  anchorsRef: ComponentProps<typeof ScrollView>['anchors']
  onPullToRefresh: () => void
  isPullingToRefresh: boolean
}

export function SmallCalendarLayout({
  children,
  footer,
  anchorsRef,
  header,
  isPullingToRefresh,
  onPullToRefresh,
}: Props) {
  const { colors } = useTheme()
  const scrollRef = useScrollToTop()
  //
  return (
    <View sx={{ flex: 1, bg: 'background' }}>
      <Sizer flex={1}>
        {/* <WebHeaderSpacer /> */}
        {!!header && header}
        <ScrollView
          ref={scrollRef as any}
          refreshControl={Platform.select({
            native: (
              <RefreshControl
                onRefresh={onPullToRefresh}
                refreshing={isPullingToRefresh}
                colors={[colors.background]}
                tintColor={colors.text}
                titleColor={colors.text}
              />
            ),
          })}
          anchors={anchorsRef}
        >
          {Platform.OS === 'web' && <Spacer height={3} />}
          <Sizer flexDirection={['column', 'column', 'row']}>{children}</Sizer>
        </ScrollView>
      </Sizer>
      {!!footer && footer}
    </View>
  )
}

SmallCalendarLayout.Header = function SmallCalendarLayoutFilters({
  // children,
  selectedVenueCount,
  loading,
  onRefresh,
  onPressCreateExternalBooking,
  onPressCreateVenue,
  selectedVenues,
  userRole,
}: {
  // children: ReactNode
  selectedVenueCount: number
  loading: boolean
  onRefresh: () => void
  userRole: UserRole
  onPressCreateExternalBooking: () => void
} & React.ComponentProps<typeof SmartSmallCalendarScreenFilters>) {
  const { top } = useSafeAreaInsets()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const sx = useSx()

  const onClose = () => setIsModalOpen(false)

  return (
    <View sx={{ pt: top, mt: 2 }}>
      <View
        sx={{
          flexDirection: 'row',
          mb: 3,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {userRole === UserRole.BUYER ? (
          <Press
            sx={{ px: 3, flexDirection: 'row', alignItems: 'center' }}
            onPress={() => setIsModalOpen(true)}
          >
            <Text sx={{ color: 'primary', textAlign: 'center', mr: 2 }}>
              Venues {selectedVenueCount !== 0 && `(${selectedVenueCount})`}
            </Text>
            <Ionicons
              name="information-circle-outline"
              size={24}
              color="primary"
            />
          </Press>
        ) : (
          <View />
        )}
        <View sx={{ flexDirection: 'row', alignItems: 'flex-start' }}>
          {userRole !== UserRole.SELLER && (
            <Press sx={{ px: 2 }} onPress={onPressCreateExternalBooking}>
              <Ionicons color="text" name="cloud-upload-outline" size={28} />
            </Press>
          )}
          {Platform.OS === 'web' && (
            <Press onPress={onRefresh} disabled={loading}>
              <View sx={{ mr: 3, ml: 2 }}>
                <View style={{ opacity: loading ? 0 : 1 }}>
                  <Ionicons color="text" name="refresh-outline" size={24} />
                </View>
                <MotiView
                  animate={{
                    opacity: loading ? 1 : 0,
                  }}
                  transition={{ type: 'timing', duration: 100 }}
                  style={sx({
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    justifyContent: 'center',
                  })}
                >
                  <ActivityIndicator color="text" />
                  {/* <Loading /> */}
                </MotiView>
              </View>
            </Press>
          )}
        </View>
      </View>
      <Modal
        animationType="slide"
        transparent
        visible={isModalOpen}
        onRequestClose={onClose}
      >
        <WebModal
          headerIcon="close"
          title={
            'Your Venues'
            // selectedVenueCount > 1 ? `Venues (${selectedVenueCount})` : 'Venue'
          }
          button={
            <Press onPress={onClose}>
              <Text
                sx={{ fontWeight: '500', color: 'primary', pr: 2, fontSize: 3 }}
              >
                Done
              </Text>
            </Press>
          }
          headerTopInset={Platform.select({ android: 0 })}
          header
          onRequestClose={onClose}
        >
          {/* {children} */}
          <SmartSmallCalendarScreenFilters
            onPressCreateVenue={() => {
              onClose()
              onPressCreateVenue()
            }}
            selectedVenues={selectedVenues}
          />
        </WebModal>
      </Modal>
    </View>
  )
}

SmallCalendarLayout.Calendar = function SmallCalendarLayoutCalendar({
  children,
}: {
  children: ReactNode
}) {
  return <Sizer {...{ px: 3, flex: [null, null, 1, 0.75] }}>{children}</Sizer>
}

SmallCalendarLayout.List = function SmallCalendarLayoutList({
  children,
}: {
  children: ReactNode
}) {
  const { vh } = useResponsive()
  return (
    <Sizer flex={[null, null, 1]}>
      <Target name="list" />
      <View sx={{ minHeight: vh(80) }}>
        <Spacer height={3} />
        {children}
        <Spacer height={5} />
      </View>
    </Sizer>
  )
}

SmallCalendarLayout.ActionsRequired =
  function SmallCalendarLayoutActionsRequired({ children }) {
    return (
      <Sizer mt={(theme) => [null, null, theme.space[4] * -1]} pb={3}>
        {children}
      </Sizer>
    )
  }

type FooterProps = {
  children: ReactNode
}

SmallCalendarLayout.Footer = function SmallCalendarLayoutFooter({
  children,
}: FooterProps) {
  return (
    <View
      style={StyleSheet.absoluteFillObject}
      pointerEvents="box-none"
      sx={{
        top: 'auto',
        pl: 3,
        pb: Platform.select({
          web: 3,
          default: 0,
        }),
        mb: 2,
        alignItems: Platform.select({
          web: 'flex-start',
          default: 'center',
        }),
      }}
    >
      {children}
    </View>
  )
}
