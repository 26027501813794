import React, { useMemo } from 'react'
import { Location, ServerError } from '@beatgig/api-services'
import { Formik } from 'formik'
import { Text, View } from 'dripsy'
import useRequestCloseForm from '@beatgig/forms/hooks/use-request-close-form'
import WebModal from '@beatgig/components/web-modal'
import Spacer from '@beatgig/design/components/spacer'
import {
  GooglePlaceDetail,
  GooglePlaceData,
} from 'react-native-google-places-autocomplete'
import ErrorNote from '@beatgig/components/ErrorNote'
import locationToString from '@beatgig/helpers/location-to-string'
import useMyAccount from '@beatgig/auth-hooks/use-my-account'
import { User } from '@beatgig/api/user'
import { useCreateVenue } from '@beatgig/features/venue/new/use-create'
import SubmitButton from '@beatgig/forms/fields/submit'
import PickerField from '@beatgig/forms/fields/picker-field'
import FormRow from '@beatgig/forms/components/row'
import GooglePlacesField from '@beatgig/forms/fields/google-places-field'
import * as yup from 'yup'
import AutoCompleteFieldRequired from '@beatgig/forms/fields/auto-complete-field-required'
import { useAccountExecsQuery, VenueType } from '@beatgig/gql'

type Form = NonNullable<
  Parameters<ReturnType<typeof useCreateVenue>['execute']>[0]
>

type FormState = Form & {
  googlePlaceInfo?: {
    data?: GooglePlaceData
    detail?: GooglePlaceDetail
  }
  location?: Location
}

type Props = {
  // onPressIsMissingGoogleAccount: () => void
  goBack: () => void
  onSubmit: (venue: NonNullable<Form>) => Promise<boolean>
  formHeader?: React.ReactElement | null
  error?: ServerError | null
  animated?: boolean
}

const venueTypes = Object.values(VenueType)

export default function CreateVenueForm({
  // onPressIsMissingGoogleAccount,
  goBack,
  onSubmit,
  error,
  formHeader = null,
  animated,
}: Props) {
  // const { replace: editVenue } = useOpenEditVenue()

  const { data: me } = useMyAccount()

  const isAdmin = User.isAdmin(me)

  const { requestClose } = useRequestCloseForm()

  return (
    <Formik<FormState>
      initialValues={{
        placeId: '',
        venueType: VenueType.BAR,
      }}
      onSubmit={async ({ googlePlaceInfo, location, ...venue }) => {
        // omit googlePlaceInfo. We want it in local state, but not sent to
        return onSubmit(venue)
      }}
      validationSchema={
        isAdmin
          ? yup.object<Pick<Form, 'accountExecId'>>({
              accountExecId: yup
                .string()
                .typeError()
                .required('Please select an account exec.'),
            })
          : undefined
      }
    >
      {({ dirty, values }) => (
        <WebModal
          animated={animated}
          title="New Venue"
          header
          button={() =>
            !!values.placeId && (
              <SubmitButton networkError={!!error} formName="Create Venue">
                Create
              </SubmitButton>
            )
          }
          onRequestClose={requestClose(dirty, goBack)}
        >
          {formHeader}
          <ErrorNote sx={{ mb: 3, zIndex: -1 }} error={error} />
          <FormRow>
            <GooglePlacesField<FormState>
              names={{
                place_id: 'placeId',
                googlePlace: 'googlePlaceInfo',
                location: 'location',
              }}
              query={{
                type: 'establishment',
              }}
              // setPlaceIdOnly
              required
              label="Venue Name"
              placeholder="Search for your venue..."
              Label={Text}
            />

            <Spacer height={3} />
            <View sx={{ zIndex: -1 }}>
              <Text sx={{ color: 'mutedText' }}>
                Search the name and address of your venue as it appears on
                Google Maps.
              </Text>
              <Spacer height={3} />
              {!!values.location ? (
                <Text>{locationToString(values.location)}</Text>
              ) : (
                <Text>No location selected</Text>
              )}
            </View>
          </FormRow>
          {User.isAdmin(me) && (
            <>
              <FormRow sx={{ zIndex: -1 }}>
                <PickerField<FormState>
                  items={venueTypes}
                  name="venueType"
                  label="Venue Type"
                />
              </FormRow>
              <AccountExecPicker />
            </>
          )}
          <View sx={{ zIndex: -1 }}>
            <Spacer height={7} />
          </View>
        </WebModal>
      )}
    </Formik>
  )
}

function AccountExecPicker() {
  const [admins] = useAccountExecsQuery({
    variables: {
      limit: 1000,
      offset: 0,
    },
  })

  return (
    <FormRow sx={{ zIndex: 2 }}>
      <AutoCompleteFieldRequired<Form>
        options={useMemo(
          () =>
            admins.data?.users.map((user) => ({
              value: user.id,
              label: user.name,
            })) || [],
          [admins.data]
        )}
        filter={true}
        shouldSetControlledStateOnPress="label"
        label="Account Exec"
        placeholder="Search an account exec..."
        renderValue={(value) => {
          if (!value) return null

          const user = admins.data?.users?.find((user) => user.id === value)

          if (!user) return null

          return (
            <View sx={{ mt: '$3', zIndex: -1 }}>
              <Text>{user.name} is assigned to this venue.</Text>
            </View>
          )
        }}
        name="accountExecId"
      />
    </FormRow>
  )
}
