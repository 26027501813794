import { useRoute, useNavigation } from '@react-navigation/native'
import { useRef, useEffect, useCallback } from 'react'

type Props<T> = {
  onSelect?: (item: T) => void
  callback: {
    paramKey: string
  }
  title?: string
}

/**
 * Utility for using react navigation modal with a callback, by listening to params.
 */
export default function useOpenModalCallback<T>(
  routeName: string,
  { callback, title, ...props }: Props<T>
) {
  const route = useRoute()
  const { navigate, getState } = useNavigation()

  const onSelect = useRef(props.onSelect)

  const state: T | undefined = route?.params?.[callback.paramKey]

  useEffect(() => {
    if (typeof state !== 'undefined') {
      onSelect.current?.(state)
    }
  }, [state])

  const open = useCallback(
    (params = {}) => {
      const { index, routes } = getState()
      const callbackRouteName = routes[index].name
      // @ts-expect-error RN types
      navigate({
        name: routeName,
        params: {
          callback: {
            routeName: callbackRouteName,
            paramKey: callback.paramKey,
          },
          title,
          ...params,
        },
        merge: true,
      })
    },
    [callback.paramKey, getState, navigate, routeName, title]
  )

  return {
    open,
    state,
  }
}
