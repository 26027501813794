import React, { useRef, useEffect } from 'react'
import { SmallCalendar } from '@beatgig/calendars/components/calendar'
import { BookingsSmallCalendarProps } from './types'
import { useBookingsSmallCalendar } from './methods'
import { View } from 'dripsy'
import { DateTime } from 'luxon'
import useStable from '@beatgig/design/hooks/use-stable'
import isThisMonth from 'date-fns/isThisMonth'
import { useLatestCallback } from '@beatgig/helpers/use-latest-callback'

export function BookingsSmallCalendar(props: BookingsSmallCalendarProps) {
  const {
    markedDates,
    onMonthChange,
    onDayPress,
    minDate,
  } = useBookingsSmallCalendar(props)

  const timeout = useRef(0)

  useEffect(() => {
    const timer = timeout.current
    return () => {
      clearTimeout(timer)
    }
  }, [props.selectedDate])

  const selected = DateTime.fromObject(props.selectedDate)
  const stableSelectedDate = useStable(selected)
  const stableOnMonthChange = useLatestCallback(onMonthChange)
  const stableDayPress = useLatestCallback(onDayPress)
  const handleMonthChange = useLatestCallback<
    NonNullable<
      React.ComponentProps<typeof SmallCalendar>['onVisibleMonthsChange']
    >
  >((months) => {
    if (months?.length === 1) {
      const newMonth = months[0]
      stableOnMonthChange(newMonth)

      const { year, month } = newMonth
      const currentDate = stableSelectedDate.current

      if (year !== currentDate?.year || month !== currentDate?.month) {
        // if we just scrolled to the current month, change to today instead
        const isCurrentMonth = isThisMonth(
          DateTime.fromObject({ year, month }).toJSDate()
        )
        const { day } = DateTime.local()
        stableDayPress({ year, month, day: isCurrentMonth ? day : 1 })
      }
    }
  })

  const current = selected.isValid
    ? selected.set({ hour: 12 }).toFormat('yyyy-MM-dd')
    : undefined

  const { calendarRef } = props

  return (
    <View>
      <SmallCalendar
        markingType="multi-dot"
        calendarRef={calendarRef}
        onVisibleMonthsChange={handleMonthChange}
        current={current}
        markedDates={markedDates}
        onMonthChange={onMonthChange}
        onDayPress={onDayPress}
        minDate={minDate}
      />
    </View>
  )
}
