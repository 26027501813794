import React, { useReducer, useEffect } from 'react'
import { View, styled, Text, useSx } from 'dripsy'


import { Platform } from 'react-native'
import Button from '@beatgig/components/button'

import Spacer from '@beatgig/design/components/spacer'
import Link from '@beatgig/design/components/link'
import { MotiView } from 'moti'
import { APP_NAME } from '@beatgig/constants'
import {
  Target,
  ScrollView as AnchorScrollView,
  useScrollTo,
} from '@nandorojo/anchor'
import useIntercom from '@beatgig/hooks/use-intercom'

const ScrollView = styled(AnchorScrollView)()

export const EmptyBookingRequestListSeller = () => {
  const targetName = (stepIndex: number) => `step-${stepIndex}`
  const fontSize = 3

  const sx = useSx()
  const intercom = useIntercom()

  const steps: {
    linkText: string | null
    content: React.ReactNode
    linkDelay?: number
  }[] = [
    {
      linkText: 'Tell me more',
      content: (
        <Text sx={{ textAlign: 'center', color: 'mutedText', fontSize }}>
          When venues need to fill a date, they create a{' '}
          <Text sx={{ fontSize }}>Booking Request</Text>.{'\n\n'}Those requests
          will show on this screen.
        </Text>
      ),
    },
    {
      linkText: 'Okay...',
      linkDelay: 8000,
      content: (
        <>
          {/* <Spacer height={4} /> */}
          <View sx={{ height: 1, bg: 'mutedText' }} />
          <Spacer height={4} />
          <Text sx={{ textAlign: 'center', fontWeight: 500, fontSize: 4 }}>
            The Venue 🍻
          </Text>
          <Spacer height={4} />
          <MotiView
            transition={Platform.select({ web: { type: 'timing' } })}
            from={{ opacity: 0, translateY: -10 }}
            animate={{ opacity: 1, translateY: 0 }}
            delay={250}
            style={sx({ alignItems: 'center' })}
          >
            <Text sx={{ textAlign: 'center', fontSize }}>
              First, a venue enters their criteria, such as genre, band size,
              and price.
            </Text>
          </MotiView>
          <Spacer height={4} />
          <MotiView
            transition={Platform.select({ web: { type: 'timing' } })}
            from={{ opacity: 0, translateY: -10 }}
            animate={{ opacity: 1, translateY: 0 }}
            delay={5000}
            style={sx({ alignItems: 'center' })}
          >
            <Text sx={{ textAlign: 'center', fontSize }}>
              {`The ${APP_NAME} algorithm uses that info to match an artist (like you!)`}
            </Text>
          </MotiView>
        </>
      ),
    },
    {
      content: (
        <>
          {/* <Spacer height={4} /> */}
          <View sx={{ height: 1, bg: 'mutedText' }} />
          <Spacer height={4} />
          <Text sx={{ textAlign: 'center', fontWeight: 500, fontSize: 4 }}>
            The Artist 🎤
          </Text>
          <Spacer height={4} />
          <MotiView
            transition={Platform.select({ web: { type: 'timing' } })}
            from={{ opacity: 0, translateY: -10 }}
            animate={{ opacity: 1, translateY: 0 }}
            delay={250}
            style={sx({ alignItems: 'center' })}
          >
            <Text sx={{ textAlign: 'center', fontSize }}>
              If the artist is available, they enter a bid with their price to
              perform.
            </Text>
          </MotiView>
          <Spacer height={4} />
          <MotiView
            transition={Platform.select({ web: { type: 'timing' } })}
            from={{ opacity: 0, translateY: -10 }}
            animate={{ opacity: 1, translateY: 0 }}
            delay={5000}
            style={sx({ alignItems: 'center' })}
          >
            <Text sx={{ textAlign: 'center', fontSize }}>
              {`...& if the venue accepts the artist's bid, then the show is confirmed!`}
            </Text>
          </MotiView>
        </>
      ),
      linkText: 'Have more questions?',
      linkDelay: 8500,
    },
    {
      linkText: null,
      content: (
        <>
          <View sx={{ height: 1, bg: 'mutedText' }} />
          <Spacer height={4} />
          <Text sx={{ textAlign: 'center', fontWeight: 500, fontSize: 4 }}>
            {`We're here to help!`}
          </Text>
          <Spacer height={4} />
          <MotiView
            transition={{
              type: 'timing',
              translateY: {
                type: 'timing',
                loop: true,
                duration: 2000,
              },
            }}
            from={{ opacity: 0, translateY: -10 }}
            animate={{ opacity: 1, translateY: 0 }}
            delay={250}
            style={sx({ alignItems: 'center' })}
          >
            <Button
              onPress={() =>
                intercom.showNewMessage(
                  'Hey! I had some questions about booking requests.'
                )
              }
            >
              Live Chat Us
            </Button>
          </MotiView>
        </>
      ),
    },
  ]

  const { scrollTo } = useScrollTo()

  const maxSteps = steps.length

  const [stepIndex, nextStep] = useReducer(
    (step) => (step + 1 <= maxSteps ? step + 1 : step),
    0
  )

  useEffect(() => {
    scrollTo(targetName(stepIndex))
  }, [stepIndex, scrollTo])

  const canAdvanceStep = stepIndex + 1 < maxSteps

  const spacer = <Spacer height={3} />

  return (
    <View sx={{ p: [3, 4], flex: 1, mt: 4, pb: 6 }}>
      <Text sx={{ fontSize: 5, fontWeight: 500, textAlign: 'center' }}>
        No booking requests (yet).
      </Text>
      <Spacer height={3} />

      {steps.map((step, i) => (
        <React.Fragment key={i}>
          <Target name={targetName(i)} />
          {i <= stepIndex && (
            <>
              {step.content}
              <Spacer height={4} />
              {i === stepIndex && canAdvanceStep && !!step.linkText && (
                <MotiView
                  key={`${stepIndex}`}
                  from={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  // exit={{ opacity: 0 }}
                  transition={{ delay: step.linkDelay ?? 1000 }}
                  exitTransition={{ delay: 0 }}
                >
                  <Link
                    sx={{ alignSelf: 'center' }}
                    onPress={nextStep}
                    block
                    color
                    iconName="arrow-down"
                  >
                    {step.linkText}
                  </Link>
                </MotiView>
              )}
            </>
          )}
        </React.Fragment>
      ))}
    </View>
  )
}
