import { FirstArg, renameRequestBody, RequestBody } from './types'
import { RecommendedBookingsService } from '@beatgig/api-services/recommended-booking'
import { swr } from '@beatgig/providers/swr/cache'

export class RecommendedBookingApi {
  static get createRecommendedBooking() {
    return renameRequestBody(
      RecommendedBookingsService.createRecommendedBookings,
      'recommendedBooking'
    )
  }
  // static createRecommendedBooking({
  //   recommendedBooking,
  //   ...rest
  // }: RenameRequestBody<
  //   typeof RecommendedBookingsService.createRecommendedBookings,
  //   'recommendedBooking'
  // >) {
  //   return RecommendedBookingsService.createRecommendedBookings({
  //     requestBody: recommendedBooking,
  //     ...rest,
  //   })
  // }
  static get getRecommendedBooking() {
    return RecommendedBookingsService.getRecommendedBooking
  }
  static getRecommendedBookingSWRKey({
    recommendedBookingId,
  }: FirstArg<typeof RecommendedBookingApi.getRecommendedBooking>) {
    type Key = [id: string, key: 'recommended-booking']
    const key: Key = [recommendedBookingId, 'recommended-booking']

    return key
  }
  static refetchRecommendedBookingSWR(
    keyProps: FirstArg<typeof RecommendedBookingApi['getRecommendedBooking']>
  ) {
    return swr.mutate(
      RecommendedBookingApi.getRecommendedBookingSWRKey(keyProps)
    )
  }
  // static mutateRecommendedBookingSWRCache(
  //   data: Unpromisify<
  //     ReturnType<typeof RecommendedBookingApi['getRecommendedBooking']>
  //   >,
  //   shouldRevalidate = false
  // ) {
  //   return mutate(
  //     RecommendedBookingApi.getRecommendedBookingSWRKey({
  //       recommendedBookingId: data.display_recommended_booking.id,
  //     }),
  //     data,
  //     shouldRevalidate
  //   )
  // }
  static get getInputRecommendedBooking() {
    return RecommendedBookingsService.getRecommendedBookingUpdate
  }
  static getInputRecommendedBookingSWRKey({
    recommendedBookingId,
  }: FirstArg<typeof RecommendedBookingApi['getInputRecommendedBooking']>) {
    return [recommendedBookingId, 'input-recommended-booking-swr-key']
  }
  // static getRecommendedBookingsForVenue(
  //   props: FirstArg<
  //     typeof RecommendedBookingsService.getre
  //   >
  // ) {
  //   return RecommendedBookingBuyerService.getRecommendedBookingsForVenue(props)
  // }
  // static recommendedBookingsForVenueSWRKey({
  //   // lastDocId,
  //   // limit,
  //   venueId,
  // }: FirstArg<typeof RecommendedBookingApi.getRecommendedBookingsForVenue>) {
  //   return [venueId, 'recommended-bookings-for-venue'] as const
  // }
  // static revalidateRecommendedBookingsForVenueSWR({
  //   venueId,
  // }: FirstArg<typeof RecommendedBookingApi.recommendedBookingsForVenueSWRKey>) {
  //   if (!venueId) return

  //   return mutate([
  //     ...RecommendedBookingApi.recommendedBookingsForVenueSWRKey({ venueId }),
  //   ])
  // }
  static editRecommendedBooking(
    recommendedBookingId: FirstArg<
      typeof RecommendedBookingsService.updateRecommendedBooking
    >['recommendedBookingId'],
    recommendedBooking: RequestBody<
      typeof RecommendedBookingsService.updateRecommendedBooking
    >
  ) {
    return RecommendedBookingsService.updateRecommendedBooking({
      requestBody: recommendedBooking,
      recommendedBookingId,
    })
  }
  static get cancelRecommendedBooking() {
    return RecommendedBookingsService.cancelRecommendedBooking
  }
  static get acceptRecommendedBooking() {
    return RecommendedBookingsService.acceptRecommendedBooking
  }
  static get rejectRecommendedBooking() {
    return RecommendedBookingsService.rejectRecommendedBooking
  }
  // static get getRecommendedBookingsByUserId(
  // ) {
  //   return RecommendedBookingsService.getRecommendedBookingsByUserId
  // }
  // static getRecommendedBookingsByUserIdSWRKey({
  //   userId,
  //   venueId,
  // }: FirstArg<typeof RecommendedBookingApi.getRecommendedBookingsByUserId>) {
  //   return [userId, venueId, 'recommended-bookings-by-user-id'] as const
  // }
  // static get getArtistsFromRecommendedBooking() {

  //   return RecommendedBookingsService.getArtistsFromRecommendedBooking
  // }
  // static getArtistsFromRecommendedBookingSWRKey({
  //   recommendedBookingId,
  // }: FirstArg<
  //   typeof RecommendedBookingApi['getArtistsFromRecommendedBooking']
  // >) {
  //   return [recommendedBookingId, 'artists-from-recommended-booking'] as const
  // }
}
