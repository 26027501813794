import useCloseModalCallback from './use-close-modal-callback'
import useOpenModalCallback from './use-open-modal-callback'
import { useCallback } from 'react'
import { useRoute } from '@react-navigation/native'
import { ImageSmall } from '@beatgig/api-services/image'

type Images = ImageSmall[]

export function useOpenImagePickerModal(
  paramKey: string,
  {
    onSelect,
    suggestedImages,
    title,
  }: {
    onSelect: (images: ImageSmall[]) => void
    suggestedImages?: Images
    title?: string
  }
) {
  const { open, state } = useOpenModalCallback<Images>('ImagePicker', {
    callback: {
      paramKey,
    },
    onSelect,
    title,
  })

  const connectedOpen = useCallback(
    (params = {}) => {
      return open({
        ...params,
        suggestedImages,
      })
    },
    [open, suggestedImages]
  )

  return {
    open: connectedOpen,
    images: state,
  }
}

export function useSubmitImagePickerModal() {
  const close = useCloseModalCallback<Images>()
  const params = useRoute().params as { suggestedImages: Images } | undefined

  return {
    ...close,
    suggestedImages: params?.suggestedImages,
  }
}
