import React from 'react'
import { View, Container } from 'dripsy'
import { User } from '@beatgig/api/user'
import Note from '@beatgig/design/components/note'
import { Platform } from 'react-native'
import useMyAccount from '@beatgig/auth-hooks/use-my-account'
import { sellerInviteOnlyText } from '@beatgig/auth-components/sellers-invite-only/text'

type Props = {
  sx?: React.ComponentProps<typeof View>['sx']
}

export default function UserProfilePendingBanner({ sx }: Props) {
  const user = useMyAccount().data

  if (User.isApproved(user) || !User.hasOnboarded(user)) return null

  let text = ''

  if (User.isSeller(user)) {
    text = sellerInviteOnlyText
  } else if (User.isBuyer(user)) {
    text = `Your account is pending approval. We will reach out to you via phone or email. In the meantime, you can like and search artists.`
  }

  return (
    <View
      sx={{
        p: Platform.select({ web: 3 }),
        ...sx,
      }}
    >
      <Container>
        <Note variant="text" filling="filled" label="Account Pending">
          {text}
        </Note>
      </Container>
    </View>
  )
}
