import { getBookingRequestStatusColor } from '@beatgig/hooks/use-booking-request-status-to-color'
import { useMemo } from 'react'
import { SmallCalendarMarkings } from '@beatgig/calendars/components/calendar'
import { DateTime } from 'luxon'
import { BookingsSmallCalendarProps } from './types'
import useTheme from '@beatgig/theme/use-theme'
import useBookingStatusToColor from '@beatgig/hooks/use-booking-status-to-color'
import useConsoleLog from '@beatgig/hooks/use-log'
import { WhatShouldIDoAlert } from '@beatgig/api-services'
import isFuture from 'date-fns/isFuture'
import { useLatestCallback } from '@beatgig/helpers/use-latest-callback'

const DATE_STRING = 'yyyy-MM-dd'

export function useBookingsSmallCalendar(props: BookingsSmallCalendarProps) {
  const {
    selectedDate,
    bookings,
    externalBookings,
    userRole,
    bookingRequests,
  } = props
  const { year, day, month } = selectedDate
  const isSelected = !!selectedDate
  const { colors } = useTheme()

  const selectedDateString = DateTime.fromObject({ year, day, month }).toFormat(
    DATE_STRING
  )
  useConsoleLog('useBookingsSmallCalendar', { selectedDateString })

  const { bookingStatusToColor } = useBookingStatusToColor()

  const events = useMemo<SmallCalendarMarkings>(() => {
    const events: Array<
      | (NonNullable<BookingsSmallCalendarProps['externalBookings']>[number] & {
          eventType: 'external'
        })
      | (NonNullable<BookingsSmallCalendarProps['bookings']>[number] & {
          eventType: 'booking'
        })
      // | (DisplayRecommendedBooking & { eventType: 'recommended' })
      | (NonNullable<BookingsSmallCalendarProps['bookingRequests']>[number] & {
          eventType: 'request'
        })
    > = []

    if (bookings) {
      events.push(
        ...bookings.map((booking) => ({
          ...booking,
          eventType: 'booking' as const,
        }))
      )
    }
    if (externalBookings) {
      events.push(
        ...externalBookings.map((event) => ({
          ...event,
          eventType: 'external' as const,
        }))
      )
    }
    if (bookingRequests) {
      events.push(
        ...bookingRequests.map((event) => ({
          ...event,
          eventType: 'request' as const,
        }))
      )
    }

    const markings: SmallCalendarMarkings = {}

    events.forEach((booking) => {
      const { start_time } = booking
      if (start_time) {
        const dateString = DateTime.fromJSDate(new Date(start_time)).toFormat(
          DATE_STRING
        )

        const selected = dateString === selectedDateString && isSelected

        const marking: SmallCalendarMarkings[string] = {
          ...(markings[dateString] || {
            dots: [],
            selected,
          }),
        }

        let dot: SmallCalendarMarkings[string]['dots'][number] | undefined

        const isEventInTheFuture = isFuture(new Date(booking.start_time))

        if (booking.eventType === 'external') {
          dot = {
            color: colors.text,
            key: booking.id,
          }
        } else if (booking.eventType === 'request') {
          const { color } = getBookingRequestStatusColor(colors, {
            status: booking.status,
            isActionRequired: booking.isActionRequired,
            isPastEvent: booking.isPast,
          })

          dot = {
            color,
            key: booking.id,
          }
        } else if (booking.eventType === 'booking') {
          const isActionRequired =
            booking.what_should_i_do_alerts?.[userRole] ===
              WhatShouldIDoAlert.ACTION_REQUIRED && isEventInTheFuture
          const { variant } = bookingStatusToColor(booking.status, {
            isActionRequired,
          })
          dot = {
            color: colors[variant],
            key: booking.id,
          }
        }

        if (dot) {
          dot.selectedDotColor = colors.background
          marking.dots.push(dot)
        }

        markings[dateString] = marking
      }
    })

    if (isSelected && !markings[selectedDateString]) {
      markings[selectedDateString] = {
        selected: true,
        dots: [],
      }
    }

    const maxDotsPerDay = 5

    Object.keys(markings).forEach((dateString) => {
      const marking = markings[dateString]
      if (marking.dots.length > maxDotsPerDay) {
        marking.dots = marking.dots.slice(0, maxDotsPerDay)
      }
    })

    return markings
  }, [
    bookingRequests,
    bookingStatusToColor,
    bookings,
    colors,
    externalBookings,
    isSelected,
    selectedDateString,
    userRole,
  ])

  const onMonthChange = useLatestCallback(props.onMonthChange)

  const onDayPress = useLatestCallback(props.onPressDate)

  return {
    markedDates: events,
    onMonthChange,
    onDayPress,
    minDate: undefined,
  }
}
