import React, { useContext } from 'react'
import { View } from 'dripsy'
import EntityContext, { EntityContext as ContextType } from './entity-context'
import Sizer from '@beatgig/components/sizer'

type Props = Pick<ContextType, 'indent' | 'indentSize'> & {
  children?: React.ReactNode
} & React.ComponentProps<typeof View>

export default function EntityFooter(props: Props) {
  const { children, indent, indentSize, sx = {}, ...view } = props

  const maybeContext = useContext(EntityContext)

  const shouldIndent = indent ?? maybeContext?.indent
  const indentWidth = indentSize ?? maybeContext?.indentSize

  if (!children) return null

  return (
    <View sx={{ flexDirection: 'row' }}>
      {!!shouldIndent && (
        <Sizer {...{ width: indentWidth, mr: 3 }} display={['none', 'flex']} />
      )}
      <View
        sx={{
          flex: 1,
          borderTopWidth: '1',
          borderColor: 'border',
          mt: 3,
          pt: 3,
          ...sx,
        }}
      >
        {children}
      </View>
    </View>
  )
}
