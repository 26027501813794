import defaultBreakpoints from '@beatgig/theme/breakpoints'

export const getBreakpoint = (width: number) => {
  const breakpointPixels = [...defaultBreakpoints]
    .reverse()
    .find((breakpoint) => width >= breakpoint)

  let breakpointIndex = defaultBreakpoints.findIndex(
    (breakpoint) => breakpointPixels === breakpoint
  )
  breakpointIndex = breakpointIndex === -1 ? 0 : breakpointIndex + 1
  return breakpointIndex
}
