import { Box } from '@beatgig/design/components/box'
import { useSyncdExternalCalendarQuery } from '@beatgig/gql'
import { Text } from '@beatgig/design/components/text'
import Spacer from '@beatgig/design/components/spacer'
import Button from '@beatgig/components/button'
import { useEffect, useState } from 'react'
import { PromoStudioPanel } from '@beatgig/ui/promo-studio/panel'
import { PromoStudioIconButton } from '@beatgig/ui/promo-studio/icon-button'
import { useUserGoogleCalendarAuth } from '@beatgig/features/external-calendar/oauth/use-user-google-calendar-auth'
import useToast from '@beatgig/design/hooks/use-toast'
import { SignInWithGoogleButton } from './google-button'
import { User } from '@beatgig/api/user'
import useMyAccount from '@beatgig/auth-hooks/use-my-account'

export function SellerSyncCalendar() {
  const [_isExpanded, setIsExpanded] = useState<null | boolean>(null)
  const [user] = useSyncdExternalCalendarQuery({
    requestPolicy: 'cache-first',
  })

  const isAdmin = User.isAdmin(useMyAccount().data)

  const shouldSyncCalendar =
    user.data?.me.__typename == 'User' && !user.data.me.hasSyncedCalendar

  const isExpanded = _isExpanded ?? shouldSyncCalendar

  const Google = useUserGoogleCalendarAuth()

  const { toast } = useToast()

  useEffect(
    function onGoogleSuccess() {
      if (Google.success) {
        toast('Google calendar successfully added.')
      }
    },
    [Google.success, toast]
  )

  if (!Google.request || !isAdmin) return null

  return (
    <Box bg="background">
      <PromoStudioPanel
        action={<PromoStudioIconButton size={22} name="calendar" />}
        title="Sync your calendar"
        onPress={() =>
          setIsExpanded((state) => {
            if (state === null) return !isExpanded

            return !state
          })
        }
      >
        {isExpanded && (
          <Box mx="$2" p="$3" borderRadius="$3" bg="muted" mt="$1">
            <Text color="mutedText">
              {shouldSyncCalendar
                ? `Keep your bookings in-sync with your other events by connecting your Google Calendar.`
                : `You successfully sync'd your calendar.`}
            </Text>
            {shouldSyncCalendar && (
              <>
                <Spacer y="$3" />
                <SignInWithGoogleButton
                  onPress={Google.prompt}
                  disabled={user.stale}
                />
              </>
            )}
            {null && (
              <Button
                onPress={Google.prompt}
                color="blue"
                iconLeft={{ name: 'logo-google' }}
                loading={Google.isLoading}
              >
                {shouldSyncCalendar
                  ? `Connect Google Calendar`
                  : 'Connect Another Calendar'}
              </Button>
            )}
          </Box>
        )}
      </PromoStudioPanel>
    </Box>
  )
}
