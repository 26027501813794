import { User } from '@beatgig/api/user'
import useMyAccount from '@beatgig/auth-hooks/use-my-account'
import { Box } from '@beatgig/design/components/box'
import { UserRole } from '@beatgig/gql'
import { BookingRequestsSellerListSmart } from '@beatgig/ui/booking-requests/seller-requests-list'
import { SellerSyncCalendar } from './sync-calendar'

export function SellerCalendarSidebar({
  userId,
  bookingRequestId,
  userRole,
}: {
  userId: string
  bookingRequestId?: string
  userRole: UserRole
}) {
  const isAdmin = User.isAdmin(useMyAccount().data)

  return (
    <Box flex={1} bg="muted">
      {isAdmin && <SellerSyncCalendar />}
      <Box flex={1}>
        <BookingRequestsSellerListSmart
          highlightedBookingRequestId={bookingRequestId || null}
          userId={userId}
          userRole={userRole}
        />
      </Box>
    </Box>
  )
}
