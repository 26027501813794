import {
  SelectedCalendarVenueSlugs,
  UseSelectedCalendarVenueSlugs,
} from './types'
import Router, { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import useStable from '@beatgig/design/hooks/use-stable'

const PARAM_KEY = 'venue'

export const useSelectedCalendarVenueSlugs: UseSelectedCalendarVenueSlugs = () => {
  const router = useRouter()

  const { query = {} } = router || {}

  const urlParam = query[PARAM_KEY]

  const visibleVenueSlugs = useMemo(() => {
    let visibleVenueSlugs: SelectedCalendarVenueSlugs = null

    const venueSlugsFromURL = urlParam

    if (venueSlugsFromURL) {
      visibleVenueSlugs = Array.isArray(venueSlugsFromURL)
        ? venueSlugsFromURL
        : [venueSlugsFromURL]
    }

    return visibleVenueSlugs
  }, [urlParam])

  const stableVisibleVenueSlugs = useStable(visibleVenueSlugs)

  const setVisibleVenueSlugs = useCallback<
    ReturnType<UseSelectedCalendarVenueSlugs>['setVisibleVenueSlugs']
  >(
    (stateFactory) => {
      let nextState: SelectedCalendarVenueSlugs = null
      if (typeof stateFactory === 'function') {
        nextState = stateFactory(stableVisibleVenueSlugs.current)
      } else {
        nextState = stateFactory
      }
      const { query = {} } = Router

      const next = { ...query }
      next[PARAM_KEY] = nextState || []

      Router.push(
        {
          pathname: Router.pathname,
          query: next,
        },
        undefined,
        {
          shallow: true,
        }
      )
    },
    [stableVisibleVenueSlugs]
  )

  return {
    visibleVenueSlugs,
    setVisibleVenueSlugs,
  }
}
