import { BookingFlow, BookingStatusV2 } from '@beatgig/gql'
import { useAccountExecVenuesFilter } from './use-account-exec-venues-filter'
import useAlgoliaBookingFilters from './use-admin-calendar-filters'
import { useBookingFlowFilter } from './use-booking-flow-filter'
import useBookingStatusFilter from './use-booking-status-filter'
import useChargedBuyerFilter from './use-charged-booking-filter'
import usePaidSellerFilter from './use-paid-seller-filter'
import { useAdminCalendar } from './use-query'
import { useQueryAdminCalBy } from './use-query-admin-calendar-by'
import { useBookingSlugsFilter } from './use-slug-filter'

export function useFilteredBookingsFromAdmin() {
  const filters = useAlgoliaBookingFilters()
  const bookingStatusFilters = useBookingStatusFilter()
  const chargedBuyerFilter = useChargedBuyerFilter()
  const paidSellerFilter = usePaidSellerFilter()
  const bookingFlowFilter = useBookingFlowFilter()
  const accountExecVenuesFilter = useAccountExecVenuesFilter()

  const artistFilter = useBookingSlugsFilter()
  const venueFilter = useBookingSlugsFilter()

  const searchQueryBy = useQueryAdminCalBy().by

  const { handlers, query, graphqlDateRangeFilter } = filters
  const calendarQuery = useAdminCalendar({
    dateRange: graphqlDateRangeFilter,
    accountExecSlugs: accountExecVenuesFilter.selectedAccountExecSlugs,
    artistQueryName: searchQueryBy == 'artist' ? query : undefined,
    statuses: bookingStatusFilters.graphqlFilter as Array<BookingStatusV2>,
    hasCharge: chargedBuyerFilter.graphqlFilter,
    hasPayout: paidSellerFilter.graphqlFilter,
    venueQueryName: searchQueryBy == 'venue' ? query : undefined,
    bookingFlows: bookingFlowFilter.graphqlFilter as Array<BookingFlow>,
    artistSlugs: artistFilter.slugs,
    venueSlugs: venueFilter.slugs,
  })

  return {
    ...filters,
    calendarQuery,
    handlers,
    bookingStatusFilters,
    chargedBuyerFilter,
    paidSellerFilter,
    bookingFlowFilter,
    accountExecVenuesFilter,
    searchQueryBy,
    artistFilter,
    venueFilter,
  }
}
